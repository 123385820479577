import React, { useEffect, useRef, useState } from "react";
import { ClearCanvas } from "./renderers/ClearCanvas";

// we create a React context with a _null_ default value
const CanvasContext = React.createContext(null);

export const Canvas = ({ children, width, height }) => {
  const canvasRef = useRef(null);
  const [renderingContext, setRenderingContext] = React.useState(null);

  const [redrawRequested, setRedrawRequested] = useState(true);
  useEffect(() => {
    if (canvasRef.current) {
      const context2d = canvasRef.current.getContext("2d");
      setRenderingContext(context2d);
    }
  }, [canvasRef]);

  useEffect(() => {
    setRedrawRequested(false);
  }, [redrawRequested]);

  return (
    <CanvasContext.Provider value={{ renderingContext, setRedrawRequested }}>
      <canvas ref={canvasRef} height={height} width={width} />
      <ClearCanvas />
      {children}
    </CanvasContext.Provider>
  );
};

export const useCanvas = () => {
  const { renderingContext, setRedrawRequested } = React.useContext(
    CanvasContext
  );
  return { renderingContext, setRedrawRequested };
};

import { useState } from "react";
import { Icon } from "../../../commonjs/icons";
import { useTranslation } from "react-i18next";

export const Annotation = ({
  annotation,
  setAnnotation,
  placeholder,
  helpText,
  inline,
}) => {
  const { t } = useTranslation();
  const [isAnnotating, setIsAnnotating] = useState(false);
  const [text, setText] = useState(annotation);

  return (
    <div className={"distributionAnnotation"}>
      {!isAnnotating && (
        <button
          onClick={() => setIsAnnotating(true)}
          title={helpText}
          className={"open-editor " + (inline ? "inline-editor" : "")}
          style={{ float: "left" }}
        >
          {text?.length > 0 ? "..." : ""}
          <Icon icon={"comment-alt-plus"} />
        </button>
      )}
      {isAnnotating && (
        <button
          onClick={() => setIsAnnotating(false)}
          title={t("Cancel this Annotation")}
          className={"open-editor " + (inline ? "inline-editor" : "")}
        >
          {text?.length > 0 ? "..." : ""}
          <Icon icon={"comment-alt-plus"} color={"grey"} />
        </button>
      )}

      {isAnnotating && (
        <div className="annotationEditor">
          <input
            type={"text"}
            value={text || ""}
            placeholder={placeholder}
            onChange={(event) => setText(event.target.value)}
            style={{ opacity: "100%" }}
          />
          <button onClick={() => setIsAnnotating(false)}>
            {t("Cancel Annotation")}
          </button>
          <button
            onClick={() => {
              setAnnotation(text);
              setIsAnnotating(false);
            }}
            style={{ opacity: "100%" }}
          >
            {t("Save Annotation")}
          </button>
        </div>
      )}
    </div>
  );
};

import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { NonStandardProductContext } from "./NonStandardProductContext";
import { useNavigate } from "react-router-dom";
import { named_urls } from "../../../../../routing/known_urls";
import { useShoppingCart, select } from "../../ShoppingCart";
import { shallow } from "zustand/shallow";
import { useDraftOrder } from "../../useDraftOrder";

export const AddProductRow = () => {
  const { t } = useTranslation();

  const { dispatchRowEvent } = useContext(NonStandardProductContext);

  return (
    <div>
      <div
        onClick={(evt) => dispatchRowEvent({ type: "addRow" })}
        className="button button-tertiary buttonAddProduct"
      >
        {t("ADD PRODUCT")}
      </div>
    </div>
  );
};

export const CheckoutButton = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { dispatchRowEvent, hasUnconfirmedPrintData } = useContext(
    NonStandardProductContext
  );

  const {
    shoppingCart,
    clearOrder,
    hasWarnings,
    hasUnacknowledgedWarnings,
    setWarningAcknowledged,
    warningAcknowledged,
  } = useShoppingCart(
    (state) => ({
      shoppingCart: state.shoppingCart,
      clearOrder: state.clearOrder,
      hasWarnings: select.hasWarnings(state),
      hasUnacknowledgedWarnings:
        select.hasWarnings(state) && state.warningAcknowledged === false,
      warningAcknowledged: state.warningAcknowledged,
      setWarningAcknowledged: state.setWarningAcknowledged,
    }),
    shallow
  );

  const tryingToOrderStandardProducts = shoppingCart.reduce(
    (hasStandardProduct, cartEntry) =>
      hasStandardProduct || cartEntry
        ? cartEntry.quantity > 0 &&
        cartEntry.printData === undefined &&
        Number.isInteger(
          cartEntry.quantity / cartEntry.product.labels_per_unit
        )
        : false,
    false
  );

  const resetNonStandardProducts = () => {
    clearOrder();
    dispatchRowEvent({ type: "clearRows" });
  };

  const { canOrder } = useDraftOrder();

  const checkout = () => {
    navigate(named_urls["orders:summary:adressAndDelivery"]);
  };

  return (
    <div className="order__buttons right">
      {hasWarnings && (
        <div className="acknowledge-warning">
          <input
            id="acknowledge-warning"
            type="checkbox"
            onChange={(evt) => {
              setWarningAcknowledged(!warningAcknowledged);
            }}
            value={warningAcknowledged}
          />
          <label htmlFor="acknowledge-warning" className="acknowledged-warning">
            {t(
              "You have partial rolls without label imprint in your order: please check your order positions again and confirm here that this is correct."
            )}
          </label>
        </div>
      )}
      <div
        role="button"
        onClick={resetNonStandardProducts}
        className="button button-tertiary"
      >
        <span>{t("CLEAR ORDER")}</span>
      </div>
      <div
        role="button"
        className={`button button-primary ${canOrder &&
            !hasUnconfirmedPrintData &&
            !tryingToOrderStandardProducts &&
            !hasUnacknowledgedWarnings
            ? "clickable"
            : "button-primary--disabled"
          }`}
        onClick={checkout}
      >
        {t("CHECKOUT")}
      </div>
    </div>
  );
};


import { useRef } from "react";
import { Form, Field } from "react-final-form";
import { RenderField } from "../../commonjs/components";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "react-query";
import { endpoint } from "../../commonjs/endpoints";
import { CountryCodesQuery } from "../orders/order_process/Queries";

export const AddressForm = ({ addressAdded }) => {
  const { t } = useTranslation();
  const formRef = useRef();

  const notify = (msg) => toast.success(msg, { duration: 5000 });
  const { data: countryCodes } = useQuery(CountryCodesQuery);

  const { mutate: saveAddress } = useMutation((address) => {
    endpoint("accounts:api_address", "POST", {
      body: { ...address, category: "DISTRIBUTION" },
    }).then((result) => {
      notify(t("Address added"));
      formRef.current.restart();
      addressAdded({ addressPk: result.pk });
    });
  });

  const validateAddress = (values) => {
    const errors = {};
    if (!values.company_name) errors.company_name = t("Required");
    //if (!values.company_id) errors.company_id = t("Required"); // TODO is this required?
    if (!values.street_address) errors.street_address = t("Required");
    if (!values.city) errors.city = t("Required");
    if (!values.postal_code) errors.postal_code = t("Required");
    if (!values.country_code) errors.country_code = t("Required");

    return errors;
  };

  return (
    <Form onSubmit={saveAddress} validate={validateAddress}>
      {({ handleSubmit, pristine, submitting, invalid, form }) => {
        formRef.current = form;
        return (
          <div className="addressForm">
            <h1>{t("Add New Distribution Address")}</h1>
            <form onSubmit={handleSubmit}>
              <div className="grid__item xs-12">
                <Field
                  name="company_name"
                  component={RenderField}
                  type="text"
                  placeholder={t("Company Name")}
                />
              </div>
              <div className="grid__item xs-12">
                <Field
                  name="company_id"
                  component={RenderField}
                  type="text"
                  placeholder={t(
                    "COFOR(10): min. 6 digits, max. 10 digits without space characters"
                  )}
                />
              </div>
              <div className="grid__item xs-12">
                <Field
                  name="street_address"
                  component={RenderField}
                  type="text"
                  placeholder={t("Street address")}
                />
              </div>
              <div className="grid__item xs-12">
                <Field
                  name="address_details"
                  component={RenderField}
                  type="text"
                  placeholder={t("Address details")}
                />
              </div>
              <div className="grid__item sm-12 md-4">
                <Field
                  name="postal_code"
                  component={RenderField}
                  type="text"
                  placeholder={t("Postal code")}
                />
              </div>
              <div className="grid__item sm-12 md-8">
                <Field
                  name="city"
                  component={RenderField}
                  type="text"
                  placeholder={t("City")}
                />
              </div>
              <div className="grid__item xs-12">
                <Field
                  name="country_code"
                  component="select"
                  className="vertical-spacing-half"
                >
                  {(countryCodes || []).map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.name} ({country.code})
                    </option>
                  ))}
                </Field>
              </div>

              <div className="grid__item xs-12">
                <button
                  type="submit"
                  disabled={pristine || submitting || invalid}
                  className={`button button-primary ${
                    pristine || submitting || invalid
                      ? "button-primary--disabled"
                      : "clickable"
                  }`}
                >
                  {t("ADD ADDRESS")}
                </button>
              </div>
            </form>
          </div>
        );
      }}
    </Form>
  );
};

const hasNote = (product) => {
  return product && product.note && product.note.length > 0;
};

export function sortProducts(products) {
  return products.sort((a, b) => {
    // 1st criterion: product group
    // sort by sort_index provided by the backend
    if (a.product_group.sort_index < b.product_group.sort_index) {
      return -1;
    } else if (a.product_group.sort_index > b.product_group.sort_index) {
      return 1;
    }

    // both products are within the same product group

    // 1a criterion: note
    // move products with a note to the end
    if ((hasNote(a) && !hasNote(b)) || (!hasNote(a) && hasNote(b))) {
      return hasNote(a) ? 1 : -1;
    }
    // 1b criterion: Rewind (causing extra-fees)
    // move products with rewind flag to the end, but in front of products with a note
    if (a.rewind_flag !== b.rewind_flag) {
      return a.rewind_flag ? 1 : -1;
    }
    // 2nd criterion: label geometry
    if (a.label_length < b.label_length) {
      return -1;
    } else if (a.label_length > b.label_length) {
      return 1;
    }
    if (a.label_width < b.label_width) {
      return -1;
    } else if (a.label_width > b.label_width) {
      return 1;
    }
    // 3rd criterion: number of labels
    if (a.labels_per_unit < b.labels_per_unit) {
      return -1;
    } else if (a.labels_per_unit > b.labels_per_unit) {
      return 1;
    }
    // 4th criterion: description
    if (!(a.description || b.description)) {
      return 0;
    } else {
      return a.description > b.description ? 1 : -1;
    }
  });
}

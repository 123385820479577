import React from "react";

import { useAuthentication } from "../../../../commonjs/AuthenticationContext";
export const ProductListContext = React.createContext({});

export const ProductListContextProvider = ({
  products,
  shopSettings,
  children,
}) => {
  const { companyProfile } = useAuthentication();

  // Customer is blocked generally or has single products that are blocked: Either way he cannot order
  const blockedDueToUnpaidBills = companyProfile.blocked_for_ordering;
  const blockedDueToNonActivatedProducts = products.find(
    (product) => product.blocked
  );
  const blockedDueToInactivity = companyProfile.blocked_completely;
  const blocked =
    blockedDueToUnpaidBills ||
    blockedDueToNonActivatedProducts ||
    blockedDueToInactivity;

  const contextValues = {
    products,
    shopSettings,
    blocked,
    blockedDueToUnpaidBills,
    blockedDueToNonActivatedProducts,
    blockedDueToInactivity,
  };

  return (
    <ProductListContext.Provider value={contextValues}>
      {children}
    </ProductListContext.Provider>
  );
};

import { create } from "zustand";

export const useRepeatOrder = create((set) => ({
  orderPositions: [],
  addOrderPosition: (orderPosition) =>
    set((state) => state.orderPositions.push(orderPosition)),
  addOrderPositions: (orderPositions) =>
    set((state) => ({
      orderPositions: state.orderPositions.concat(orderPositions),
    })),
  removeOrderPosition: (productPk) =>
    set((state) => ({
      orderPositions: state.orderPositions.filter(
        (entry) => entry.product.pk !== productPk
      ),
    })),
  clearRepeatOrder: () => set({ orderPositions: [] }),
}));
import React from "react";

import { withTranslation } from "react-i18next";

export const ContactInformation = withTranslation()(({ t }) => (
  <section className="contact-information">
    <h1>{t("CONTACT INFORMATION")}</h1>

    <table>
      <tbody>
        <tr className="contact-information__header-line">
          <td>scribos® GmbH</td>
          <td>
            <span>scribos GmbH</span>
            <span>Sickingenstr. 65</span>
            <span>69126 Heidelberg Germany</span>
          </td>
        </tr>
        <tr>
          <td>{t("Homepage")}</td>
          <td>www.scribos.com</td>
        </tr>
        <tr>
          <td>{t("Commercial register")}</td>
          <td>Heidelberg HRB 337200</td>
        </tr>
        <tr>
          <td>{t("D-U-N-S")}</td>
          <td>34-441-9564</td>
        </tr>
        <tr>
          <td>{t("VAT No.")}</td>
          <td>DE 813503237</td>
        </tr>
        <tr>
          <td>{t("Bank Name")}</td>
          <td>Commerzbank AG Heidelberg</td>
        </tr>
        <tr>
          <td>{t("Bank No.")}</td>
          <td>672 800 51</td>
        </tr>
        <tr>
          <td>{t("Bank Account No.")}</td>
          <td>4 800 114 00</td>
        </tr>
        <tr>
          <td>{t("IBAN")}</td>
          <td>DE79 6728 0051 0480 0114 00</td>
        </tr>
        <tr>
          <td>{t("SWIFT-BIC")}</td>
          <td>DRESDEFF672</td>
        </tr>
        <tr className="contact-information__header-line">
          <td>{t("Contact")}</td>
          <td>{t("(in English, French or German)")}</td>
        </tr>
        <tr className="contact-information__header-line">
          <td>{t("Order processing")}</td>
          <td>{t("Mr. Rémy Oruzio / Mrs. Yvonne Hering")}</td>
        </tr>
        <tr>
          <td>{t("Telephone")}</td>
          <td>+49 (0)6221 33507-17</td>
        </tr>
        <tr>
          <td>{t("Telefax")}</td>
          <td>+49 (0)6221-33507-18</td>
        </tr>
        <tr>
          <td>{t("Email")}</td>
          <td>customer-service@scribos.com</td>
        </tr>
        <tr className="contact-information__header-line">
          <td>{t("Shipping process")}</td>
          <td>{t("Mrs. Nicole Maier / Mrs. Alexandra Stifanelli")}</td>
        </tr>
        <tr>
          <td>{t("Telephone")}</td>
          <td>+49 (0) 6221 33507 60</td>
        </tr>
        <tr>
          <td>{t("Telefax")}</td>
          <td>+49 (0) 6221 33507 12</td>
        </tr>
        <tr>
          <td>{t("Email")}</td>
          <td>customer-service@scribos.com</td>
        </tr>
      </tbody>
    </table>
  </section>
));

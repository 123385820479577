import { useState, useEffect } from "react";
import { useCanvas } from "../Canvas";

export const LabelImage = ({ src, width, height, opacity }) => {
  const { renderingContext: ctx } = useCanvas();

  const [labelBackground] = useState(new Image());
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    setImageLoaded(false);
    labelBackground.onload = () => setImageLoaded(true);
    labelBackground.src = src;
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  if (ctx && imageLoaded) {
    ctx.globalCompositeOperation = "destination-over";
    ctx.globalAlpha = opacity;
    ctx.drawImage(labelBackground, 0, 0, width, height);
    ctx.globalAlpha = 1;
    ctx.globalCompositeOperation = "source-over";
  }
  return null;
};

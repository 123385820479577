import React, { createContext, useContext, useRef } from "react";
import NotificationSystem from "react-notification-system";

export const NotificationContext = createContext();

export const NotificationContextProvider = ({ children }) => {
  const notificationSystemRef = useRef();

  return (
    <NotificationContext.Provider value={notificationSystemRef}>
      <NotificationSystem
        position={"tc"}
        autoDismiss={5}
        ref={notificationSystemRef}
      />
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => {
  const notificationSystem = useContext(NotificationContext);
  return {
    addNotification: (props) =>
      notificationSystem.current.addNotification({
        position: "tc",
        autoDismiss: 5,
        level: "success",
        ...props,
      }),
    notificationSystem: notificationSystem.current,
  };
};

import numeral from "numeral";

// DO NOT REMOVE THE IMPORT - otherwise the application will break for non-english users
// disabling eslint is intentional here
// eslint-disable-next-line
import numeral_fr from "numeral/locales/fr"; // required to add french number formatting

import { format as dateFormatFn, parseISO } from "date-fns";

function format(value, language, format, suffix = "") {
  let result = "";
  if (typeof value === "string") {
    // switch to english - all values in string format (e.g. from Decimal) are in this represenation
    numeral.locale("en");
    // convert string value to numeral (uses number internally - not that good for decimals but sufficient for final results)
    let num = numeral(value);
    // now switch to destination language - it is only possible globally
    numeral.locale(language);
    // create the currency format
    result = num.format(format);
  } else {
    // switch to destination language
    numeral.locale(language);
    result = numeral(value).format(format);
  }
  // switch back to english
  numeral.locale("en");
  return result.concat(suffix);
}

export function formatToFloat(
  value,
  language,
  precision = 2,
  formatString = "0,0."
) {
  for (let i = 0; i < precision; i++) {
    formatString += "0";
  }
  return format(value, language, formatString);
}

export function formatToCurrency(value, language) {
  return format(value, language, "0,0.00", " €");
}

export function formatToInteger(value, language) {
  return format(value, language, "0,0");
}

export function formatToShortDate(value) {
  const toFormat =
    typeof value === "string" || value instanceof String
      ? parseISO(value)
      : value;

  const parsed = dateFormatFn(toFormat, "yyyy-MM-dd");

  return parsed;
}

import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

export const ContactCustomerService = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      {t("Please contact scribos® <customer-service@scribos.com>.")}
    </Fragment>
  );
};

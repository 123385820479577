import { useEffect } from "react";
import { useAuthentication } from "../../../commonjs/AuthenticationContext";
import { useShoppingCart } from "./ShoppingCart";

import { endpoint } from "../../../commonjs/endpoints";
import { useQuery } from "react-query";

import { useDebounce } from "use-debounce";

const emptyDraftOrder = {
  total: 0,
  order_positions: [],
  delivery_costs: 0,
  handling_costs: 0,
  handling_sub_costs_operator: 0,
};

export const useDraftOrder = () => {
  const { account, companyProfile } = useAuthentication();

  const { shoppingCart, customerTransportation } = useShoppingCart((state) => ({
    shoppingCart: state.shoppingCart,
    customerTransportation: state.customerTransportation,
  }));

  const [deboucedShoppingCart] = useDebounce(shoppingCart, 500);

  const fetchDraftOrder = () => {
    return endpoint("orders:api_order_draft", "POST", {
      body: {
        user: account.pk,
        order_positions: shoppingCart.map((cartEntry) => ({
          uuid: cartEntry.rowId,
          product: cartEntry.product.pk,
          quantity: cartEntry.quantity,
          extra_service_data: cartEntry.extra_services,
        })),
        transport_by_customer: customerTransportation,
        billing_address: companyProfile.billing_address.pk,
        delivery_address: companyProfile.delivery_address.pk,
      },
    });
  };

  const {
    data: draftOrder,
    refetch,
    isSuccess,
    isFetching,
  } = useQuery({
    queryKey: ["draftOrder"].join(
      shoppingCart.map(
        (cartEntry) => cartEntry.product.pk + "_" + cartEntry.quantity
      )
    ),
    queryFn: () => fetchDraftOrder(deboucedShoppingCart),
    manual: true,
    enabled: false,
    keepPreviousData: false,
  });

  useEffect(() => {
    if (deboucedShoppingCart.length > 0) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deboucedShoppingCart]);

  const findOrderPosition = (uuid) => {
    return isSuccess &&
      deboucedShoppingCart.length > 0 &&
      deboucedShoppingCart.find((entry) => entry.rowId === uuid)
      ? draftOrder.order_positions.find((position) => position.uuid === uuid)
      : undefined;
  };

  const findProductEntry = (productPk) => {
    return draftOrder
      ? draftOrder.order_positions.find(
          (position) => position.product.pk === productPk
        )
      : undefined;
  };

  return {
    draftOrder: deboucedShoppingCart.length > 0 ? draftOrder : emptyDraftOrder,
    canOrder:
      deboucedShoppingCart.length > 0 && draftOrder
        ? draftOrder.total > 0
        : false,
    findOrderPosition,
    findProductEntry,
    isCalculating: isFetching,
    isCalculated: isSuccess,
    recalculate: refetch,
  };
};

import React, { Fragment } from "react";


import bingMaps from "./to_bing_maps.png";
import googleMaps from "./to_google_maps.png";
import { ClientType } from "./ClientType";
import { getDescription } from "./VerificationStates";
import { useTranslation } from "react-i18next";

const LogEntry = params => {
  const { name, value } = params;

  return (
    <tr valign="middle">
      <td>{name}&nbsp;</td>
      <td>{value}</td>
    </tr>
  );
};
const GroupSeparator = params => {
  const { label } = params;

  return (
    <tr valign="left">
      <td colSpan={2} style={{ fontSize: "1.2em" }}>
        <b>{label}</b>
      </td>
    </tr>
  );
};

const GoogleLink = params => {
  const { lat, lon } = params;
  return (
    <div className={"mapLinks"}>
      <span>Google:</span>
      <a
        href={`https://www.google.com/maps/place/${lat}+${lon}/@${lat},${lon},17z`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={googleMaps} alt="google.cn" />
      </a>
    </div>
  );
};

const BingLink = params => {
  const { lat, lon } = params;

  return (
    <div className={"mapLinks"}>
      <span className="link-label">Bing:</span>
      <a
        href={`https://www.bing.com/ditu/?v=2&cp=${lat}~${lon}&lvl=18&style=r`}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          display: "block",
          height: "16px",
          textDecoration: "none"
        }}
      >
        <img src={bingMaps} alt="cn.bing" />
      </a>
    </div>
  );
};

const Redistribution = ({ data }) => {
  const { t } = useTranslation();

  if (!data) return null;

  return (
    <Fragment>
      <GroupSeparator label={t("Redistribution")} />
      <LogEntry name={t("Company")} value={data.company_name} />
      <LogEntry
        name={t("City/Country")}
        value={data.city + "/" + data.country_code}
      />
    </Fragment>
  );
};

const VlogPopUp = ({ vlog }) => {
  const { t } = useTranslation();

  if (vlog === "loading") {
    return <p>Loading</p>;
  } else if (vlog === null) {
    return <p>No Info Available.</p>;
  } else {
    const { lat, lon } = vlog.position;

    return (
      <table width="100%" className="map-popup-table">
        <tbody>
          <GroupSeparator label={t("Authentication")} />
          <LogEntry
            name={"Timestamp"}
            value={vlog.timestamp.split("Z")[0].replace("T", " ")}
          />
          <LogEntry name="Check" value={getDescription(vlog.status)} />
          <LogEntry
            name="No. of Verifications"
            value={vlog.verification_count}
          />
          <LogEntry
            name="Object Id"
            value={vlog.holospot_id ? "#" + vlog.holospot_id : "N/A"}
          />
          <LogEntry
            name="Client"
            value={
              ClientType[vlog.client_type] +
              ", " +
              vlog.agent +
              "(" +
              vlog.agent_version +
              ")"
            }
          />
          <LogEntry name="Geolocation" value={lat + "," + lon} />
          <LogEntry
            name="Virtual Investigation"
            value={
              <Fragment>
                <GoogleLink lat={lat} lon={lon} />
                <BingLink lat={lat} lon={lon} />
              </Fragment>
            }
          />
          <LogEntry
            name="Geolocation Accuracy"
            value={vlog.accuracy > 0 ? vlog.accuracy + " meters" : "IP based"}
          />
          <GroupSeparator label={"Order"} />
          <LogEntry name={"Company"} value={vlog.attr_order_company_name} />
          <LogEntry
            name={"City/Country"}
            value={vlog.attr_order_city + "/" + vlog.attr_order_country}
          />
          <LogEntry name={"Product Group"} value={vlog.attr_product_group} />
          <Redistribution data={vlog.redistribution} />
        </tbody>
      </table>
    );
  }
};

export default VlogPopUp;

import React, { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { endpoint } from "../../commonjs/endpoints";

import { IfRight } from "../../commonjs/util";
import { useAuthentication } from "../../commonjs/AuthenticationContext";

import { NewsEditor } from "./NewsEditor";
import { Toaster } from "react-hot-toast";

const ButtonWithPlaceholder = ({ link, caption }) => {
  if (link) {
    return (
      <a href={link} target="_blank" rel="noreferrer">
        <div className="button button--invert vertical-spacing">{caption}</div>
      </a>
    );
  } else {
    // placeholder to maintain the design
    return <div className="no-button" />;
  }
};

export const Landing = (props) => {
  const { t, i18n } = useTranslation();
  const { hasRights } = useAuthentication();

  const [showEditor, setShowEditor] = useState(false);

  const { data: news, isLoading } = useQuery("corporate_news", () =>
    endpoint("welcome:api_corporate_news", "GET")
  );

  const queryClient = useQueryClient();

  const { pathname } = useLocation();
  if (pathname !== "/welcome")
    return (
      <Navigate
        to={{ pathname: "/welcome", state: { from: props.location } }}
      />
    );
  const news_text = isLoading ? t("Loading...") : news[i18n.language || "en"];

  const handleUpdatedNews = (updatedNews) => {
    setShowEditor(false);
    queryClient.invalidateQueries({ queryKey: ["corporate_news"] });
  };

  return (
    <section className="landing">
      <h1>{t("NEWS")}</h1>

      <Toaster />
      <section className="grid">
        <div className="grid__item xs-12">
          <div className="news-ticker bg-ticker">
            <header>{t("Corporate")}</header>
            <div className="news-ticker__text-bg">
              {/* eslint-disable-next-line jsx-a11y/no-distracting-elements */}
              <marquee className="news-ticker__text vertical-spacing">
                {news_text}
              </marquee>
            </div>
            <ButtonWithPlaceholder link={news?.link} caption={t("READ MORE")} />
            <IfRight needed={["welcome.*_corporatenews"]} rights={hasRights}>
              <button
                onClick={() => setShowEditor(true)}
                className={"no-button no-button-secondary edit-link"}
              >
                {t("Edit news")}
              </button>
            </IfRight>
          </div>
        </div>
      </section>

      {news && (
        <NewsEditor
          show={showEditor}
          news={news}
          newsUpdated={handleUpdatedNews}
        />
      )}
    </section>
  );
};

import React from "react";

import { withTranslation } from "react-i18next";

export const TemplateAddress = ({ title, address }) => (
  <div>
    <h4>{title}</h4>
    <section>
      <span>{address.street_address}</span>
      <span>
        {address.postal_code} {address.city}
      </span>
      <span>
        {address.country} ({address.country_code})
      </span>
      <span>{address.address_details ? address.address_details : <br />}</span>
    </section>
  </div>
);

const _AddressDelivery = ({ t, objAddress }) => (
  <TemplateAddress title={t("Delivery address")} address={objAddress} />
);
export const AddressDelivery = withTranslation()(_AddressDelivery);

const _AddressBilling = ({ t, objAddress }) => (
  <TemplateAddress title={t("Billing address")} address={objAddress} />
);
export const AddressBilling = withTranslation()(_AddressBilling);

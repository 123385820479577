/* named URLs */
export const named_urls = {
  Welcome: "/welcome",
  accounts: "/accounts",
  "accounts:client_address_confirmation":
    "/accounts/client_address_confirmation/:code",
  "accounts:client_confirmation": "/accounts/client_confirmation/:code",
  "accounts:confirmation": "/accounts/confirmation/:code",
  "accounts:login": "/accounts/login",
  "accounts:password_confirmation":
    "/accounts/password_confirmation/:code/:account_id?",
  "accounts:profile": "/accounts/profile",
  "accounts:register": "/accounts/register",
  "accounts:register_confirm": "/accounts/register_confirm",
  "accounts:reset_expired_password":
    "/accounts/reset_expired_password/:email/:account_id",
  "accounts:reset_password": "/accounts/reset_password",
  contact: "/contact",
  "contact:contact": "/contact/contact",
  "contact:contact_information": "/contact/contact_information",
  distribution: "/distribution",
  "distribution:address_list": "/distribution/addressList",
  "distribution:distribution": "/distribution/distribution",
  information: "/information",
  "information:faq": "/information/faq",
  "information:information": "/information/information",
  statistics: "/statistics/landing",
  "statistics:orderStatistics": "/statistics/orderStatistics",
  "statistics:authentications": "/statistics/authentications",
  "statistics:company_detail": "/statistics/company_detail",
  "statistics:history": "/statistics/history",
  "statistics:id_analysis": "/statistics/id_analysis",
  "statistics:landing": "/statistics/landing",
  "statistics:order_overview": "/statistics/order_overview",
  "statistics:registrations": "/statistics/registrations",
  "statistics:spool_analysis": "/statistics/spool_analysis",
  "statistics:supplier_analysis": "/statistics/supplier_analysis",
  "statistics:volume_status": "/statistics/volume_status",
  orders: "/orders",
  "orders:process": "/orders/order_process",
  "orders:summary": "/orders/order_process/order_summary",
  "orders:summary:adressAndDelivery":
    "/orders/order_process/order_summary/adressAndDelivery",
  "orders:summary:error": "/orders/order_process/order_summary/error",
  "orders:summary:payment": "/orders/order_process/order_summary/payment",
  "orders:summary:thankyou": "/orders/order_process/order_summary/thankyou",
  psa_accounts: "/psa_accounts",
  psa_terms: "/psa_terms",
  "psa_terms:terms": "/psa_terms/terms",
  welcome: "/welcome",
};

/* API (Django) URLs */
export const api_urls = {
  "accounts:api_address": "/accounts/api/addresses/",
  "accounts:api_address_list": "/accounts/api/addresses/",
  "accounts:api_client_address_confirmation":
    "/accounts/api/client_address_confirmation/",
  "accounts:api_client_confirmation": "/accounts/api/client_confirmation/",
  "accounts:api_cofor_list": "/accounts/api/cofors/",
  "accounts:api_company_profile": "/accounts/api/company_profiles/",
  "accounts:api_company_profile_list": "/accounts/api/company_profiles/",
  "accounts:api_confirm_password": "/accounts/api/confirm_password/",
  "accounts:api_confirmation": "/accounts/api/confirmation/",
  "accounts:api_country_list": "/accounts/api/countries/",
  "accounts:api_email_list": "/accounts/api/emails/",
  "accounts:api_login": "/accounts/api/login/",
  "accounts:api_logout": "/accounts/api/logout/",
  "accounts:api_registration": "/accounts/api/registration/",
  "accounts:api_reset_password": "/accounts/api/reset_password/",
  "accounts:api_session_heartbeat": "/accounts/api/session_heartbeat/",
  "accounts:api_session_status": "/accounts/api/session_status/",
  "accounts:api_user": "/accounts/api/users/",
  "accounts:api_user_list": "/accounts/api/users/",
  "accounts:api_users_disable": "/accounts/api/users_disable/",
  "accounts:api_users_enable": "/accounts/api/users_enable/",
  "contact:api_send_message": "/contact/api/send_message/",
  "distribution:api_distribution_list": "/distribution/api/distributions/",
  "distribution:api_spool_list": "/distribution/api/spools/",
  "information:api_document_list": "/information/api/documents/",
  "orders:api_order": "/orders/api/orders/",
  "orders:api_order_draft": "/orders/api/orders/draft",
  "orders:api_order_field_format": "/orders/api/orders/field_format",
  "orders:api_order_list": "/orders/api/orders/",
  "orders:api_order_product_code_format":
    "/orders/api/orders/product_code_format",
  "orders:api_order_repeat": "/orders/api/order_repeat/",
  "orders:api_product_list": "/orders/api/products/",
  "orders:api_quota_update": "/orders/api/quota_update/",
  "orders:api_settings_list": "/orders/api/settings/",
  "orders:api_shopping_cart_list": "/orders/api/shopping_cart/",
  "psa_accounts:supplier_data": "/psa_accounts/supplier_data/",
  "statistics:api_company_detail": "/statistics/api/company_detail/",
  "statistics:api_label_info": "/statistics/api/label_info/",
  "statistics:api_object_id_lookup": "/statistics/api/object_id_lookup/",
  "statistics:api_order_overview_statistics": "/statistics/api/order_overview/",
  "statistics:api_products": "/statistics/api/products/",
  "statistics:api_registration_statistics": "/statistics/api/registrations/",
  "statistics:api_suppliers": "/statistics/api/suppliers/",
  "statistics:api_tags_lookup": "/statistics/api/tags_lookup/",
  "statistics:api_verification_lookup": "/statistics/api/verifications/",
  "statistics:api_verifications": "/statistics/api/verifications/",
  "statistics:api_verifications_download":
    "/statistics/api/verifications/download",
  "welcome:api_corporate_news": "/welcome/api/corporate_news/",
};

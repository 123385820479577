import React from "react";

import { withTranslation } from "react-i18next";
import { format } from "date-fns";
import { remove } from "lodash";
import Query from "../Query";

import {
  ValidCodes,
  ValidState,
  AggregatedStates,
} from "../authentications/Map/VerificationStates";
import { ResultTable } from "./ResultTable";

const formatSupplierData = (data) => {
  const suppliers = data.map((company_entry) => {
    return company_entry.products.map((product_entry) => {
      const product_line = {
        company_name: company_entry.supplier.name,
        cofor10: company_entry.supplier.cofor10,
        country: company_entry.supplier.country_code,
        city: company_entry.supplier.city,
        domain: product_entry.product.domain,
        reference: product_entry.product.reference,
      };

      const authentications = product_entry.authentications;

      const validAuthentications = remove(authentications, (authentication) =>
        ValidCodes.includes(authentication.status)
      );

      // continue HERE: the aggregated line needs to be added back into the data!!!!!
      const aggregated = validAuthentications.reduce(
        (prev, curr) => ({ ...prev, count: prev.count + curr.count }),
        { status: ValidState.status, count: 0 }
      );
      authentications.push(aggregated);
      product_line["status_" + ValidState.status] = aggregated.count;

      AggregatedStates.forEach((state) => {
        const found_status = authentications.find(
          (authentication) => authentication.status === state.status
        );
        product_line["status_" + state.status] = found_status
          ? found_status.count
          : 0;
      });
      return product_line;
    });
  });

  return suppliers.reduce((acc, val) => acc.concat(val), []);
};

const _SupplierTable = (props) => {
  const { params } = props;
  const {
    date_start,
    date_end,
    company_ids,
    customer_references,
    verification_status,
    selected_countries,
    domains,
  } = params;

  return (
    <div>
      <Query
        debug={false}
        query={"statistics:api_verifications"}
        method={"POST"}
        params={{
          aggregation: "by_company_by_product",
          date_start: format(date_start, "yyyy-MM-dd"),
          date_end: format(date_end, "yyyy-MM-dd"),
          company_ids,
          order_countries: selected_countries,
          customer_references,
          verification_status,
          domains,
        }}
      >
        {(result) => (
          <ResultTable
            data={formatSupplierData(result.data)}
            selectedStates={verification_status}
          />
        )}
      </Query>
      <br />
    </div>
  );
};

export const SupplierTable = withTranslation()(_SupplierTable);

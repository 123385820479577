import React from "react";
import ReactModal from "react-modal";

export const ModalAlert = ({ close, message }) => (
	<ReactModal
		isOpen={true}
		onRequestClose={close}
		className="modal__content--alert"
		overlayClassName="modal__overlay"
		bodyOpenClassName="modal__body--open"
		contentLabel="Modal"
		ariaHideApp={false}
	>
		<div onClick={close} className="modal-close" />
		<div className="modal--alert">{message}</div>
	</ReactModal>
);

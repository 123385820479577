////@ts-check
import React from "react";

import { useTranslation } from "react-i18next";
import { NonStandardRows, RowHeader } from "./NonStandardRow";

import { NonStandardProductContextProvider } from "./components/NonStandardProductContext";
import { TotalCosts } from "./components/TotalCosts";
import { CheckoutButton, AddProductRow } from "./components/ActionButtons";

export const NonStandardProducts = (props) => {
  const { t } = useTranslation();

  return (
    <NonStandardProductContextProvider>
      <div className="nonStandardProducts">
        <div className="order-process-description">
          {t(
            "Please fill out the form below to order full rolls with print and partial rolls with or without print."
          )}{" "}
          {t("Costs for Rewinding are included in the service-costs.")}
          {t(
            "To order standard labels without print in rolls, please change to the section STANDARD PRODUCTS above."
          )}
        </div>
        <div className="table">
          <RowHeader />
          <NonStandardRows />
          <AddProductRow />
          <TotalCosts />
          <CheckoutButton />
        </div>
      </div>
    </NonStandardProductContextProvider>
  );
};

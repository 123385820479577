import React from "react";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";
import { i18n } from "../../../i18next";
import { RenderField, RenderCheckboxGroup } from "../../../commonjs/components";
import { isEmail, normalizePhone } from "../utils";
import { E_REGISTER_CASE } from "./defines";

/* 
  register: company details as partial form 
  only to reduce the size of the Register.js file
*/

/* Address form part for delivery and billing address 
   
   delivery address:
     registration case NONE:          all fields can be edited
     registration case COFOR10_FIRST: only address details can be edited
     registration case COFOR10_NEXT:  no field can be edited

   billing address:
     registration case NONE:          all fields can be edited
     registration case COFOR10_FIRST: all fields can be edited
     registration case COFOR10_NEXT:  no field can be edited
*/

const AddressFormPart = ({
  t,
  addressId,
  category,
  countryOptions,
  fieldsDisable,
  addressDetailsDisable,
}) => {
  const categoryMapping = {
    DELIVERY: t("Delivery address"),
    BILLING: t("Billing address"),
  };

  return (
    <div>
      <div className="grid__item xs-12">
        <h4>{categoryMapping[category]}</h4>
      </div>

      <div>
        <div className="grid__item xs-12 md-6">
          <Field
            name={`addresses[${addressId}].street_address`}
            placeholder={t("Street Address")}
            disabled={fieldsDisable}
            component={RenderField}
            type="text"
          />
        </div>

        <div className="grid__item xs-12 md-3">
          <Field
            name={`addresses[${addressId}].postal_code`}
            placeholder={t("Postal Code")}
            disabled={fieldsDisable}
            component={RenderField}
            type="text"
          />
        </div>

        <div className="grid__item xs-12 md-3">
          <Field
            name={`addresses[${addressId}].city`}
            placeholder={t("City")}
            disabled={fieldsDisable}
            component={RenderField}
            type="text"
          />
        </div>

        <div className="grid__item xs-12 md-6">
          <Field
            name={`addresses[${addressId}].country_code`}
            component="select"
            disabled={fieldsDisable}
            className="vertical-spacing"
          >
            {countryOptions.map((x) => (
              <option key={x.code} value={x.code}>
                {x.name} ({x.code})
              </option>
            ))}
          </Field>
        </div>

        <div className="grid__item xs-12">
          <Field
            name={`addresses[${addressId}].address_details`}
            placeholder={t("Address details (Building, Desk, ...)")}
            disabled={addressDetailsDisable}
            component={RenderField}
            type="text"
          />
        </div>
      </div>
    </div>
  );
};

/* Contact form part

   provisioning, commercial
     registration case NONE:          all fields can be edited
     registration case COFOR10_FIRST: no field can be edited
     registration case COFOR10_NEXT:  no field can be edited

   invoice, delivery
     registration case NONE:          all fields can be edited
     registration case COFOR10_FIRST: all fields can be edited
     registration case COFOR10_NEXT:  no field can be edited
*/

const ContactFormPart = ({ t, contactId, category, fieldsDisable }) => {
  const categoryMapping = {
    PROVISIONING: t("Provisioning contact"),
    COMMERCIAL: t("Commercial contact"),
    TS_INVOICE: t("Contact for invoices"),
    TS_DELIVERY: t("Contact for delivery notes"),
  };
  return (
    <div>
      <div className="grid__item xs-12 vertical-spacing-half">
        <h6>{categoryMapping[category]}</h6>
      </div>
      <div className="grid__item xs-12 md-4">
        <Field
          name={`contacts[${contactId}].name`}
          placeholder={t("Name")}
          disabled={fieldsDisable}
          component={RenderField}
          type="text"
        />
      </div>
      <div className="grid__item xs-12 md-4">
        <Field
          name={`contacts[${contactId}].phone`}
          placeholder={t("Phone")}
          disabled={fieldsDisable}
          component={RenderField}
          type="text"
          normalize={normalizePhone}
        />
      </div>
      <div className="grid__item xs-12 md-4">
        <Field
          name={`contacts[${contactId}].email`}
          placeholder={t("Email")}
          disabled={fieldsDisable}
          component={RenderField}
          type="text"
        />
      </div>
    </div>
  );
};

/* Quota form */

export const QuotaForm = ({ quotas, disabled }) => {
  const { t } = useTranslation();

  // use mapping here to get values into the translation process
  const quotaMapping = {
    MOPAR: t("MOPAR"),
    SUSTAINERA: t("SUSTAINera"),
    EUR: t("Eurorepar"),
  };
  const options = Object.keys(quotaMapping).map((name) => ({
    name: name,
    label: quotaMapping[name],
  }));

  return (
    <div>
      <Field
        name={`quotas`}
        component={RenderCheckboxGroup}
        options={options}
        value={quotas}
        type="checkbox"
        disabled={disabled}
      />
    </div>
  );
};

/* register company details form part integrates addresses and contacts parts */

export const validate_company_details = (values) => {
  let errors = {};
  // company details
  if (!values["cofor6"] && !values["accountId"]) {
    const errorText = i18n.t("Option 1 or Option 2 is required");
    errors["cofor6"] = errorText;
    errors["accountId"] = errorText;
  }

  if (values.company_profile) {
    const companyErrors = {};

    if (!values.company_profile.name) companyErrors.name = i18n.t("Required");
    if (!values.company_profile.vat_identification_no)
      companyErrors.vat_identification_no = i18n.t("Required");

    errors.company_profile = companyErrors;
  }

  // addresses
  if (values.addresses) {
    const addressErrorsArray = [];
    values.addresses.forEach((addr, id) => {
      const addressErrors = {};
      if (!addr["street_address"])
        addressErrors.street_address = i18n.t("Required");
      if (!addr["postal_code"]) addressErrors.postal_code = i18n.t("Required");
      if (!addr["city"]) addressErrors.city = i18n.t("Required");
      addressErrorsArray[id] = addressErrors;
    });
    errors.addresses = addressErrorsArray;
  }

  // contacts
  if (values.contacts) {
    const { registerCase } = values;
    const contactErrorsArray = [];
    values.contacts.forEach((contact, id) => {
      const contactErrors = {};
      // COFOR10_FIRST case: accept provisioning and commercial contact as is
      // COFOR10_NEXT case: accept all contacts as is
      if (
        (registerCase === E_REGISTER_CASE.COFOR10_FIRST &&
          (contact.category === "PROVISIONING" ||
            contact.category === "COMMERCIAL")) ||
        registerCase === E_REGISTER_CASE.COFOR10_NEXT
      ) {
        return;
      }
      if (!contact["name"]) contactErrors.name = i18n.t("Required");
      if (!contact["email"]) contactErrors.email = i18n.t("Required");
      if (contact["email"] && !isEmail(contact["email"]))
        contactErrors.email = i18n.t("Invalid email");
      if (!contact["phone"]) contactErrors.phone = i18n.t("Required");
      if (contact["phone"] && contact["phone"].length < 8)
        contactErrors.phone = i18n.t("Please provide at least 8 digits");
      contactErrorsArray[id] = contactErrors;
    });
    errors.contacts = contactErrorsArray;
  }

  // product groups
  if (!(values.quotas && values.quotas.length > 0)) {
    // at least one product group (= quota) must be chosen
    errors.quotas = {
      value: i18n.t("Choose at least one product group"),
    };
  }

  return errors;
};

export const RegisterCompanyDetailsFormPart = ({
  t,
  coforOptions,
  cofor10,
  initialValues,
  countryOptions,
}) => {
  if (coforOptions === undefined) {
    return null;
  }

  const NotFound = (
    <div className="grid__item xs-12">
      <span className="form-error">
        {t(
          "COFOR6 or Account ID not found - please reload the page and enter a valid COFOR6 or Account ID."
        )}
      </span>
    </div>
  );

  const { mode, options } = coforOptions;

  if (mode === undefined) {
    return NotFound;
  }

  // four modes:
  // - COFOR6 (options for company profiles + create new)
  // - COFOR6_RESTRICTED (only create new company profile)
  // - COFOR6_UNKNOWN (only create new company profile)
  // - COFOR10 (a given company profile for selection)
  const modeCofor6 = mode === "COFOR6";
  const modeCofor6Restricted = mode === "COFOR6_RESTRICTED";
  const modeCofor6Unknown = mode === "COFOR6_UNKNOWN";
  const modeCofor10 = mode === "COFOR10";

  if ((modeCofor6 || modeCofor10) && options.length === 0) {
    return NotFound;
  }

  const { registerCase } = initialValues;

  return (
    <div className="captchaComplete">
      <div className="grid__item xs-12 vertical-spacing">
        <h3>{t("Choose company details")}</h3>
      </div>

      {/* Cofor10 selection */}
      <div className="grid__item xs-12 vertical-spacing">
        <Field name="company_profile.cofor10" component="select">
          <option value={""} disabled hidden>
            {t("Please choose...")}
          </option>
          {options.map((x) => (
            <option key={x.index} value={x.index}>
              {x.cofor}, {x.name}, {x.address.street_address},{" "}
              {x.address.postal_code} {x.address.city}, {x.address.country} (
              {x.address.country_code})
            </option>
          ))}
          {modeCofor6 && (
            <option value={"-1"}>{t("None of the above...")}</option>
          )}
          {(modeCofor6Restricted || modeCofor6Unknown) && (
            <option value={"-1"}>{t("Create new company profile...")}</option>
          )}
        </Field>
      </div>

      {/* Cofor10 conditional rendering block, cofor10 is undefined or contains the index into the select (which is a string) */}
      {cofor10 === undefined ? null : (
        <div className="cofor10step">
          <div className="grid__item xs-12 md-12">
            <Field
              name="company_profile.name"
              placeholder={t("Company Name")}
              disabled={registerCase !== E_REGISTER_CASE.NONE}
              component={RenderField}
              type="text"
            />
          </div>
          <div className="grid__item xs-12 md-6">
            <Field
              name="company_profile.vat_identification_no"
              placeholder={t("VAT")}
              disabled={registerCase === E_REGISTER_CASE.COFOR10_NEXT}
              component={RenderField}
              type="text"
            />
          </div>
          {/* Addresses */}
          <div className="grid__item xs-12 vertical-spacing">
            <h3>{t("Addresses")}</h3>
          </div>
          {initialValues.addresses &&
            initialValues.addresses.map((x, id) => {
              // billing addresses (all fields!) can not be edited in COFOR10_NEXT mode
              let fieldsDisable = registerCase === E_REGISTER_CASE.COFOR10_NEXT;
              let addressDetailsDisable =
                registerCase === E_REGISTER_CASE.COFOR10_NEXT;
              if (x.category === "DELIVERY") {
                // delivery addresses can not be edited in COFOR10_FIRST and COFOR10_NEXT mode
                // however, the address details field can be edited in COFOR10_FIRST mode
                fieldsDisable = registerCase !== E_REGISTER_CASE.NONE;
                addressDetailsDisable =
                  registerCase === E_REGISTER_CASE.COFOR10_NEXT;
              }
              return (
                <AddressFormPart
                  t={t}
                  key={id}
                  addressId={id}
                  category={x.category}
                  countryOptions={countryOptions}
                  fieldsDisable={fieldsDisable}
                  addressDetailsDisable={addressDetailsDisable}
                />
              );
            })}
          {/* Contacts */}
          <div className="grid__item xs-12 vertical-spacing">
            <h3>{t("Contacts")}</h3>
          </div>
          {initialValues.contacts &&
            initialValues.contacts.map((x, id) => {
              return (
                /*
                    COFOR10_FIRST: only scribos specific contacts can be edited
                    COFOR10_NEXT: no contact can be edited
                    NONE: all contacts can be edited
                  */
                <ContactFormPart
                  t={t}
                  key={id}
                  contactId={id}
                  category={x.category}
                  fieldsDisable={
                    (registerCase === E_REGISTER_CASE.COFOR10_FIRST &&
                      (x.category === "PROVISIONING" ||
                        x.category === "COMMERCIAL")) ||
                    registerCase === E_REGISTER_CASE.COFOR10_NEXT
                  }
                />
              );
            })}
          {/* Quotas */}
          {(registerCase === E_REGISTER_CASE.NONE ||
            registerCase === E_REGISTER_CASE.COFOR10_FIRST) && (
              <div>
                <div className="grid__item xs-12 vertical-spacing">
                  <h3>{t("Product categories")}</h3>
                  {registerCase === E_REGISTER_CASE.COFOR10_FIRST && (
                    <h6>{t("You will be allowed to order labels for:")}</h6>
                  )}
                </div>
                <QuotaForm
                  quotas={initialValues.quotas}
                  disabled={registerCase === E_REGISTER_CASE.COFOR10_FIRST}
                />
                {registerCase === E_REGISTER_CASE.COFOR10_FIRST && (
                  <div className="grid__item xs-12 vertical-spacing">
                    <h6>
                      {t(
                        "If the authorization(s) do not match with your need, please contact"
                      )}{" "}
                      <a href="mailto:stellantis-secure-label@stellantis.com">
                        stellantis-secure-label@stellantis.com
                      </a>
                    </h6>
                  </div>
                )}
              </div>
            )}
        </div>
      )}
      {/* End conditional cofor10 block */}
    </div>
  );
};

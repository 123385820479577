import { useState, useContext, useMemo, useEffect } from "react";
import { useForm, Field } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { NonStandardProductContext } from "../components/NonStandardProductContext";
import { getExtraServiceFieldsFromProduct, getFilteredExtraServiceFieldsFromProduct } from "../utils";

import {
  printingServiceFieldFullfillsDependencies,
  prepareFormFieldsFromProduct,
} from "../utils";
import { FieldRenderer } from "./FieldDefinitions";
import { FormFooter, CORRECTNESS_CONFIRMED } from "./FormFooter";

// see https://github.com/final-form/react-final-form/issues/348
// slightly adapted: dest field is set to the same value as the source field
const CopyOnFieldChange = ({ src, dest }) => (
  <Field name={dest} subscription={{}}>
    {({ input: { onChange } }) => (
      <OnChange name={src}>
        {(value) => onChange(value)}
      </OnChange>
    )}
  </Field>
);

const ResetOnFieldChange = ({ src, dest, setResetDependencies }) => (
  <Field name={dest} subscription={{}}>
    {({ input: { onChange } }) => (
      <OnChange name={src}>
        {() => {
          onChange(undefined);
          // For some reason form.resetFieldState must be called,
          // otherwise the field display is not resetted.
          // However, calling form.resetFieldState here does not work.
          // Therefore, we are just setting a reset indicator and
          // call it outside of this function.
          // See useEffect below.
          setResetDependencies(true);
        }}
      </OnChange>
    )}
  </Field>
);

export const PrintServiceFields = ({ product }) => {
  const form = useForm();
  const formState = form.getState();
  const { countryCodes } = useContext(NonStandardProductContext);

  // reset indicator that dependent fields should be cleared, see useEffect below
  const [resetDependencies, setResetDependencies] = useState(false);

  const { values } = formState;

  // determine fields depending on another field (required for ResetOnFieldChange below)
  const dependencies = getExtraServiceFieldsFromProduct(product)
    .filter(e => e.dependencies && typeof e.dependencies === "object" && Object.keys(e.dependencies).length)
    .map(e => ({ src: Object.keys(e.dependencies)[0], dest: e.name }));

  // determine fields linked to a source field (required for CopyOnFieldChange below)
  const esFields = getFilteredExtraServiceFieldsFromProduct(product, values);
  const links = esFields.filter(e => e.linked).map(e => ({ src: e.linked, dest: e.name }));

  const fields = useMemo(() => {
    return prepareFormFieldsFromProduct(product, countryCodes);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  // this filters out fields that are dependent on values in other fields 
  // (e.g. the partnumber, which depends on the selected brand)
  const filterFields = (values) => {
    const filteredFields = fields.filter((field) =>
      printingServiceFieldFullfillsDependencies(field, values)
    );
    return filteredFields;
  };

  const printServiceDataComplete = (formState) => {
    // form has no errors except the CORRECTNESS_CONFIRMED
    return (
      Object.keys(formState.errors).filter(
        (key) => key !== CORRECTNESS_CONFIRMED
      ).length === 0
    );
  };

  const confirmed = values[CORRECTNESS_CONFIRMED];

  // Resetting dependent fields after a dependency has changed.
  // Resetting the field value is done in the ResetOnFieldChange component.
  // For some reason form.resetFieldState must be called
  // after an onChange call in ResetOnFieldChange,
  // otherwise the field display is not resetted.
  // However, calling form.resetFieldState in ResetOnFieldChange does not work.
  // Therefore, we are just setting a reset indicator and call it in useEffect.
  useEffect(() => {
    if (resetDependencies) {
      dependencies.map(e => form.resetFieldState(e.dest));
      setResetDependencies(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, [resetDependencies]);

  return (
    <form>
      <div>
        {filterFields(values).map((field, idx) => (
          <FieldRenderer
            field={field}
            key={idx}
            disabled={confirmed}
          />
        ))}
        {/* for linked fields: copy value of src field to dest field */}
        {links.map(({ src, dest }) => (
          <CopyOnFieldChange key={`${src}-${dest}`} src={src} dest={dest} />
        ))}
        {/* for dependent fields: reset dependent field (= dest) if the src field changes */}
        {dependencies.map(({ src, dest }, index) => (
          <ResetOnFieldChange key={`${src}-${dest}-${index}`} src={src} dest={dest} form={form} setResetDependencies={setResetDependencies} />
        ))}
      </div>
      <FormFooter printServiceDataComplete={printServiceDataComplete(formState)} />
    </form >
  );
};

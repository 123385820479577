import React, { Component } from "react";

import { withTranslation } from "react-i18next";


import { compose } from "redux";
import { connect } from "react-redux";

import { DateFilterForQueries } from "../filters/DateFilterForQueries";

import { VerificationsMap } from "../authentications/Map/VerificationsMap";
import { HitCount } from "../authentications/HitCount";
import { SupplierTable } from "./SupplierTable";
import { SupplierFilter } from "./SupplierFilter";
import { ProductFilter } from "./ProductFilter";
import { CountryFilter } from "./CountryFilter";

import { VerificationStatusFilter } from "../authentications/VerificationStatusFilter";
import { DomainFilter } from "../authentications/DomainFilter";
import { Cofor10Filter } from "./Cofor10Filter";

class _SupplierAnalysis extends Component {
  state = {
    date_start: new Date("2018-01-01"),
    date_end: new Date(),
    selectedSuppliers: [],
    selectedProducts: [],
    selectedStates: [],
    selectedCountries: [],
    selectedDomains: [],
  };

  updateDateRange = (date_start, date_end) => {
    this.setState({ date_start, date_end });
  };

  onFilterSuppliers = (suppliers) => {
    this.setState({ selectedSuppliers: suppliers.map((entry) => entry.value) });
  };

  onFilterProducts = (products) => {
    this.setState({ selectedProducts: products.map((entry) => entry.value) });
  };

  onSelectVerificationStatus = (selectedStates) => {
    this.setState({ selectedStates });
  };

  onSelectCountries = (selectedCountries) => {
    this.setState({ selectedCountries });
  };
  onSelectDomain = (domains) => {
    // react-select returns null if no option is selected
    domains = domains || [];
    this.setState({ selectedDomains: domains.map((domain) => domain.value) });
  };

  render() {
    const { t } = this.props;
    const {
      date_start,
      date_end,
      selectedSuppliers,
      selectedProducts,
      selectedStates,
      selectedCountries,
      selectedDomains,
    } = this.state;

    const params = {
      date_start,
      date_end,
      company_ids: selectedSuppliers,
      customer_references: selectedProducts,
      verification_status: selectedStates,
      selected_countries: selectedCountries,
      domains: selectedDomains,
    };

    return (
      <section className={"statistics"}>
        <h1>{t("SUPPLIERS SCAN ANALYSIS")}</h1>
        <div className={"grid query-params filter-panel"}>
          <div className={"grid__item xs-12"}>
            <div className={"grid__item xs-5"}>
              <DateFilterForQueries
                onChange={this.updateDateRange}
                date_start={date_start}
                date_end={date_end}
              />
            </div>
            <div className={"grid__item xs-7"}>
              <VerificationStatusFilter
                onChange={this.onSelectVerificationStatus}
              />
            </div>
          </div>
          <div className={"grid__item xs-12"}>
            <div className={"grid__item xs-5"}>
              <Cofor10Filter filter={this.onFilterSuppliers} />
            </div>
            <div className={"grid__item xs-7"}>
              <SupplierFilter filter={this.onFilterSuppliers} />
            </div>
          </div>
          <div className={"grid__item xs-12"}>
            <div className={"grid__item xs-5"}>
              <ProductFilter filter={this.onFilterProducts} />
            </div>
            <div className={"grid__item xs-7"}>
              <CountryFilter updateFilter={this.onSelectCountries} />
            </div>
          </div>
          <div className={"grid__item xs-12"}>
            <div className={"grid__item xs-5"}>
              <DomainFilter onChange={this.onSelectDomain} />
            </div>
            <div className={"grid__item xs-7"}>&nbsp;</div>
          </div>
        </div>
        <h3>
          <HitCount queryParams={params} />{" "}
          {t("Verification(s) match(es) your criteria")}
        </h3>
        <SupplierTable params={params} />
        <div className="map" style={{ width: "100%", height: "600px" }}>
          <VerificationsMap params={params} options={{ hideLegend: false }} />
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    countryCodes:
      state.settings && state.settings.countryCodes
        ? state.settings.countryCodes
        : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export const SupplierAnalysis = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(_SupplierAnalysis);

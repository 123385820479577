import { useEffect, useCallback, useReducer } from "react";

import { endpoint as fetchEndpoint } from "./endpoints";

export const STATUS = {
  IDLE: "IDLE",
  PENDING: "PENDING",
  REJECTED: "REJECTED",
  RESOLVED: "RESOLVED",
};

const initialState = {
  status: STATUS.IDLE,
  error: null,
  data: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "started":
      return { ...state, status: STATUS.PENDING };
    case "success":
      return {
        ...state,
        status: STATUS.RESOLVED,
        data: action.payload,
      };
    case "error":
      return { ...state, status: STATUS.REJECTED, error: action.payload };
    default:
      throw new Error();
  }
};

async function callFetch(action) {
  const { method, endpoint, data } = action;
  const response = await fetchEndpoint(endpoint, method || "GET", data || {});
  if (response.errors) throw response.errors;
  return response;
}

export const useMutation = (actionCreator) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const mutate = useCallback(
    async (params) => {
      const action = actionCreator(params);
      dispatch({ type: "started" });
      try {
        const result = await callFetch(action);
        dispatch({ type: "success", payload: result });
        return { error: null, data: result };
      } catch (error) {
        dispatch({ type: "error", payload: error });
        return { error, data: null };
      }
    },
    // redefine the function only when the action creator changes
    [actionCreator]
  );

  return [mutate, { ...state }];
};

export const useQuery = (actionCreator, params = {}) => {
  const [refetch, state] = useMutation(actionCreator);

  useEffect(() => {
    refetch(params);
    // Run the effect only when the refetch function or
    // the content of the data object changes (not the object itself).
    // We have to disable the deps check, as eslint wants the params object
    // in the dependency list.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, JSON.stringify(params)]);

  return { ...state, refetch };
};

export const useLazyQuery = (actionCreator) => {
  return useMutation(actionCreator);
};

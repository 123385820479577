import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import { useQuery, useQueryClient } from "react-query";
import scrollToComponent from "react-scroll-to-component";

import { Workspace } from "./components/Workspace";
import { useDialog } from "../orders/modals/useDialog";
import { CRITICAL } from "../orders/modals/useModal";
import { useAuthentication } from "../../commonjs/AuthenticationContext";

import {
  TableHead,
  Cell,
  IconCell,
  ToolsCell,
  StatusCell,
} from "../../commonjs/components/DataTable";
import { endpoint } from "../../commonjs/endpoints";
import { AddressForm } from "./AddressForm";
import { useRef, useState } from "react";

const AddressTableHead = () => {
  const { t } = useTranslation();
  // prettier-ignore
  const columns = [
    { name: "company-name", displayName: t("Company Name"), title: "" },
    { name: "cofor10",      displayName: t("COFOR(10)"),    title: "" },
    { name: "street",       displayName: t("street"),       title: "" },
    { name: "details",      displayName: t("Details"),      title: "" },
    { name: "postal-code",  displayName: t("Postal Code"),  title: "" },
    { name: "city",         displayName: t("City"),         title: "" },
    { name: "country",      displayName: t("Country"),      title: "" },
    { name: "address_status",displayName: t("Status"),       title: "" },
    { name: "tools",        displayName: "",                title: "" }
  ]
  return <TableHead columns={columns} />;
};

const AddressLine = ({
  address,
  highlighted,
  isOwnAddress,
  confirmation,
  addressDeleted,
}) => {
  const { t } = useTranslation();
  const notify = (msg) => toast.success(msg, { duration: 5000 });
  const deleteAddress = () => {
    confirmation({
      confirmed: () => {
        endpoint("accounts:api_address", "DELETE", { param: address.pk }).then(
          () => {
            addressDeleted();
            notify(t("Address has been deleted."));
          }
        );
      },
    });
  };

  const address_state = isOwnAddress
    ? "valid"
    : {
        CLIENT_APPROVAL_REQUESTED: "pending",
        APPROVED: "valid",
        REJECTED: "rejected",
      }[address.state];

  return (
    <tr key={address.pk} className={highlighted ? "highlight" : ""}>
      <Cell value={address.company_name} />
      <Cell value={address.company_id} />
      <Cell value={address.street_address} />
      <Cell value={address.address_details} />
      <Cell value={address.postal_code} />
      <Cell value={address.city} />
      <Cell value={address.country} />
      <StatusCell label={t(address_state)} status={address_state} />
      {isOwnAddress ? (
        <IconCell
          icon="user"
          message={t(
            "Non-distributed rolls must be assigned to your own address"
          )}
        />
      ) : (
        <ToolsCell icon="trash-alt" callback={deleteAddress} />
      )}
    </tr>
  );
};

export const AddressList = () => {
  const { t } = useTranslation();
  const { companyProfile } = useAuthentication();
  const { data: addresses } = useQuery("address_list", () =>
    endpoint("accounts:api_address", "GET")
  );
  const addressListRef = useRef(null);
  const [highlightedAddress, setHighlightedAddress] = useState(undefined);

  const ownAddress = {
    ...companyProfile.delivery_address,
    company_name: companyProfile.name,
    company_id: companyProfile.cofor10,
  };
  const distributionAddresses =
    addresses?.length > 0
      ? addresses.filter((address) => address.category === "DISTRIBUTION")
      : [];

  const queryClient = useQueryClient();
  const handleUpdateAddressList = (props) => {
    setHighlightedAddress(props?.addressPk); // highlights the newly added adress, when present
    queryClient.invalidateQueries({ queryKey: ["address_list"] });
    scrollToComponent(addressListRef.current, {
      offset: 35,
      align: "bottom",
      duration: 1000,
      ease: "out-circ",
    });
  };

  const [WarningDialog, openWarningDialog] = useDialog({ level: CRITICAL });

  return (
    <Workspace
      title={t("ADDRESS LIST")}
      description={t(
        "Please enter all suppliers relevant for the redistribution of rolls here. Your entries will be validated by PSA before they can be used."
      )}
    >
      <WarningDialog title={t("Delete Address")} isCriticalAction={true}>
        <div className="grid__item xs-12">
          {t(
            "You are about to delete an address used for distribution. Are you sure you want to delete this entry?"
          )}
        </div>
      </WarningDialog>

      <table ref={addressListRef}>
        <AddressTableHead />
        <tbody>
          {[ownAddress, ...distributionAddresses].map((address) => (
            <AddressLine
              key={address.pk}
              address={address}
              highlighted={address.pk === highlightedAddress}
              isOwnAddress={address.pk === ownAddress.pk}
              confirmation={openWarningDialog}
              addressDeleted={handleUpdateAddressList}
            />
          ))}
        </tbody>
      </table>
      <br />
      <AddressForm addressAdded={handleUpdateAddressList} />
    </Workspace>
  );
};

import React, { useRef } from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { i18n } from "../../i18next";

import { RenderField, RenderTextArea } from "../../commonjs/components";

import { useMutation } from "react-query";
import { Form, Field } from "react-final-form";
import { named_urls } from "../../routing/known_urls";
import { endpoint } from "../../commonjs/endpoints";
import { toast } from "react-hot-toast";

/* Contact form validation */
const validateContact = (values) => {
  const errors = {};
  if (!values.topic) errors.topic = i18n.t("Please enter a topic");
  if (!values.text) errors.text = i18n.t("Please enter a message");
  return errors;
};

/* Contact form */
const ContactForm = () => {
  const { t } = useTranslation();
  const notify = (msg) => toast.success(msg, { duration: 5000 });
  const formRef = useRef();

  const { mutate: sendMessage } = useMutation(({ topic, text }) => {
    endpoint("contact:api_send_message", "POST", {
      body: { topic, text },
    }).then((result) => {
      notify(t("Message has been sent."));
      formRef.current.restart();
    });
  });

  return (
    <Form onSubmit={sendMessage} validate={validateContact}>
      {({ handleSubmit, pristine, submitting, invalid, form }) => {
        formRef.current = form;
        return (
          <form onSubmit={handleSubmit}>
            <Field
              name="topic"
              type="text"
              component={RenderField}
              placeholder={t("Your topic")}
            />
            <Field
              name="text"
              component={RenderTextArea}
              rows="20"
              placeholder={t("Your message")}
            />
            <button
              type="submit"
              disabled={pristine || submitting}
              className={`button button-primary ${
                pristine || submitting || invalid
                  ? "button-primary--disabled"
                  : "clickable"
              }`}
            >
              {t("SEND")}
            </button>
          </form>
        );
      }}
    </Form>
  );
};

/* Contact page */
export const Contact = () => {
  const { t } = useTranslation();

  return (
    <section className="contact">
      <h1>{t("CONTACT US")}</h1>
      <div className="grid">
        <div className="grid__item xs-12 md-8">
          <ContactForm />
        </div>

        <div className="grid__item xs-12 md-4">
          <div className="grid">
            <Link
              to={named_urls["contact:contact_information"]}
              className="grid__item xs-12 vertical-spacing"
            >
              <div className="o-box vert-sm c-box--primary">
                {t("CONTACT INFORMATION")}
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

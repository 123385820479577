import { useCanvas } from "../Canvas";

export const ClearCanvas = () => {
  const { renderingContext: ctx } = useCanvas();

  if (ctx) {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  }
  return null;
};

export const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "1em",
    border: "2px solid #999 !important"
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      color: data.color
    };
  },
  multiValue: (styles, { data }) => ({
    ...styles,
    minHeight: "1.2em",
    lineHeight: "1.2em",
    backgroundColor: data.color
  }),
  multiValueLabel: (styles, { data }) => {
    return { ...styles, color: "white" };
  },
  menu: (provided, state) => ({
    ...provided,
    zIndex: 1000
  })
};

export const plainSelectStyles = {
...selectStyles,

multiValueLabel: (styles, { data }) => {
  return { ...styles, color: "black" };
},
}
import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { useQuery, useMutation, STATUS } from "../../../commonjs/fetchHooks";
import { Spinner } from "../../../commonjs/components";

import {
  AddressApprovalDataFailed,
  AddressApprovalData,
} from "./AddressApprovalData";
import {
  AddressApprovalResultFailed,
  AddressApprovalResult,
} from "./AddressApprovalResult";
import { useParams } from "react-router-dom";

const GET_CLIENT_ADDRESS_CONFIRMATION = ({ token }) => ({
  endpoint: "accounts:api_client_address_confirmation",
  data: { query: { token } },
});

const POST_CLIENT_ADDRESS_CONFIRMATION = ({ token, approved, feedback }) => ({
  method: "POST",
  endpoint: "accounts:api_client_address_confirmation",
  data: { body: { token, approved, feedback } },
});

export function ClientAddressConfirm(props) {
  const { t } = useTranslation();
  // we are going through two phases:
  // 1. loading and displaying of the data required for the approval process
  // 2. uploading the user's approval decision and displaying a confirmation page
  // maybe a state machine would make things a bit clearer ...

  const { code } = useParams();
  const [approved, setApproved] = useState(null);
  const { data, status: statusLoad } = useQuery(
    GET_CLIENT_ADDRESS_CONFIRMATION,
    {
      token: code,
    }
  );
  const [mutate, { status: statusUpload }] = useMutation(
    POST_CLIENT_ADDRESS_CONFIRMATION
  );

  const handleApproval = (approved, feedback) => {
    setApproved(approved);
    mutate({ token: code, approved, feedback });
  };

  // handle user data loading (phase 1)
  if (statusLoad === STATUS.IDLE || statusLoad === STATUS.PENDING) {
    return <Spinner />;
  }

  if (statusLoad === STATUS.REJECTED) {
    return <AddressApprovalDataFailed />;
  }

  // here we are sure that user data loading was successfull (statusLoad === STATUS.RESOLVED)

  // check for feedback in phase 2
  if (statusUpload === STATUS.REJECTED) {
    return (
      <AddressApprovalResultFailed
        message={t(
          "Your action regarding the address failed due to an invalid/expired confirmation code."
        )}
      />
    );
  }

  if (statusUpload === STATUS.RESOLVED) {
    return <AddressApprovalResult approved={approved} />;
  }

  // prevent duplicate approvals
  console.log(data.address.state);
  console.log(data);
  if (data.address.state !== "CLIENT_APPROVAL_REQUESTED") {
    return (
      <AddressApprovalResultFailed
        message={t("This task has already been performed.")}
      />
    );
  }

  // here we are sure that phase 2 has not been started yet (idle) or is running (pending)

  // show the user data
  return (
    <AddressApprovalData
      requestingCompany={data.company_profile}
      addressToConfirm={data.address}
      addressHistory={data.history}
      onApproval={handleApproval}
    />
  );
}

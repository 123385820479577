import React, { useState } from "react";

import { Navigate } from "react-router-dom";

import { named_urls } from "../../../../routing/known_urls";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";

import { Icon } from "../../../../commonjs/icons";
import { endpoint } from "../../../../commonjs/endpoints";
import { formatToInteger } from "../../../../commonjs/langutils";
import { ModalCancelOrder } from "../../modals/ModalCancelOrder";
import { useNotifications } from "../../../../commonjs/NotificationContext";

import { ORDER_HISTORY_QUERY } from "./OrderHistory";

import { useRepeatOrder } from "../../order_process/useRepeatOrder";
import { useAuthentication } from "../../../../commonjs/AuthenticationContext";

export const OrderRow = ({
  order,
  showDetails,
  productAvailable,
  hasBlockedProducts,
}) => {
  const queryClient = useQueryClient();
  const { addNotification } = useNotifications();
  const [orderRepeated, setOrderRepeated] = useState(false);
  const [cancelOrderDialog, setCancelOrderDialog] = useState(false);
  const [cancelRequested, setCancelRequested] = useState(false);

  const { t, language } = useTranslation();
  const { blockedFromOrdering, blockedCompletely } = useAuthentication();

  const numberOfLabels = order.order_positions.reduce((total, current) => {
    return total + current.quantity;
  }, 0);
  const isStandardProduct =
    order.order_positions[0].extra_service_data.length === 0;

  const allProductsStillAvailable = order.order_positions.reduce(
    (valid, position) => valid && productAvailable(position.product.pk),
    true
  );

  const notBlocked = !(
    blockedFromOrdering ||
    blockedCompletely ||
    hasBlockedProducts
  );

  const canBeRepeated =
    isStandardProduct && allProductsStillAvailable && notBlocked;

  const { addOrderPositions } = useRepeatOrder();

  const repeatOrder = () => {
    addOrderPositions(order.order_positions);
    setOrderRepeated(true);
  };

  const cancelOrder = useMutation(
    (order) => {
      endpoint("orders:api_order", "PATCH", {
        param: order.pk,
        body: { state: "CANCEL_REQUESTED" },
      }).then((result) => {
        setCancelRequested(true);
        setCancelOrderDialog(false);
        addNotification({
          message: t(
            "A request to cancel your order was sent to the customer service."
          ),
          level: "success",
        });
      });
    },
    { onSuccess: () => queryClient.invalidateQueries(ORDER_HISTORY_QUERY) }
  );

  if (orderRepeated) {
    return <Navigate to={named_urls["orders:process"]} push={true} />;
  }

  const orderCanBeCancelled =
    ["ORDER_PENDING", "ORDER_RECEIVED"].includes(order.state) &&
    !cancelRequested;

  return (
    <tr>
      {cancelOrderDialog && (
        <ModalCancelOrder
          handleOk={() => cancelOrder.mutate(order)}
          handleCancel={() => setCancelOrderDialog(false)}
          order={order}
          t={t}
        />
      )}

      <td>
        <div
          className="cell open-modal"
          style={{ textAlign: "center" }}
          onClick={() => showDetails(order)}
        >
          <Icon icon={"eye"} size="lg" />
        </div>
      </td>

      <td>
        <div className="cell">{order.order_id}</div>
      </td>

      <td>
        <div className="cell">{order.customer_order_no}</div>
      </td>

      <td>
        <div className="cell">{order.desired_delivery_date}</div>
      </td>

      <td>
        <div className="cell text-right">
          {formatToInteger(numberOfLabels, language)}
        </div>
      </td>

      <td>
        <div className="cell">
          <span className={"status status--" + (order.state || "activated")} />
          <span className="nobr">{order.state_display}</span>
        </div>
      </td>

      <td>
        <div className="cell">
          {canBeRepeated && (
            <button
              className="mini-button button-primary"
              onClick={(_) => repeatOrder()}
            >
              {t("REPEAT")}
            </button>
          )}
        </div>
      </td>

      <td>
        <div className="cell">
          <button
            disabled={!orderCanBeCancelled}
            className={`mini-button ${
              orderCanBeCancelled
                ? "button-critical-action"
                : "button--disabled"
            }`}
            onClick={
              orderCanBeCancelled ? () => setCancelOrderDialog(true) : undefined
            }
          >
            {t("CANCEL")}
          </button>
        </div>
      </td>
    </tr>
  );
};

import { useState } from "react";

import { useQueries } from "react-query";

import { useTranslation } from "react-i18next";

import { endpoint } from "../../commonjs/endpoints";
import { Spinner } from "../../commonjs/components";

import { Workspace } from "./components/Workspace";

import { DistributionDescription } from "./components/DistributionDescription";
import { Recipient } from "./components/Recipient";
import { Rolls } from "./components/Rolls";
import { DistributionEntries } from "./components/DistributionEntries";
import { AllProductsQuery } from "../orders/order_process/Queries";

import { useAuthentication } from "../../commonjs/AuthenticationContext";
import { useDistributions } from "./useDistribution";

const Distribution = ({ addresses, spools, products }) => {
  const { t } = useTranslation();
  const [selectedAddress, setSelectedAddress] = useState();

  const {
    distributions,
    addDistribution,
    removeDistribution,
    rollContaintainedInDistribution,
    sendDistributions,
  } = useDistributions();

  const availableRolls = spools.filter(
    (roll) => !rollContaintainedInDistribution(roll)
  );

  const spoolSelectionEnabled = selectedAddress && availableRolls.length > 0;

  const addToDistribuition = (rolls) => {
    addDistribution({
      rolls,
      recipient: selectedAddress,
    });
    setSelectedAddress(null);
  };

  return (
    <Workspace
      title={t("REDISTRIBUTION")}
      description={<DistributionDescription />}
    >
      <Recipient
        addresses={addresses}
        selectedAddress={selectedAddress}
        setSelectedAddress={setSelectedAddress}
      />
      <Rolls
        addToDistribution={addToDistribuition}
        availableRolls={availableRolls}
        products={products}
        enabled={spoolSelectionEnabled}
      />

      <DistributionEntries
        distributions={distributions}
        removeDistribution={removeDistribution}
      />
      <section>
        <button
          type="submit"
          disabled={!distributions.length > 0}
          className={`button button-primary ${
            distributions.length > 0 ? "clickable" : "button-primary--disabled"
          }`}
          onClick={() => sendDistributions()}
        >
          {t("SEND DISTRIBUTIONS")}
        </button>
      </section>
    </Workspace>
  );
};

export const DistributionLoader = () => {
  const { account } = useAuthentication();

  const queries = useQueries([
    {
      queryKey: "spoolList",
      queryFn: () => endpoint("distribution:api_spool_list", "GET"),
    },
    {
      queryKey: "addresses",
      queryFn: () => endpoint("accounts:api_address", "GET"),
    },
    AllProductsQuery,
  ]);

  const allQueriesLoaded = !queries.find((query) => query.isLoading);
  if (!allQueriesLoaded) return <Spinner />;

  const spools = queries[0].data;
  const ownAddress = {
    ...account.company_profile.delivery_address,
    company_name: account.company_profile.name,
    company_id: account.company_profile.cofor10,
    ownAddress: true,
  };
  const distributionAddresses = queries[1].data.filter(
    (entry) => entry.category === "DISTRIBUTION"
  );
  const allAddresses = [ownAddress].concat(distributionAddresses);
  const products = queries[2].data;

  return allQueriesLoaded ? (
    <Distribution
      spools={spools}
      addresses={allAddresses}
      products={products}
    />
  ) : (
    <Spinner />
  );
};

import React from "react";
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";

import { format } from "date-fns";

import { formatToShortDate } from "../../../../commonjs/langutils";
import { translateBlockingReason } from "./Blocked";

const download = ({ companies, t }) => {
  let csv = [];

  const labels = [
    t("Company Name"),
    t("COFOR6"),
    t("COFOR10"),
    t("Street Address"),
    t("Postal Code"),
    t("City"),
    t("Country"),
    t("Domain"),
    t("Registration"),
    t("Registration Contact"),
    t("Ordered Quantity"),
    t("Provisioning Contact"),
    t("Commercial Contact"),
    t("Delivery Contact"),
    t("Invoice Contact"),
    t("Blocked"),
  ];
  csv.push(labels.join(";") + "\n");

  companies.forEach((entry) => {
    // escape fields with "..." to handle special chars like delimiters with the field values
    csv.push(
      [
        `"${entry.company_name}"`,
        `"${entry.cofor6}"`,
        `"${entry.cofor10}"`,
        `"${entry.street_address}"`,
        `"${entry.postal_code}"`,
        `"${entry.city}"`,
        `"${entry.country_code}"`,
        `"${entry.product_groups}"`,
        `"${formatToShortDate(entry.registration_date)}"`,
        `"${entry.registration_contact}"`,
        `"${entry.quantity}"`,
        `"${entry.provisioning_contact}"`,
        `"${entry.commercial_contact}"`,
        `"${entry.delivery_contact}"`,
        `"${entry.invoice_contact}"`,
        `"${entry.blocked.split(",").map(e => translateBlockingReason(t, e)).join(",")}"`,
      ]
        .join(";")
        .replace(/(\r\n|\n|\r)/gm, "") + "\n"
    );
  });

  const csvBlob = new Blob(csv, {
    type: "text/csv;charset=utf-8",
    endings: "native",
  });

  saveAs(csvBlob, format(new Date(), "yyyy-MM-dd") + "_registrations.csv");
};

const DownloadButton = ({ companies }) => {
  const { t } = useTranslation();
  return (
    <button
      type="submit"
      className={`button button-primary download-button`}
      onClick={() => download({ companies, t })}
    >
      {t("DOWNLOAD")}
    </button>
  );
};

export default DownloadButton;

import React from "react";

export function Workspace(props) {
  const { title, description, children } = props;
  return (
    <section className="workspace">
      <div className="workspace-header">
        <h1>{title}</h1>
        <div className="workspace-description">{description}</div>
      </div>
      <div className="workspace-body">{children}</div>
    </section>
  );
}

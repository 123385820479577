import React from "react";
import { useTranslation } from "react-i18next";
import { ContactCustomerService } from "../../../commonjs/constants";

function AccountApprovalResultFailed() {
  const { t } = useTranslation();
  return (
    <ErrorMessage>
      {t(
        "Your action regarding the user account failed due to an invalid/expired confirmation code."
      )}
      <br />
      <ContactCustomerService />
    </ErrorMessage>
  );
}

function AccountApprovalResult({ approved }) {
  const { t } = useTranslation();
  return approved ? (
    <section className="client-confirm">
      <h1 className="success">{t("APPROVAL SUCCESSFUL")}</h1>
      <section>
        {t("You approved the user registration.")}
        <br />
        {t("The user will be informed accordingly.")}
      </section>
    </section>
  ) : (
    <section className="client-confirm">
      <h1 className="success">{t("REJECTION SUCCESSFUL")}</h1>
      <section>
        {t("You rejected the user registration.")}
        <br />
        {t("The potential user was informed and the account was deleted.")}
      </section>
    </section>
  );
}

function ApprovalMessage({ message, status, children }) {
  return (
    <section className="client-confirm">
      <h1 className={status}>{message}</h1>
      <section>{children}</section>
    </section>
  );
}

function ErrorMessage({ children }) {
  const { t } = useTranslation();
  return (
    <ApprovalMessage status="failed" message={t("ACTION FAILED")}>
      {children}
    </ApprovalMessage>
  );
}

export {
  ApprovalMessage,
  ErrorMessage,
  AccountApprovalResult,
  AccountApprovalResultFailed,
};

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";

import { IdSearch } from "../id_analysis/IdSearch";
import Query from "../Query";

import { VerificationsMap } from "../authentications/Map/VerificationsMap";
import { StatusTable } from "./StatusTable";
import { OrderLink } from "../order_overview/OrderLink";
import { Redistribution } from "../components/Redistribution";
import { KeyValueDisplay } from "../components/KeyValueComponent";

const OrderInformation = (props) => {
  const { company_name, cofor10, country_code, order_id } = props.label_info;
  const { t } = props;

  const orderDetails = [
    [t("Company Name"), company_name],
    [t("COFOR(10)"), cofor10],
    [t("Country"), country_code],
    [" ", <OrderLink order_pk={order_id} />],
  ];

  return (
    <div style={{ paddingTop: "0.5em", width: "50%" }}>
      <h3>{t("Order")}</h3>
      <KeyValueDisplay keyValueMap={orderDetails} />
    </div>
  );
};

const VerificationsMapForSpool = (props) => {
  const { date_start, date_end, spool_id } = props;
  return (
    <div className="map" style={{ width: "100%", height: "600px" }}>
      <VerificationsMap
        params={{
          date_start,
          date_end,
          spool_id,
        }}
        options={{ hideLegend: false }}
      />
    </div>
  );
};

const SpoolDetail = (props) => {
  const { label, t } = props;

  const date_start = new Date("2018-01-01");
  const date_end = new Date();
  const spool_id = label.spool_id;

  return (
    <div>
      {label && label.order_id && (
        <div>
          <h2>{t("Authentications for Spool {{spool_id}}", { spool_id })}</h2>
          <OrderInformation label_info={label} t={t} />
        </div>
      )}
      {label && label.redistribution && (
        <Redistribution data={label.redistribution} />
      )}
      <br />
      <Query
        debug={false}
        query={"statistics:api_verifications"}
        method={"POST"}
        params={{
          aggregation: "by_spool",
          date_start: format(date_start, "yyyy-MM-dd"),
          date_end: format(date_end, "yyyy-MM-dd"),
          spool_id: spool_id,
        }}
      >
        {(result) => {
          const data = result.data;
          const searchedForObjectId = label.holospot_id;

          if (data.verifications && data.verifications.length > 0) {
            return (
              <div>
                <StatusTable
                  verifications={data.verifications}
                  searchedForObjectId={searchedForObjectId}
                />
                <br />
                <VerificationsMapForSpool
                  date_start={date_start}
                  date_end={date_end}
                  spool_id={spool_id}
                />
              </div>
            );
          } else {
            return (
              <h2 styl={{ paddingTop: "1.5em" }}>
                {t(
                  "No Authentications found for Labels on Spool {{ spool_id }}!",
                  {
                    spool_id,
                  }
                )}
              </h2>
            );
          }
        }}
      </Query>
    </div>
  );
};

export const SpoolAnalysis = () => {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <section className={"statistics"}>
      <h1>{t("SPOOL ANALYSIS")}</h1>
      <IdSearch result={SpoolDetail} value={location.state?.labelId} />
    </section>
  );
};

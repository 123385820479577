import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";



export const NonActivatedOrdersMessage = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h2>
        {t("You have too many non-activated order(s) for your products.")}
      </h2>
      <p>
        {t(
          "Please fill in and send back the respective security feedback for all non-activated orders. The orders will then be activated and the ordering function deblocked."
        )}
      </p>
    </div>
  );
};

export const UnpaidBillsAlertMessage = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h2>{t("Your account has been blocked because of overdue invoices.")}</h2>
      <p>
        {t(
          "Please contact your accounting department to resolve this issue and be able to order again."
        )}
      </p>
    </div>
  );
};
export const InactivityAlertMessage = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h2>
        {t(
          "Your account has been deactivated because it has been inactive for a long time."
        )}
      </h2>
      <p>
        {t(
          "Please contact SCRIBOS Customer Service at customer-service@scribos.com"
        )}
      </p>
    </div>
  );
};

export const BlockingMessages = {
  NON_ACTIVATED_ORDERS: <NonActivatedOrdersMessage />,
  UNPAID_BILLS: <UnpaidBillsAlertMessage />,
  INACTIVITY: <InactivityAlertMessage />,
};

export const PendingValue = ({ isPending, children }) => {
  return isPending ? (
    <div>
      &nbsp;
      <div
        className="button-spinner"
        style={{ float: "left" }}
        data-testid={"pending"}
      ></div>
    </div>
  ) : (
    <Fragment>{children}</Fragment>
  );
};

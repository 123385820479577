import { endpoint } from "../../../../../commonjs/endpoints";

export const markersQuery = (params) => {
  return endpoint("statistics:api_verifications", "POST", {
    body: { aggregation: "markers", ...params },
  }).then((result) => {
    if (result.data.type === "single_hits") {
      return result.data.hits;
    } else {
      return result.data.buckets;
    }
  });
};

export const verificationLookup = (id) => {
  return endpoint("statistics:api_verification_lookup", "GET", {
    param: id,
  }).then((result) => {
    return result;
  });
};

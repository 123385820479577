import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-final-form";

export const PasswordForm = ({ initialValues, onSubmit }) => {
  const { t } = useTranslation();
  return (
    <Form initialValues={initialValues} onSubmit={onSubmit} validate={() => {}}>
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <div className="grid">
            <div className="grid__item xs-12">
              <button
                type="submit"
                disabled={submitting}
                className={`button button-primary ${
                  submitting ? "button-primary--disabled" : "clickable"
                }`}
              >
                {t("RESET")}
              </button>
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};

import React from "react";
import { AvailableStates } from "./authentications/Map/VerificationStates";
import { SortIndicator } from "react-virtualized";

export const sortList = ({ list, sortBy, sortDirection }) => {
  return [...list].sort((a, b) => {
    if (a[sortBy] > b[sortBy]) return sortDirection[sortBy] === "ASC" ? 1 : -1;
    if (a[sortBy] < b[sortBy]) return sortDirection[sortBy] === "ASC" ? -1 : 1;

    return 0;
  });
};

export const StatusHeaderRenderer = (sortState) => {
  const renderHeader = ({ dataKey, label }) => {
    const status = AvailableStates.find((state) => state.status === dataKey);
    const showSortIndicator = sortState.sortBy.includes(dataKey);
    return (
      <div
        className={"sort-header"}
        style={{ color: status ? status.color : "" }}
      >
        <span title={label}>{label}</span>
        {showSortIndicator && (
          <SortIndicator sortDirection={sortState.sortDirection[dataKey]} />
        )}
      </div>
    );
  };

  return renderHeader;
};

export const HeaderRenderer = (sortState) => {
  const renderHeader = ({ dataKey, label }) => {
    const showSortIndicator = sortState.sortBy.includes(dataKey);
    return (
      <div className={"sort-header"}>
        <span title={label}>{label}</span>
        {showSortIndicator && (
          <SortIndicator sortDirection={sortState.sortDirection[dataKey]} />
        )}
      </div>
    );
  };
  return renderHeader;
};

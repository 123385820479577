import React from "react";
import { useTranslation } from "react-i18next";
import DownloadButton from "./DownloadButton";

import { DateFilter, SelectFilter, DomainFilter } from "../filters";
import { translateBlockingReason } from "./Blocked";

const CompanyFilters = ({
  updateFilter,
  resetFilter,
  countryOptionsFor,
  optionsFor,
  companies,
}) => {
  const { t } = useTranslation();

  return (
    <div className={"grid query-params"}>
      <div className={"grid"}>
        <div className={"grid__item xs-6"}>
          <DateFilter
            updateFilter={updateFilter}
            resetFilter={resetFilter}
            filterColumn={"registration_date"}
          />
        </div>
        <div className={"grid__item xs-6"}>
          <DownloadButton companies={companies} />
        </div>
      </div>
      <div className={"grid"} style={{ paddingTop: "0.5em" }}>
        <div className={"grid__item xs-6"}>
          <SelectFilter
            label={t("Country")}
            placeholder={t("Select one or more countries")}
            filterName={"COUNTRY_FILTER"}
            options={countryOptionsFor({
              fieldName: "country_code",
              exclude: "COUNTRY_FILTER",
            })}
            fieldName={"country_code"}
            updateFilter={updateFilter}
            resetFilter={resetFilter}
          />
        </div>
        <div className={"grid__item xs-6"}>
          <SelectFilter
            label={t("City")}
            placeholder={t("Select one or more cities")}
            filterName={"PRODUCT_CITY_FILTER"}
            options={optionsFor({
              fieldName: "city",
              exclude: "PRODUCT_CITY_FILTER",
            })}
            fieldName={"city"}
            updateFilter={updateFilter}
            resetFilter={resetFilter}
          />
        </div>
      </div>
      <div className={"grid"} style={{ paddingTop: "1em" }}>
        <div className={"grid__item xs-6"}>
          <DomainFilter
            label={t("Domain")}
            placeholder={t("Select one or more domains")}
            filterName={"DOMAIN_FILTER"}
            options={optionsFor({
              fieldName: "product_groups",
              exclude: "DOMAIN_FILTER",
            })}
            fieldName={"product_groups"}
            updateFilter={updateFilter}
            resetFilter={resetFilter}
          />
        </div>
        <div className={"grid__item xs-6"}>
          <SelectFilter
            label={t("COFOR10")}
            placeholder={t("Select one or more COFOR(10)")}
            filterName={"COFOR10_FILTER"}
            options={optionsFor({
              fieldName: "cofor10",
              exclude: "COFOR10_FILTER",
            })}
            fieldName={"cofor10"}
            updateFilter={updateFilter}
            resetFilter={resetFilter}
          />
        </div>
        <div className={"grid__item xs-6"} style={{ paddingTop: "1em" }}>
          <SelectFilter
            label={t("Supplier")}
            placeholder={t("Select one or more Supplier")}
            filterName={"supplier_name"}
            options={optionsFor({
              fieldName: "company_name",
              exclude: "supplier_name",
            })}
            fieldName={"company_name"}
            updateFilter={updateFilter}
            resetFilter={resetFilter}
          />
        </div>
        <div className={"grid__item xs-6"} style={{ paddingTop: "1em" }}>
          <SelectFilter
            label={t("Blocked supplier")}
            placeholder={t("Select one or more blocking reasons")}
            filterName={"blocked"}
            options={[
              { label: translateBlockingReason(t, "BLOCKED_FOR_ORDERING"), value: "BLOCKED_FOR_ORDERING" },
              { label: translateBlockingReason(t, "BLOCKED_COMPLETELY"), value: "BLOCKED_COMPLETELY" },
              { label: translateBlockingReason(t, "NON_ACTIVATED_PRODUCTS"), value: "NON_ACTIVATED_PRODUCTS" },
            ]}
            fieldName={"blocked"}
            updateFilter={updateFilter}
            resetFilter={resetFilter}
            multi={true}
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyFilters;

import { useTranslation } from "react-i18next";
import { formatAddressLabel } from "../utils";
import Select from "react-select";

export const Recipient = ({
  setSelectedAddress,
  selectedAddress,
  addresses,
}) => {
  const { t } = useTranslation();

  const options = addresses.map((address) => ({
    label: formatAddressLabel(address),
    value: address.pk,
  }));

  return (
    <section id="distribution-recipient">
      <h1>{t("Recipient")}</h1>
      <Select
        value={
          selectedAddress
            ? options.find((option) => option.value === selectedAddress.pk)
            : null
        }
        isClearable={true}
        name="select-recipient"
        placeholder={t("Select Recipient...")}
        searchable={false}
        onChange={(selection) =>
          setSelectedAddress(
            addresses.find((address) => address.pk === selection?.value)
          )
        }
        options={options}
      />

      <br />
    </section>
  );
};

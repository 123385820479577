import React, { useMemo } from "react";
import { useFormState } from "react-final-form";

import { Canvas } from "./Canvas";
import {
  LabelImage,
  BarcodePreview,
  MadeInArabic,
  LabelTextPreview,
} from "./renderers/Renderers";

import { getOverlaySpecification } from "./OverlaySpecifications";
import { getPrintingExtraServiceFromProduct, getFilteredExtraServiceFieldsFromProduct } from "../utils";

const getRenderer = (fieldName) => {
  const specialHandlers = {
    made_in_arabic: MadeInArabic,
  };
  return specialHandlers[fieldName] || LabelTextPreview;
};

const extractPrintImageFromProduct = (product) => {
  const printingService =
    product && product.extra_services.find((es) => es.category === "PRINTING");
  if (!printingService) {
    return null;
  }
  return printingService.image;
};

const createDataEntries = (dataEntries, values) => {
  let data = null;
  if (dataEntries) {
    data = dataEntries.reduce((acc, entry) => {
      return { ...acc, [entry]: values[entry] };
    }, {});
  }
  return data;
}

export const LabelPreview = ({ product }) => {
  const values = useFormState().values;

  const printingImageUrl = extractPrintImageFromProduct(product);

  const lookupSpecification = () => {
    const printingService = getPrintingExtraServiceFromProduct(product);
    let overlaySpecification = getOverlaySpecification("DEFAULT");
    if (printingService) {
      try {
        overlaySpecification = JSON.parse(printingService.overlay_specification);
      } catch (_) { }
    }
    return overlaySpecification;

    // use this during development to access overlay specification from js file
    // NOTE: comment out lines above in this function
    // return getOverlaySpecification(product.customer_reference);
  };
  //eslint-disable-next-line react-hooks/exhaustive-deps
  const specification = useMemo(() => lookupSpecification(), [product]);

  const { width, height } = specification.dimensions;

  const previewWidth = 450;
  // const previewWidth = 1000;  // for development
  const scalingFactor = previewWidth / width;
  const previewHeight = height * scalingFactor;
  const fontScalingFactor = 3.5;

  return (
    <Canvas width={previewWidth} height={previewHeight}>
      <LabelImage
        src={printingImageUrl}
        width={previewWidth}
        height={previewHeight}
        opacity={1.1}
      // opacity={0.1}  // for development
      />
      {Object.entries(specification.fields).map(
        ([name, overlaySpecification]) => {
          const Renderer = getRenderer(name);
          // create data key value pairs from the overlay spec or use the field name as fallback
          const data = createDataEntries(overlaySpecification.data || [name], values);
          const fields = getFilteredExtraServiceFieldsFromProduct(product, values);
          return (
            <Renderer
              key={"field_" + name}
              data={data}
              overlaySpecification={overlaySpecification}
              scalingFactor={scalingFactor}
              fontScalingFactor={fontScalingFactor}
              // fixme: "contextValues" is a hack used in the MadeInArabic renderer
              contextValues={values}
              fields={fields}
            />
          );
        }
      )}
      {specification.barcodes &&
        Object.entries(specification.barcodes).map(
          ([name, overlaySpecification]) => {
            // create data key value pairs from the overlay spec or use the field name as fallback
            const data = createDataEntries(overlaySpecification.data || [name], values);
            const fields = getFilteredExtraServiceFieldsFromProduct(product, values);
            return (
              <BarcodePreview
                key={"barcode_" + name}
                data={data}
                overlaySpecification={overlaySpecification}
                scalingFactor={scalingFactor}
                fields={fields}
              />
            );
          }
        )}
    </Canvas>
  );
};

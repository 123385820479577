import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import Select from "react-select";

/**
 * TODO this is dirty, as we should probably fetch these from the DB, but as we are not doing that elsewhere, this seems like the pragmatic thing to do
 */
const domainOptions = t => {
  return [
    { label: t("Eurorepar"), value: "Eurorepar" },
    { label: t("MOPAR"), value: "MOPAR" },
    { label: t("Original Part"), value: "Original Part" },
    { label: t("Accessories"), value: "Accessories" },
    { label: t("SUSTAINera"), value: "SUSTAINera" },
  ];
};

class _DomainFilter extends PureComponent {
  onSelectVerificationStatus = selection => {
    const { onChange } = this.props;
    onChange(selection);
  };

  render() {
    const { t } = this.props;
    return (
      <label>
        {t("Domain")}
        <Select
          options={domainOptions(t)}
          isMulti={true}
          closeMenuOnSelect={false}
          className="domain-select"
          onChange={this.onSelectVerificationStatus}
        />
      </label>
    );
  }
}

export const DomainFilter = withTranslation()(_DomainFilter);

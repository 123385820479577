import { useTranslation } from "react-i18next";
import Decimal from "decimal.js-light";

import { formatToCurrency } from "../../../../../commonjs/langutils";

import { useDraftOrder } from "../../useDraftOrder";
import { PendingValue } from "../../utils";

export const TotalCosts = () => {
  const { t, language } = useTranslation();
  const { draftOrder, isCalculating, isCalculated } = useDraftOrder();

  const {
    handlingCosts,
    totalWithoutDelivery,
    totalRowPrice,
    partialRollAndPrintDesignCosts,
  } = isCalculated
    ? {
        handlingCosts: Decimal(draftOrder.handling_costs).minus(
          draftOrder.handling_sub_costs_operator
        ),
        deliveryCosts: draftOrder.delivery_costs,
        partialRollAndPrintDesignCosts: draftOrder.handling_sub_costs_operator,
        totalWithoutDelivery: Decimal(draftOrder.total).minus(
          Decimal(draftOrder.delivery_costs)
        ),
        totalRowPrice: Decimal(draftOrder.total)
          .minus(Decimal(draftOrder.delivery_costs))
          .minus(draftOrder.handling_costs),
      }
    : {
        handlingCosts: 0,
        deliveryCosts: 0,
        partialRollAndPrintDesignCosts: 0,
        totalWithoutDelivery: 0,
        totalRowPrice: 0,
      };

  return (
    <div className="grid">
      <div className="order__sum grid__item right xs-2">
        <div className="order__handling-costs">{t("HANDLING COSTS")}</div>
        <div>
          <PendingValue isPending={isCalculating}>
            {formatToCurrency(handlingCosts, language)}
          </PendingValue>
        </div>
        <br />
        <div className="order__handling-costs">
          {t("PARTIAL ROLL / PRINT DESIGN COSTS")}
        </div>
        <div>
          <PendingValue isPending={isCalculating}>
            {formatToCurrency(partialRollAndPrintDesignCosts, language)}
          </PendingValue>
        </div>
        <br />
        <div className="order__handling-costs">{t("LABEL COSTS")}</div>
        <div>
          <PendingValue isPending={isCalculating}>
            {formatToCurrency(totalRowPrice, language)}
          </PendingValue>
        </div>
        <hr className="hr--secondary" />
        <div className="order__total">{t("TOTAL")}</div>
        <div>
          <PendingValue isPending={isCalculating}>
            {formatToCurrency(totalWithoutDelivery, language)}
          </PendingValue>
        </div>
        <hr className="hr--total" />
      </div>
    </div>
  );
};

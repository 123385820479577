import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";
import { saveAs } from "file-saver";
import { format } from "date-fns";

import {
  formatToInteger,
  formatToShortDate,
  formatToFloat,
} from "../../../../commonjs/langutils";

import {
  Column,
  Table,
  AutoSizer,
  createTableMultiSort,
  SortIndicator,
} from "react-virtualized";

import uniqBy from "lodash/uniqBy";
import uniq from "lodash/uniq";
import sortBy from "lodash/sortBy";

import { DateFilter, SelectFilter } from "../filters";
import { OrderLink } from "./OrderLink";

import { ModalOrderHistoryDetails } from "../../modals/ModalOrderHistoryDetails";

const sortList = ({ list, sortBy, sortDirection }) => {
  return [...list].sort((a, b) => {
    if (a[sortBy] > b[sortBy]) return sortDirection[sortBy] === "ASC" ? 1 : -1;
    if (a[sortBy] < b[sortBy]) return sortDirection[sortBy] === "ASC" ? -1 : 1;

    return 0;
  });
};

class _OrderOverviewTable extends PureComponent {
  state = {
    filteredList: [],
    sortBy: "company_name",
    sortDirection: "ASC",
    selectedOrder: null,
    showOrderDetails: false,
    loadingOrder: null,
  };

  componentDidMount() {
    const { orders } = this.props;

    this.setState({ filteredList: orders });
  }

  sort = ({ sortBy, sortDirection }) => {
    const { filteredList } = this.state;
    const sortedList = sortList({ list: filteredList, sortBy, sortDirection });
    this.setState({ filteredList: sortedList, sortBy, sortDirection });
  };

  sortState = createTableMultiSort(this.sort);

  headerRenderer = ({ dataKey, label }) => {
    const showSortIndicator = this.sortState.sortBy.includes(dataKey);
    return (
      <div className={"sort-header"}>
        <span title={label}>{label}</span>
        {showSortIndicator && (
          <SortIndicator
            sortDirection={this.sortState.sortDirection[dataKey]}
          />
        )}
      </div>
    );
  };

  filters = new Map();
  updateFilter = (key, filter) => {
    this.filters.set(key, filter);

    this.applyFilters();
  };

  resetFilter = (key) => {
    this.filters.delete(key);

    this.applyFilters();
  };

  applyFilters = () => {
    const { orders } = this.props;
    const { sortBy, sortDirection } = this.state;

    const reduced = Array.from(this.filters.values()).reduce(
      (currentResult, filter) => currentResult.filter((entry) => filter(entry)),
      orders
    );

    const sortedAndReduced = sortList({ list: reduced, sortBy, sortDirection });

    this.setState({ filteredList: sortedAndReduced });
  };

  countryOptionsFor = ({ fieldName, exclude }) => {
    const { countryCodes } = this.props;

    const hashedCountries = countryCodes.reduce(
      (acc, entry) => ({ ...acc, [entry.code]: entry.name }),
      {}
    );
    const labelMapping = (label) => hashedCountries[label] + " (" + label + ")";
    return this.optionsFor({ fieldName, exclude, labelMapping });
  };

  optionsFor = ({ fieldName, exclude, labelMapping = (label) => label }) => {
    const { orders } = this.props;

    const includedFilters = new Map(this.filters);
    includedFilters.delete(exclude);
    const filteredList = Array.from(includedFilters.values()).reduce(
      (currentResult, filter) =>
        filter.key === exclude
          ? currentResult
          : currentResult.filter((entry) => filter(entry)),
      orders
    );

    const labels = uniqBy(filteredList, fieldName)
      .map((entry) => entry[fieldName])
      .filter((fieldValue) => fieldValue.length > 0);
    // 2021-08-11/GK: Don't split data by "," as
    // the filter function in the select filter component
    // cannot handle this.
    // Keep the code for now, as I don't have the full picture ...
    // .reduce((previousValue, currentValue) => {
    //   return previousValue.concat(currentValue.split(",").map(s => s.trim()));
    // }, new Array());

    const uniqueValues = uniq(labels).map((label) => ({
      label: labelMapping(label),
      value: label,
    }));

    const sorted = sortBy(uniqueValues, (entry) => entry.label);

    return sorted;
  };

  download = () => {
    const { t, i18n } = this.props;
    const { filteredList } = this.state;

    let csv = [];
    const labels = [
      t("Company Name"),
      t("COFOR6"),
      t("COFOR10"),
      t("City"),
      t("Country"),
      t("Reference"),
      t("NART"),
      t("Order No."),
      t("Domain"),
      t("No. of Labels"),
      t("Order Date"),
      t("Status"),
      t("Note"),
      t("Label Costs"),
      t("Operator Costs"),
      t("Handling Fees"),
      t("Delivery Costs"),
      t("Order Total Costs"),
      t("Company Group"),
    ];

    csv.push(labels.join(";") + "\n");

    filteredList.forEach((entry) => {
      let line = [
        `"${entry.company_name}"`,
        `"${entry.cofor6}"`,
        `"${entry.cofor10}"`,
        `"${entry.city}"`,
        `"${entry.country_code}"`,
        `"${entry.customer_reference}"`,
        `"${entry.nart}"`,
        `"${entry.order_id}"`,
        `"${entry.product_group}"`,
        `"${entry.quantity}"`,
        `"${formatToShortDate(entry.order_date)}"`,
        `"${entry.state}"`,
        `"${entry.note}"`,
        `"${formatToFloat(
          parseFloat(entry.position_total),
          i18n.language,
          2,
          "0."
        )}"`,
        `"${formatToFloat(
          parseFloat(entry.operator_costs),
          i18n.language,
          2,
          "0."
        )}"`,
        `"${formatToFloat(
          parseFloat(entry.handling_fees),
          i18n.language,
          3,
          "0."
        )}"`,
        `"${formatToFloat(
          parseFloat(entry.delivery_costs),
          i18n.language,
          3,
          "0."
        )}"`,
        `"${formatToFloat(
          parseFloat(entry.order_total),
          i18n.language,
          3,
          "0."
        )}"`,
        `"${entry.company_group}"`,
      ].join(";");
      csv.push(line.replace(/(\r\n|\n|\r)/gm, "") + "\n");
    });
    const csvBlob = new Blob(csv, {
      type: "text/csv;charset=utf-8",
      endings: "native",
    });

    saveAs(csvBlob, format(new Date(), "yyyy-MM-dd") + "_order_overview.csv");
  };

  render() {
    const { t, i18n } = this.props;
    const { filteredList, selectedOrder, showOrderDetails } = this.state;

    return (
      <div>
        {showOrderDetails && (
          <ModalOrderHistoryDetails
            close={this.closeOrderDetails}
            order={selectedOrder}
          />
        )}

        <div className={"grid query-params"}>
          <div className={"grid__item xs-6"}>
            <DateFilter
              updateFilter={this.updateFilter}
              resetFilter={this.resetFilter}
              filterColumn={"order_date"}
            />
          </div>

          <div className={"grid__item xs-6"}>
            <button
              type="submit"
              className={`button button-primary download-button`}
              onClick={this.download}
            >
              {t("DOWNLOAD")}
            </button>
          </div>
          <div className={"grid"} style={{ paddingTop: "0.5em" }}>
            <div className={"grid__item xs-6"}>
              <SelectFilter
                label={t("Country")}
                placeholder={t("Select one or more countries")}
                filterName={"COUNTRY_FILTER"}
                options={this.countryOptionsFor({
                  fieldName: "country_code",
                  exclude: "COUNTRY_FILTER",
                })}
                fieldName={"country_code"}
                updateFilter={this.updateFilter}
                resetFilter={this.resetFilter}
              />
            </div>
            <div className={"grid__item xs-6"}>
              <SelectFilter
                label={t("City")}
                placeholder={t("Select one or more cities")}
                filterName={"PRODUCT_CITY_FILTER"}
                options={this.optionsFor({
                  fieldName: "city",
                  exclude: "PRODUCT_CITY_FILTER",
                })}
                fieldName={"city"}
                updateFilter={this.updateFilter}
                resetFilter={this.resetFilter}
              />
            </div>
            <div className={"grid__item xs-6"}>
              <SelectFilter
                label={t("Domain")}
                placeholder={t("Select one or more domain")}
                filterName={"PRODUCT_GROUP_FILTER"}
                options={this.optionsFor({
                  fieldName: "product_group",
                  exclude: "PRODUCT_GROUP_FILTER",
                })}
                fieldName={"product_group"}
                updateFilter={this.updateFilter}
                resetFilter={this.resetFilter}
              />
            </div>
            <div className={"grid__item xs-6"}>
              <SelectFilter
                label={t("Order")}
                placeholder={t("Select one or more orders")}
                filterName={"PRODUCT_ORDERNR_FILTER"}
                options={this.optionsFor({
                  fieldName: "order_id",
                  exclude: "PRODUCT_ORDERNR_FILTER",
                })}
                fieldName={"order_id"}
                updateFilter={this.updateFilter}
                resetFilter={this.resetFilter}
                getFilterValue={this.getFilterValue}
              />
            </div>
            <div className={"grid__item xs-6"}>
              <SelectFilter
                label={t("COFOR6")}
                placeholder={t("Select one or more COFOR(6)")}
                filterName={"COFOR6_FILTER"}
                options={this.optionsFor({
                  fieldName: "cofor6",
                  exclude: "COFOR6_FILTER",
                })}
                fieldName={"cofor6"}
                updateFilter={this.updateFilter}
                resetFilter={this.resetFilter}
              />
            </div>
            <div className={"grid__item xs-6"}>
              <SelectFilter
                label={t("COFOR10")}
                placeholder={t("Select one or more COFOR(10)")}
                filterName={"COFOR10_FILTER"}
                options={this.optionsFor({
                  fieldName: "cofor10",
                  exclude: "COFOR10_FILTER",
                })}
                fieldName={"cofor10"}
                updateFilter={this.updateFilter}
                resetFilter={this.resetFilter}
              />
            </div>
            <div className={"grid__item xs-6"}>
              <SelectFilter
                label={t("Supplier")}
                placeholder={t("Select one or more Supplier")}
                filterName={"supplier_name"}
                options={this.optionsFor({
                  fieldName: "company_name",
                  exclude: "supplier_name",
                })}
                fieldName={"company_name"}
                updateFilter={this.updateFilter}
                resetFilter={this.resetFilter}
              />
            </div>
            <div className={"grid__item xs-6"}>&nbsp;</div>
          </div>
        </div>
        <h3>
          {filteredList.length} {t("order(s) match(es) your Criteria")}
        </h3>
        <AutoSizer className={"AutoSizerWrapper statistics-table"}>
          {({ width, height }) => (
            <Table
              width={width}
              height={760}
              headerHeight={48}
              rowHeight={48}
              rowCount={filteredList.length}
              rowGetter={({ index }) => filteredList[index]}
              sort={this.sortState.sort}
              sortBy={undefined}
              sortDirection={undefined}
            >
              <Column
                label=""
                dataKey="order_id"
                width={30}
                style={{ paddingLeft: 0 }}
                headerRenderer={this.headerRenderer}
                cellRenderer={(entry) => (
                  <OrderLink order_pk={entry.rowData.order_pk} />
                )}
              />
              <Column
                label={t("Company Name")}
                dataKey="company_name"
                width={230}
                headerRenderer={this.headerRenderer}
              />
              <Column
                label={t("COFOR6")}
                dataKey={"cofor6"}
                width={120}
                headerRenderer={this.headerRenderer}
              />
              <Column
                label={t("City")}
                dataKey="city"
                width={120}
                headerRenderer={this.headerRenderer}
              />
              <Column
                label={t("Country")}
                dataKey="country_code"
                width={90}
                headerRenderer={this.headerRenderer}
              />
              <Column
                label={t("Domain")}
                dataKey="product_group"
                width={120}
                headerRenderer={this.headerRenderer}
              />
              <Column
                label={t("description")}
                dataKey="description"
                width={140}
                headerRenderer={this.headerRenderer}
              />
              <Column
                style={{ textAlign: "right" }}
                label={t("No. of Labels")}
                dataKey="quantity"
                width={130}
                cellRenderer={(entry) =>
                  formatToInteger(entry.cellData, i18n.language)
                }
                headerRenderer={this.headerRenderer}
              />
              <Column
                label={t("Order Date")}
                dataKey="order_date"
                width={130}
                cellRenderer={(entry) => formatToShortDate(entry.cellData)}
                headerRenderer={this.headerRenderer}
              />
              <Column
                label={t("Status")}
                dataKey="state_display"
                width={180}
                headerRenderer={this.headerRenderer}
                cellRenderer={(entry) => (
                  <div className="cell">
                    <span
                      className={`status status--${
                        entry.rowData.state || "activated"
                      }`}
                    />
                    <span>{entry.cellData}</span>
                  </div>
                )}
              />
            </Table>
          )}
        </AutoSizer>
      </div>
    );
  }
}

export const OrderOverviewTable = withTranslation()(_OrderOverviewTable);

import React, { useEffect, useRef, useState } from "react";
import { PAGINATION_ITEMS_PER_PAGE } from "./OrderHistory";

export const Pagination = ({
  items,
  setItemsToShow,
  page,
  setPage,
  paginationData,
  isFetching,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const clickedButton = useRef(null);

  //make sure we reset, when the items are changed(e.g, when filtered)
  useEffect(() => {
    setCurrentPage(0);
  }, [items]);

  useEffect(() => {
    const itemsToShow = items.slice(
      currentPage * PAGINATION_ITEMS_PER_PAGE,
      currentPage * PAGINATION_ITEMS_PER_PAGE + PAGINATION_ITEMS_PER_PAGE
    );
    setItemsToShow(itemsToShow);
  }, [currentPage, items, setItemsToShow]);

  const hasPreviousPage = !!paginationData.previous;
  const hasNextPage = !!paginationData.next;

  const previousButtonClicked =
    clickedButton.current === "PREVIOUS" && isFetching;
  const nextButtonClicked = clickedButton.current === "NEXT" && isFetching;

  const handlePreviousButtonClick = () => {
    clickedButton.current = "PREVIOUS";
    setPage((page) => page - 1);
  };

  const handleNextButtonClick = () => {
    clickedButton.current = "NEXT";
    setPage((page) => page + 1);
  };

  return (
    <div style={paginationStyling.paginationBox}>
      <div style={paginationStyling.buttonsContainer}>
        <button
          style={paginationStyling.button}
          disabled={!hasPreviousPage || previousButtonClicked}
          onClick={handlePreviousButtonClick}
        >
          {previousButtonClicked ? (
            <div className="button-spinner"></div>
          ) : (
            <div>&lt;</div>
          )}
        </button>
        <button
          style={paginationStyling.button}
          disabled={!hasNextPage || nextButtonClicked}
          onClick={handleNextButtonClick}
        >
          {nextButtonClicked ? (
            <div className="button-spinner"></div>
          ) : (
            <div>&gt;</div>
          )}
        </button>
      </div>
      <div>
        Page {page} of{" "}
        {Math.ceil(paginationData.count / PAGINATION_ITEMS_PER_PAGE)}
      </div>
    </div>
  );
};

const paginationStyling = {
  paginationBox: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    marginTop: "16px",
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  button: {
    border: "1px solid #0b0b3b",
    height: "35px",
    width: "40px",
    borderRadius: "5px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

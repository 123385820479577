import React, { createContext, useReducer } from "react";

import { v1 as uuid } from "uuid";
import { useQueries } from "react-query";

import {
  AllProductsQuery,
  CartSettingsQuery,
  CountryCodesQuery,
  CountryCodesArabicQuery,
} from "../../Queries";
import { sortProducts } from "../../commonCalculations";
import { Spinner } from "../../../../../commonjs/components";
import { useShoppingCart } from "../../ShoppingCart";

export const NonStandardProductContext = createContext({});

const getEmptyRow = () => ({
  rowId: uuid(),
  partialRollSurcharge: 0,
  isPartialRoll: false,
  numOfLabels: 0,
  hasPrintingService: 0,
});

/**
 * This is the beef of the editing of rows: We have several actions to manipulate rows
 * clearRows: removes all rows from the context
 * addRow: adds a new row
 * removeRow: removes the row with the given rowId
 * addPrintingService: adds printingServices to the row with the given rowId
 * setNrOfLabels: changes the number of labels ordered for the row with the given rowId
 * setPartialRoll: sets partial roll to true for this row
 *
 * anything else throws an error
 */
const rowReducer = (state, action) => {
  switch (action.type) {
    case "clearRows":
      return [getEmptyRow()];
    case "addRow":
      return [...state, { ...getEmptyRow() }];
    case "removeRow":
      return state.filter((row) => row.rowId !== action.payload.rowId);
    case "addPrintingService":
      return state.map((row) =>
        row.rowId === action.payload.rowId
          ? {
            ...row,
            hasPrintData: true,
            confirmed: action.payload.confirmed,
          }
          : row
      );
    case "removePrintingService":
      return state.map((row) =>
        row.rowId === action.payload.rowId
          ? {
            ...row,
            hasPrintData: false,
            confirmed: false,
          }
          : row
      );
    case "confirmPrintingService":
      return state.map((row) =>
        row.rowId === action.payload.rowId
          ? {
            ...row,
            confirmed: true,
          }
          : row
      );
    case "unconfirmPrintingService":
      return state.map((row) =>
        row.rowId === action.payload.rowId
          ? {
            ...row,
            confirmed: false,
          }
          : row
      );

    default:
      throw new Error(
        "action.type for manipulating rows not supported",
        action
      );
  }
};

export const NonStandardProductContextProvider = ({ children }) => {
  const shoppingCart = useShoppingCart();

  const queries = useQueries([
    AllProductsQuery,
    CartSettingsQuery,
    CountryCodesQuery,
    CountryCodesArabicQuery,
  ]);

  const allQueriesLoaded = !queries.find((query) => query.isLoading);

  const [rows, dispatchRowEvent] = useReducer(rowReducer, [getEmptyRow()]);

  const clearForm = () => {
    dispatchRowEvent({ type: "clearRows" });
  };

  if (!allQueriesLoaded) {
    return <Spinner />;
  } else {
    //FIXME this is not cool, referencing the results by index!
    const products = queries[0].data;
    const shopSettings = queries[1].data[0];
    const countryCodes = queries[2].data;
    const countryCodesArabic = queries[3].data;

    const nonStandardProducts = products.filter(
      (product) => product.extra_services.length !== 0
    );
    const sortedProducts = sortProducts(nonStandardProducts);

    const blockedDueToNonActivatedProducts = products.find(
      (product) => product.blocked
    );

    const hasUnconfirmedPrintData =
      rows.find((row) => row.hasPrintData && row.confirmed !== true) !==
      undefined;

    const rowHasUnconfirmedPrintData = (rowId) =>
      rows.find(
        (row) =>
          row.rowId === rowId && row.hasPrintData && row.confirmed !== true
      ) !== undefined;

    const contextValues = {
      products: sortedProducts,
      clearForm,
      rows,
      dispatchRowEvent,
      countryCodes,
      countryCodesArabic,
      blockedDueToNonActivatedProducts,
      shopSettings,
      shoppingCart,
      hasUnconfirmedPrintData,
      rowHasUnconfirmedPrintData,
    };
    return (
      <NonStandardProductContext.Provider value={contextValues}>
        {children}
      </NonStandardProductContext.Provider>
    );
  }
};

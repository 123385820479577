import { useLocation } from "react-router-dom";
import { useAuthentication } from "./AuthenticationContext";
import { checkRights } from "./util";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { named_urls } from "../routing/known_urls";

export const AuthorizedContent = ({ children, requires }) => {
  const { isLoggedIn, hasRights } = useAuthentication();

  const { t } = useTranslation();

  const isAuthorized = checkRights(requires, hasRights);
  const location = useLocation();

  if (!isLoggedIn) {
    return (
      <Navigate
        to={named_urls["accounts:login"]}
        state={{ prevUrl: location }}
      />
    );
  }

  if (!isAuthorized) {
    return (
      <div>
        <h1>{t("Unauthorized Access")}</h1>
        <p>{t("You don't have sufficient rights to access this Content.")}</p>
      </div>
    );
  }

  return children;
};

import React, { Component } from "react";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import "react-tabs/style/react-tabs.css";

import { withTranslation } from "react-i18next";
import format from "date-fns/format";

import { DateFilterForQueries } from "../filters/DateFilterForQueries";
import { ByCompany } from "./ByCompany";
import { ByCountry } from "./ByCountry";
import { HitCount } from "./HitCount";

import { VerificationStates } from "./Map/VerificationStates";
import { VerificationStatusFilter } from "./VerificationStatusFilter";
import { DomainFilter } from "./DomainFilter";
import { GeolocationFilter } from "./GeolocationFilter";
import { VerificationsMap } from "./Map/VerificationsMap";
import { LineChart } from "./LineChart/LineChart";

import { api_urls } from "../../../../routing/known_urls";

import { TagFilter } from "./TagFilter";
import { IdFilter } from "./IdFilter";

const downloadLink = (queryParams) => {
  const cleanedParams = {
    ...queryParams,
    order_countries: queryParams.selected_countries,
    date_start:
      queryParams.date_start instanceof Date
        ? format(queryParams.date_start, "yyyy-MM-dd")
        : queryParams.date_start,
    date_end:
      queryParams.date_end instanceof Date
        ? format(queryParams.date_end, "yyyy-MM-dd")
        : queryParams.date_end,
  };

  const urlParams = Object.entries(cleanedParams)
    .filter(
      (entry) => entry[1] !== undefined && (entry[1] > 0 || entry[1].length > 0)
    )
    .map((entry) => entry[0] + "=" + entry[1])
    .join("&");

  return api_urls["statistics:api_verifications_download"] + "?" + urlParams;
};

export class _Authentications extends Component {
  state = {
    holospotId: undefined,
    date_start: new Date("2018-01-01"),
    date_end: new Date(),
    selectedStates: VerificationStates.map((state) => state.status),
    selectedDomains: [],
    selectedGeolocationMethod: "BOTH",
  };

  updateDateRange = (date_start, date_end) => {
    this.setState({ date_start, date_end });
  };

  onSelectVerificationStatus = (selectedStates) => {
    this.setState({ selectedStates });
  };

  onSetHolospotId = (holospotId) => {
    this.setState({ holospotId });
  };

  onSelectDomain = (domains) => {
    // react-select returns null if no option is chosen
    domains = domains || [];
    this.setState({ selectedDomains: domains.map((domain) => domain.value) });
  };

  onSetTags = (tags) => {
    // react-select returns null if no option is chosen
    tags = tags || [];
    this.setState({ selectedTags: tags.map((tag) => tag.value) });
  };

  onSelectGeolocationMethod = (methods) => {
    // react-select returns null if no option is chosen
    methods = methods || [];
    const selectedMethods = methods.map((method) => method.value);
    const selectedGeolocationMethod =
      selectedMethods && selectedMethods.length > 0
        ? selectedMethods.length > 1
          ? "BOTH"
          : selectedMethods[0]
        : "BOTH";

    this.setState({
      selectedGeolocationMethod,
    });
  };

  render() {
    const { t } = this.props;
    const {
      holospotId,
      date_start,
      date_end,
      selectedStates,
      selectedDomains,
      selectedGeolocationMethod,
      selectedTags,
    } = this.state;

    const queryParams = {
      date_start: format(date_start, "yyyy-MM-dd"),
      date_end: format(date_end, "yyyy-MM-dd"),
      verification_status: selectedStates,
      domains: selectedDomains,
      geolocation_method: selectedGeolocationMethod,
      tags: selectedTags,
      _object_id__label_id_or_checksum: holospotId,
    };

    return (
      <div className="authentications">
        <h1>{t("AUTHENTICATIONS")}</h1>
        <div className={"grid query-params filter-panel"}>
          <div className={"grid__item xs-12"}>
            <div className={"grid__item xs-10"} style={{ paddingLeft: "0px" }}>
              &nbsp;
            </div>
            <div className={"grid__item xs-2"} style={{ textAlign: "right" }} />
            <div
              className={"grid__item xs-12"}
              style={{ paddingLeft: "0px", paddingRight: "0px" }}
            >
              <div className={"grid__item xs-5"} style={{ paddingLeft: "0px" }}>
                <DateFilterForQueries
                  onChange={this.updateDateRange}
                  date_start={date_start}
                  date_end={date_end}
                />
              </div>
              <div
                className={"grid__item xs-7"}
                style={{ paddingRight: "0px" }}
              >
                <VerificationStatusFilter
                  onChange={this.onSelectVerificationStatus}
                />
              </div>
            </div>
            <div
              className={"grid__item xs-12"}
              style={{
                paddingRight: "0px",
                paddingLeft: "0px",
                marginTop: "0.5em",
              }}
            >
              <div className={"grid__item xs-5"} style={{ paddingLeft: "0px" }}>
                <GeolocationFilter onChange={this.onSelectGeolocationMethod} />
              </div>
              <div
                className={"grid__item xs-7"}
                style={{ paddingRight: "0px" }}
              >
                <DomainFilter onChange={this.onSelectDomain} />
              </div>
            </div>
            <div
              className={"grid__item xs-12"}
              style={{
                paddingLeft: "0px",
                paddingRight: "0px",
                marginTop: "15px",
              }}
            >
              <div className={"grid__item xs-5"} style={{ paddingLeft: "0px" }}>
                <TagFilter onChange={this.onSetTags} t={t} />
              </div>
              <div className={"grid__item xs-6"} style={{ marginLeft: "15px" }}>
                <IdFilter t={t} setHolospotId={this.onSetHolospotId} />
              </div>
            </div>
            <div
              className={"grid__item xs-12"}
              style={{
                paddingLeft: "0px",
                paddingRight: "0px",
                marginTop: "15px",
              }}
            >
              <div
                className={"grid__item xs-10"}
                style={{
                  paddingRight: "0px",
                  marginTop: "15px",
                  paddingLeft: "0px",
                }}
              >
                &nbsp;
              </div>
              <div
                className={"grid__item xs-2"}
                style={{ textAlign: "right", paddingRight: "0px" }}
              >
                <a
                  href={downloadLink(queryParams)}
                  className="download-button button"
                  style={{ marginRight: "0px", marginTop: "15px" }}
                >
                  {t("DOWNLOAD")}
                </a>
              </div>
            </div>
          </div>
        </div>
        <h3>
          <HitCount queryParams={queryParams} />{" "}
          {t("Verifications match your criteria")}
        </h3>
        <Tabs defaultIndex={0}>
          <TabList>
            <Tab>{t("Verifications by Time")}</Tab>
            <Tab>{t("Map")}</Tab>
          </TabList>
          <TabPanel>
            <div className="line-chart">
              <LineChart t={t} params={queryParams} />
            </div>
          </TabPanel>
          <TabPanel>
            <div className="map" style={{ width: "100%", height: "600px" }}>
              <VerificationsMap
                params={queryParams}
                options={{ hideLegend: false }}
              />
            </div>
          </TabPanel>
        </Tabs>
        <br />
        <div className="rankings">
          <Tabs defaultIndex={0}>
            <TabList>
              <Tab>{t("Rank By Company")}</Tab>
              <Tab>{t("Rank By Country")}</Tab>
            </TabList>
            <TabPanel>
              <ByCompany queryParams={queryParams} />
            </TabPanel>
            <TabPanel>
              <ByCountry queryParams={queryParams} />
            </TabPanel>
          </Tabs>
        </div>
        <br />
      </div>
    );
  }
}

export const Authentications = withTranslation()(_Authentications);

import React, { Component } from "react";

import { withTranslation } from "react-i18next";
import intersection from "lodash/fp/intersection";

import Select from "react-select";

export class _DomainFilter extends Component {
  state = {
    selectedValues: [],
  };

  filter = (selectedOptions) => {
    const { updateFilter, resetFilter, fieldName, filterName } = this.props;

    // react-select returns null if no option is selected
    selectedOptions = selectedOptions || [];

    const values = selectedOptions.map((option) => option.value);

    values.length > 0
      ? updateFilter(filterName, (entry) => {
          return (
            intersection(
              entry[fieldName].split(",").map((entry) => entry.trim()),
              values
            ).length > 0
          );
        })
      : resetFilter(filterName);

    this.setState({ selectedValues: selectedOptions });
  };

  render() {
    const { options, label, placeholder } = this.props;
    const { selectedValues } = this.state;

    const domains = options.reduce((acc, current) => {
      const newDomains = current.label.split(",")
        .map(e => e.trim()) // remove leading/trailing whitespaces
        .filter(e => acc.indexOf(e) === -1); // ignore already added domains
      return [ ...acc, ...newDomains];
    }, []);

    // map to required select format
    const normalizedOptions = domains.sort().map(e => ({label: e, value: e}));

    return (
      <div className={"select-filter"}>
        <label>{label}</label>
        <Select
          isMulti={true}
          openonFocus={true}
          options={normalizedOptions}
          onChange={this.filter}
          value={selectedValues}
          placeholder={placeholder}
        />
      </div>
    );
  }
}

export const DomainFilter = withTranslation()(_DomainFilter);

import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import { withOnMount } from "../../hoc";

import { selectAccounts } from "./duck";
import { localSettings } from "../../settings";

const template = (props) => (
  <div className="account-box right">
    <span className="account-box-line">{props.accounts.email}</span>
    {!localSettings.accountsHideAccountBoxLine ? (
      <span className="account-box-line">
        COFOR6:{" "}
        {props.accounts.company_profile &&
          props.accounts.company_profile.cofor6}
      </span>
    ) : (
      <br />
    )}
    <span className="account-box-line">
      Account:{" "}
      {props.accounts.company_profile && props.accounts.company_profile.cofor10}
    </span>
  </div>
);

const mapStateToProps = (state, ownProps) => ({
  accounts: selectAccounts(state).data,
});

const mapDispatchToProps = (dispatch) => ({});

class _AccountBox extends React.Component {
  render() {
    return template(this.props);
  }
}
export const AccountBox = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withOnMount,
  withTranslation()
)(_AccountBox);

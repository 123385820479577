import React from "react";
import { useTranslation } from "react-i18next";

export const TableHeader = () => {
  const { t } = useTranslation();
  // Translation Data: this is solely used to make sure that the labels get picked up by the translations
  const T = {
    ORDERNUM: t("order No."),
    CUSTOMERORDERNUM: t("your order No."),
    // DELIVERYNUM: t("delivery No."),
    DELIVERYDATE: t("desired delivery"),
    // RECEPTIONDATE: t("reception"),
    LABELSNUM: t("No. of labels"),
    STATUS: t("STATUS"),
    REPEAT: t("REPEAT"),
    CANCEL: t("CANCEL")
  };

  const tableRows = [
    "icon_details",
    T["ORDERNUM"],
    T["CUSTOMERORDERNUM"],
    // T["DELIVERYNUM"],
    T["DELIVERYDATE"],
    // T["RECEPTIONDATE"],
    T["LABELSNUM"],
    T["STATUS"],
    "action_repeat",
    "action_cancel"
  ];

  return (
    <thead>
      <tr>
        {tableRows.map((colName, id) => {
          if (colName.startsWith("action") || colName.startsWith("icon"))
            return (
              <th key={id}>
                <em>&nbsp;</em>
              </th>
            );
          return (
            <th key={id}>
              <em className={colName}>{colName}</em>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

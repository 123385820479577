import { i18n } from "../../i18next";

export function isInvalidPassword(password) {
  if (password.length < 8) {
    return i18n.t("Minimal length is 8");
  }
  // min 8 chars and only printable ASCII characters (no space)
  const re1 = /^[!-~]{8,}$/;
  // 1 digit, 1 lower case letter, 1 special char
  const re2 = /(?=.*\d)(?=.*[a-z])(?=.*\W)/;
  if (re1.test(password) && re2.test(password)) {
    return null;
  }
  return i18n.t("Provide 1 digit, 1 lower case, 1 special char");
}

export function normalizePhone(value) {
  if (!value) {
    return value;
  }
  // allow only digits, +, ., (,), -, [space] in phone numbers
  // eslint-disable-next-line
  return value.replace(/[^\d\+\.\(\) -]/g, "");
}

export function isEmail(email) {
  // see http://emailregex.com/, HTML5 regex - changed last char from * to +
  // to require a .xyz suffix
  const re = /^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9-]+(\.[a-z0-9-]+)+$/;
  return re.test(email.toLowerCase());
}

import React from "react";

import Select from "react-select";

import { plainSelectStyles } from "../Styles";
import { t } from "../../../../i18next";

import { useProductLoader } from "./useProductLoader";

export const ProductFilter = (props) => {
  const { filter } = props;

  const productOptions = useProductLoader();
  const isLoading = !(productOptions && productOptions.length > 0);

  return (
    <label>
      {t("Products")}
      <Select
        defaultValue={t("Choose one or more products")}
        styles={plainSelectStyles}
        options={productOptions}
        isMulti={true}
        closeMenuOnSelect={true}
        className="product-select"
        onChange={(options) => filter(options || [])}
        isLoading={isLoading}
      />
    </label>
  );
};

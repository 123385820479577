import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Column, Table, AutoSizer } from "react-virtualized";
import Tooltip from "react-tooltip";
import { debounce } from "lodash";
import {
  formatToInteger,
  formatToShortDate,
} from "../../../../commonjs/langutils";

import CompanyDetailsLink from "./CompanyDetailsLink";
import { Blocked } from "./Blocked";

const rebuildTooltip = debounce(() => Tooltip.rebuild(), 200, {
  leading: false,
  trailing: true,
});

const CompaniesTable = ({ companies, sort, headerRenderer }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => rebuildTooltip());

  return (
    <div style={{ height: "800px" }}>
      <AutoSizer className={"AutoSizerWrapper statistics-table"}>
        {({ width, height }) => (
          <Table
            width={width}
            height={600}
            headerHeight={48}
            rowHeight={48}
            rowCount={companies.length}
            rowGetter={({ index }) => companies[index]}
            sort={sort}
            sortBy={undefined}
            sortDirection={undefined}
            onScroll={rebuildTooltip}
            on
          >
            <Column
              label=""
              dataKey="cofor6_cofor10" // use combined cofor6/cofor10 as there are still edge cases
              width={45}
              headerRenderer={headerRenderer}
              cellRenderer={(cell) => <CompanyDetailsLink id={cell.cellData} />}
            />
            <Column
              label=""
              dataKey="blocked" // displays the blocking reasons
              width={45}
              headerRenderer={headerRenderer}
              cellRenderer={({ cellData }) => <Blocked reasons={cellData} />}
            />
            <Column
              label={t("Company Name")}
              dataKey="company_name"
              width={320}
              headerRenderer={headerRenderer}
            />
            <Column
              label={t("COFOR6")}
              dataKey="cofor6"
              width={100}
              headerRenderer={headerRenderer}
            />
            <Column
              label={t("COFOR10")}
              dataKey="cofor10"
              width={100}
              headerRenderer={headerRenderer}
            />

            <Column
              width={180}
              label={t("City")}
              dataKey="city"
              headerRenderer={headerRenderer}
            />
            <Column
              width={100}
              label={t("Country")}
              dataKey="country_code"
              headerRenderer={headerRenderer}
            />
            <Column
              width={180}
              label={t("Domain")}
              dataKey="product_groups"
              headerRenderer={headerRenderer}
            />
            <Column
              width={160}
              label={t("Registration")}
              dataKey="registration_date"
              cellRenderer={(entry) => formatToShortDate(entry.cellData)}
              headerRenderer={headerRenderer}
            />
            <Column
              width={160}
              label={t("Ordered Quantity")}
              dataKey="quantity"
              cellRenderer={(entry) =>
                formatToInteger(entry.cellData, i18n.language)
              }
              headerRenderer={headerRenderer}
              style={{ textAlign: "right" }}
            />
          </Table>
        )}
      </AutoSizer>
      <Tooltip type="info" effect="solid" multiline={true} />
    </div>
  );
};

export default CompaniesTable;

import React, { Component } from "react";

import { withTranslation } from "react-i18next";

import {
  Column,
  Table,
  AutoSizer,
  createTableMultiSort,
} from "react-virtualized";
import "react-virtualized/styles.css";

import { sortList, HeaderRenderer, StatusHeaderRenderer } from "../fn";
import {
  AggregatedStates,
  ValidCodes,
  ValidState,
} from "../authentications/Map/VerificationStates";

class _ResultTable extends Component {
  state = {
    data: [],
    selectedStates: [],
    sortDirection: undefined,
    sortBy: undefined,
  };

  sort = ({ sortBy, sortDirection }) => {
    const { data } = this.state;
    const sortedList = sortList({
      list: data,
      sortBy,
      sortDirection,
    });
    this.setState({ data: sortedList, sortBy, sortDirection });
  };

  sortState = createTableMultiSort(this.sort);

  componentDidMount() {
    const { data } = this.props;
    this.setState({ data });
  }

  render() {
    const { t, selectedStates } = this.props;
    const { data } = this.state;

    const statesToShow =
      selectedStates.length > 0
        ? AggregatedStates.filter((state) =>
            selectedStates.includes(state.status)
          )
        : [...AggregatedStates];

    const includesAllValidCodes = ValidCodes.reduce(
      (prev, curr) => prev && selectedStates.includes(curr),
      true
    );

    const includeValidState =
      selectedStates.length === 0 || includesAllValidCodes;

    if (includeValidState) statesToShow.unshift(ValidState);

    return (
      <div className="statistics-table" style={{ height: "600px" }}>
        <AutoSizer className={"AutoSizerWrapper statistics-table"}>
          {({ width, height }) => (
            <Table
              width={width}
              height={height}
              headerHeight={48}
              rowHeight={48}
              rowCount={data.length}
              rowGetter={({ index }) => data[index]}
              sort={this.sortState.sort}
              sortBy={undefined}
              sortDirection={undefined}
            >
              <Column
                label={t("Company Name")}
                dataKey={"company_name"}
                width={250}
                headerRenderer={HeaderRenderer(this.sortState)}
              />
              <Column
                label={t("COFOR(10)")}
                dataKey={"cofor10"}
                width={200}
                headerRenderer={HeaderRenderer(this.sortState)}
              />
              <Column
                label={t("Country")}
                dataKey={"country"}
                width={140}
                headerRenderer={HeaderRenderer(this.sortState)}
              />
              <Column
                label={t("City")}
                dataKey={"city"}
                width={140}
                headerRenderer={HeaderRenderer(this.sortState)}
              />
              <Column
                label={t("Domain")}
                dataKey={"domain"}
                width={140}
                headerRenderer={HeaderRenderer(this.sortState)}
              />

              <Column
                label={t("Reference")}
                dataKey={"reference"}
                width={180}
                headerRenderer={HeaderRenderer(this.sortState)}
              />
              {statesToShow.map((state) => (
                <Column
                  key={"state_column_" + state}
                  label={t(state.description)}
                  dataKey={"status_" + state.status}
                  width={180}
                  headerRenderer={StatusHeaderRenderer(this.sortState)}
                  cellRenderer={(entry) => {
                    return (
                      <span style={{ color: state.color, textAlign: "right" }}>
                        {entry.rowData["status_" + state.status]}
                      </span>
                    );
                  }}
                />
              ))}
            </Table>
          )}
        </AutoSizer>
      </div>
    );
  }
}

export const ResultTable = withTranslation()(_ResultTable);

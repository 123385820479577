import React from "react";
import { useTranslation } from "react-i18next";
import { useAuthentication } from "../commonjs/AuthenticationContext";
import { getTermsOfUse } from "./utils";

export function TermsOfUse() {
  const { t } = useTranslation();
  const { isLoggedIn } = useAuthentication();

  const content = getTermsOfUse(t);

  return (
    <div>
      {!isLoggedIn && <div className="bg-login" />}
      <h1>{t("LEGAL NOTICE / TERMS OF USE")}</h1>
      <section
        className="terms-of-use"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
}

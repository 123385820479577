import React, { useState } from "react";
import ReactModal from "react-modal";
import { useTranslation } from "react-i18next";
import { useFunction } from "../../../commonjs/useFunction";

const Dialog = ({
  isOpen,
  setIsOpen,
  handleOk,
  handleCancel,
  isCriticalAction,
  title,
  children,
}) => {
  const { t } = useTranslation();
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleCancel}
      shouldCloseOnOverlayClick={false}
      className="modal__content"
      overlayClassName="modal__overlay"
      bodyOpenClassName="modal__body--open"
      contentLabel="ModalDialog"
      ariaHideApp={false}
    >
      <div onClick={handleCancel} className="modal-close" />
      <div className="grid">
        <div className="grid__item xs-12">
          <h3>{title}</h3>
        </div>
        <div className="grid__item xs-12">
          <hr />
          <br />
        </div>
        {children}
        <div className="grid__item xs-12">
          <br />
          <br />
        </div>
        <button
          className={`button ${
            isCriticalAction ? "button-critical-action" : "button-primary"
          }`}
          onClick={handleOk}
        >
          {t("YES")}
        </button>
        <button
          className="button button-primary"
          onClick={() => (handleCancel ? handleCancel() : setIsOpen(false))}
        >
          {t("NO")}
        </button>
      </div>
    </ReactModal>
  );
};

export const useDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [onConfirm, setOnConfirm] = useFunction(() =>
    console.log("dialog confirmed")
  );

  return [
    (props) => (
      <Dialog
        isOpen={isOpen}
        handleCancel={() => setIsOpen(false)}
        handleOk={() => {
          onConfirm();
          setIsOpen(false);
        }}
        {...props}
      />
    ),
    ({ confirmed }) => {
      setOnConfirm(confirmed);
      setIsOpen(true);
    },
  ];
};


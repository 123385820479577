import React from "react";
import { i18n } from "../../../../i18next";

// get a certain extra service for a product
export const getExtraServiceFromProduct = (product, extraServiceName) => {
  return product?.extra_services?.find((val) => val.category === extraServiceName);
}

// get a printing extra service for a product
export const getPrintingExtraServiceFromProduct = (product) => {
  return product?.extra_services?.find((val) => val.category === "PRINTING");
}

// extract the extra service fields (printing only) for a given product
export const getExtraServiceFieldsFromProduct = (product) => {
  const printingExtraService = getPrintingExtraServiceFromProduct(product);
  return printingExtraService?.fields ?? [];
}

// this filters out fields that are dependent on values in other fields
const filterExtraServiceFields = (fields, values) => {
  const filteredFields = fields.filter((field) =>
    printingServiceFieldFullfillsDependencies(field, values)
  );
  return filteredFields;
};

export const getFilteredExtraServiceFieldsFromProduct = (product, values) => {
  const fields = getExtraServiceFieldsFromProduct(product);
  return filterExtraServiceFields(fields, values);
}

export const prepareFormFieldsFromProduct = (product, countryCodes) => {
  const rawFields = getExtraServiceFieldsFromProduct(product);

  const countryOptions = countryCodes.map((c) => ({
    value: c.code,
    label: `${c.name} (${c.code})`,
  }));

  const preparedObjects = rawFields.map((field) => {
    let { field_type: fieldType } = field;
    if (fieldType.startsWith("PRODUCT_CODE")) {
      fieldType = "STRING";
    }
    switch (fieldType) {
      case "INTEGER":
        return prepareIntegerField(field);
      case "STRING":
        if (field.options) {
          return prepareSelectField(field, field.options);
        }
        return prepareStringField(field);
      case "BOOLEAN":
        return prepareBooleanField(field);
      case "DATE":
        return prepareDateField(field);
      case "COUNTRY_CODE":
        return prepareSelectField(field, countryOptions);
      default:
        console.warn(`${fieldType} not identified`);
        return `${fieldType} not identified`;
    }
  });
  const sortedObjects = preparedObjects.sort((a, b) => a.index - b.index);
  return sortedObjects;
};

const prepareIntegerField = (field) => {
  const {
    name,
    description,
    validation: { min, max, blank },
    validation_message,
    default: default_value,
    read_only,
    index,
    dependencies,
  } = field;
  const validate = (val) => {
    if (val === undefined) {
      return blank ? "" : i18n.t("Required");
    }
    return val >= min && val <= max ? "" : validation_message;
  };

  return {
    name,
    placeholder: description,
    label: description,
    min,
    max,
    validate,
    default_value,
    read_only,
    component: "input",
    type: "number",
    index,
    dependencies,
  };
};

const prepareStringField = (field) => {
  const {
    name,
    description,
    validation,
    validation_message,
    default: default_value,
    read_only,
    pk,
    index,
    dependencies,
  } = field;
  const validation_regex = new RegExp(validation.regexp);
  const validate = (val) => {
    if (val === undefined) {
      // if string value is undefined, test if empty string is accepted
      val = "";
      return validation_regex.test(val) ? "" : i18n.t("Required");
    }
    return validation_regex.test(val) ? "" : validation_message;
  };
  return {
    name,
    validate,
    component: "input",
    label: description,
    default_value,
    read_only,
    pk,
    index,
    dependencies,
  };
};

const prepareBooleanField = (field) => {
  const {
    name,
    description,
    default: default_value,
    read_only,
    pk,
    index,
    dependencies,
  } = field;
  return {
    name,
    id: name,
    label: description,
    component: "input",
    type: "checkbox",
    default_value,
    read_only,
    pk,
    index,
    dependencies,
  };
};

const prepareDateField = (field) => {
  const {
    name,
    description,
    validation,
    validation_message,
    default: default_value,
    read_only,
    pk,
    index,
    dependencies,
  } = field;
  const validation_regex = new RegExp(validation.regexp);
  const validate = (val) => {
    if (val === undefined) {
      // if string value is undefined, test if empty string is accepted
      val = "";
      return validation_regex.test(val) ? "" : i18n.t("Required");
    }
    return validation_regex.test(val) ? "" : validation_message;
  };
  return {
    name,
    validate,
    id: name,
    label: description,
    component: "input",
    type: "date",
    default_value,
    read_only,
    pk,
    index,
    dependencies,
  };
};

const prepareSelectField = (field, options) => {
  const {
    name,
    description,
    validation,
    validation_message,
    default: default_value,
    read_only,
    pk,
    index,
    dependencies,
  } = field;
  const validation_regex = new RegExp(validation.regexp);
  const validate = (val) => {
    if (val === undefined) {
      // if string value is undefined, test if empty string is accepted
      val = "";
      return validation_regex.test(val) ? "" : i18n.t("Required");
    }
    return validation_regex.test(val) ? "" : validation_message;
  };

  const children = options.map(({ value, label }) => (
    <option value={value} key={value}>
      {label}
    </option>
  ));

  children.unshift(
    <option value="" key={-1}>
      {description}
    </option>
  );
  return {
    name,
    validate,
    component: "input",
    type: "select",
    default_value,
    read_only,
    children,
    pk,
    index,
    dependencies,
  };
};

/**
 *
 * Some fields are dependent on selected values in other fields (e.g. the applicabale product_code depends on
 * the selected supplier_reference):
 * Therefore, we filter all fields to show only the ones that are applicable to the selected values.
 *
 * There is also a null-reference: this makes sure, that a field like the product-code is still shown, even when
 * the supplier_reference has not yet been selected.
 *
 * NOTE: Currently, only dependencies from ONE field are handled.
 */
export function printingServiceFieldFullfillsDependencies(printingServiceField, values) {
  const { dependencies } = printingServiceField;

  // check for dependencies (it is a key / value structure)
  if (
    dependencies === null ||
    typeof dependencies !== "object" ||
    Object.keys(dependencies).length === 0
  ) {
    return true;
  }

  // dependencies - NOTE: only first key is handled currently
  const dependencyKey = Object.keys(dependencies)[0];
  let dependencyValues = dependencies[dependencyKey];
  // might be a single value or an array
  if (!Array.isArray(dependencyValues)) {
    dependencyValues = [dependencyValues];
  }
  // field value (might be null / undefined)
  const value = values[dependencyKey];

  // dependency is fulfilled if on of the options match
  const fulfilled = dependencyValues.some(dependencyValue => {
    if (dependencyValue === null) {
      // check also for undefined for a "null" dependency
      return [null, undefined].includes(value);
    }
    return value === dependencyValue;
  });

  return fulfilled;
}

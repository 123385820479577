// Creates a text string from input data and a display string.
// Input data is provided as a dictionary with key/value pairs
// The display string can contain placeholders in the form of {key}.
// An empty display string results in concatenated values with undefined order.
export function createDisplayText(data, display) {
  let result = "";
  let nonEmptyValue = false;
  if (data) {
    if (!display) {
      // create display string by concatenate data keys in undefined order
      display = ""
      for (let key of Object.keys(data)) {
        display += display + `{${key}}`;
      }
    }
    result = display;
    for (let [key, value] of Object.entries(data)) {
      nonEmptyValue = nonEmptyValue || value;
      result = result.replaceAll(`{${key}}`, value ?? "");
    }
  }
  // if all data values are empty return emptry string
  // NOTE: display might contain some fixed chars
  return nonEmptyValue ? result : "";
}


export function fieldCanBeFormatted(field) {
  return field.field_type.startsWith("PRODUCT_CODE") || field.format;
}


export function getFont({ size, fontScalingFactor, scalingFactor, narrow = false, bold = false }) {
  return (
    size * fontScalingFactor * scalingFactor +
    "px " +
    (narrow ? "'Liberation Sans Narrow" : "'Liberation Sans") +
    (bold ? " Bold'" : "'") +
    ", sans-serif"
  );
}


import React, { Component } from "react";
import { isAfter, isBefore, parseISO } from "date-fns";
import fr from "date-fns/locale/fr";
import enUS from "date-fns/locale/en-US";
import DatePicker, { registerLocale } from "react-datepicker";
import { Icon } from "../../../../commonjs/icons";

import { withTranslation } from "react-i18next";

registerLocale("en", enUS);
registerLocale("en-US", enUS);
registerLocale("fr", fr);

const FILTER_NAME = "DATE_RANGE_FILTER";

class _DateFilter extends Component {
  state = {
    fromDate: new Date("2017-01-01T00:00:00"),
    toDate: new Date(),
  };

  minDate = new Date("2017-01-01T00:00:00");

  changeFromDate = (newDate) => {
    const { toDate } = this.state;
    this.setState({ fromDate: newDate });
    this.updateDateRange(newDate, toDate);
  };

  changeToDate = (newDate) => {
    const { fromDate } = this.state;
    this.setState({ toDate: newDate });

    this.updateDateRange(fromDate, newDate);
  };

  updateDateRange = (fromDate, toDate) => {
    const { updateFilter, filterColumn } = this.props;

    updateFilter(FILTER_NAME, (entry) => {
      const compareDate = parseISO(entry[filterColumn]);

      return isAfter(compareDate, fromDate) && isBefore(compareDate, toDate);
    });
  };

  render() {
    const { t, i18n } = this.props;
    const { fromDate, toDate } = this.state;

    return (
      <div className={"select-filter"}>
        <Icon
          icon={"calendar-alt"}
          size="2x"
          style={{
            float: "left",
            marginLeft: "6px",
            marginTop: "24px",
            marginRight: "6px",
          }}
        />
        <div className="query-param from-date">
          <label style={{ marginRight: "2px" }}>{t("from")}</label>
          <DatePicker
            locale={i18n.language}
            readOnly={false}
            minDate={this.minDate}
            selected={fromDate}
            onChange={this.changeFromDate}
          />
        </div>
        <div className="query-param to-date">
          <label style={{ marginLeft: "4px", marginRight: "2px" }}>
            {t("to")}
          </label>
          <DatePicker
            locale={i18n.language}
            readOnly={false}
            minDate={fromDate}
            selected={toDate}
            onChange={this.changeToDate}
          />
        </div>
      </div>
    );
  }
}

export const DateFilter = withTranslation()(_DateFilter);

import React from "react";

import { Toaster } from "react-hot-toast";
import { connect } from "react-redux";
import Notifications from "react-notification-system-redux";

import { useTranslation } from "react-i18next";
import { Link, BrowserRouter, useLocation, Navigate } from "react-router-dom";

import { MainRoutes } from "../routing/routes";

import { NavigationMain } from "./NavigationMain";
import { NavigationSide } from "./NavigationSide";

import { AccountBox } from "../licensing/accounts";
import { useAuthentication } from "../commonjs/AuthenticationContext";

import { Footer } from "./footer";

const notificationStyle = {
  NotificationItem: {
    DefaultStyle: {
      fontSize: "16px",
    },
  },
  Title: {
    DefaultStyle: {
      fontSize: "16px",
    },
  },
};
const mapStateToProps = (state) => ({
  notifications: state.notifications,
});

const ReduxNotifications = connect(mapStateToProps)((props) => (
  <Notifications
    notifications={props.notifications}
    style={notificationStyle}
  />
));

const RedirectToPrevUrl = ({ children }) => {
  const location = useLocation();
  const { isLoggedIn } = useAuthentication();

  if (isLoggedIn && location.state?.prevUrl) {
    return <Navigate to={location.state.prevUrl} />;
  } else return children;
};

export const Layout = () => {
  const { isLoggedIn, hasRights } = useAuthentication();
  const { t } = useTranslation();

  return (
    <BrowserRouter>
      <RedirectToPrevUrl>
        <div className="container">
          <Toaster />
          <header className="header">
            <div className="grid vertical-spacing-half">
              <div className="grid__item xs-12">
                <Link className="logo left" to="/" />
                <div className="claim">
                  <div className="claim-firstline">
                    {t("Stellantis - Secure Labels")}
                  </div>
                  <div className="claim-secondline">
                    {t("ordering platform")}
                  </div>
                </div>
                <NavigationSide />
              </div>
            </div>

            <hr />

            {!isLoggedIn ? (
              ""
            ) : (
              <div>
                <div className="grid">
                  <div className="grid__item left  xs-6 md-9 ">
                    <NavigationMain hasRights={hasRights} />
                  </div>

                  <div className="grid__item right xs-6 md-3 vertical-spacing-half">
                    <AccountBox />
                  </div>
                </div>
                <hr className="nav-main-hr" />
              </div>
            )}
          </header>

          {/* ROUTING / MAIN CONTENT includes staging mode display */}
          <main className="grid">
            <div className="grid__item xs-12">
              <MainRoutes />
            </div>
          </main>

          <Footer />
        </div>
        <ReduxNotifications />
      </RedirectToPrevUrl>
    </BrowserRouter>
  );
};

import React, { Component } from "react";

import { withTranslation } from "react-i18next";
import Select from "react-select";

import { endpoint } from "../../../../commonjs/endpoints";
import { plainSelectStyles } from "../Styles";

class _SupplierFilter extends Component {
  state = {
    selectedSuppliers: [],
    isLoading: true,
    supplierOptions: [],
  };

  componentDidMount() {
    endpoint("statistics:api_suppliers").then((result) => {
      this.setState({
        supplierOptions: result.data.map((entry) => ({
          label: entry.name,
          value: entry.id,
        })),
        isLoading: false,
      });
    });
  }

  onSelectSupplier = (options) => {
    const { filter } = this.props;
    filter(options || []);
  };

  render() {
    const { t } = this.props;
    const { isLoading, supplierOptions } = this.state;

    return (
      <label>
        {t("Supplier")}
        <Select
          defaultValue={t("Choose one or more suppliers")}
          styles={plainSelectStyles}
          options={supplierOptions}
          isMulti={true}
          closeMenuOnSelect={true}
          className="supplier-select"
          onChange={this.onSelectSupplier}
          isLoading={isLoading}
        />
      </label>
    );
  }
}

export const SupplierFilter = withTranslation()(_SupplierFilter);

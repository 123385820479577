import React, { useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { accountsSendPasswordReset } from "./duck";
// 2023-11-29 stf login still runs over redux, so this is our last connection to rdx 
import { store as reduxStore } from "../..";
import {accountsLogout} from "./duck"



const PasswordExpiredRequestWrapped = ({ accountsSendPasswordReset }) => {
  const { t } = useTranslation();
  const { email, account_id } = useParams();

  useEffect(
    () => {
      accountsSendPasswordReset({
        email,
        account_id,
        suppress_notification: true,
      });
      reduxStore.dispatch(accountsLogout());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <section className="password-expired-request">
      <div className="bg-login" />
      <h1>{t("YOUR PASSWORD HAS EXPIRED")}</h1>
      <section>
        <span>
          {t(
            "An email with a password reset link has been sent to your email address: "
          )}{" "}
          {email}.
        </span>
        <br />
        <span>
          {t(
            "Please reset your password by clicking on the reset link and enter a new password."
          )}
        </span>
      </section>
    </section>
  );
}

const mapDispatchToProps = (dispatch) => ({
  accountsSendPasswordReset: (data) => {
    dispatch(accountsSendPasswordReset(data));
  },
});

class ReduxWrapper extends React.Component {
  render() {
    return <PasswordExpiredRequestWrapped {...this.props} />;
  }
}
export const PasswordExpiredRequest = compose(
  connect(null, mapDispatchToProps),
)(ReduxWrapper);

import React from "react";
import Query from "../Query";

import { withTranslation } from "react-i18next";

const _ByCountry = (props) => {
  const { t, queryParams } = props;

  return (
    <Query
      debug={false}
      query={"statistics:api_verifications"}
      method={"POST"}
      params={{ ...queryParams, aggregation: "by_country" }}
    >
      {(result) => (
        <table className="table">
          <thead>
            <tr>
              <td>{t("Authentifications")}</td>
              <td>{t("Country")}</td>
            </tr>
          </thead>
          <tbody>
            {result.data.map((hit) => (
              <tr key={hit.key}>
                <td>{hit.doc_count}</td>
                <td>{hit.key}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </Query>
  );
};

export const ByCountry = withTranslation()(_ByCountry);

import { useReducer } from "react";
import { toast } from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import uuid from "uuid/v4";

import { endpoint } from "../../commonjs/endpoints";
import { useTranslation } from "react-i18next";

const addDistribution = ({ distributions, recipient, rolls }) => {
  const existingDistribution = distributions.find(
    (dist) => dist.recipient.pk === recipient.pk
  );

  if (existingDistribution) {
    return [
      ...distributions.filter((dist) => dist.recipient.pk !== recipient.pk),
      {
        uuid: uuid(),
        rolls: rolls.concat(existingDistribution.rolls),
        recipient,
      },
    ];
  } else {
    return [...distributions, { uuid: uuid(), recipient, rolls }];
  }
};
export const distributionsReducer = (state, action) => {
  switch (action.type) {
    case "addDistribution":
      return addDistribution({
        distributions: state,
        recipient: action.payload.recipient,
        rolls: action.payload.rolls,
      });
    case "removeDistribution":
      return state.filter(
        (distribution) => distribution.uuid !== action.payload.uuid
      );
    case "clearDistributions":
      return [];
    default:
      console.log(`action of type ${action} is not supported`, action.type);
      return state;
  }
};

export const useDistributions = () => {
  const { t } = useTranslation();
  const notify = (msg) => toast.success(msg, { duration: 5000 });
  const [state, dispatch] = useReducer(distributionsReducer, []);

  const queryClient = useQueryClient();
  const { mutate: sendDistributions } = useMutation(() => {
    endpoint("distribution:api_distribution_list", "POST", {
      body: {
        distribution_entries: state.map((distribution) => ({
          distribution_address: distribution.recipient.pk,
          comment: distribution.comment,
          spool_entries: distribution.rolls.map((roll) => ({
            spool_no: roll.spool_no,
            comment: roll.comment,
          })),
        })),
      },
    }).then((result) => {
      notify(t("Distributions have been saved."));
      dispatch({ type: "clearDistributions" });
      queryClient.invalidateQueries({ queryKey: ["spoolList"] });
    });
  });

  return {
    distributions: state,
    addDistribution: ({ rolls, recipient }) =>
      dispatch({ type: "addDistribution", payload: { rolls, recipient } }),
    removeDistribution: (uuid) =>
      dispatch({ type: "removeDistribution", payload: { uuid } }),
    rollContaintainedInDistribution: (roll) =>
      state
        .map((entry) => entry.rolls)
        .flat()
        .find((entry) => roll.pk === entry.pk),
    sendDistributions: () => sendDistributions(),
  };
};

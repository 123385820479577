// NOTE: don't change identation etc. as this will require the update of the translations
export function getTermsOfUse(t) {
  return t(`
  <p>
    The site accessible at p-sl.com (the “Site”) is intended for ordering security labels applied on packaging of spare parts commercialized by Automobiles Peugeot, Automobiles Citroën, Automobiles DS and Euro Repar Car Service SAS. Access to and use of this Site is subject to the detailed “terms of use“ set out below, and to applicable laws and/or regulations. By connecting to this Site you unconditionally and unreservedly accept these terms of use in full.
  </p>
  <p>
  The Site/application is published by SCRIBOS GmbH with the following address: SCRIBOS GmbH, Sickingenstraße 65, 69126, Heidelberg, Germany registered with the Heidelberg, Germany, Register of Companies under the following number HRB337200.
  </p>
  <p>
  The Site/application is published by SCRIBOS GmbH and designed for Peugeot S.A, French company	located to: 7, rue Sainte-Claire Deville, 92500 RUEIL-MALMAISON with share capital of 904 828 213€ registered with the Nanterre Register of companies under number: 552 100 554.
  </p>
  <p>Director of Publication: Christophe Musy</p>
  <p>Hosting Company: SCRIBOS GmbH</p>
  
  <h2>Intellectual property rights</h2>
  
  <p>The Site owner warns users that the majority of the Site content:</p>
  <ol>
  <li>
    is protected by copyright, whether in the form of photographs, articles, designs, animated sequences or recordings in digital audio or video format,
  </li>
  <li>
    is protected by trademark law, more particularly the “Peugeot” trademark, the “lion” logo, the	“Citroën” trademark, the “double chevron” logo, the “DS” logo, the ”Eurorepar” trademark and its logo, the semi-figurative trademark “PSA Group”, together with all other brands of the Group displayed on the Site.
  </li>
  </ol>
  
  <p>
  The protected content is owned by Peugeot S.A, by its subsidiaries or by third parties which have authorized the Site owner to use this content on the Site.
  </p>
  <p>
  Any reproduction, representation, use, adaptation, modification, inclusion, translation or marketing, whether in whole or in part, in any manner whatsoever and by any means whatsoever (paper, digital, etc.) of the protected elements on the Site is prohibited, unless approved in advance and in writing by Peugeot S.A, apart from those exceptions provided for in article 122-5 of the French Intellectual Property Code (Code de la Propriété Intellectuelle). In all other circumstances any such use will be deemed an infringement of copyright and/or of rights over designs and models and/or trademarks, punishable by a term of imprisonment of three (3) years and a fine of three hundred thousand euros (€300,000) in the case of copyright infringement, and a term of imprisonment of four (4) years and a fine of four hundred thousand euros (€400,000) in the case of trademark infringement.
  </p>
  <h2>Information on products and services</h2>
  <p>
  The Site/application is intended for ordering security labels applied on packaging of spare parts commercialized by Automobiles Peugeot, Automobiles Citroën and Automobiles DS and Euro Repar Car Service SAS.
  </p>
  <p>
  The information displayed on the Site is in any event of a general nature and is not contractually binding.
  </p>
  
  <h2>Protection of personal data and cookies</h2>
  
  <h3>1 – Personal Data:</h3>
  <p>
  In order to access certain functions on our website you may be required to input some items of personal information. These items of personal information may include: name, email address, and other contact information. We may collect and process any personal information that you voluntarily provide to us in this way, but we will only do so in accordance with this Privacy Policy.
  </p>
  <p>
  We may share your personal information with other entities within our international group of companies (“Our Group”). We may also share your personal information with third parties who provide a service to us or Our Group.
  </p>
  <p>
  We may also share your personal information with third parties where we are required to do so for location purposes and for the prevention or detection of fraud.
  </p>
  <p>
  Please note that, other than the third parties listed in this Privacy Policy, we will not disclose or sell your personal information to any other third party for marketing purposes.
  </p>
  <p>
  In accordance with the French Data Protection Act (loi Informatique et Libertés, n° 78-17 of 6 January 1978), user of the site is informed and accepts:
  </p>
  <ol class="lower-alpha">
  <li>
    Some personal data is necessary in order to collect, to process your request concerning the creation of account or in case of suspicious counterfeiting label.
  </li>
  <li>
    These personal data could be used to investigate, to analyze or for the purpose of better understanding your needs and improving our service offer.
  </li>
  <li>These data are stored 3 years starting with the year’s collection.</li>
  </ol>
  
  <p>
  Data collected are processed electronically. You have the right to access, change, correct or remove your personal data in accordance with Data Protection Act.
  </p>
  
  <p>
  You may exercise this right by writing to us at Peugeot S.A: 7 rue Sainte, Claire Deville, 92500 Rueil-Malmaison.
  </p>
  
  <h3>2 – Cookies:</h3>
  
  <p>Visitors are informed that the Site uses cookies.</p>
  
  <p>
  This section explains what cookies are, how they are used on our website and how you can manage and	remove them if you chose to.
  </p>
  
  <p>
  Cookies are small files send from our website to your device, such as your computer or mobile phone. They remember you and your personal preferences such as your location, which helps us to improve your experience on our site. Cookies served from our site can only be read by us and we will not share this information with any third parties.
  </p>
  
  <p>We only use cookies for anonymous analytics. We do not use any third party cookies.</p>
  
  <p>
  Most websites will send cookies to your device if your browser settings allow it. You can change these settings yourself.
  </p>
  
  <p>To find out more about cookies and what they do, please visit www.aboutcookies.org.</p>
  
  <h2>Disclaimer</h2>
  
  <p>
  Your use of this website is at your sole and entire risk. We, and our group companies, will have no liability for any direct, indirect, incidental, consequential or other damages, including any material loss, loss of data or financial loss, arising out of or in connection with the use of this website or any associated websites, the information contained in them and / or any action taken in reliance on them.
  </p>
  
  <p>
  We shall not be responsible for any damage caused to or any loss suffered by user of this website resulting from any viruses or other items of a destructive nature that may infect devices or other property as a result of the use of this website or the downloading of any of its contents.
  </p>
  
  <p>
  We do not exclude or limit our liability to you where it would be unlawful to do so. This includes (i) death or persona injury caused by our negligence or the negligence of our employees, agents or sub-contractors and (ii) for fraud or fraudulent misrepresentation.
  </p>
  
  <h2>User responsibility</h2>
  
  <p>
  You are responsible for configuring your information technology, computer programs and platform to access this website. You should use your own virus protection software. We do not guarantee that this website will be secure or free from bugs or viruses.
  </p>
  <p>
  You must not misuse this website by knowingly introducing viruses, trojans, worms, logic bombs or other	material that is malicious or technologically harmful. You must not attempt to gain unauthorized access to this website, the server on which this website is stored or any server, computer or database	connected to this website. You must not attack this website via a denial-of- service attack or a distributed denial-of-service attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use this website will cease immediately.
  </p>
  
  <h2>Hypertext links</h2>
  
  <p>
  The prior written authorization of SCRIBOS and Peugeot S.A is necessary for the creation of any hypertext link to the Site.
  </p>
  
  <p>
  SCRIBOS and Peugeot S.A will not in any event have any liability for the content or products and services offered on the sites to which the Site is linked by either hypertext links or any other type of link.
  </p>
  
  <h2>Governing law and jurisdiction</h2>
  
  <p>
  The Site and these terms of use are governed by French law, excluding rules relating to the conflict of laws. In all matters of opinion or interpretation of information, the original language version in French takes precedence over the translation.
  </p>
  
  <p>
  Any dispute arising in connection with the interpretation or performance of these terms of use will be referred to the exclusive jurisdiction of the Paris courts, subject however to any contrary mandatory provisions.
  </p>
  `);
}

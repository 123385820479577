import React from "react";

import { withTranslation } from "react-i18next";
import { ModalDialog, ModalAlert } from "../../../commonjs/modals";

const _ModalOrderSwitch = ({ t, order, handleCancel, handleOk }) => {
  return (
    <ModalDialog
      isOpen={true}
      handleOk={handleOk}
      handleCancel={handleCancel}
      isCriticalAction={true}
      title={t("Switching between Standard- and Non-Standard-Orders")}
    >
      <div className="grid__item xs-12">
        {t(
          "Switching between Standard- and Non-Standard-products will reset your current order - are you sure you want to do this?"
        )}
      </div>
    </ModalDialog>
  );
};

export const ModalOrderSwitch = withTranslation()(_ModalOrderSwitch);

const _OrderAlert = ({ title, message, t, order, close }) => {
  return (
    <ModalAlert
      isOpen={true}
      isCriticalAction={true}
      title={title}
      close={close}
      message={message}
    />
  );
};

export const OrderAlert = withTranslation()(_OrderAlert);

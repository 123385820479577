import React, { Component } from "react";


import { withTranslation } from "react-i18next";

import { compose } from "redux";
import { connect } from "react-redux";

import Select from "react-select";

class _CountryFilter extends Component {
  state = {
    selectedCountries: [],
    availableCountries: [],
  };

  filterCountries = (selectedOptions) => {
    const { updateFilter } = this.props;

    updateFilter((selectedOptions || []).map((entry) => entry.value));
    this.setState({ selectedCountries: selectedOptions || [] });
  };

  componentDidUpdate(prevProps) {
    const { countryCodes } = this.props;
    const { availableCountries } = this.state;

    if (availableCountries.length < countryCodes.length) {
      const availableCountries = countryCodes
        .map((entry) => ({
          label: entry.name + " (" + entry.code + ")",
          value: entry.code,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      this.setState({ availableCountries });
    }
  }

  render() {
    const { t } = this.props;
    const { availableCountries, selectedCountries } = this.state;
    return (
      <div className={"select-filter"}>
        <label>{t("Country")}</label>
        <Select
          isMulti
          simpleValue
          openOnFocus={true}
          options={availableCountries}
          onChange={this.filterCountries}
          value={selectedCountries}
          placeholder={t("Select one or more countries")}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    countryCodes:
      state.settings && state.settings.countryCodes
        ? state.settings.countryCodes
        : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export const CountryFilter = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(_CountryFilter);

import React from "react";
import { ModalDisableCompanyUsers } from "./ModalDisableCompanyUsers";
import { ModalEnableCompanyUsers } from "./ModalEnableCompanyUsers";

// see https://stackoverflow.com/questions/35623656/how-can-i-display-a-modal-dialog-in-redux-that-performs-asynchronous-actions/35641680

const MODAL_COMPONENTS = {
  DISABLE_COMPANY_USERS: ModalDisableCompanyUsers,
  ENABLE_COMPANY_USERS: ModalEnableCompanyUsers
};

export const ModalRoot = ({ modalType, modalProps }) => {
  if (!modalType) {
    return null;
  }
  const SpecificModal = MODAL_COMPONENTS[modalType];
  return <SpecificModal {...modalProps} />;
};

import React from "react";

import Query from "../Query";
import { withTranslation } from "react-i18next";

const _ByCompany = props => {
  const { t, queryParams } = props;

  return (
    <Query
      debug={false}
      query={"statistics:api_verifications"}
      method={"POST"}
      params={{ ...queryParams, aggregation: "by_company" }}
    >
      {result => (
        <table className="table">
          <thead>
            <tr>
              <th style={{ width: "1%", minWidth: "20px" }}>{t("Rank")}</th>
              <th style={{ width: "30%" }}>{t("Company Name")}</th>
              <th style={{ width: "20%" }}>{t("COFOR(10)")}</th>
              <th style={{ width: "20%" }}>{t("City")}</th>
              <th style={{ width: "20%" }}>{t("Country")}</th>
              <th style={{ width: "19%" }}>{t("Nr. of Checks")}</th>
            </tr>
          </thead>
          <tbody>
            {result.data.map((hit, idx) => (
              <tr key={idx}>
                <td
                  style={{ width: "1%", minWidth: "20px", textAlign: "right", paddingRight: "20px" }}
                >
                  {idx + 1}
                </td>
                <td>{hit.company.name}</td>
                <td>{hit.company.cofor10}</td>
                <td>{hit.company.delivery_address.city}</td>
                <td>{hit.company.delivery_address.country}</td>
                <td style={{ textAlign: "right" }}>
                  {hit.verifications}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </Query>
  );
};

export const ByCompany = withTranslation()(_ByCompany);

import React, { Component } from "react";

import isEqual from "lodash/isEqual";
import { format } from "date-fns";

import { Map, TileLayer, LayersControl, LayerGroup } from "react-leaflet";

import { remove } from "lodash";

import ClusteredPieMarkers from "./ClusteredPieMarkers";
// import sampleChartData from "./fullSampleChartData.json";
import "leaflet/dist/leaflet.css";

/* currently not used, keep as reference
import CustomMapControl, {
  PieLegend,
  LocationBase,
  MapControlContainer
} from "./MapControl";
*/

import { markersQuery } from "./StatisticsQuery";

import {
  VerificationStates,
  ValidCodes,
  ValidState,
} from "./VerificationStates";

const { BaseLayer, Overlay } = LayersControl;

const defaultLayoutConfig = {
  height: "600px",
  width: "100%",
};

const getPieChartPrecision = (zoom) => {
  const mapping = [
    { precision: 2, zoomLevel: [0, 4] },
    { precision: 3, zoomLevel: [4, 6] },
    { precision: 4, zoomLevel: [6, 7] },
    { precision: 5, zoomLevel: [7, 8] },
    { precision: 6, zoomLevel: [8, 10] },
    { precision: 7, zoomLevel: [10, 14] },
    { precision: 8, zoomLevel: [14, 15] },
    { precision: 9, zoomLevel: [15, 100] },
  ].find((map) => zoom > map.zoomLevel[0] && zoom <= map.zoomLevel[1]);
  return mapping !== undefined ? mapping.precision : 2;
};

const normalizeLongitude = (lon) => {
  if (lon < -180) return -180;
  if (lon > 180) return 180;

  return lon;
};
const normalizeLatitude = (lat) => {
  if (lat < -90) return -90;
  if (lat > 90) return 90;

  return lat;
};



// TODO need to revisit the map to either clean this up or nuke it
//eslint-disable-next-line
const filterData = (chartData, disabledKeys, excludeStates) => {
  if (!("buckets" in chartData)) {
    let singlePinsData = {};
    let bucketData = chartData.data;
    let singleBucketKey = chartData.bucket_key;
    Object.keys(bucketData).forEach((key) => {
      if (!disabledKeys.includes(bucketData[key][singleBucketKey])) {
        singlePinsData[key] = bucketData[key];
      }
    });

    return singlePinsData;
  }
  let filteredData = [];
  let bucketData = chartData.buckets;
  let bucketKey = chartData.bucket_key;

  bucketData.forEach((data) => {
    let total_count_subtract = 0;
    let statuses = data[bucketKey].buckets.filter(
      (value) => ![...disabledKeys, ...excludeStates].includes(value.key)
    );
    disabledKeys.forEach((key) => {
      let count = data[bucketKey].buckets.find((value) => value.key === key);
      total_count_subtract += count ? count.doc_count : 0;
    });

    let doc_count = data.doc_count - total_count_subtract;
    if (doc_count > 0) {
      filteredData.push({
        center_lat: data.center_lat,
        center_lon: data.center_lon,
        status: {
          buckets: statuses,
        },
        doc_count: doc_count,
      });
    }
  });

  return filteredData;
};

const baseLayers = (options) => {
  const baseLayers = [];

  baseLayers.push(
    <BaseLayer name={"English"} checked key={1}>
      <TileLayer
        url="https://{s}.maps.tcnc.eu/1748f2449db243539fbfeb7214b4a0a7/{z}/{x}/{y}.png"
        attribution='Tiles Courtesy of OpenStreetMap <http://www.openstreetmap.org/copyright> and contributors, <a href="http://wiki.openstreetmap.org/wiki/Legal_FAQ#3a._I_would_like_to_use_OpenStreetMap_maps._How_should_I_credit_you.3F" target="_blank">ODb License</a>.'
        maxZoom={18}
        subdomains={["a", "b", "c"]}
      />
    </BaseLayer>
  );

  if (options === undefined || !options.hideLegend) {
    baseLayers.push(
      <BaseLayer name={"German"} key={2}>
        <TileLayer
          url="https://{s}.maps.tcnc.eu/0c0afd09f349435cbfd25dc0a7e274c8/{z}/{x}/{y}.png"
          attribution='Tiles Courtesy of OpenStreetMap <http://www.openstreetmap.org/copyright> and contributors, <a href="http://wiki.openstreetmap.org/wiki/Legal_FAQ#3a._I_would_like_to_use_OpenStreetMap_maps._How_should_I_credit_you.3F" target="_blank">ODb License</a>.'
          maxZoom={18}
          subdomains={["a", "b", "c"]}
        />
      </BaseLayer>,
      <BaseLayer name={"Localized"} key={3}>
        <TileLayer
          url="https://{s}.maps.tcnc.eu/14891ecd30bc4233bf2c45f9560c6385/{z}/{x}/{y}.png"
          attribution='Tiles Courtesy of OpenStreetMap <http://www.openstreetmap.org/copyright> and contributors, <a href="http://wiki.openstreetmap.org/wiki/Legal_FAQ#3a._I_would_like_to_use_OpenStreetMap_maps._How_should_I_credit_you.3F" target="_blank">ODb License</a>.'
          maxZoom={18}
          subdomains={["a", "b", "c"]}
        />
      </BaseLayer>
    );
  }
  return baseLayers;
};

class _VerificationsMap extends Component {
  constructor(props) {
    super(props);
    this.map = React.createRef();
  }

  state = {
    zoom: 2,
    bounding_box: undefined,
    disabledKeys: [],
    locationBase: "all",
    chartData: [],
  };

  componentDidMount = () => {
    this.loadData();
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    let oldState = {
      ...prevState,
      chartData: null,
    };
    let newState = {
      ...this.state,
      chartData: null,
    };

    if (!isEqual(oldState, newState) || !isEqual(prevProps, this.props)) {
      this.loadData();
    }
  };

  sendErrorMessage = (errors) => {
    const hasError = Object.keys(errors).length !== 0;
    if (hasError) {
      console.log(
        "An Error in the Map occurred.",
        Object.keys(errors).map((key) => errors[key])
      );
    }
  };

  loadData = () => {
    const { zoom } = this.state;
    const { bounding_box, locationBase } = this.state;
    const {
      date_start,
      date_end,
      verification_status,
      geolocation_method,
      domains,
      spool_id,
      company_ids,
      selected_countries,
      customer_references,
      tags,
      _object_id__label_id_or_checksum,
    } = this.props.params;

    let queryParams = {
      date_start:
        date_start instanceof Date
          ? format(date_start, "yyyy-MM-dd")
          : date_start,
      date_end:
        date_end instanceof Date ? format(date_end, "yyyy-MM-dd") : date_end,
      verification_status: verification_status,
      geolocation_method: geolocation_method,
      domains: domains,
      precision: getPieChartPrecision(zoom),
      accuracy: locationBase,
      hits: zoom > 8 ? 1 : undefined,
      bounding_box: bounding_box,
      spool_id,
      company_ids,
      order_countries: selected_countries,
      customer_references,
      tags,
      _object_id__label_id_or_checksum,
    };

    markersQuery(queryParams).then((result) => {
      this.setState({ chartData: result });
    });
  };

  getBoundingBox = () => {
    const bounds = this.map.current.leafletElement.getBounds();
    const topRight = bounds.getNorthEast();
    const bottomLeft = bounds.getSouthWest();

    return [
      normalizeLatitude(topRight.lat),
      normalizeLongitude(topRight.lng),
      normalizeLatitude(bottomLeft.lat),
      normalizeLongitude(bottomLeft.lng),
    ];
  };

  onLegendInteraction = (disabledKeys) => {
    this.setState({ disabledKeys: disabledKeys });
  };

  onLocationChange = (location) => {
    this.setState({ locationBase: location });
  };

  handleMoveEnd = (event) => {
    const zoom = event.target._zoom;
    const bounding_box = this.getBoundingBox();

    if (!isEqual(this.state.bounding_box, bounding_box)) {
      this.setState({ zoom: zoom, bounding_box: bounding_box });
    }
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    const newProps = { dataset: nextProps.dataset, params: nextProps.params };
    const oldProps = { dataset: this.props.dataset, params: this.props.params };

    return !isEqual(nextState, this.state) || !isEqual(oldProps, newProps)
      ? true
      : false;
  };

  onOverlayAdd = (layer) => {
    let overlays = [...this.state.overlays];
    overlays.push(layer.name);
    this.setState({
      overlays: overlays,
    });
  };

  onOverlayRemove = (layer) => {
    let overlays = [...this.state.overlays];
    let index = overlays.indexOf(layer.name);
    if (index > -1) {
      overlays.splice(index, 1);
      this.setState({
        overlays: overlays,
      });
    }
  };

  // Groups all valid codes into an aggregated hit
  groupStatusCodes(data) {
    const grouped = data.map((entry) => {
      const validBuckets = remove(entry.status.buckets, (bucket) =>
        ValidCodes.includes(bucket.key)
      );

      const validCodeBucket = {
        key: ValidState.status,
        doc_count: 0,
        single_checks: { hits: { hits: [], total: 0 } },
      };
      const aggregatedBucket = validBuckets.reduce((prev, curr) => {
        const aggregated = {
          ...prev,
          doc_count: prev.doc_count + curr.doc_count,
          single_checks: {
            hits: {
              hits: prev.single_checks.hits.hits.concat(
                curr.single_checks.hits.hits
              ),
              total:
                prev.single_checks.hits.total + curr.single_checks.hits.total,
            },
          },
        };
        return aggregated;
      }, validCodeBucket);

      // There is a weird edge-case on IE11/Edge, that happens when a 0-doc-count-bucket
      // is added to the aggregations: this additional, empty bucket prevents an svg-fix from being applied
      if (aggregatedBucket.doc_count > 0) {
        entry.status.buckets.push(aggregatedBucket);
      }

      return entry;
    });
    return grouped;
  }

  render() {
    const { zoom, chartData } = this.state;
    const { options, layoutConfig } = this.props;
    const mergedlayoutConfig = { ...defaultLayoutConfig, ...layoutConfig };

    const stateInfos = {
      states: VerificationStates.concat(ValidState),
      excludeStates: [1, 2],
    };

    // the query can either return a geohashed/aggregated arrray of hits or single hits
    const filteredChartData = Array.isArray(chartData)
      ? this.groupStatusCodes(chartData)
      : chartData;

    const clusters = (
      <Overlay name="Clusters" checked>
        <LayerGroup>
          <ClusteredPieMarkers
            data={filteredChartData}
            zoom={zoom}
            aggregationCategories={stateInfos.states}
            aggregationKey={"status"}
            map={this.map}
          />
        </LayerGroup>
      </Overlay>
    );
    return (
      <Map
        ref={this.map}
        minZoom={2}
        maxZoom={18}
        center={[49, 9]}
        zoom={zoom}
        maxBounds={[
          [-90, -180],
          [90, 180],
        ]}
        onMoveEnd={this.handleMoveEnd}
        onOverlayAdd={this.onOverlayAdd}
        onOverlayRemove={this.onOverlayRemove}
        style={{
          height: mergedlayoutConfig.height,
          width: mergedlayoutConfig.width,
        }}
      >
        <LayersControl position="bottomright">
          {baseLayers(options)}
          {clusters}
        </LayersControl>
      </Map>
    );
  }
}

export const VerificationsMap = _VerificationsMap;

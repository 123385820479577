import { i18n } from "../../../../../i18next";

export const VerificationStates: Array = [
  {
    status: 0,
    description: i18n.t("Unknown"),
    color: "#7c7c7c",
    text: "We are sorry. This code is not in our database. Please try again.",
    instruction:
      "In case of doubt, please contact us and send us more information on this incident:",
  },
  {
    status: 1,
    description: i18n.t("Valid Code"),
    color: "#00b488",
    text: "",
    instruction: "",
  },
  {
    status: 2,
    description: i18n.t("Valid - Unfinished"),
    color: "#009688",
    text: "",
    instruction: "",
  },
  {
    status: 3,
    description: i18n.t("Checked too often"),
    color: "#d50000",
    text: "",
    instruction:
      "To solve this issue, please contact us and send us and send more information on this incident: ",
  },
  {
    status: 4,
    description: i18n.t("Imitated Code"),
    color: "#000000",
    text: "This code is known to be problematic. Your product could also be an Imitation.",
    instruction:
      "To solve this issue, please contact us and send more information on this incident:",
  },
  {
    status: 5,
    description: i18n.t("Demo Code"),
    color: "#009ddF",
    text: "This is a Demo Code. We are sorry, but we cannot complete the authentication process with this code.",
    instruction:
      "If you have found this code on a product, please contact us and send more information on this incident:",
  },
  {
    status: 6,
    description: i18n.t("Animation Mismatch"),
    color: "#ca7e51",
    text: "You stated that you are in doubt concerning the appearance of the security label.",
    instruction:
      "To solve this issue, please contact us and send more information on this incident:",
  },
];

export const ValidState: VerificationState = {
  status: 99,
  description: i18n.t("Valid"),
  color: "#00b488",
  text: "Authentication yielded a valid result",
  instruction: "",
};
export const InvalidState: VerificationState = {
  status: -1,
  description: i18n.t("Invalid"),
  color: "#d50000",
  text: "Authentication yielded an invalid result",
  instruction:
    "To solve this issue, please contact us and send us and send more information on this incident: ",
};

export const ValidCodes = [1, 2];
export const InvalidCodes = [3, 4, 6];

export const AmbigousCodes = [5, 0];

export const getDescription = (state: number) => {
  const verificationState = VerificationStates.find(
    (verificationState) => verificationState.status === state
  );
  return verificationState ? verificationState.description : "Unknown";
};

// exclude "Demo Code" from the Spool-Analysis
export const AvailableStates: Array<VerificationState> =
  VerificationStates.filter(
    (state: VerificationState) => ![5].includes(state.status)
  );

export const AggregatedStates: Array<VerificationState> =
  AvailableStates.filter(
    (state: VerificationState) => !ValidCodes.includes(state.status)
  );

import React from "react";
// import { Redirect } from "react-router-dom";

import { useTranslation } from "react-i18next";

export const OrderSummary = ({ children }) => {
  const { t } = useTranslation();
  return (
    <div className="orderSummary">
      <h1>{t("ORDER SUMMARY")}</h1>
      {children}
    </div>
  );
};

import React, { useEffect, useState } from "react";

import Select from "react-select";

import { endpoint } from "../../../../commonjs/endpoints";

export const TagFilter = (props) => {
  const { t, onChange } = props;

  const [tagOptions, setTagOptions] = useState([]);
  useEffect(() => {
    endpoint("statistics:api_tags_lookup", "GET").then((tags) => {
      setTagOptions(tags.map((tag) => ({ label: tag, value: tag })));
    });
  }, []);

  return (
    <label>
      {t("Tags")}
      <Select
        options={tagOptions}
        isMulti={true}
        closeMenuOnSelect={true}
        className="tag-select"
        onChange={onChange}
      />
    </label>
  );
};

const LABEL_60x50MM = {
  // positions, width, height in 1/10 mm
  // font size in pt
  dimensions: {
    width: 600,
    height: 500,
  },
  barcodes: {
    product_code: {
      x: 60,
      y: 225,
      height: 80,
      width: 2.145,
      prefix: "P",
      max_x: 563,
    },
    quantity: {
      x: 270,
      y: 378,
      height: 60,
      width: 1.65,
      prefix: "Q",
      max_x: 563,
    },
  },
  fields: {
    product_code: {
      placeholder: "",
      x: 97,
      y: 365,
      angle: 0,
      size: 18,
      narrow: true,
      bold: true,
      fixed: {
        x: 41,
        y: 352,
        size: 8,
        text: "(P)",
        bold: true,
      },
    },
    exchange_part: {
      placeholder: "",
      x: 20,
      y: 140,
      size: 5,
      angle: 0,
      lookup: true,
    },
    designation: {
      placeholder: "",
      x: 20,
      y: 217,
      size: 6,
      angle: 0,
    },
    quantity: {
      placeholder: "",
      x: 205,
      y: 437,
      size: 18,
      narrow: true,
      bold: true,
      angle: 0,
      align: "right",
      fixed: {
        x: 41,
        y: 433,
        size: 8,
        text: "(Q)",
        bold: true,
      },
    },
    supplier_information: {
      placeholder: "",
      x: 563,
      y: 248,
      size: 6,
      angle: 90,
    },
    made_in: {
      placeholder: "",
      x: 22,
      y: 471,
      size: 6,
      angle: 0,
      prefix: "Made in ",
    },
    made_in_arabic: {
      x: 530,
      y: 467,
      size: 5,
      angle: 0,
      align: "right",
      arabic: true,
      prefix: "صنع في ",
    },
  },
};

const LABEL_75x30MM = {
  dimensions: {
    width: 750,
    height: 300,
  },
  barcodes: {
    product_code: {
      x: 271,
      y: 61,
      height: 70,
      width: 1.8,
      prefix: "P",
      max_x: 706,
    },
    quantity: {
      x: 462,
      y: 193,
      height: 60,
      width: 1.7,
      prefix: "Q",
      max_x: 706,
    },
  },
  fields: {
    product_code: {
      placeholder: "",
      x: 302,
      y: 184,
      angle: 0,
      size: 18,
      narrow: true,
      bold: true,
      fixed: {
        x: 245,
        y: 181,
        size: 8,
        text: "(P)",
        bold: true,
      },
    },
    exchange_part: {
      placeholder: "",
      x: 251,
      y: 27,
      size: 5,
      angle: 0,
      lookup: true,
    },
    designation: {
      placeholder: "",
      x: 253,
      y: 51,
      size: 6,
      angle: 0,
    },
    quantity: {
      placeholder: "",
      x: 414,
      y: 253,
      size: 18,
      angle: 0,
      align: "right",
      narrow: true,
      bold: true,
      fixed: {
        x: 245,
        y: 247,
        size: 8,
        text: "(Q)",
        bold: true,
      },
    },
    supplier_information: {
      placeholder: "",
      x: 706,
      y: 76,
      size: 6,
      angle: 90,
    },
    made_in: {
      placeholder: "",
      x: 252,
      y: 279,
      size: 6,
      angle: 0,
      prefix: "Made in ",
    },
    made_in_arabic: {
      placeholder: "",
      x: 677,
      y: 279,
      size: 5,
      angle: 0,
      align: "right",
      arabic: true,
      prefix: "صنع في ",
    },
  },
};

const MOPAR_LABEL_75x30MM = {
  dimensions: {
    width: 750,
    height: 300,
  },
  barcodes: {
    product_code: {
      x: 256, //256
      y: 60, //60
      height: 69.5,
      width: 1.805,
      prefix: "P",
      max_x: 685,
    },
    quantity: {
      x: 420, //420
      y: 195,
      height: 59,
      width: 1.72,
      prefix: "Q",
      max_x: 700,
    },
  },
  fields: {
    product_code: {
      placeholder: "",
      x: 290,
      y: 182,
      angle: 0,
      size: 18,
      narrow: true,
      bold: true,
      fixed: {
        x: 240,
        y: 178,
        size: 7.6,
        text: "(P)",
        bold: true,
      },
    },
    exchange_part: {
      placeholder: "",
      x: 242,
      y: 29,
      size: 5,
      angle: 0,
      lookup: true,
    },
    designation: {
      placeholder: "",
      x: 241,
      y: 55,
      size: 6.04,
      angle: 0,
    },
    quantity: {
      placeholder: "",
      x: 403,
      y: 249,
      size: 18,
      angle: 0,
      align: "right",
      narrow: true,
      bold: true,
      fixed: {
        x: 242,
        y: 245,
        size: 7,
        text: "(Q)",
        bold: true,
      },
    },
    supplier_information: {
      placeholder: "",
      x: 706,
      y: 10,
      size: 6,
      angle: 90,
    },
    made_in: {
      placeholder: "",
      x: 241,
      y: 279,
      size: 6,
      angle: 0,
      prefix: "Made in ",
    },
    made_in_arabic: {
      placeholder: "",
      x: 678,
      y: 279,
      size: 4.8,
      angle: 0,
      align: "right",
      arabic: true,
      prefix: "صنع في ",
    },
  },
};

const MOPAR_LABEL_60x50MM = {
  dimensions: {
    width: 600,
    height: 500,
  },
  barcodes: {
    product_code: {
      x: 41,
      y: 230,
      height: 80,
      width: 2.285,
      prefix: "P",
      max_x: 541,
    },
    quantity: {
      x: 272,
      y: 380,
      height: 60,
      width: 1.64,
      prefix: "Q",
      max_x: 540,
    },
  },
  fields: {
    product_code: {
      placeholder: "",
      x: 89,
      y: 368,
      angle: 0,
      size: 18.1,
      narrow: true,
      bold: true,
      fixed: {
        x: 40,
        y: 365,
        size: 7,
        text: "(P)",
        bold: true,
      },
    },
    exchange_part: {
      placeholder: "",
      x: 20,
      y: 141,
      size: 5,
      angle: 0,
      lookup: true,
    },
    designation: {
      placeholder: "",
      x: 20,
      y: 220,
      size: 6.02,
      angle: 0,
    },
    quantity: {
      placeholder: "",
      x: 210,
      y: 440,
      size: 18,
      narrow: true,
      bold: true,
      angle: 0,
      align: "right",
      fixed: {
        x: 40,
        y: 434,
        size: 7,
        text: "(Q)",
        bold: true,
      },
    },
    supplier_information: {
      placeholder: "",
      x: 563,
      y: 201,
      size: 6,
      angle: 90,
    },
    made_in: {
      placeholder: "",
      x: 20,
      y: 474,
      size: 6,
      angle: 0,
      prefix: "Made in ",
    },
    made_in_arabic: {
      x: 531,
      y: 473,
      size: 4.85,
      angle: 0,
      align: "right",
      arabic: true,
      prefix: "صنع في ",
    },
  },
};

const MOPAR_XF_LABEL_60x60MM = {
  dimensions: {
    width: 600,
    height: 600,
  },
  barcodes: {
    barcode_ee: {
      x: 40,
      y: 211,
      height: 86,
      width: 2.285,
      max_x: 560,
      align: "center",
      data: ["xf_ee_part_number", "xf_quantity_ee"],
      display: "{xf_ee_part_number}{xf_quantity_ee}",
      format: true,
    },
    barcode_na: {
      x: 40,
      y: 411,
      height: 86,
      width: 2.24,
      max_x: 560,
      align: "center",
      data: ["xf_na_part_number"],
    },
  },
  fields: {
    text_ee: {
      x: 300,
      y: 342,
      size: 14,
      angle: 0,
      align: "center",
      bold: true,
      data: ["xf_ee_part_number", "xf_quantity_ee"],
      display: "{xf_ee_part_number} - {xf_quantity_ee}",
    },
    text_made_in: {
      x: 300,
      y: 370,
      size: 7,
      angle: 0,
      bold: true,
      align: "center",
      data: ["xf_made_in"],
      display: "Made in {xf_made_in}"
    },
    text_packaging_date: {
      x: 20,
      y: 400,
      size: 7,
      angle: 0,
      data: ["xf_packaging_date"],
      display: "PKG: {xf_packaging_date}",
    },
    xf_supplier_code: {
      x: 365,
      y: 400,
      size: 7,
      angle: 0,
      align: "center",
    },
    xf_packaging_source_code: {
      x: 580,
      y: 400,
      size: 7,
      angle: 0,
      align: "right",
    },
    text_na: {
      x: 300,
      y: 543,
      size: 14,
      angle: 0,
      align: "center",
      bold: true,
      data: ["xf_na_part_number", "xf_quantity_na"],
      display: "{xf_na_part_number} - {xf_quantity_na}",
    },
  }
};

const MOPAR_XF_LABEL_60x45MM = {
  dimensions: {
    width: 600,
    height: 450,
  },
  barcodes: {
    barcode_ee: {
      x: 50,
      y: 148,
      height: 63,
      width: 2.285,
      max_x: 550,
      align: "center",
      data: ["xf_ee_part_number", "xf_quantity_ee"],
      display: "{xf_ee_part_number}{xf_quantity_ee}",
      format: true,
    },
    barcode_na: {
      x: 60,
      y: 304,
      height: 63,
      width: 2.285,
      max_x: 540,
      align: "center",
      data: ["xf_na_part_number"],
    },
  },
  fields: {
    text_ee: {
      x: 300,
      y: 242,
      size: 8,
      angle: 0,
      align: "center",
      bold: true,
      data: ["xf_ee_part_number", "xf_quantity_ee"],
      display: "{xf_ee_part_number} - {xf_quantity_ee}",
    },
    text_made_in: {
      x: 300,
      y: 263,
      size: 5,
      angle: 0,
      align: "center",
      bold: true,
      data: ["xf_made_in"],
      display: "Made in {xf_made_in}"
    },
    text_packaging_date: {
      x: 19,
      y: 290,
      size: 6,
      angle: 0,
      data: ["xf_packaging_date"],
      display: "PKG: {xf_packaging_date}",
    },
    xf_supplier_code: {
      x: 350,
      y: 290,
      size: 6,
      angle: 0,
      align: "center",
      display: "{xf_supplier_code}"
    },
    xf_packaging_source_code: {
      x: 580,
      y: 290,
      size: 6,
      angle: 0,
      align: "right",
    },
    text_na: {
      x: 300,
      y: 398,
      size: 8,
      angle: 0,
      align: "center",
      bold: true,
      data: ["xf_na_part_number", "xf_quantity_na"],
      display: "{xf_na_part_number} - {xf_quantity_na}",
    },
  }
};

const OverlaySpecifications = {
  DEFAULT: {
    dimensions: {
      width: 2370,
      height: 1976,
    },
    fields: {
      product_code: {
        placeholder: "",
        x: 72,
        y: 273,
        angle: 0,
        size: 45,
        narrow: true,
        bold: true,
      },
      exchange_part: {
        placeholder: "",
        x: 15,
        y: 105,
        size: 10,
        angle: 0,
        lookup: true,
      },
      designation: {
        placeholder: "",
        x: 15,
        y: 163,
        size: 16,
        angle: 0,
      },
      quantity: {
        placeholder: "1",
        x: 160,
        y: 328,
        size: 44,
        angle: 0,
        align: "right",
      },
      supplier_information: {
        placeholder: "",
        x: 423,
        y: 188,
        size: 14,
        angle: 90,
      },
      made_in: {
        placeholder: "",
        x: 16,
        y: 353,
        size: 14,
        angle: 0,
        prefix: "MADE IN ",
      },
    },
  },
  B939W561F5: LABEL_60x50MM,
  B939W561G5: LABEL_60x50MM,
  B939W561F6: LABEL_75x30MM,
  B939W561G6: LABEL_75x30MM,
  B939W561F7: LABEL_60x50MM,
  B939W561G7: LABEL_60x50MM,
  B939W561F3: LABEL_60x50MM,
  B939W561G3: LABEL_60x50MM,
  B939W561F4: LABEL_75x30MM,
  B939W561G4: LABEL_75x30MM,
  B939W561Fx: MOPAR_LABEL_75x30MM,
  B939W561H3: MOPAR_LABEL_60x50MM,
  ITLEOE3: MOPAR_XF_LABEL_60x60MM,
  ITLEOE1: MOPAR_XF_LABEL_60x45MM,
};

export const getOverlaySpecification = (customerReference) => {
  return (
    OverlaySpecifications[customerReference] || OverlaySpecifications["DEFAULT"]
  );
};

import React from "react";
import { Link } from "react-router-dom";

import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import { compose } from "redux";

import { Icon } from "../../../commonjs/icons";
import { IfRight, UnlessRight } from "../../../commonjs/util";

import { named_urls, api_urls } from "../../../routing/known_urls";

const Box = (props) => {
  const { links, primary, hasRights, type } = props;

  const grid_size = type === "large" ? "xs-12" : "xs-6";
  const vert = type === "large" ? "vert-md" : "vert-sm";
  const primary_class = primary ? "c-box--primary" : "c-box--secondary";

  return (
    <div
      className={
        "grid__item vertical-spacing statistics-landing-box " + grid_size
      }
    >
      <div className={`o-box ${vert} ${primary_class}`}>
        <h4>{props.title}</h4>
        {props.children}
        <ul>
          {links.map((link, idx) => {
            const { to, renderAsHref = false } = link;
            return (
              <li key={"link_" + idx}>
                <IfRight needed={link.requiredPermissions} rights={hasRights}>
                  {renderAsHref && (
                    // download attribute included to avoid warning in browser console
                    <a href={to} download>
                      <Icon
                        icon={"long-arrow-alt-right"}
                        size="lg"
                        color={"#db0b3b"}
                      />
                      {link.label}
                    </a>
                  )}
                  {!renderAsHref && (
                    <Link to={link.to}>
                      <Icon
                        icon={"long-arrow-alt-right"}
                        size="lg"
                        color={"#db0b3b"}
                      />
                      {link.label}
                    </Link>
                  )}
                </IfRight>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

const _OrderStatisticsLanding = (props) => {
  const { t, hasRights } = props;

  const orderLinks = [
    {
      to: named_urls["statistics:history"],
      label: t("HISTORY OF YOUR ORDERS"),
    },
    {
      to: named_urls["statistics:order_overview"],
      label: t("SUPPLIERS ORDERS"),
      requiredPermissions: ["statistics.view_orderoverviewstatistics"],
    },
  ];

  const supplierLinks = [
    {
      to: named_urls["statistics:registrations"],
      label: t("SUPPLIERS ACCOUNTS"),
      requiredPermissions: ["statistics.view_registrationstatistics"],
    },
    {
      to: named_urls["statistics:supplier_analysis"],
      label: t("SUPPLIERS SCAN ANALYSIS"),
      requiredPermissions: ["statistics.*"],
    },
  ];

  const labelLinks = [
    {
      to: named_urls["statistics:authentications"],
      label: t("Authentication"),
      requiredPermissions: ["statistics.view_orderoverviewstatistics"],
    },
    {
      to: named_urls["statistics:id_analysis"],
      label: t("ID Based Analysis"),
      requiredPermissions: ["statistics.view_registrationstatistics"],
    },
    {
      to: named_urls["statistics:spool_analysis"],
      label: t("Spool Analysis"),
      requiredPermissions: ["statistics.view_registrationstatistics"],
    },
  ];

  const supplierData = [
    {
      to: api_urls["psa_accounts:supplier_data"],
      renderAsHref: true,
      label: t("DOWNLOAD LATEST SUPPLIERS FILE"),
      requiredPermissions: ["psa_accounts.view_supplierdata"],
    },
  ];

  return (
    <section className="order_statistics">
      <h1>{t("YOUR STATISTICS")}</h1>

      <UnlessRight needed={["statistics.*"]} rights={hasRights}>
        <div className="grid">
          <div className="grid__item xs-6 sm-6">
            <div className="grid">
              <Box
                title={t("Orders")}
                primary={true}
                hasRights={hasRights}
                links={orderLinks}
                type={"large"}
              />
            </div>
          </div>
          <div className="grid__item xs-6 sm-6">
            <div className="o-box vert-md c-box--primary bg-statistics bg" />
          </div>
        </div>
      </UnlessRight>

      <IfRight needed={["statistics.*"]} rights={hasRights}>
        <div className="grid">
          <div className="grid__item xs-8 sm-8">
            <div className="grid">
              <Box
                title={t("Orders")}
                primary={false}
                hasRights={hasRights}
                links={orderLinks}
              />
              <Box
                title={t("Labels")}
                primary={true}
                hasRights={hasRights}
                links={labelLinks}
              />
              <Box
                title={t("Suppliers")}
                primary={true}
                hasRights={hasRights}
                links={supplierLinks}
              />
              <IfRight
                needed={["psa_accounts.view_supplierdata"]}
                rights={hasRights}
              >
                <Box
                  title={t("Suppliers FILE")}
                  primary={false}
                  hasRights={hasRights}
                  links={supplierData}
                />
              </IfRight>
            </div>
          </div>
          <div className="grid__item xs-4 sm-4">
            <div className="o-box vert-md c-box--primary bg-statistics bg" />
          </div>
        </div>
      </IfRight>
    </section>
  );
};

export const OrderStatisticsLanding = compose(
  connect((state) => ({
    hasRights: state.accounts.rights,
  })),
  withTranslation()
)(_OrderStatisticsLanding);

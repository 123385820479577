import React, { Component } from "react";
import { Spinner } from "../../../../commonjs/components";
import { Icon } from "../../../../commonjs/icons";
import { endpoint } from "../../../../commonjs/endpoints";

import { withTranslation } from "react-i18next";


function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

const validLabel = RegExp(/[BCDFGHJKLMNPQRSTVWXYZ1234567890]/);
class _IdSearch extends Component {
  state = {
    labelId: "",
    labelNotFound: false,
    labelIdTooShort: false,
    label: undefined,
    loading: false,
  };

  updateLabelId = (event) => {
    const label = event.target.value;

    if (label.startsWith("#")) {
      this.enterHolospotId(label);
    } else {
      this.enterLabelId(label);
    }
  };

  enterHolospotId = (label) => {
    const cleanedLabel = label.toUpperCase().replace(/[^#1234567890]/g, "");

    this.setState({
      labelId: cleanedLabel,
      labelNotFound: false,
    });
  };

  enterLabelId = (label) => {
    const cleanedLabel = label
      .toUpperCase()
      .replace(/[^BCDFGHJKLMNPQRSTVWXYZ1234567890]/g, "")
      .slice(0, 12);

    this.setState({
      labelId: cleanedLabel,
      labelNotFound: false,
      labelIdTooShort: cleanedLabel.length < 12,
    });
  };

  isValidLabel = () => {
    const { labelId } = this.state;
    if (labelId.startsWith("#")) {
      return labelId.length > 3;
    } else {
      return labelId.length === 12 && validLabel.test(labelId);
    }
  };

  keyPress = (event) => {
    if (event.keyCode === 13) {
      this.triggerLookup();
    }
  };

  triggerLookup = () => {
    const { labelId } = this.state;

    if (this.isValidLabel()) {
      if (labelId.startsWith("#")) {
        this.lookupLabel(labelId.slice(1, labelId.length));
      } else {
        this.lookupLabel(labelId);
      }
    }
  };

  lookupLabel = (labelId) => {
    this.setState({
      label: undefined,
      loading: true,
      labelNotFound: false,
    });

    endpoint("statistics:api_label_info", "GET", { param: labelId }).then(
      (result) => {
        const empty = isEmpty(result);

        this.setState({
          label: empty ? undefined : result,
          loading: false,
          labelNotFound: empty,
        });
      },
      (error) => {
        this.setState({
          label: undefined,
          loading: false,
          labelNotFound: true,
        });
      }
    );
  };

  componentDidMount() {
    const { value } = this.props;

    if (value) {
      this.setState({ labelId: "#" + value, loading: true });
      this.lookupLabel(value);
    }
  }

  render() {
    const { t } = this.props;
    const { labelId, loading, label, labelNotFound } = this.state;

    const ResultComponent = this.props.result;

    return (
      <div className={"grid query-params"}>
        <div className={"grid__item xs-12"}>
          <Icon
            icon={"search"}
            size="2x"
            onClick={this.triggerLookup}
            style={{
              float: "left",
              marginLeft: "4px",
              marginTop: "24px",
              marginRight: "4px",
              cursor: "pointer",
            }}
          />
          <div className="grid__item xs-4">
            <label>{t("Label ID/ # Object ID")}</label>
            <input
              type={"text"}
              value={labelId}
              onChange={this.updateLabelId}
              onKeyDown={this.keyPress}
              autoFocus
              maxLength={12}
              width={"50%"}
            />
            {labelId.length > 0 && !this.isValidLabel() && (
              <span className="errorMessage" style={{ color: "red" }}>
                {t("Please enter 12 Characters for the Label Id")}
              </span>
            )}
          </div>
          <div className={"grid__item xs-4"}>
            <span style={{ verticalAlign: "bottom" }}>
              <br />
              {t(
                "only uppercase letters (except vowels), and numbers from 0-9 are allowed, must be 12 characters long"
              )}
              <br />
            </span>
          </div>
        </div>

        <div
          className={"grid__item xs-12 statistics-table"}
          style={{ marginTop: "3em" }}
        >
          {loading && <Spinner />}
          {!loading && labelNotFound && (
            <h1>{t("Label {{label_id}} not found", { label_id: labelId })}</h1>
          )}
          {!loading && label && !label.spool_id && (
            <h1>
              {t(
                "The label was found, but does not have any associated order information"
              )}
            </h1>
          )}
          {!loading && label && label.spool_id && (
            <ResultComponent t={t} label={label} labelId={labelId} />
          )}
        </div>
      </div>
    );
  }
}

export const IdSearch = withTranslation()(_IdSearch);

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { named_urls } from "../routing/known_urls";
import { version } from "../../package.json";

export const Footer = () => {
  const STAGING_MODE = parseInt(process.env.REACT_APP_STAGING_MODE, 10);
  const { t } = useTranslation();
  return (
    <footer className="grid footer">
      <div className="grid__item xs-12">
        <hr className="hr--footer" />
      </div>
      <div className="grid__item md-8 xs-12 vertical-spacing-half">
        <span className={`${STAGING_MODE ? "version-test" : ""}`}>
          {t(
            "© 2024 - PSA Automobiles SA - Stellantis Group - Developed by SCRIBOS GmbH"
          )}{" "}
          | {STAGING_MODE ? "STAGING-" : ""}
          {version}
        </span>
      </div>
      <div
        className="grid__item md-4 xs-12 vertical-spacing-half"
        style={{ textAlign: "right" }}
      >
        <Link to={named_urls["psa_terms:terms"]}>
          <span>{t("Terms of use")}</span>
        </Link>
      </div>
    </footer>
  );
};

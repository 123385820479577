import { api_urls } from "../routing/known_urls";
import { getCookie } from "./util";
import { i18n } from "../i18next";

/*
  handles all api calls - and handles 401/403 responses with hard relocate
  set handleUnauthorizedAndForbidden param to false to handle errors "on your own"
*/
export function endpoint(
  url,
  method,
  data = {},
  handleUnauthorizedAndForbidden = true
) {
  const body = data.body ? JSON.stringify(data.body) : void 0;
  const param = data.param ? getParam(data.param) : "";
  const query = data.query ? "?" + url_serialize(data.query) : "";

  const language = i18n.language;

  const request = fetch(api_urls[url] + param + query, {
    credentials: "same-origin",
    method: method,
    headers: {
      "X-CSRFToken": getCookie("csrftoken"),
      Accept: "application/json",
      "Content-Type": "application/json",
      "Accept-Language": language,
    },
    body,
  }).then((response) => {
    if (!response.ok) {
      if (
        handleUnauthorizedAndForbidden &&
        (response.status === 401 || response.status === 403)
      ) {
        // hard relocate on unauthorized / forbidden
        window.location = "/";
      }
      return response
        .json()
        .then((data) => {
          // convert token, data detail and non input field related errors in redux forms error tag
          if (data.type === undefined && data.token !== undefined) {
            return { errors: { ...data, _error: data.token } };
          }
          if (data.detail !== undefined) {
            return { errors: { ...data, _error: data.detail } };
          }
          if (data.non_field_errors !== undefined) {
            return { errors: { ...data, _error: data.non_field_errors } };
          }
          return { errors: data };
        })
        .catch((e) => {
          throw Error(e);
        });
    }
    return response.json();
  });

  return request;
}

// util
function url_serialize(obj, prefix) {
  var str = [],
    p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[p];
      str.push(
        v !== null && typeof v === "object"
          ? url_serialize(v, k)
          : encodeURIComponent(k) + "=" + encodeURIComponent(v)
      );
    }
  }
  return str.join("&");
}

function isNumber(obj) {
  return !isNaN(parseFloat(obj));
}

function isString(obj) {
  return Object.prototype.toString.call(obj) === "[object String]";
}

function getParam(obj) {
  if (isString) {
    return obj;
  } else if (isNumber) {
    return parseFloat(obj).toString();
  } else {
    // only fallback; param should always be either string or number
    return obj.toString();
  }
}

import React from "react";
import ReactModal from "react-modal";

export const ModalRaw = ({ close, children }) => (
  <ReactModal
    isOpen={true}
    onRequestClose={close}
    className="modal__content"
    overlayClassName="modal__overlay"
    bodyOpenClassName="modal__body--open"
    contentLabel="Modal"
    ariaHideApp={false}
  >
    <div onClick={close} className="modal-close" />
    {children}
  </ReactModal>
);

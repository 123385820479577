import React, { Component } from "react";

import { withTranslation } from "react-i18next";
import { remove } from "lodash";
import {
  Column,
  Table,
  AutoSizer,
  createTableMultiSort,
} from "react-virtualized";
import "react-virtualized/styles.css";

import { sortList, HeaderRenderer, StatusHeaderRenderer } from "../fn";

import {
  AggregatedStates,
  ValidCodes,
  ValidState,
} from "../authentications/Map/VerificationStates";

class _StatusTable extends Component {
  state = {
    sortedVerifications: [],
    sortBy: "Checked too often",
    sortDirection: "DESC",
  };

  sort = ({ sortBy, sortDirection }) => {
    const { sortedVerifications } = this.state;
    const sortedList = sortList({
      list: sortedVerifications,
      sortBy,
      sortDirection,
    });
    this.setState({ sortedVerifications: sortedList, sortBy, sortDirection });
  };

  sortState = createTableMultiSort(this.sort);

  componentDidMount() {
    const { verifications } = this.props;

    const aggregatedVerifications = verifications.map((verification) => {
      const validVerifications = remove(verification.verifications, (v) =>
        ValidCodes.includes(v.status)
      );
      const aggregatedValidCount = validVerifications.reduce(
        (prev, curr) => prev + curr.count,
        0
      );
      verification.verifications.push({
        status: ValidState.status,
        count: aggregatedValidCount,
      });
      return verification;
    });

    const sorted = aggregatedVerifications.map((label) => {
      const labelHits = { label_id: label.label_id };
      /** TODO: not sure, what happens here, but this is never used
      const statusHits = [ValidState, ...AggregatedStates].forEach((state) => {
        const found = label.verifications.find(
          (verification_count) => verification_count.status == state.status
        );
        labelHits[state.status] = found ? found.count : 0;
      });
      */

      return labelHits;
    });
    this.setState({ sortedVerifications: sorted });
  }
  render() {
    const { t, searchedForObjectId } = this.props;
    const { sortedVerifications } = this.state;

    return (
      <div className="statistics-table" style={{ height: "540px" }}>
        <h1>You searched for #{searchedForObjectId}</h1>
        <AutoSizer className={"AutoSizerWrapper statistics-table"}>
          {({ width, height }) => (
            <Table
              width={width}
              height={500}
              headerHeight={48}
              rowHeight={48}
              rowCount={sortedVerifications.length}
              rowGetter={({ index }) => sortedVerifications[index]}
              sort={this.sortState.sort}
              sortBy={undefined}
              sortDirection={undefined}
            >
              <Column
                label={t("Object ID")}
                dataKey={"label_id"}
                width={230}
                headerRenderer={HeaderRenderer(this.sortState)}
                cellRenderer={(entry) => {
                  return entry.cellData === searchedForObjectId
                    ? "> " + entry.cellData
                    : entry.cellData;
                }}
              />
              {[ValidState, ...AggregatedStates].map((state) => (
                <Column
                  key={"state_column_" + state}
                  label={t(state.description)}
                  dataKey={state.status}
                  width={230}
                  headerRenderer={StatusHeaderRenderer(this.sortState)}
                  cellRenderer={(entry) => {
                    return (
                      <span style={{ color: state.color }}>
                        {entry.rowData[state.status]}
                      </span>
                    );
                  }}
                />
              ))}
            </Table>
          )}
        </AutoSizer>
      </div>
    );
  }
}

export const StatusTable = withTranslation()(_StatusTable);

export function isEmptyObj(obj) {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) return false;
  }
  return JSON.stringify(obj) === JSON.stringify({});
}

export function fetchMOCK(url) {
  return Promise.resolve();
}

export const checkRights = (needed, rights) => check_rights(needed, rights);

// checks the 'rights' list of definitions against the user_rights list
export function check_rights(needed, rights) {
  const regexes = [];
  let allowed = false;

  /* without right definitions, assume 'public' */
  if (!needed || needed.length === 0) return true;

  /* if a right is needed, but the user has none, he must not access it */
  if (!rights) return false;

  for (let i = 0, len = needed.length; i < len; i++) {
    const regex_escape = needed[i]
      // eslint-disable-next-line
      .replace(/[\-\[\]\/\{\}\(\)\+\?\.\\\^\$\|]/g, "\\$&") // RegEx-escaping acc. to http://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex, excluding '*'
      // eslint-disable-next-line
      .replace(/[\*]/g, ".*"); // '*' should match anything

    // rework right-descriptor into a regex
    regexes.push(new RegExp("^" + regex_escape + "$"));
  }

  for (let i = 0, len = regexes.length; i < len; i++) {
    for (let j = 0, len2 = rights.length; j < len2; j++) {
      if (regexes[i].test(rights[j])) {
        allowed = true;
        break;
      }
    }
    if (allowed) break;
  }

  return allowed;
}

// functional React component to show/hide children depending on user's rights
export function IfRight(props) {
  if (check_rights(props.needed, props.rights)) {
    return props.children;
  } else {
    return null;
  }
}

export function UnlessRight(props) {
  if (check_rights(props.needed, props.rights)) {
    return null;
  } else {
    return props.children;
  }
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(func, wait, immediate) {
  let timeout;

  return function(...args) {
    const context = this;

    clearTimeout(timeout);

    timeout = setTimeout(function() {
      timeout = null;

      if (!immediate) func.apply(context, args);
    }, wait);

    if (immediate && !timeout) func.apply(context, args);
  };
}

export function replaceItemInArray(oldArray, sliceIndex, item) {
  var front = oldArray.slice(0, sliceIndex);
  var end = oldArray.slice(sliceIndex + 1, oldArray.length);

  return [...front, item, ...end];
}

export function deleteItemInArray(oldArray, index) {
  var front = oldArray.slice(0, index);
  var end = oldArray.slice(index + 1, oldArray.length);

  return [...front, ...end];
}

export function getCookie(name) {
  var re = new RegExp(name + "=([^;]+)");
  var value = re.exec(document.cookie);
  return value != null ? unescape(value[1]) : null;
}

export function toArray(arrayLike) {
  return [].map.call(arrayLike, x => x);
}

//http://davidwalsh.name/javascript-clone?utm_source=javascriptweekly&utm_medium=email
export function clone(src) {
  function mixin(dest, source, copyFunc) {
    var name,
      s,
      empty = {};
    for (name in source) {
      // the (!(name in empty) || empty[name] !== s) condition avoids copying properties in "source"
      // inherited from Object.prototype.   For example, if dest has a custom toString() method,
      // don't overwrite it with the toString() method that source inherited from Object.prototype
      s = source[name];
      if (
        !(name in dest) ||
        (dest[name] !== s && (!(name in empty) || empty[name] !== s))
      ) {
        dest[name] = copyFunc ? copyFunc(s) : s;
      }
    }
    return dest;
  }

  if (
    !src ||
    typeof src != "object" ||
    Object.prototype.toString.call(src) === "[object Function]"
  ) {
    // null, undefined, any non-object, or function
    return src; // anything
  }
  if (src.nodeType && "cloneNode" in src) {
    // DOM Node
    return src.cloneNode(true); // Node
  }
  if (src instanceof Date) {
    // Date
    return new Date(src.getTime()); // Date
  }
  if (src instanceof RegExp) {
    // RegExp
    return new RegExp(src); // RegExp
  }
  var r, i, l;
  if (src instanceof Array) {
    // array
    r = [];
    for (i = 0, l = src.length; i < l; ++i) {
      if (i in src) {
        r.push(clone(src[i]));
      }
    }
    // we don't clone functions for performance reasons
    //    }else if(d.isFunction(src)){
    //      // function
    //      r = function(){ return src.apply(this, arguments); };
  } else {
    // generic objects
    r = src.constructor ? new src.constructor() : {};
  }
  return mixin(r, src, clone);
}

export function waitForGlobal(name, timeout = 300) {
  return new Promise((resolve, reject) => {
    let waited = 0;

    function wait(interval) {
      setTimeout(() => {
        waited += interval;
        // some logic to check if script is loaded
        // usually it something global in window object
        if (window[name] !== undefined) {
          return resolve();
        }
        if (waited >= timeout * 1000) {
          return reject({ message: "Timeout" });
        }
        wait(interval * 2);
      }, interval);
    }

    wait(30);
  });
}

// eslint-disable-next-line
export const pick = (O, ...K) => K.reduce((o, k) => ((o[k] = O[k]), o), {});


export const removeDiacritics = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

// Determines if a haystack contains a needle.  Case and accent insensitive.
// Example: normalizedContains('Le Samouraï', 'I') -> true
export const normalizedContains = (haystack, needle) => {
  const regExp = new RegExp(removeDiacritics(needle), "gi");
  return regExp.test(removeDiacritics(haystack));
};
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { formatToShortDate } from "../../../../commonjs/langutils";
import { KeyValueDisplay } from "./KeyValueComponent";

export const Redistribution = ({ data: distribution }) => {
  const { t } = useTranslation();

  const redistributionDetails = [
    [t("Company Name"), distribution.company_name],
    [t("Comment"), distribution.comment],
    [t("Date of Redistribution"), formatToShortDate(distribution.date_created)],
    [t("Street"), distribution.street_address],
    [t("Address Details"), distribution.address_details],
    [t("City"), distribution.city],
    [t("Postal Code"), distribution.postal_code],
    [t("Country"), distribution.country_code],
  ];
  return (
    <Fragment>
      <br />
      <div width="50%">
        <h2>
          {t("Spool {{spool_id}} has been redistributed", {
            spool_id: distribution.spool_id,
          })}
        </h2>
        <KeyValueDisplay keyValueMap={redistributionDetails} />
      </div>
    </Fragment>
  );
};

import { useTranslation } from "react-i18next";

export const DistributionDescription = () => {
  const { t } = useTranslation();

  // prettier-ignore
  return (
    <div className={"epic"}>
      <p>
        {t("Please choose a supplier in order to assign rolls for distribution. Please be aware that rolls used for your own production need to be assigned to your own address.")}
      </p>
      <br />
      <h2>{t("Please Note")}</h2>
      <ul>
        <li>{t("Redistribution tasks need to be tracked in the platform for all redistributions - for full rolls as well as for rolls which are redistributed in several steps to different suppliers (partial roll distribution).")}</li>
        <li><b>{t("Partial Roll Distribution")}:</b>&nbsp;{t("Please keep track of all distributions done for one roll in a separate document. Once you distribute the last part of one roll, indicate all recipients of labels of this specific roll and the corresponding number of labels in the free field next to the generated distribution tasks.")}</li>
      </ul>
    </div>
  );
};

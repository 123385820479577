import { useContext } from "react";

import { NonStandardProductContext } from "../NonStandardProductContext";
import { useCanvas } from "../Canvas";
import { getFont } from "./utils";

export const MadeInArabic = ({
  overlaySpecification,
  scalingFactor,
  fontScalingFactor,
  contextValues
}) => {
  const { x, y, color = "black", size, align = "left", prefix = "" } = overlaySpecification;

  const { renderingContext: ctx } = useCanvas();
  const { countryCodesArabic } = useContext(NonStandardProductContext);

  if (ctx && contextValues.made_in) {
    ctx.save();

    const [country] = countryCodesArabic.filter(
      country => country.code === contextValues.made_in
    );

    ctx.translate(x * scalingFactor, y * scalingFactor);

    ctx.strokeStyle = color;
    ctx.textAlign = align;
    ctx.font = getFont({ size, fontScalingFactor, scalingFactor });
    ctx.direction = "rtl";

    ctx.fillText(prefix + country.name, 0, 0);

    // restore takes care of resetting the ctx to default values (including ctx.direction)
    ctx.restore();
  }
  // Canvas-Elements never render anything
  return null;
};

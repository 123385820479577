import React from "react";

import { withTranslation } from "react-i18next";


import { NavLink, Navigate } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import Recaptcha from "./google-invisible-recaptcha";

import { withOnMount } from "../../hoc";
import { i18n } from "../../i18next";
import { named_urls } from "../../routing/known_urls";
import { RenderField } from "../../commonjs/components";
import { localSettings } from "../../settings";

import {
  accountsSessionStatus,
  accountsLogin,
  accountsLoginCaptchaComplete,
  accountsClearNavigation,
  selectAccounts,
} from "./duck";

/* Login Form */
const validate_login = (values) => {
  const errors = {};
  if (!values.username) errors.username = i18n.t("Required");
  if (!values.account_id) errors.account_id = i18n.t("Required");
  if (!values.password) errors.password = i18n.t("Required");
  return errors;
};

class _LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onResolved = this.onResolved.bind(this);
  }

  onSubmit() {
    this.props.accountsLogin();
    this.recaptcha.execute();
  }
  onResolved() {
    const captcha = this.recaptcha.getResponse();
    this.recaptcha.reset();
    this.props.accountsLoginCaptchaComplete(captcha);
  }

  render() {
    const { error, pristine, submitting, invalid, t } = this.props;
    const language = i18n.language;
    const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;

    return (
      <form
        className="grid vertical-spacing"
        onSubmit={(e) => {
          e.preventDefault();
          this.onSubmit();
        }}
      >
        <div className="grid__item xs-12 md-4 vertical-spacing">
          <Field
            name="username"
            component={RenderField}
            type="text"
            placeholder={t("Email")}
          />
        </div>
        <div className="grid__item xs-12 md-4 vertical-spacing">
          <Field
            name="account_id"
            component={RenderField}
            type="text"
            placeholder={t("Account Id")}
          />
        </div>
        <div className="grid__item xs-12 md-4 vertical-spacing">
          <Field
            name="password"
            component={RenderField}
            type="password"
            placeholder={t("Password")}
          />
        </div>
        {error && (
          <span className="grid__item xs-12 vertical-spacing form-error">
            {error}
          </span>
        )}
        <span className="grid__item xs-12 vertical-spacing">
          <NavLink to={named_urls["accounts:reset_password"]}>
            <span>{t("Forgot your password?")}</span>
          </NavLink>
        </span>
        {!localSettings.accountsHideRegisterLink && (
          <div className="grid__item xs-12  vertical-spacing">
            <NavLink to={named_urls["accounts:register"]}>
              <span>{t("Register account or a new user")}</span>
            </NavLink>
          </div>
        )}
        <Recaptcha
          locale={language}
          ref={(ref) => (this.recaptcha = ref)}
          sitekey={RECAPTCHA_KEY}
          onResolved={this.onResolved}
          badge="bottomright"
        />
        <button
          type="submit"
          className={`grid__item xs-3 button vertical-spacing ${pristine || invalid || submitting
            ? "button-primary--disabled"
            : "clickable"
            }`}
        >
          {t("LOGIN")}
        </button>
      </form>
    );
  }
}

const LoginForm = compose(
  connect(null, (dispatch) => ({
    accountsLogin: (_) => dispatch(accountsLogin()),
    accountsLoginCaptchaComplete: (data) =>
      dispatch(accountsLoginCaptchaComplete(data)),
  })),
  reduxForm({
    form: "login",
    validate: validate_login,
  }),
  withTranslation()
)(_LoginForm);

const _Login = ({ t, isLoggedIn, timedOut, location, navigate, accountsClearNavigation }) => {

  if (navigate) {
    accountsClearNavigation();
    return <Navigate to={navigate} />;
  }

  if (isLoggedIn) {
    return (
      <Navigate
        to={{
          pathname: named_urls["Welcome"],
          state: { from: location },
        }}
      />
    );
  }

  const MAINTENANCE_MODE = parseInt(process.env.REACT_APP_MAINTENANCE_MODE, 10);

  if (MAINTENANCE_MODE) {
    return (
      <section className="login">
        <div className="bg-login" />
        <h1>{t("USER LOGIN")}</h1>
        <h2 className="warning vertical-spacing">
          {t("The ordering platform is currently in maintenance mode.")}
        </h2>
        <span className="vertical-spacing">
          {t("We are back in a few minutes. Thank you for your patience.")}
        </span>
      </section>
    );
  }

  return (
    <section className="login">
      <div className="bg-login" />
      <h1>{t("USER LOGIN")}</h1>
      {timedOut && (
        <h2 className="warning vertical-spacing">
          {t("Your session has expired.")}
        </h2>
      )}
      <span className="vertical-spacing">
        {t("Please login for ordering your security labels.")}
      </span>
      <LoginForm />
    </section>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: selectAccounts(state).authenticated,
  timedOut: selectAccounts(state).timedOut,
  navigate: state.accounts.navigate,
});

const mapDispatchToProps = (dispatch) => ({
  onMount: () => {
    dispatch(accountsSessionStatus());
  },
  accountsClearNavigation: () => {
    dispatch(accountsClearNavigation());
  },

});

export const Login = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withOnMount,
  withTranslation()
)(_Login);

import React from "react";
import { useCanvas } from "../Canvas";
import { FieldDebounced } from "./FieldDebounced";
import { createDisplayText, fieldCanBeFormatted, getFont } from "./utils";


const LabelText = ({
  data,
  overlaySpecification,
  scalingFactor,
  fontScalingFactor,
  fields
}) => {
  const {
    x,
    y,
    color = "black",
    size,
    angle,
    placeholder = "",
    lookup,
    align = "left",
    prefix = "",
    display,
    narrow = false,
    bold = false,
    fixed,
  } = overlaySpecification;

  const { renderingContext: ctx } = useCanvas();

  const newData = { ...data };

  if (lookup) {
    const entries = Object.entries(data);
    if (entries.length === 1) {
      const [name, value] = entries[0];
      const lookupField = fields.find(e => e.name === name);
      const selectedOption = lookupField?.options?.find((option) => option.value === value);
      newData[name] = selectedOption ? selectedOption.label : value;
    }
  }

  const text = createDisplayText(newData, display);

  if (ctx) {
    ctx.save();

    ctx.translate(x * scalingFactor, y * scalingFactor);
    if (angle !== 0) {
      ctx.rotate((angle * Math.PI) / 180);
    }

    ctx.strokeStyle = color;
    ctx.textAlign = align;
    ctx.font = getFont({ size, narrow, fontScalingFactor, scalingFactor, bold });

    if (text) {
      ctx.fillText(prefix + text, 0, 0);
    } else {
      ctx.fillText(placeholder, 0, 0);
    }

    // restore takes care of resetting the ctx to default values (including ctx.direction)
    ctx.restore();

    if (text && fixed) {
      ctx.save();
      ctx.font = getFont({
        size: fixed.size,
        fontScalingFactor,
        scalingFactor,
        bold: fixed.bold ?? false,
        narrow: fixed.narrow ?? false
      });
      ctx.translate(fixed.x * scalingFactor, fixed.y * scalingFactor);
      ctx.fillText(fixed.text, 0, 0);
    }

    ctx.restore();
  }
  // Canvas-Elements never render anything
  return null;
};

export const LabelTextPreview = (props) => {
  const { data, overlaySpecification, fields } = props;

  const dataFieldNames = data ? Object.keys(data) : [];
  const requiredProductFields = fields.filter(e => dataFieldNames.includes(e.name));
  const canBeFormatted = requiredProductFields.some(fieldCanBeFormatted);
  // for text fields formatting is ENABLED by default
  const toFormat = overlaySpecification.format ?? true;

  if (toFormat && canBeFormatted) {
    return <FieldDebounced {...props} component={LabelText} />
  }
  return <LabelText {...props} />;
}
import React, { Component } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import enUS from "date-fns/locale/en-US";

import { Icon } from "../../../../commonjs/icons";

import { withTranslation } from "react-i18next";

import "react-datepicker/dist/react-datepicker.css";

registerLocale("en", enUS);
registerLocale("en-US", enUS);
registerLocale("fr", fr);

class _DateFilterForQueries extends Component {
  state = {
    fromDate: new Date("2017-01-01T00:00:00"),
    toDate: new Date(),
  };

  minDate = new Date("2017-01-01T00:00:00");

  changeFromDate = (newDate: Date) => {
    const { toDate } = this.state;
    this.setState({ fromDate: newDate });
    this.updateDateRange(newDate, toDate);
  };

  changeToDate = (newDate: Date) => {
    const { fromDate } = this.state;
    this.setState({ toDate: newDate });

    this.updateDateRange(fromDate, newDate);
  };

  updateDateRange = (fromDate: Date, toDate: Date) => {
    const { onChange } = this.props;

    onChange(fromDate, toDate);
  };

  componentDidMount() {
    const { date_start, date_end } = this.props;

    if (date_start && date_end) {
      this.setState({
        fromDate: date_start,
        toDate: date_end,
      });
    }
  }

  render() {
    const { t, i18n } = this.props;
    const { fromDate, toDate } = this.state;

    return (
      <div className={"select-filter"}>
        <Icon
          icon={"calendar-alt"}
          size="2x"
          style={{
            float: "left",
            marginLeft: "4px",
            marginTop: "20px",
            marginRight: "4px",
          }}
        />
        <div className="query-param from-date">
          <label>{t("from")}</label>
          <DatePicker
            locale={i18n.language}
            readOnly={false}
            minDate={this.minDate}
            selected={fromDate}
            onChange={this.changeFromDate}
          />
        </div>
        <div className="query-param to-date">
          <label>{t("to")}</label>
          <DatePicker
            locale={i18n.language}
            readOnly={false}
            minDate={fromDate}
            selected={toDate}
            onChange={this.changeToDate}
          />
        </div>
      </div>
    );
  }
}

export const DateFilterForQueries = withTranslation()(_DateFilterForQueries);

import React, { Component, useState } from "react";

import { withTranslation } from "react-i18next";

import { LabelDetail } from "./LabelDetail";
import { IdFilter } from "../authentications/IdFilter";

import { Redistribution } from "../components/Redistribution";

const Lookup = (props) => {
  const [holospotId, setHolospotId] = useState(undefined);
  const [result, setResult] = useState(undefined);

  const { t } = props;

  return (
    <div>
      <div className={"grid query-params"}>
        <div
          className="grid__item xs-12"
          style={{ marginLeft: "15px", marginBottom: "20px" }}
        >
          <IdFilter {...{ t, setHolospotId, setResult }} />
        </div>
        <div className="grid__item xs-12">
          {result && result.order_id && (
            <LabelDetail label={result} labelId={holospotId} t={t} />
          )}
          {result && !result.order_id && (
            <h2>
              {t(
                "Label was found, but has no order information attached to it."
              )}
            </h2>
          )}
          {result && result.redistribution && (
            <Redistribution data={result.redistribution} />
          )}
        </div>
      </div>
    </div>
  );
};

class _IdAnalysis extends Component {
  render() {
    const { t } = this.props;

    return (
      <section className={"statistics"}>
        <h1>{t("ID BASED ANALYSIS")}</h1>
        <Lookup t={t} />
      </section>
    );
  }
}

export const IdAnalysis = withTranslation()(_IdAnalysis);

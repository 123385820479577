import { useState, useEffect } from "react";
import { endpoint } from "../../../../commonjs/endpoints";

export const useProductLoader = () => {
  const [productOptions, setProductOptions] = useState([]);

  // TODO to really make the loader reusable, we should probably move the mapping into options out of this hook
  const loadProducts = () => {
    endpoint("statistics:api_products").then((result) => {
      const options = result.data.map((entry) => ({
        label: entry,
        value: entry,
      }));
      setProductOptions(options);
    });
  };
  useEffect(loadProducts, []);

  return productOptions;
};

import { formatDescription } from "../../orders/order_process/StandardProducts/StandardProducts";
import { Annotation } from "./Annotation";
import { useTranslation } from "react-i18next";

export const RollEntry = ({ roll }) => {
  const { t } = useTranslation();
  const product = roll.product;

  return (
    <li key={roll.pk} style={{ clear: "both" }}>
      <span style={{ float: "left" }}>
        {roll.spool_no}:&nbsp;&nbsp;{product.customer_reference}
        ,&nbsp;{product.product_group.name}&nbsp;
        {formatDescription(product)}
      </span>
      <span style={{ float: "left" }}>
        <Annotation
          annotation={roll.comment}
          setAnnotation={(comment) => (roll.comment = comment)}
          placeholder={t("Add a comment to this Roll.")}
          helpText={t("Add an Annotation to this Roll.")}
          inline={true}
        />
      </span>
    </li>
  );
};

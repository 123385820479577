import React from "react";
import { ModalAlert } from "../../../commonjs/modals";
import { ModalOrderHistoryDetails } from "./ModalOrderHistoryDetails";
import { ModalCancelOrder } from "./ModalCancelOrder";
import { ModalOrderSwitch, OrderAlert } from "./ModalOrderSwitch";

import { ModalImage } from "./ModalImage";

// see https://stackoverflow.com/questions/35623656/how-can-i-display-a-modal-dialog-in-redux-that-performs-asynchronous-actions/35641680

const MODAL_COMPONENTS = {
  ALERT: ModalAlert,
  ORDER_HISTORY_DETAILS: ModalOrderHistoryDetails,
  ORDER_SWITCH: ModalOrderSwitch,
  CANCEL_ORDER: ModalCancelOrder,
  ORDER_ALERT: OrderAlert,
  IMAGE: ModalImage,
};

export const ModalRoot = ({ modalType, modalProps }) => {
  if (!modalType) {
    return null;
  }
  const SpecificModal = MODAL_COMPONENTS[modalType];

  if (SpecificModal) {
    return <SpecificModal {...modalProps} />;
  } else {
    console.error(
      "Modal for type <" + modalType + "> not found. Registered types are:",
      MODAL_COMPONENTS
    );
  }
};

import React from "react";
import ReactModal from "react-modal";

import { NavSideLinkList } from "../layout/NavigationSide";

export const ModalMobileNavSide = ({ isOpen, close }) =>
	<ReactModal
		isOpen={isOpen}
		onRequestClose={close}
		className="modal-nav-side"
		overlayClassName="modal__overlay"
		bodyOpenClassName="modal__body--open"
		contentLabel="Modal"
	>
		<NavSideLinkList onClick={close} />
	</ReactModal>;

import React, { createContext, useContext, useEffect } from "react";
import { connect } from "react-redux";

import { useHeartBeat } from "./useHeartbeat";
export const AuthenticationContext = createContext();

function useAuthentication() {
  const context = useContext(AuthenticationContext);

  if (context === undefined) {
    throw new Error(
      "useAuthentication must be used within AuthenticationContextProvider"
    );
  }

  return context;
}

function _AuthenticationContextProvider({
  isLoggedIn,
  hasRights,
  companyProfile,
  blockedFromOrdering,
  blockedCompletely,
  deliveryAddressApproved,
  deliveryAddressApprovalPending,
  children,
  account,
  session_heartbeat_interval_seconds,
}) {
  const { startHeartbeat, stopHeartbeat } = useHeartBeat(
    session_heartbeat_interval_seconds * 1000
  );
  useEffect(() => {
    if (isLoggedIn) {
      startHeartbeat();
    } else {
      stopHeartbeat();
    }
    // eslint-disable-next-line
  }, [isLoggedIn]);

  return (
    <AuthenticationContext.Provider
      value={{
        isLoggedIn,
        hasRights,
        companyProfile,
        blockedFromOrdering,
        blockedCompletely,
        deliveryAddressApproved,
        deliveryAddressApprovalPending,
        account,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.accounts.authenticated,
  hasRights: state.accounts.rights,
  companyProfile: state.accounts.data.company_profile,
  account: state.accounts.data,
  session_heartbeat_interval_seconds:
    state.accounts?.params?.session_heartbeat_interval_seconds,
  blockedFromOrdering:
    state.accounts && state.accounts.data && state.accounts.data.company_profile
      ? state.accounts.data.company_profile.blocked_for_ordering
      : false,
  blockedCompletely: state.accounts?.data?.company_profile?.blocked_completely,
  deliveryAddressApproved:
    state.accounts && state.accounts.data && state.accounts.data.company_profile
      ? state.accounts.data.company_profile.delivery_address.state ===
        "APPROVED"
      : false,
  deliveryAddressApprovalPending:
    state.accounts && state.accounts.data && state.accounts.data.company_profile
      ? state.accounts.data.company_profile.delivery_address.state !==
        "APPROVED"
      : false,
});

const mapDispatchToProps = () => ({});

const AuthenticationContextProvider = connect(
  mapStateToProps,
  mapDispatchToProps
)(_AuthenticationContextProvider);

export { AuthenticationContextProvider, useAuthentication };

import React, { useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { accountsEmailConfirm } from "./duck";
import { useTranslation } from "react-i18next";

const ConfirmationInProgress = () => {
  const { t } = useTranslation();
  return <h1>{t("CONFIRMATION IN PROGRESS ...")}</h1>;
};

const ConfirmationSuccess = () => {
  const { t } = useTranslation();
  return (
    <div>
      <h1 className="success">{t("CONFIRMATION SUCCESSFUL")}</h1>
      <section>
        {t("Thank you for confirming your account.")}
        <br />
        {t(
          "Please note that your account needs to be activated in the P-SL system before you can login to the platform."
        )}
        <br />
        <br />
        {t("You will receive an email after the final activation.")}
      </section>
    </div>
  );
};

const ConfirmationFailure = () => {
  const { t } = useTranslation();
  return (
    <div>
      <h1 className="failed">{t("CONFIRMATION FAILED")}</h1>
      <section>
        {t(
          "The confirmation of your account failed due to an invalid/expired confirmation code."
        )}
        <br />
        {t("Please contact scribos® <customer-service@scribos.com>.")}
        <br />
      </section>
    </div>
  );
};

const EmailConfirmWrapped = ({
  accountsEmailConfirm,
  emailConfirmInProgress,
  emailConfirmResult,
}) => {
  const { code } = useParams();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => accountsEmailConfirm({ token: code }), []);

  return (
    <section className="email-confirm">
      <div className="bg-login" />
      {emailConfirmInProgress && <ConfirmationInProgress />}
      {!emailConfirmInProgress && emailConfirmResult && <ConfirmationSuccess />}
      {!emailConfirmInProgress && !emailConfirmResult && (
        <ConfirmationFailure />
      )}
    </section>
  );
};

const mapStateToProps = (state) => ({
  emailConfirmInProgress: state.accounts.emailConfirmInProgress,
  emailConfirmResult: state.accounts.emailConfirmResult,
});

const mapDispatchToProps = (dispatch) => ({
  accountsEmailConfirm: (data) => {
    dispatch(accountsEmailConfirm(data));
  },
});

class ReduxWrapper extends React.Component {
  render() {
    return <EmailConfirmWrapped {...this.props} />;
  }
}
export const EmailConfirm = compose(
  connect(mapStateToProps, mapDispatchToProps)
)(ReduxWrapper);

import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import Select from "react-select";

/**
 * TODO this is dirty, as we should probably fetch these from the DB, but as we are not doing that elsewhere, this seems like the pragmatic thing to do
 */
const methodOptions = (t) => {
  return [
    { label: t("IP based"), value: "IP" },
    { label: t("GPS based"), value: "GPS" },
  ];
};

class _GeolocationFilter extends PureComponent {
  render() {
    const { t } = this.props;
    return (
      <label>
        {t("Localization Method")}
        <Select
          options={methodOptions(t)}
          isMulti={true}
          closeMenuOnSelect={true}
          className="geolocation-select"
          onChange={this.props.onChange}
        />
      </label>
    );
  }
}

export const GeolocationFilter = withTranslation()(_GeolocationFilter);

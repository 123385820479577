import React from "react";
import { ModalRaw } from "../../../commonjs/modals";

export const ModalImage = ({ close, image }) => (
  <ModalRaw close={close}>
    <div className="modal--image">
      <img src={image} alt="Detail" />
    </div>
  </ModalRaw>
);

import { useRef } from "react";
import uuid from "uuid/v4";

export const useId = () => {
  const ref = useRef();
  if (ref.current === undefined) {
    ref.current = uuid();
  }
  return ref.current;
};

import React from "react";
import { withTranslation } from "react-i18next";
import { ModalDialog } from "../../../commonjs/modals";

const _ModalDisableCompanyUsers = ({ t, users, handleCancel, handleOk }) => {
  return (
    <ModalDialog
      isOpen={true}
      handleOk={handleOk}
      handleCancel={handleCancel}
      isCriticalAction={true}
      title={t("Disable active company users")}
    >
      <div className="grid__item xs-12">
        {t("Do you want to disable the selected company users")}:
      </div>
      <div className="grid__item xs-12">
        <br />
        {users &&
          users.map((user, id) => {
            return (
              user.value &&
              user.value === true && (
                <span key={id}>
                  <em>
                    {user.first_name} {user.last_name} ({user.email})
                  </em>
                </span>
              )
            );
          })}
        <br />
      </div>
      <div className="grid__item xs-12">
        {t(
          "Please note, that on deactivation an email will be sent to the provisioning manager."
        )}
      </div>
    </ModalDialog>
  );
};

export const ModalDisableCompanyUsers = withTranslation()(
  _ModalDisableCompanyUsers
);

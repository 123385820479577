import { useTranslation } from "react-i18next";
import { named_urls } from "../../../../routing/known_urls";

import { Link } from "react-router-dom";

import { useShoppingCart } from "../ShoppingCart";
import { useDraftOrder } from "../useDraftOrder";

export const Checkout = () => {
  const { t } = useTranslation();

  const { clearOrder } = useShoppingCart((state) => ({
    clearOrder: state.clearOrder,
  }));

  const { canOrder } = useDraftOrder();

  return (
    <div className="order__buttons right">
      <div onClick={clearOrder} className="button button-tertiary">
        <span>{t("CLEAR ORDER")}</span>
      </div>
      <Link
        className={`button button-primary ${
          canOrder ? "clickable" : "button-primary--disabled"
        }`}
        style={{ marginRight: "0px" }}
        to={named_urls["orders:summary:adressAndDelivery"]}
      >
        {t("CHECKOUT")}
      </Link>
    </div>
  );
};

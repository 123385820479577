import { format } from "date-fns";
import { create } from "zustand";
import { printingServiceFieldFullfillsDependencies } from "./NonStandardProducts/utils";

const removeCartEntry = (currentCart, rowId) => {
  const cartEntry = currentCart.find((entry) => entry.rowId === rowId);
  const cart = currentCart.filter((entry) => entry.rowId !== rowId);
  return { cartEntry, cart };
};
const changeQuantity = (state, product, quantity, rowId) => {
  const { cartEntry, cart } = removeCartEntry(state, rowId);

  const extra_services = !Number.isInteger(quantity / product.labels_per_unit)
    ? addExtraService({
        extraServices: cartEntry ? cartEntry.extra_services : [],
        category: "PARTIAL_ROLL",
        product,
      })
    : cartEntry
    ? removeExtraServices({
        extraServices: cartEntry.extra_services,
        product: cartEntry.product,
        categories: ["PARTIAL_ROLL"],
      })
    : [];
  // make sure you preserve the printdata, when you change the quantity
  const newCart = [
    ...cart,
    {
      ...cartEntry,
      rowId,
      product,
      quantity,
      extra_services,
    },
  ];

  return newCart;
};

const addPkForField = (product, printData) => {
  const fields = product.extra_services
    .find((es) => es.category === "PRINTING")
    // Filter out fields whose dependencies are not fulfilled (e.g. product_code)
    .fields.filter((field) =>
      printingServiceFieldFullfillsDependencies(field, printData)
    )
    .map((field) => ({
      pk: field.pk,
      // field can be a boolean, so value could be "false"
      value: printData[field.name] ?? (field.default ?? ""),
      name: field.name,
    }));
  return fields;
};

const removeExtraServices = ({ extraServices, product, categories }) => {
  const extraServiceToRemove = product.extra_services
    .filter((es) => categories.includes(es.category))
    .map((es) => es.pk);

  return extraServices && extraServices.length > 0
    ? extraServices.filter(
        (es) => !extraServiceToRemove.includes(es.extra_service)
      )
    : [];
};

const addExtraService = ({
  product,
  extraServices,
  category,
  additionalData,
}) => {
  const extraService = product.extra_services.find(
    (es) => es.category === category
  );

  return [
    ...(extraServices
      ? extraServices.filter((es) => es.extra_service !== extraService.pk)
      : []),
    { extra_service: extraService.pk, ...additionalData },
  ];
};

const addPrintData = (state, product, printData, rowId) => {
  const { cartEntry, cart } = removeCartEntry(state, rowId);
  const addedPrintDesign = addExtraService({
    extraServices: cartEntry ? cartEntry.extra_services : [],
    category: "PRINT_DESIGN",
    product,
  });

  const extra_services = addExtraService({
    extraServices: addedPrintDesign,
    category: "PRINTING",
    product,
    additionalData: { fields: addPkForField(product, printData) },
  });

  return [
    ...cart,
    {
      ...cartEntry,
      rowId,
      product,
      printData,
      hasPrintingService: true,
      extra_services,
    },
  ];
};

const removePrintData = (state, rowId) => {
  const { cartEntry, cart } = removeCartEntry(state, rowId);

  const extra_services = removeExtraServices({
    extraServices: cartEntry.extra_services,
    product: cartEntry.product,
    categories: ["PRINT_DESIGN", "PRINTING"],
  });

  if (cartEntry) {
    const updatedCartEntry = {
      ...cartEntry,
      printData: undefined,
      hasPrintingService: false,
      extra_services,
    };

    return [...cart, updatedCartEntry];
  } else {
    return cart;
  }
};

const removeEntry = (shoppingCart, rowId) => {
  return shoppingCart.filter((entry) => entry.rowId !== rowId);
};

const hasExtraServicesWithAndWithoutPrintData = (shoppingCart) => {
  return (
    shoppingCart.find((cartEntry) => cartEntry.hasPrintingService === true) &&
    shoppingCart.find((cartEntry) => cartEntry.hasPrintingService !== true)
  );
};
export const select = {
  canOrder: (state) =>
    state.shoppingCart.find((cartEntry) => cartEntry.quantity > 0),
  cartEmpty: (state) => state.shoppingCart.length > 0,
  hasWarnings: (state) =>
    hasExtraServicesWithAndWithoutPrintData(state.shoppingCart),
};

export const useShoppingCart = create((set, get) => ({
  shoppingCart: [],
  customerTransportation: false,

  warningAcknowledged: false,
  setWarningAcknowledged: (warningAcknowledged) => {
    set((state) => ({ warningAcknowledged: warningAcknowledged }));
  },
  removeEntry: (rowId) =>
    set((state) => ({
      shoppingCart: removeEntry(state.shoppingCart, rowId),
    })),
  changeQuantity: (product, quantity, rowId) =>
    set((state) => ({
      shoppingCart:
        quantity > 0
          ? changeQuantity(state.shoppingCart, product, quantity, rowId)
          : removeEntry(state.shoppingCart, rowId),
    })),
  addPrintData: (product, printData, rowId) =>
    set((state) => ({
      shoppingCart: addPrintData(state.shoppingCart, product, printData, rowId),
    })),
  removePrintData: (rowId) =>
    set((state) => ({
      shoppingCart: removePrintData(state.shoppingCart, rowId),
    })),
  clearOrder: () =>
    set({
      shoppingCart: [],
      customerTransportation: false,
      customerOrderNo: undefined,
      deliveryDate: undefined,
      warningDismissed: false,
    }),
  setDeliveryDate: (val) =>
    set((state) => ({ deliveryDate: format(val, "yyyy-MM-dd") })),
  setCustomerOrderNo: (val) => set((state) => ({ customerOrderNo: val })),
  setCustomerTransportation: (val) =>
    set((state) => ({ customerTransportation: val })),
  setWarnings: (val) => set((state) => ({ warnings: val })),
}));

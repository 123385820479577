import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "./locale/en.json";
import fr from "./locale/fr.json";

export const i18n = i18next.use(LanguageDetector).init({
  debug: false,
  nsSeparator: false,
  keySeparator: false,
  fallbackLng: "en",
  resources: { en: en["en"], fr: fr["fr"] },
  whitelist: ["en", "fr"],
});

export const t = i18n.t.bind(i18n);

import { createStore, applyMiddleware } from "redux";
import { routerMiddleware } from "react-router-redux";
import { createEpicMiddleware } from "redux-observable";

import thunk from "redux-thunk";

import { history } from "../history";

import { rootReducer } from "../rootReducer";
import { rootEpic } from "../rootEpic";

import { endpoint } from "../commonjs/endpoints";

const epicMiddleware = createEpicMiddleware(rootEpic);

// Build the middleware for intercepting and dispatching navigation actions
const rr4middleware = routerMiddleware(history);

export function configureStore() {
  return createStore(
    rootReducer,
    applyMiddleware(
      rr4middleware,
      epicMiddleware,
      thunk.withExtraArgument(endpoint)
    )
  );
}

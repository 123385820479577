// import "./polyfills";

// app
import NProgress from "nprogress";
import "rxjs"; // all operators
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import { i18n } from "./i18next";

import { configureStore } from "./store/configureStore";
import { Layout } from "./layout";

import { accountsVerifySession } from "./licensing/accounts";

import { settingsCountryCodesFetch } from "./commonjs/duck";

import { QueryClient, QueryClientProvider } from "react-query";
import { NotificationContextProvider } from "./commonjs/NotificationContext";
import { AuthenticationContextProvider } from "./commonjs/AuthenticationContext";

import "./style/index.scss";

NProgress.start();

export const store = configureStore();

// first action init reducers
store.dispatch({ type: JSON.stringify(Date.now()) });
store.dispatch(accountsVerifySession());
store.dispatch(settingsCountryCodesFetch());

const queryClient = new QueryClient();

export function boot() {
  NProgress.done();
  ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <AuthenticationContextProvider>
          <NotificationContextProvider>
            <QueryClientProvider client={queryClient}>
              <Layout />
            </QueryClientProvider>
          </NotificationContextProvider>
        </AuthenticationContextProvider>
      </Provider>
    </I18nextProvider>,
    document.getElementById("react-root")
  );
}

import { Routes, Route } from "react-router-dom";
import { AuthorizedContent } from "../commonjs/AuthorizedContent";
import {
  OrderProcess,
  OrderSummary,
  OrderSummaryAdressDelivery,
  OrderSummaryPayment,
  OrderSummaryThankYou,
  OrderSummaryError,
} from "../licensing/orders";
import React from "react";

export const OrderingRoutes = () => (
  <Routes>
    <Route
      path="order_process"
      element={
        <AuthorizedContent requires={["orders.issue_order"]}>
          <OrderProcess />
        </AuthorizedContent>
      }
    />
    <Route
      exact
      path="order_process/order_summary"
      element={
        <AuthorizedContent requires={["orders.issue_order"]}>
          <OrderSummary />
        </AuthorizedContent>
      }
    />
    ,
    <Route
      exact
      path="order_process/order_summary/adressAndDelivery"
      element={
        <AuthorizedContent requires={["orders.issue_order"]}>
          <OrderSummaryAdressDelivery />
        </AuthorizedContent>
      }
    />
    <Route
      exact
      path="order_process/order_summary/payment"
      element={
        <AuthorizedContent requires={["orders.issue_order"]}>
          <OrderSummaryPayment />
        </AuthorizedContent>
      }
    />
    <Route
      exact
      path="order_process/order_summary/thankyou"
      element={
        <AuthorizedContent requires={["orders.issue_order"]}>
          <OrderSummaryThankYou />
        </AuthorizedContent>
      }
    />
    <Route
      exact
      path="order_process/order_summary/error"
      element={
        <AuthorizedContent requires={["orders.issue_order"]}>
          <OrderSummaryError />
        </AuthorizedContent>
      }
    />
  </Routes>
);

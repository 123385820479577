import { combineEpics } from "redux-observable";
import { Observable } from "rxjs";
import { notificationError } from "./notifications";
import { endpoint } from "./endpoints";

const SETTINGS_COUNTRY_CODES_FETCH = "SETTINGS_COUNTRY_CODES_FETCH";
const SETTINGS_COUNTRY_CODES_FETCH_SUCCESS =
  "SETTINGS_COUNTRY_CODES_FETCH_SUCCESS";
const SETTINGS_COUNTRY_CODES_ARABIC_FETCH =
  "SETTINGS_COUNTRY_CODES_ARABIC_FETCH";
const SETTINGS_COUNTRY_CODES_ARABIC_FETCH_SUCCESS =
  "SETTINGS_COUNTRY_CODES_ARABIC_FETCH_SUCCESS";

export const settingsCountryCodesFetch = _ => ({
  type: SETTINGS_COUNTRY_CODES_FETCH
});

export const settingsCountryCodesFetchSuccess = data => ({
  type: SETTINGS_COUNTRY_CODES_FETCH_SUCCESS,
  payload: data
});

export const settingsCountryCodesArabicFetch = _ => ({
  type: SETTINGS_COUNTRY_CODES_ARABIC_FETCH
});

export const settingsCountryCodesArabicFetchSuccess = data => ({
  type: SETTINGS_COUNTRY_CODES_ARABIC_FETCH_SUCCESS,
  payload: data
});

const settingsShape = {
  countryCodes: [],
  countryCodesArabic: []
};

export function settingsReducer(state = settingsShape, action) {
  switch (action.type) {
    case SETTINGS_COUNTRY_CODES_FETCH_SUCCESS:
      let countryCodes = action.payload;
      return {
        ...state,
        countryCodes
      };
    case SETTINGS_COUNTRY_CODES_ARABIC_FETCH_SUCCESS:
      let countryCodesArabic = action.payload;
      return {
        ...state,
        countryCodesArabic
      };
    default:
      break;
  }

  return state;
}

const countryCodesFetchEpic = (action$, { dispatch, getState }) => {
  return action$.ofType(SETTINGS_COUNTRY_CODES_FETCH).mergeMap(_ => {
    if (stateContainsCountryCodes(getState())) {
      return Observable.of(
        settingsCountryCodesFetchSuccess(getState().settings.countryCodes)
      );
    } else {
      return Observable.from(endpoint("accounts:api_country_list", "GET"))
        .map(response => {
          if (!response.errors) {
            return settingsCountryCodesFetchSuccess(response);
          } else {
            return notificationError("countryCodesFetch");
          }
        })
        .catch(e => Observable.of(notificationError(e)));
    }
  });
};

const countryCodesArabicFetchEpic = (action$, { dispatch, getState }) => {
  return action$.ofType(SETTINGS_COUNTRY_CODES_ARABIC_FETCH).mergeMap(_ => {
    if (stateContainsCountryCodesArabic(getState())) {
      return Observable.of(
        settingsCountryCodesArabicFetchSuccess(
          getState().settings.countryCodesArabic
        )
      );
    } else {
      const query = {
        lang: "ar"
      };
      return Observable.from(
        endpoint("accounts:api_country_list", "GET", { query })
      )
        .map(response => {
          if (!response.errors) {
            return settingsCountryCodesArabicFetchSuccess(response);
          } else {
            return notificationError("countryCodesArabicFetch");
          }
        })
        .catch(e => Observable.of(notificationError(e)));
    }
  });
};

export const settingsEpics = combineEpics(
  countryCodesFetchEpic,
  countryCodesArabicFetchEpic
);

function stateContainsCountryCodes(state) {
  return (
    state.settings &&
    state.settings.countryCodes &&
    state.settings.countryCodes.length > 0
  );
}

function stateContainsCountryCodesArabic(state) {
  return (
    state.settings &&
    state.settings.countryCodesArabic &&
    state.settings.countryCodesArabic.length > 0
  );
}

import React from "react";
import { useTranslation } from "react-i18next";
import { useShoppingCart } from "./ShoppingCart";

export const OrderSummaryThankYou = () => {
  const { t } = useTranslation();
  const { warnings } = useShoppingCart((state) => ({
    warnings: state.warnings,
  }));

  return (
    <div className="orderSummary">
      <h2>{t("Your order was successfully placed.")}</h2>
      {warnings && warnings.length > 0 && (
        <div className="checkout-warning">
          <p>
            <b>{t("Please note")}</b>:&nbsp;
            {t(
              "The security labels will only be activated after reception of the Security Feedback. The ordering functionality will be blocked after multiple orders of non-activated labels under your account."
            )}
          </p>
          <p>
            <b>{t("Where to find the Security Feedback")}</b>:&nbsp;
            {t(
              "With each delivery you receive the corresponding Security Feedback document per mail attached to your shipping confirmation.  You will also find a printed version attached to your delivery."
            )}
          </p>
          <p>
            <b>{t("At delivery")}</b>:&nbsp;
            {t(
              "Please verify the content, sign the document and  send it back to scribos per mail (electronical signature) or fax."
            )}
          </p>
        </div>
      )}
    </div>
  );
};

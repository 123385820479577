import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { ModalOrderHistoryDetails } from "../../modals/ModalOrderHistoryDetails";
import { endpoint } from "../../../../commonjs/endpoints";

import { Icon } from "../../../../commonjs/icons";

class _OrderLink extends PureComponent {
  state = {
    order: undefined,
    show: false,
    loading: false,
  };

  showOrder = () => {
    const { order_pk } = this.props;
    const { order } = this.state;

    if (order && order.pk === order_pk) {
      this.setState({ show: true, loading: false });
    } else {
      this.setState({ loading: true, show: false });
      endpoint("orders:api_order", "GET", { param: order_pk }).then(
        (result) => {
          this.setState({
            order: result,
            show: true,
            loading: false,
          });
        }
      );
    }
  };

  closeOrderDetails = () => {
    this.setState({ show: false });
  };

  render() {
    const { order, show, loading } = this.state;

    return (
      <span>
        {show && (
          <ModalOrderHistoryDetails
            close={this.closeOrderDetails}
            order={order}
          />
        )}
        {loading && <Icon icon={"spinner"} spin pulse />}
        {!loading && (
          // switching to button here breaks the layout - deeper look required
          // eslint-disable-next-line
          <a onClick={this.showOrder} className={"detail-view-link"}>
            <Icon icon={"eye"} size="lg" />
          </a>
        )}
      </span>
    );
  }
}

export const OrderLink = withTranslation()(_OrderLink);

import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import Select from "react-select";
import Option from "./Option";
import WindowedList from "./WindowedList";
import { normalizedContains } from "../../../../commonjs/util";

export const SelectFilter = ({
  updateFilter,
  resetFilter,
  fieldName,
  filterName,
  options,
  label,
  placeholder,
  multi,
}) => {
  const { t } = useTranslation();
  const [selectedValues, setSelectedValues] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const [, setBlurInputOnSelect] = useState(false);

  const filter = (selectedOptions) => {
    if (!selectedOptions) {
      resetFilter(filterName);
      setSelectedValues([]);
      return;
    }
    setBlurInputOnSelect(
      selectedOptions.find((option) => option.value === "all") !== undefined
    );

    const cleanedOptions = selectedOptions.find(
      (option) => option.value === "all"
    )
      ? options.filter(
          (option) =>
            option.value !== "all" &&
            normalizedContains(option.label, inputValue)
        )
      : selectedOptions.filter((option) => option.value !== "all");

    if (cleanedOptions && cleanedOptions.length > 0) {
      const values = cleanedOptions.map((option) => option.value);
      updateFilter(filterName, (entry) => {
        return multi
          ? values.some((value) => normalizedContains(entry[fieldName], value))
          : values.includes(entry[fieldName]);
      });
    } else {
      resetFilter(filterName);
    }

    setSelectedValues(cleanedOptions);
  };

  const filterOption = ({ label, value }, string) => {
    if (value === "all") {
      return true;
    } else if (string) {
      // TODO remove accents, ignore case...
      return (
        normalizedContains(label, string) || normalizedContains(value, string)
      );
    } else {
      return true;
    }
  };

  return (
    <div className={"select-filter"}>
      <label>{label}</label>

      <Select
        isMulti={true}
        openonFocus={true}
        options={[{ label: t("Select all"), value: "all" }, ...options]}
        onChange={filter}
        value={selectedValues}
        closeMenuOnSelect={false}
        placeholder={placeholder || t("Select...")}
        components={{ MenuList: WindowedList, Option }}
        hideSelectedOptions={false}
        filterOption={filterOption}
        // keep the search input on option select
        // see https://stackoverflow.com/questions/64298547/dont-clear-input-on-select-using-react-select
        onInputChange={(inputValue, action) => {
          // only change state when the user is actually typing
          if (action.action === "input-change") setInputValue(inputValue);
        }}
        inputValue={inputValue}
        blurInputOnSelect={true}
        classNamePrefix={"search-filter"}
      />
    </div>
  );
};

import React, { useState } from "react";

import ReactModal from "react-modal";

import CompanyDetail from "./CompanyDetail";
import { useQuery } from "react-query";
import { endpoint } from "../../../../commonjs/endpoints";
import { Spinner } from "../../../../commonjs/components";
import { Icon } from "../../../../commonjs/icons";

const CompanyDetailsLink = ({ id }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { data, isLoading, refetch } = useQuery(
    ["company_detail", id],
    () => endpoint("statistics:api_company_detail", "GET", { param: id }),
    {
      enabled: false,
    }
  );

  const handleModalToggle = () => {
    refetch();
    setIsOpen(true);
  };

  return (
    <React.Fragment>
      {isOpen && (
        <ReactModal
          isOpen={true}
          onRequestClose={() => setIsOpen(false)}
          className="modal__content"
          overlayClassName="modal__overlay"
          bodyOpenClassName="modal__body--open"
          contentLabel="Modal"
          ariaHideApp={false}
        >
          <div onClick={() => setIsOpen(false)} className="modal-close" />
          {isLoading && <Spinner />}
          {data && <CompanyDetail company={data} />}
        </ReactModal>
      )}
      <button
        onClick={handleModalToggle}
        className={"detail-view-link open-modal"}
        style={{ padding: "0.1em", margin: "0" }}
      >
        <Icon icon={"eye"} size="lg" />
      </button>
    </React.Fragment>
  );
};

export default CompanyDetailsLink;

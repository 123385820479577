import React from "react";
import { components } from "react-select";


const SelectableOption = (props) => {
  return (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
        <input
          type="checkbox"
          checked={props.isSelected}
          style={{ width: "20px", height: "20px", minHeight: 0, minWidth: 0 }}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </div>
    </components.Option>
  );
};

const SelectAllOption = (props) => {
  return (
    <components.Option {...props}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          fontSize: "1.2em",
          gap: "16px",
          color: "#4a8ec2",
        }}
      >
        <label>{props.label}</label>
      </div>
    </components.Option>
  );
};

const Option = (props) => {
  if (props.data.value === "all") {
    return <SelectAllOption {...props} />;
  } else {
    return <SelectableOption {...props} />;
  }
};

export default Option;

import React from "react";
import { format } from "date-fns";

import Query from "../Query";

const formatter = new Intl.NumberFormat();
export const HitCount = (props) => {
  const { queryParams } = props;
  const { date_start, date_end, selected_countries } = queryParams;

  const cleanedParams = {
    ...queryParams,
    order_countries: selected_countries,
    date_start:
      date_start instanceof Date
        ? format(date_start, "yyyy-MM-dd")
        : date_start,
    date_end:
      date_end instanceof Date ? format(date_end, "yyyy-MM-dd") : date_end,
  };

  return (
    <Query
      debug={false}
      query={"statistics:api_verifications"}
      method={"POST"}
      params={{ ...cleanedParams, aggregation: "hit_count" }}
    >
      {(result) => formatter.format(result.data)}
    </Query>
  );
};

import React from "react";
import ReactDOMServer from "react-dom/server";

import { withLeaflet, MapLayer } from "react-leaflet";

import {
  Popup,
  divIcon,
  icon,
  marker,
  point,
  markerClusterGroup
} from "leaflet";

import "leaflet.markercluster/dist/leaflet.markercluster.js";
import "leaflet.markercluster/dist/MarkerCluster.css";

import PieMarker from "./SVGPieChart";
import getSinglePinMarkers from "./SinglePinMarker";
import VlogPopUp from "./PopUp";

import isEqual from "lodash/isEqual";

import { verificationLookup } from "./StatisticsQuery";

//import Endpoints from "Components/Api/Endpoints";
//import apiClient from "Components/Api/ApiClient";

const getPieChartSize = total_count => {
  let digits = 1;
  let count = total_count;
  while (count >= 1) {
    count = count / 10;
    digits += 1;
  }
  return [(13 + 4) * (digits / 2), (13 + 4) * (digits / 2)];
};

class _ClusteredPieMarkers extends MapLayer {
  // function names starting with _ are helper functions and the rest are overridden from Maplayer

  state = {
    vlogs: {}
  };

  _onSingleMarkerClick(latlng, id) {
    const { vlogs } = this.state;
    const id_str = id.toString();

    const innerHTML = ReactDOMServer.renderToString(
      <VlogPopUp vlog={id_str in vlogs ? vlogs[id_str] : "loading"} />
    );

    if (!(id_str in vlogs)) {
      verificationLookup(id).then(result => {
        let logs = { ...vlogs };
        logs[id_str] = result.data;

        this.setState({ vlogs: logs }, () =>
          this._onSingleMarkerClick(latlng, id)
        );
      });
    }

    let popup = new Popup({ offset: point(0, -25), minWidth: 350 });
    const { lat, lng } = latlng;
    popup.setLatLng([lat, lng]);
    popup.setContent(innerHTML);

    this.props.leaflet.map.openPopup(popup);
  }

  _createMarkers(data, bucketKey) {
    let markers = [];

    if (data instanceof Array) {
      data.forEach((cluster, index) => {
        if (cluster.status.buckets.length > 0) {
          let mark = marker([
            cluster.center_lat.value,
            cluster.center_lon.value
          ]);
          mark.isAggregation = true;
          mark.bucket = cluster.status.buckets;
          mark.doc_count = cluster.doc_count;

          if (
            cluster.status.buckets.length === 1 &&
            cluster.status.buckets[0].doc_count === 1
          ) {
            mark.on("click", e =>
              this._onSingleMarkerClick(
                e.target._latlng,
                cluster.status.buckets[0].single_checks.hits.hits[0]._id
              )
            );
          }

          markers.push(mark);
        }
      });
    } else {
      Object.keys(data).forEach(id => {
        let mark = marker([data[id].position.lat, data[id].position.lon]);
        mark.id = id;
        mark.isAggregation = false;
        mark.status = data[id][bucketKey];
        mark.on("click", e => this._onSingleMarkerClick(e.target._latlng, id));
        markers.push(mark);
      });
    }

    return markers;
  }

  _createMarkerIcons(cluster, categories) {
    let doc_count = 0;
    let aggregation = {};

    cluster.getAllChildMarkers().forEach((data, index) => {
      if (data.isAggregation) {
        doc_count += data.doc_count;

        data.bucket.forEach(status => {
          if (!(status["key"] in aggregation)) {
            aggregation[status["key"]] = status["doc_count"];
          } else {
            aggregation[status["key"]] += status["doc_count"];
          }
        });
      } else {
        doc_count += 1;
        if (!(data.status in aggregation)) {
          aggregation[data.status] = 1;
        } else {
          aggregation[data.status] += 1;
        }
      }
    });

    aggregation = Object.keys(aggregation).map(key => {
      return { key: parseInt(key), doc_count: aggregation[key] };
    });

    if (doc_count > 1) {
      return divIcon({
        className: "",
        iconSize: getPieChartSize(doc_count),
        html: ReactDOMServer.renderToString(
          <PieMarker
            aggregation={aggregation}
            total_count={doc_count}
            categories={categories}
          />
        )
      });
    } else {
      return icon({
        iconUrl: this.singleMarkerStatusMap[aggregation[0]["key"]],
        iconSize: [40, 40],
        iconAnchor: [20, 40]
      });
    }
  }

  createLeafletElement(props) {
    this.singleMarkerStatusMap = getSinglePinMarkers(
      props.aggregationCategories
    );

    const clusterMarker = markerClusterGroup({
      maxClusterRadius: 50,
      chunkedLoading: true,
      chunkInterval: 80,
      singleMarkerMode: true,
      showCoverageOnHover: false,
      iconCreateFunction: cluster =>
        this._createMarkerIcons(cluster, props.aggregationCategories)
    });

    const markers = this._createMarkers(props.data, props.aggregationKey);
    clusterMarker.addLayers(markers);

    props.leaflet.map.addLayer(clusterMarker);
    return clusterMarker;
  }

  updateLeafletElement(fromProps, toProps) {
    if (
      !isEqual(fromProps.zoom, toProps.zoom) ||
      !isEqual(fromProps.data, toProps.data)
    ) {
      const markers = this._createMarkers(toProps.data, toProps.aggregationKey);
      this.leafletElement.clearLayers();
      this.leafletElement.addLayers(markers);
    }
  }
}

export default withLeaflet(_ClusteredPieMarkers);

import React from "react";
import ReactModal from "react-modal";
import { withTranslation } from "react-i18next";

const _ModalDialog = ({
  t,
  isOpen,
  handleOk,
  handleCancel,
  isCriticalAction,
  title,
  children
}) => (
  <ReactModal
    isOpen={isOpen}
    onRequestClose={handleCancel}
    shouldCloseOnOverlayClick={false}
    className="modal__content"
    overlayClassName="modal__overlay"
    bodyOpenClassName="modal__body--open"
    contentLabel="ModalDialog"
    ariaHideApp={false}
  >
    <div onClick={handleCancel} className="modal-close" />
    <div className="grid">
      <div className="grid__item xs-12">
        <h3>{title}</h3>
      </div>
      <div className="grid__item xs-12">
        <hr />
        <br />
      </div>
      {children}
      <div className="grid__item xs-12">
        <br />
        <br />
      </div>
      <button
        className={`button ${
          isCriticalAction ? "button-critical-action" : "button-primary"
        }`}
        onClick={handleOk}
      >
        {t("YES")}
      </button>
      <button className="button button-primary" onClick={handleCancel}>
        {t("NO")}
      </button>
    </div>
  </ReactModal>
);

export const ModalDialog = withTranslation()(_ModalDialog);

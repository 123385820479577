import React, { useState, useEffect } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { parse, addDays } from "date-fns";
import fr from "date-fns/locale/fr";
import enUS from "date-fns/locale/en-US";

import { useTranslation } from "react-i18next";

import { Link, useNavigate } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";

import { ModalDialog } from "../../../commonjs/modals";

import { AddressDelivery, AddressBilling } from "../../../licensing/accounts/";

import { named_urls } from "../../../routing/known_urls";

import { useShoppingCart } from "./ShoppingCart";

import { useAuthentication } from "../../../commonjs/AuthenticationContext";

registerLocale("en", enUS);
registerLocale("en-US", enUS);
registerLocale("fr", fr);

export const OrderSummaryAdressDelivery = (props) => {
  const navigate = useNavigate();

  const {
    deliveryDate,
    setDeliveryDate,
    customerOrderNo,
    setCustomerOrderNo,
    customerTransportation,
    setCustomerTransportation,
  } = useShoppingCart((state) => ({
    shoppingCart: state.shoppingCart,
    deliveryDate: state.deliveryDate,
    setDeliveryDate: state.setDeliveryDate,
    customerOrderNo: state.customerOrderNo,
    setCustomerOrderNo: state.setCustomerOrderNo,
    customerTransportation: state.customerTransportation,
    setCustomerTransportation: state.setCustomerTransportation,
  }));

  const {
    companyProfile: {
      billing_address: billingAddress,
      delivery_address: deliveryAddress,
      transport_restriction: transportRestriction,
    },
  } = useAuthentication();

  const { t, i18n } = useTranslation();

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [submitWithoutOrderReference, setSubmitWithoutOrderReference] =
    useState(false);

  const minDate = addDays(new Date(), 1);
  const selectedDate =
    deliveryDate && deliveryDate.length > 0
      ? parse(deliveryDate, "yyyy-MM-dd", minDate)
      : minDate;

  const checkOrderReference = (event) => {
    if (
      submitWithoutOrderReference ||
      (customerOrderNo && customerOrderNo.length > 0)
    ) {
      return true;
    } else {
      event.preventDefault();
      setShowConfirmationDialog(true);
      return false;
    }
  };
  const confirmMissingOrderReference = () => {
    setSubmitWithoutOrderReference(true);
    setShowConfirmationDialog(false);
    navigate(named_urls["orders:summary:payment"]);
  };

  const cancelMissingOrderReference = () => {
    setSubmitWithoutOrderReference(false);
    setShowConfirmationDialog(false);
  };

  const MissingOrderReferenceDialog = () => {
    return (
      <ModalDialog
        isOpen={showConfirmationDialog}
        handleOk={confirmMissingOrderReference}
        handleCancel={cancelMissingOrderReference}
        isCriticalAction={false}
        title={t("Your purchase order number is missing")}
      >
        <div className="grid__item xs-12">
          {t(
            "Are you sure you want to submit your order without purchase order number?"
          )}
          <br />
          {t(
            "If yes, please make sure that the invoice will be paid although no purchase order number will be mentioned."
          )}
        </div>
      </ModalDialog>
    );
  };

  // initialize customer transportation checkbox according to restrictions
  useEffect(() => {
    setCustomerTransportation(transportRestriction === "TRANSPORT_CUSTOMER");
  }, [transportRestriction, setCustomerTransportation]);

  // for transport by scribos restriction, we hide the checkbox
  const hideTransportChoice = transportRestriction === "TRANSPORT_SCRIBOS";
  // for transport by customer restriction, we disable the checkbox
  const disableTransportChoice = transportRestriction === "TRANSPORT_CUSTOMER";

  return (
    <div className="orderSummary">
      <MissingOrderReferenceDialog />
      <div className="grid">
        <div className="grid__item xs-12 md-4">
          <header className="xs-12 line-active right delivery-address">
            <div className="right mobile-only">{t("ADDRESS & DELIVERY")}</div>
            <div className="right desktop-only">&nbsp;</div>
          </header>
          <AddressDelivery objAddress={deliveryAddress} />
          {!hideTransportChoice && (
            <>
              <h4>{t("Transportation")}</h4>
              <label className="company-users-checkbox-label">
                <input
                  type="checkbox"
                  className="company-users-checkbox-input"
                  checked={customerTransportation}
                  onChange={(e) => setCustomerTransportation(e.target.checked)}
                  disabled={disableTransportChoice}
                />
                {t("Organize transport yourself")}
              </label>
            </>
          )}
        </div>

        <div className="grid__item xs-12 md-4">
          <header className="xs-12 line-active right billing-address">
            <div className="right desktop-only">{t("ADDRESS & DELIVERY")}</div>
          </header>
          <AddressBilling objAddress={billingAddress} />
        </div>

        <div className="grid__item xs-12 md-4">
          <header className="xs-12 line-inactive right">
            <div className="right desktop-only">{t("PAYMENT")}</div>
          </header>

          <section className="summaryDelivery">
            <h3>{t("Requested shipping date")}</h3>
            <div className="dhl-logo right" />
            <span className="deliveryDate">
              <DatePicker
                locale={i18n.language}
                readOnly={false}
                minDate={minDate}
                selected={selectedDate}
                onChange={(newDate) => setDeliveryDate(newDate)}
              />
            </span>
            <p className="text">
              {t(
                "The exact shipping date will be confirmed on our order confirmation."
              )}
            </p>
            <span className="customerOrderNo">
              <input
                type="text"
                maxLength={15}
                onChange={(e) => setCustomerOrderNo(e.target.value)}
                placeholder={t("Your purchase order number")}
              />
            </span>
          </section>

          <Link
            className={`button button-primary right`}
            style={{ marginRight: "0px" }}
            to={named_urls["orders:summary:payment"]}
            onClick={checkOrderReference}
          >
            {t("CONTINUE")}
          </Link>
        </div>
      </div>
    </div>
  );
};


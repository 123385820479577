import React, { useState, useEffect } from "react";
import ReactHighcharts from "react-highcharts";

import { format, parseISO } from "date-fns";

import { endpoint } from "../../../../../commonjs/endpoints";
import { i18n } from "../../../../../i18next";

import {
  VerificationStates,
  ValidState,
  ValidCodes,
} from "../Map/VerificationStates";

const LinceChartConfig = (config) => {
  const { date_start, date_end, data } = config;

  const categories = data[0].date_range.buckets.map((entry) => {
    return format(parseISO(entry.key_as_string), "dd/MM/yyyy");
  });

  // sum up all timeseries for valid codes into one aggregated series
  const validVerifications = ValidCodes.reduce(
    (prev, current) => {
      const validEntry = data.find((entry) => entry.key === current);
      if (validEntry) {
        const bucketSum = validEntry.date_range.buckets.map((currentEntry) => {
          const existsEntry = prev.date_range.buckets.find(
            (prevEntry) =>
              prevEntry.key_as_string === currentEntry.key_as_string
          );
          const additionalHits = existsEntry ? existsEntry.doc_count : 0;
          return {
            ...currentEntry,
            doc_count: currentEntry.doc_count + additionalHits,
          };
        });

        return { date_range: { buckets: bucketSum }, key: ValidState.status };
      } else return prev;
    },
    { date_range: { buckets: [] }, key: ValidState.status }
  );

  // replace all valid types with the aggregated ValidState
  const aggregatedData =
    validVerifications.date_range.buckets.length > 0
      ? [
          validVerifications,
          ...data.filter((entry) => !ValidCodes.includes(entry.key)),
        ]
      : data;

  const series = aggregatedData
    .filter((entry) => {
      // filter out states not handled by the frontend
      // background: we introduced additional states for S360
      return Boolean(
        [ValidState, ...VerificationStates].find(
          (state) => state.status === entry.key
        )
      );
    })
    .map((entry) => {
      const state = [ValidState, ...VerificationStates].find(
        (state) => state.status === entry.key
      );
      return {
        name: state.description,
        color: state.color,
        data: entry.date_range.buckets.map((entry) => entry.doc_count),
      };
    });

  return {
    credits: {
      enabled: false,
    },
    chart: {
      height: "445px",
      backgroundColor: "#F6F6F6",
      zoomType: "x",
    },
    title: {
      text: i18n.t("Nr. of Verifications"),
    },

    subtitle: {
      text: `${date_start} - ${date_end}`,
    },

    xAxis: {
      categories: categories,
    },
    yAxis: {
      title: {
        text: i18n.t("Verifications"),
      },
    },
    legend: {
      layout: "vertical",
      align: "right",
      enabled: true,
      verticalAlign: "middle",
    },

    plotOptions: {
      line: {
        dataLabels: {
          enabled: false,
        },
        enableMouseTracking: true,
      },
      series: {
        label: {
          connectorAllowed: false,
        },
      },
    },

    series: series,
    responsive: true,
  };
};

const useDateHistogramLoader = (query) => {
  const [histogramData, setHistogramData] = useState(null);

  useEffect(() => {
    endpoint("statistics:api_verifications", "POST", {
      body: { aggregation: "date_histogram", ...query },
    }).then((result) => {
      setHistogramData(result.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    query.date_start,
    query.date_end,
    query._object_id__label_id_or_checksum,
    query.verification_status,
    query.geolocation_method,
    query.domains.length,
    query.tags,
  ]);

  return histogramData;
};
export const LineChart = (props) => {
  const { t, params } = props;

  const { date_start, date_end } = params;

  const data = useDateHistogramLoader({ ...params, date_start, date_end });

  if (data) {
    if (data.length > 0) {
      return (
        <ReactHighcharts
          config={LinceChartConfig({
            date_start,
            date_end,
            data,
          })}
        />
      );
    } else
      return <h1>{t("No verifications found for the given parameters")}</h1>;
  } else {
    return "Loading...";
  }
};

import React from "react";
import ReactDOMServer from "react-dom/server";

export const SinglePinMarker = props => {
  const { keyText, color } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <linearGradient
          id={keyText}
          gradientUnits="userSpaceOnUse"
          x1="12"
          y1="0"
          x2="12"
          y2="45"
        >
          <stop
            style={{
              stopColor: color,
              stopOpacity: 1
            }}
            offset="0%"
          />
          <stop
            style={{ stopColor: "#010000", stopOpacity: 1 }}
            offset="100%"
          />
        </linearGradient>
      </defs>
      <path
        d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"
        fill={`url(#${keyText})`}
      />
    </svg>
  );
};

const getSinglePinMarkers = categories => {
  let pinMap = {};
  categories.forEach(category => {
    pinMap[category.status] =
      "data:image/svg+xml;base64," +
      window.btoa(
        ReactDOMServer.renderToString(
          <SinglePinMarker keyText={category.status} color={category.color} />
        )
      );
  });
  return pinMap;
};

export default getSinglePinMarkers;

import React, { useState } from "react";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import { NonStandardProducts } from "./NonStandardProducts";
import { StandardProducts } from "./StandardProducts";
import { useAuthentication } from "../../../commonjs/AuthenticationContext";
import { useTranslation } from "react-i18next";
import { useShoppingCart } from "./ShoppingCart";
import { useDialog } from "../modals/useDialog";

export const OrderProcess = () => {
  const { deliveryAddressApprovalPending, deliveryAddressApproved } =
    useAuthentication();

  const [tabIndex, setTabIndex] = useState(0);
  const { t } = useTranslation();
  const { clearOrder, shoppingCart } = useShoppingCart((state) => ({
    clearOrder: state.clearOrder,
    shoppingCart: state.shoppingCart,
  }));

  const [SwitchProductsWarning, openSwitchProductsWarning] = useDialog();

  const changeOrderMode = (index, lastIndex) => {
    if (index !== lastIndex) {
      if (shoppingCart.length > 0) {
        openSwitchProductsWarning({
          confirmed: () => {
            clearOrder();
            setTabIndex(index);
          },
        });
      } else {
        setTabIndex(index);
      }
    }
  };

  return (
    <section className="orderProcess">
      <SwitchProductsWarning
        title={t("Switching between Standard- and Non-Standard-Orders")}
        isCriticalAction={true}
      >
        <div className="grid__item xs-12">
          {t(
            "Switching between Standard- and Non-Standard-products will reset your current order - are you sure you want to do this?"
          )}
        </div>
      </SwitchProductsWarning>
      <h1>{t("SECURITY LABEL ORDERING")}</h1>

      {deliveryAddressApprovalPending && (
        <h2 className="warning">
          {t(
            "Currently you cannot order: Your delivery address has yet to be approved."
          )}
        </h2>
      )}
      {deliveryAddressApproved && (
        <Tabs selectedIndex={tabIndex} onSelect={changeOrderMode}>
          <TabList>
            <Tab>{t("STANDARD PRODUCTS")}</Tab>
            <Tab>{t("NON-STANDARD-PRODUCTS")}</Tab>
          </TabList>

          <TabPanel>
            <StandardProducts />
          </TabPanel>

          <TabPanel>
            <NonStandardProducts />
          </TabPanel>
        </Tabs>
      )}
    </section>
  );
};

import React from "react";
import { NavLink } from "react-router-dom";

// use local patched version instead of npm package
import Dropdown, {
  DropdownContent,
  DropdownTrigger,
} from "./react-simple-dropdown/dropdown";

import { withTranslation } from "react-i18next";

import { IfRight } from "../commonjs/util";
import { named_urls } from "../routing/known_urls";
import { Icon } from "../commonjs/icons";

const Navigate = (props) => (
  <NavLink
    {...props}
    className={({ isActive }) => [isActive ? "nav__item--active" : ""]}
  >
    <li className="nav__item">{props.children}</li>
  </NavLink>
);

const _NavigationMain = ({ t, hasRights }) => {
  // You need to translate here for the translation-process to pick up the labels properly
  /* prettier-ignore */
  const navigationItems = [
    { url: "Welcome", label: t("Home"), icon: "home", rightsNeeded: [] },
    {
      url: "orders:process",
      label: t("Ordering"),
      icon: "shopping-cart",
      rightsNeeded: ["orders.issue_order"],
    },
    {
      url: "statistics:landing",
      label: t("Statistics"),
      icon: "chart-bar",
      rightsNeeded: ["orders.view_order"],
    },
    {
      url: "accounts:profile",
      label: t("Profile"),
      icon: "user",
      rightsNeeded: [],
    },
    {
      url: "information:information",
      label: t("Documentation"),
      icon: "file-alt",
      rightsNeeded: [],
    },
    {
      url: "distribution:address_list",
      label: t("Address List"),
      icon: "address-card",
      rightsNeeded: ["distribution.distribute"],
    },
    {
      url: "distribution:distribution",
      label: t("Redistribution"),
      icon: "share-alt",
      rightsNeeded: ["distribution.distribute"],
    },
  ];

  return (
    <div>
      <nav className="nav-main mobile-only vertical-spacing-half">
        <Dropdown>
          <DropdownTrigger className="nav-main__trigger">
            <DropdownContent>
              <ul
                className="nav"
                onClick={(e) => {
                  if (e.target.classList[0] === "nav__item") {
                    return e.preventDefault();
                  }
                }}
              >
                {navigationItems.map((item, i) => (
                  <MobileNavigation
                    navigationItem={item}
                    hasRights={hasRights}
                    key={i}
                  />
                ))}
              </ul>
            </DropdownContent>
          </DropdownTrigger>
        </Dropdown>
      </nav>

      <nav className="nav-main desktop-only">
        <ul className="nav">
          {navigationItems.map((item, i) => (
            <DesktopNavigation
              navigationItem={item}
              hasRights={hasRights}
              key={i}
            />
          ))}
        </ul>
      </nav>
    </div>
  );
};

function MobileNavigation(props) {
  const { navigationItem, hasRights } = props;
  return (
    <IfRight needed={navigationItem.rightsNeeded} rights={hasRights}>
      <Navigate
        to={
          named_urls[navigationItem.url]
            ? named_urls[navigationItem.url]
            : navigationItem.url
        }
      >
        <div className="nav-icon">{navigationItem.label}</div>
      </Navigate>
    </IfRight>
  );
}

function DesktopNavigation(props) {
  const { navigationItem, hasRights } = props;
  return (
    <IfRight needed={navigationItem.rightsNeeded} rights={hasRights}>
      <Navigate
        to={
          named_urls[navigationItem.url]
            ? named_urls[navigationItem.url]
            : navigationItem.url
        }
      >
        <div>
          <Icon icon={navigationItem.icon} size="lg" />
          <span className="nav-label">{navigationItem.label}</span>
        </div>
      </Navigate>
    </IfRight>
  );
}

export const NavigationMain = withTranslation()(_NavigationMain);

import { useRef, useEffect, useState } from "react";

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();
  const [intervalId, setIntervalId] = useState();
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const start = () => {
    setIsRunning(true);
  };
  const stop = () => {
    setIsRunning(false);
  };
  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };
    if (isRunning) {
      let id = setInterval(tick, delay);
      setIntervalId(id);
    } else {
      clearInterval(intervalId);
    }
    // eslint-disable-next-line
  }, [isRunning]);

  return { start, stop };
};

import { useTranslation } from "react-i18next";
import { Field } from "react-final-form";

const checkboxCheckedValidation = (val) =>
  val === undefined || val === false ? "please check this box" : undefined;

export const CORRECTNESS_CONFIRMED = "correctnessConfirmed";
export const FormFooter = ({ printServiceDataComplete }) => {
  const { t } = useTranslation();
  return (
    <div>
      <p>
        {t(
          "Spaces between characters in the part number are calculated AUTOMATICALLY, no need for typing them in."
        )}
      </p>
      <p>
        {t("The final print on the label might vary from grey to deep black.")}{" "}
        {t(
          "Number of labels on full rolls can differ by 10% to what is mentioned on the online ordering platform."
        )}
      </p>

      {!printServiceDataComplete && (
        <p className="form-error-message">
          {t("Please complete and confirm your data.")}
        </p>
      )}

      <p>
        <label
          className={`confirm-checkbox-label ${
            !printServiceDataComplete ? "confirm-checkbox-label--disabled" : ""
          }`}
        >
          <Field
            className="confirm-checkbox-input"
            name="correctnessConfirmed"
            id="correctnessConfirmed"
            component="input"
            type="checkbox"
            validate={checkboxCheckedValidation}
            disabled={!printServiceDataComplete}
          />
          {t("Hereby I confirm the correctness of the data entered above.")}
        </label>
      </p>
    </div>
  );
};

import React from "react";

import { withTranslation } from "react-i18next";

export const VolumeStatus = withTranslation()(({ t }) => {
  // Translation Data
  const T = {
    "Initial Quantities": t("Initial Quantities"),
    "Available Quantities": t("Available Quantities"),
    "Ordered Labels": t("Ordered Labels"),
    "Delivered Labels": t("Delivered Labels"),
    "Activated Labels": t("Activated Labels")
  };

  const tableHeader = [
    "category-header",
    T["Initial Quantities"],
    T["Available Quantities"],
    T["Ordered Labels"],
    T["Delivered Labels"],
    T["Acivated Labels"]
  ];

  return (
    <section className="volumes">
      <h1>{t("VOLUME STATUS")}</h1>
      <div className="grid">
        <div className="grid__item xs-6">
          <div className="volumes__table">
            <table
              style={
                null /*{'minWidth': Object.keys(products).length*100+100+'px'}*/
              }
            >
              <thead>
                <tr>
                  {tableHeader.map((colName, id) => (
                    <th key={id}>
                      <em className={colName}>{colName}</em>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div>{T["Initial Quantities"]}</div>
                  </td>
                  <td>
                    <div>{T["Available Quantities"]}</div>
                  </td>
                  <td>
                    <div>{T["Ordered Labels"]}</div>
                  </td>
                  <td>
                    <div>{T["Delivered Labels"]}</div>
                  </td>
                  <td>
                    <div>{T["Activated Labels"]}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="grid__item xs-6">
          {false ? (
            <div className="statisticbars">[____stat bars____]</div>
          ) : null}
        </div>
      </div>
      <button className="button">{t("DOWNLOAD PDF")}</button>
    </section>
  );
});

import { useEffect, useState } from "react";

export const RenderDelayed = ({ delay = 50, children }) => {
  const [render, setRender] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setRender(true), delay);

    return () => clearTimeout(timeout);
  });

  return render ? children : null;
};

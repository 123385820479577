import React, { useState } from "react";

import { Icon } from "../../../../commonjs/icons";
import { endpoint } from "../../../../commonjs/endpoints";

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}
export const IdFilter = (props) => {
  const { t, setHolospotId, setResult } = props;
  const [labelId, setLabelId] = useState("");
  const [labelNotFound, setLabelNotFound] = useState(false);
  const [lookupPending, setLookupPending] = useState(false);
  const [objectIdNotFound, setObjectIdNotFound] = useState(false);

  const updateLabelId = (event) => {
    const label = event.target.value;
    setLabelNotFound(false);
    setObjectIdNotFound(false);

    if (setResult) setResult(undefined);

    if (label.length === 0) {
      setLabelId("");
      setHolospotId(undefined);
      return;
    }

    if (label.startsWith("#")) {
      enterHolospotId(label);
    } else {
      enterLabelId(label);
    }
  };
  const enterHolospotId = (label) => {
    const cleanedLabel = label.toUpperCase().replace(/[^#1234567890]/g, "");

    setLabelId(cleanedLabel);

    if (cleanedLabel.length > 3) {
      const lookup_id = cleanedLabel.replace("#", "");
      setLookupPending(true);
      endpoint("statistics:api_label_info", "GET", {
        param: lookup_id,
      }).then((result) => {
        setLookupPending(false);
        if (!isEmpty(result)) {
          setHolospotId(lookup_id);
          setObjectIdNotFound(false);
          if (setResult) setResult(result);
        } else {
          if (setResult) setResult(undefined);
          setObjectIdNotFound(true);
        }
      });
    }
  };

  const enterLabelId = (label) => {
    const cleanedLabel = label
      .toUpperCase()
      .replace(/[^BCDFGHJKLMNPQRSTVWXYZ1234567890]/g, "")
      .slice(0, 12);

    if (
      cleanedLabel !== labelId &&
      cleanedLabel.length > 0 &&
      cleanedLabel.length === 12
    ) {
      setLookupPending(true);
      endpoint("statistics:api_label_info", "GET", {
        param: cleanedLabel,
      }).then((result) => {
        setLookupPending(false);
        if (isEmpty(result)) {
          setLabelNotFound(true);
          if (setResult) setResult(undefined);
        } else {
          setLabelNotFound(false);
          setHolospotId(result.holospot_id);
          if (setResult) setResult(result);
        }
      });
    }
    setLabelId(cleanedLabel);
  };

  const labelIdTooShort = () => {
    if (labelId.startsWith("#")) return false;
    else return labelId.length > 0 && labelId.length < 12;
  };

  return (
    <div className={"grid query-params"}>
      <div className="grid__item xs-11" style={{ paddingLeft: "0px" }}>
        <label>{t("Label ID/ # Object ID")}</label>
        <input
          type={"text"}
          value={labelId}
          onChange={updateLabelId}
          autoFocus
          maxLength={12}
          width={"100%"}
        />
        {labelIdTooShort() && (
          <span className="errorMessage" style={{ color: "red" }}>
            {t("Please enter 12 Characters for the Label Id")}
          </span>
        )}
        {labelNotFound && (
          <span className="errorMessage" style={{ color: "red" }}>
            {t("The entered Label ID was not found")}
          </span>
        )}
        {objectIdNotFound && (
          <span className="errorMessage" style={{ color: "red" }}>
            {t("The entered Object ID was not found")}
          </span>
        )}
      </div>
      <div
        className="grid__item xs-1"
        style={{ paddingTop: "1.5em", marginLeft: "-1.5em" }}
      >
        {lookupPending && <Icon icon={"spinner"} spin pulse />}&nbsp;
      </div>
    </div>
  );
};

import React, { Fragment } from "react";
import ReactTooltip from "react-tooltip";

import { useTranslation } from "react-i18next";
import uuid from "uuid/v4";

import { Icon, WindingDirections } from "../../../../commonjs/icons";
import { useModal } from "../../modals";

const IconTooltip = ({ icon, text, type }) => {
  const tooltipId = uuid();
  const isWarning = type === "warning";

  return (
    <div style={{ float: "left" }} role="tooltip">
      <div data-for={tooltipId} data-tip={text}>
        <Icon icon={icon} className={`${isWarning ? "icon-warning" : ""}`} />
      </div>
      <ReactTooltip
        id={tooltipId}
        place="right"
        effect="solid"
        className={`tooltip-${isWarning ? "warning" : "info"}`}
      >
        <span>{text}</span>
      </ReactTooltip>
    </div>
  );
};
export const NoteIndicator = ({ note }) => {
  if (note) {
    return <IconTooltip type="warning" icon="exclamation-triangle" text={note} />;
  } else return null;
};

export const RewindFlag = ({ rewindFlag }) => {
  const { t } = useTranslation();

  return rewindFlag ? (
    <IconTooltip
      icon="undo"
      text={t(
        "This is a non-standard winding direction causing special service fees."
      )}
    />
  ) : null;
};

export function DetailImage({ image, nonStandardRow = false }) {
  const [ImageDialog, openImageDialog] = useModal({});

  return (
    <Fragment>
      <ImageDialog>
        <div className="modal--image">
          <img src={image} alt="Product Detail" />
        </div>
      </ImageDialog>
      <div className="cell open-modal" style={{ paddingLeft: "10px" }}>
        {image && <div className={`${nonStandardRow ? "info-modal-icon-non-standard-row" : "info-modal-icon"}`} onClick={openImageDialog} />}
      </div>
    </Fragment>
  );
}

export function WindingDirection(props) {
  const { direction } = props;

  return (
    <td>
      <div className="cell open-modal">
        <img
          src={WindingDirections[direction]}
          height={45}
          alt={`Winding Direction ${direction}`}
        />
      </div>
    </td>
  );
}

import { useState } from "react";

export const useFunction = (defaultFunction) => {
  const [storedFunction, setStoredFunction] = useState(
    () => () => defaultFunction
  );

  return [
    storedFunction,
    (newFunction) => setStoredFunction(() => () => newFunction()),
  ];
};

import { Observable } from "rxjs";
import { combineEpics } from "redux-observable";

import { notificationError } from "./commonjs/notifications";
import { endpoint } from "./commonjs/endpoints";

import { boot } from "./index";

import {
  ACCOUNTS_VERIFY_SESSION,
  accountsLoginSuccess,
  accountsLoginError,
  accountsEpics,
} from "./licensing/accounts/";
import { settingsEpics } from "./commonjs/duck";

/* Session verification & App Booting
 */

const verifySessionAndBootEpic = (action$, store) =>
  action$.ofType(ACCOUNTS_VERIFY_SESSION).mergeMap((action) => {
    return Observable.from(endpoint("accounts:api_login", "GET"))
      .map((response) => {
        // render the app
        boot();
        if (!response.error) {
          if (response.permissions) {
            return accountsLoginSuccess(response.permissions, response.params);
          } else {
            return accountsLoginError();
          }
        } else {
          return [notificationError("accounts verify session: fatal error")];
        }
      })
      .catch((e) => {
        boot();
        // something has gone wrong
        // user will land on login page because of missing rights see: hoc.js -> authRoute hoc
        return Observable.of(
          notificationError("Session Not Verified! Please Login " + e)
        );
      });
  });

export const combinedEpics = combineEpics(
  //boot
  verifySessionAndBootEpic,
  //accounts
  accountsEpics,
  // settings
  settingsEpics
);

// redux-observable global error handling
export const rootEpic = (action$, store) =>
  combinedEpics(action$, store) // <-- call epic w/ args
    .do({ error: (err) => alert("ROOT EPIC ERROR" + err) });

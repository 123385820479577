import React from "react";

export const RenderCheckbox = ({
  disabled,
  input,
  placeholder,
  type,
  meta: { touched, error, warning },
}) => {
  return (
    <div>
      <input
        disabled={disabled}
        className="vertical-spacing-half"
        {...input}
        placeholder={placeholder}
        type="checkbox"
      />
      {(error && <span className="error-checkbox">{error}</span>) ||
        (warning && <span className="warning">{warning}</span>)}
    </div>
  );
};

export const RenderCheckboxGroup = ({
  label,
  required,
  options,
  disabled,
  input,
  placeholder,
  type,
  meta: { touched, error, warning },
}) => {
  // We need to add conversion into proper checkbox-group-values for prefilled quotas
  const normalizedValues =
    input.value &&
    input.value.length > 0 &&
    input.value[0].product_group !== undefined
      ? input.value
          .filter((quota) => quota.value === true)
          .map((quota) => quota.product_group)
      : input.value;

  return (
    <div className="grid__item xs-12 md-12">
      {options.map((option, index) => (
        <div key={index} className={"checkbox grid__item xs-4 md-4"}>
          <input
            type="checkbox"
            className="vertical-spacing-half"
            name={`${input.name}[${index}]`}
            value={option.name}
            checked={normalizedValues.indexOf(option.name) !== -1}
            disabled={disabled}
            onChange={(event) => {
              const newValue = [...normalizedValues];
              if (event.target.checked) {
                newValue.push(option.name);
              } else {
                newValue.splice(newValue.indexOf(option.name), 1);
              }

              return input.onChange(newValue);
            }}
          />
          <label
            htmlFor={option.name}
            style={{
              display: "inline-block",
              marginLeft: "0.5rem",
              marginTop: "-2.5rem",
              lineHeight: "2rem",
              verticalAlign: "middle",
            }}
          >
            {option.label}
          </label>
        </div>
      ))}
      {error && (
        <div className={"error-checkbox grid__item xs-12 md-12"}>
          {error.value}
        </div>
      )}
    </div>
  );
};




import React from "react";
import { withTranslation } from "react-i18next";

export const Faq = withTranslation()(({ t }) => (
  <section className="faq">
    <h1>FAQs</h1>

    <hr className="hr--invert" />

    <section className="faq__entry">
      <header>
        {t(
          "My log-in for the Stellantis ordering platform does not work anymore. What can I do?"
        )}
      </header>
      {t(
        "Did you enter the right e-mail address and the correct password? If you forgot your password you can create a new one in your ‘PROFILE’ section. Or simply click on ‘Forgot your password’ on the log-in page."
      )}
    </section>

    <hr className="hr--invert" />

    <section className="faq__entry">
      <header>{t("Is there any minimum order volume?")}</header>
      {t(
        "There is no minimum order volume but handling fees for small orders below 2'000,-€ (see General Conditions under 'INFORMATION' in the platform)."
      )}
    </section>

    <hr className="hr--invert" />

    <section className="faq__entry">
      <header>{t("Which means of payment are available?")}</header>
      {t("The payment terms are 30 days net from date of invoice.")}
    </section>

    <hr className="hr--invert" />

    <section className="faq__entry">
      <header>{t("Claims")}</header>
      {t(
        "For eventual claims concerning your security labels, please use the contact form in the ‘CONTACT’ section."
      )}
    </section>

    <hr className="hr--invert" />

    <section className="faq__entry">
      <header>{t("What are the delivery terms and conditions?")}</header>
      {t(
        "FCA scribos® Heidelberg, Germany, Incoterms® 2020 including packaging. The delivery can be organized by scribos® if requested."
      )}
    </section>

    <hr className="hr--invert" />

    <section className="faq__entry">
      <header>{t("When will my order be delivered?")}</header>
      {t(
        "The date of the expected shipment will be confirmed in the order confirmation you will receive per e-mail. If you did not receive any order confirmation within two days or the delivery did not arrive on time, please contact scribos customer service. Please indicate your order number and / or account ID."
      )}
    </section>

    <hr className="hr--invert" />

    <section className="faq__entry">
      <header>{t("How can I check the status of my order?")}</header>
      {t(
        "When logged in to your account, go to ‘STATISTICS’. You can look up the order status of all past orders in your ‘ORDER HISTORY’. In addition to this, we will send you an e-mail confirming your order including delivery time after your order has been placed."
      )}
    </section>

    <hr className="hr--invert" />

    <section className="faq__entry">
      <header>{t("How can I cancel my order?")}</header>
      {t(
        "You can cancel your order as long as it has not been processed by scribos. Just click on the 'CANCEL' button in your ‘ORDER HISTORY’."
      )}
    </section>

    <hr className="hr--invert" />

    <section className="faq__entry">
      <header>
        {t(
          "How can I contact Stellantis or scribos for questions concerning my orders or the brand protection program in general?"
        )}
      </header>
      {t(
        "Go to the ’CONTACT‘ section in your account. Please check first if your question is already answered by our FAQs. If this is not the case, please select the topic of your question in the contact form and explain your issue as detailed as possible. It will then be forwarded to the person in charge."
      )}
    </section>

    <hr className="hr--invert" />

    <section className="faq__entry">
      <header>{t("My billing address has changed - what should I do?")}</header>
      {t(
        "Just click on ’PROFILE‘. You can change your billing address or update your profile there."
      )}
    </section>

    <hr className="hr--invert" />

    <section className="faq__entry">
      <header>
        {t("My delivery address has changed - what should I do?")}
      </header>
      {t(
        "Please check in the profile page on the ordering platform, if you can change the delivery address yourself. If this is not possible you have to change the delivery address on the Stellantis database called MKA by contacting your referent buyer and it will be changed automatically on the platform as well."
      )}
    </section>

    <hr className="hr--invert" />

    <section className="faq__entry">
      <header>{t("My company name has changed - what should I do?")}</header>
      {t("Please contact scribos Customer Service in order to proceed.")}
    </section>

    <hr className="hr--invert" />

    <section className="faq__entry">
      <header>{t("Can I choose a shipment date?")}</header>
      {t(
        "You can indicate a requested shipment date in your order summary. Just enter the requested date in the corresponding field. We will confirm the delivery date in our order confirmation. If the shipment date is not possible, you will be contacted by scribos customer service."
      )}
    </section>

    <hr className="hr--invert" />

    <section className="faq__entry">
      <header>
        {t("I want to order but the order is blocked – what does that mean?")}
      </header>
      {t(
        "There are several reasons for not being able to proceed with your order."
      )}
      <ul>
        <li>
          {t(
            "You still have a significant number of non-activated labels of the same reference in stock. Please contact the person in charge at Stellantis."
          )}
        </li>
        <li>
          {t(
            "You have overdue invoices and need to pay these invoices before ordering new labels. Please contact scribos for any questions."
          )}
        </li>
      </ul>
    </section>
  </section>
));

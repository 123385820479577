import { Fragment, useContext, useState } from "react";
import { Collapse } from "react-collapse";
import { PrintServiceFields } from "./PrintServiceFields";
import { Header } from "./Header";
import { useTranslation } from "react-i18next";
import { LabelPreview } from "../components/LabelPreview";
import { Form } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { CORRECTNESS_CONFIRMED } from "./FormFooter";
import { useShoppingCart } from "../../ShoppingCart";
import { NonStandardProductContext } from "../components/NonStandardProductContext";


export const PrintService = ({ product, rowId }) => {
  const { addPrintData, removePrintData } = useShoppingCart((state) => ({
    addPrintData: state.addPrintData,
    removePrintData: state.removePrintData,
  }));

  const { dispatchRowEvent, rowHasUnconfirmedPrintData } = useContext(
    NonStandardProductContext
  );

  const { t } = useTranslation();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const toggleCollapse = () => setIsFormOpen(!isFormOpen);

  const [hasPrintData, setHasPrintData] = useState(false);

  return (
    <div>
      <Form
        initialValues={{}}
        onSubmit={() => undefined}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
      >
        {({ form, values }) => {
          if (form.getState().pristine) {
            if (hasPrintData) {
              setHasPrintData(false);
              dispatchRowEvent({
                type: "removePrintingService",
                payload: {
                  rowId: rowId,
                  hasPrintData: false,
                  correctnessConfirmed: false,
                },
              });
            }
          } else {
            if (!hasPrintData) {
              setHasPrintData(true);
              dispatchRowEvent({
                type: "addPrintingService",
                payload: {
                  rowId: rowId,
                  hasPrintData: true,
                  correctnessConfirmed: values["correctness_confirmed"],
                },
              });
            }
          }

          return (
            <Fragment>
              <OnChange name={CORRECTNESS_CONFIRMED}>
                {(confirmed, previous) => {
                  if (confirmed) {
                    addPrintData(product, form.getState().values, rowId);
                    dispatchRowEvent({
                      type: "confirmPrintingService",
                      payload: { rowId },
                    });
                  } else {
                    removePrintData(rowId);
                    dispatchRowEvent({
                      type: "unconfirmPrintingService",
                      payload: { rowId },
                    });
                  }
                }}
              </OnChange>
              <Header
                isFormOpen={isFormOpen}
                setIsFormOpen={setIsFormOpen}
                toggleCollapse={toggleCollapse}
                rowId={rowId}
                errorMessage={
                  rowHasUnconfirmedPrintData(rowId)
                    ? t("Please complete your data or clear the form below.")
                    : undefined
                }
              />
              <Collapse isOpened={isFormOpen}>
                <div className="grid">
                  <div className="grid__item xs-12 vertical-spacing">
                    <p>
                      {t(
                        "Please fill in your details concerning your requested non-standard services carefully."
                      )}
                      <br />
                      {t(
                        "This information will be used as a basis for the production of your final product!"
                      )}
                    </p>
                  </div>
                  <div className="grid__item xs-12 md-7">
                    <PrintServiceFields product={product} />
                  </div>
                  <div className="grid__item xs-12 md-5">
                    <LabelPreview product={product} />
                  </div>
                </div>
              </Collapse>
            </Fragment>
          );
        }}
      </Form>
    </div>
  );
};

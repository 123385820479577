import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ContactCustomerService } from "../../../commonjs/constants";
import ProductGroupRights from "../../../commonjs/components/ProductGroupRights";

function AccountApprovalDataFailureMessage({ title, message }) {
  return (
    <section className="client-confirm">
      <h1 className="failed">{title}</h1>
      <section>
        {message}
        <br />
        <ContactCustomerService />
        <br />
      </section>
    </section>
  );
}
function AccountApprovalDataFailed() {
  const { t } = useTranslation();
  return (
    <AccountApprovalDataFailureMessage
      title={t("ACCOUNT APPROVAL FAILED")}
      message={t(
        "Retrieval of the user data failed due to an invalid approval code."
      )}
    />
  );
}

function AccountApprovalDataUserAlreadyDeleted() {
  const { t } = useTranslation();
  return (
    <AccountApprovalDataFailureMessage
      title={t("ACCOUNT ALREADY REJECTED")}
      message={t(
        "The user belonging to this account was rejected and has already been deleted."
      )}
    />
  );
}
function AccountApprovalDataUserAlreadyApproved() {
  const { t } = useTranslation();
  return (
    <AccountApprovalDataFailureMessage
      title={t("ACCOUNT ALREADY APPROVED")}
      message={t("The account for this user has already been approved.")}
    />
  );
}

function AccountApprovalData({ data, onApproval }) {
  const { t } = useTranslation();
  const [feedback, setFeedback] = useState("");
  const feedbackMaxLength = 300;

  const supplierCategoryMapping = {
    INTERNAL_SUPPLIERS: t("Internal"),
    EXTERNAL_SUPPLIERS: t("External"),
  };

  const contactCategoryMapping = {
    PROVISIONING: t("Provisioning contact"),
    COMMERCIAL: t("Commercial contact"),
  };

  const {
    first_user,
    user,
    company_profile,
    addresses,
    contacts,
    product_group_rights,
    cofor6_unknown,
    cofor6_found_in_cofor_10,
  } = data;

  const company = {
    cofor6: company_profile.cofor6,
    cofor10: company_profile.cofor10,
    product_group_rights,
  };

  return (
    <section className="client-confirm">
      <h1 style={{ paddingBottom: 0 }}>{t("APPROVE ACCOUNT")}</h1>
      <h2 style={{ paddingTop: 0 }}>
        {first_user
          ? t("New account registration")
          : t("Additional  user registration")}
      </h2>
      <hr className="company-detail-separator" />
      <h2>{t("User information")}</h2>
      <span>
        {user.first_name} {user.last_name}
      </span>
      <span>{user.email}</span>
      <hr className="company-detail-separator" />
      <h2>{t("Company information")}</h2>
      <span>
        {t("Supplier category")}:{" "}
        {supplierCategoryMapping[company_profile.company_group]}
      </span>
      <br />
      <span>
        {t("Company")}: {company_profile.name}
      </span>
      <span>
        {t("COFOR6")}: {company_profile.cofor6} / {t("Account id")}:{" "}
        {company_profile.cofor10}
      </span>
      {cofor6_unknown && !cofor6_found_in_cofor_10 && (
        <p className="warning">
          {t(
            "The company has registered with an unknown COFOR6 - please check, that the COFOR6 is valid."
          )}
        </p>
      )}
      {cofor6_found_in_cofor_10 && (
        <p className="warning">
          {t(
            "The company has registered with an unknown cofor(6) which is known as a cofor(10)."
          )}
        </p>
      )}
      {addresses && <h2>{t("Delivery address")}</h2>}
      {addresses &&
        addresses.map((a) => {
          return a.category !== "DELIVERY" ? null : (
            <div key={a.pk}>
              <span>{a.street_address}</span>
              <span>
                {a.postal_code} {a.city}
              </span>
              <span>
                {a.country} ({a.country_code})
              </span>
              <span>{a.address_details}</span>
            </div>
          );
        })}
      {contacts && <h2>{t("Contact emails")}</h2>}
      {contacts &&
        contacts.map((c) => {
          return c.category !== "COMMERCIAL" &&
            c.category !== "PROVISIONING" ? null : (
            <div key={c.pk}>
              <span>
                {contactCategoryMapping[c.category]}: {c.email}
              </span>
            </div>
          );
        })}
      <hr className="company-detail-separator" />
      <ProductGroupRights
        cofor6={company.cofor6}
        cofor10={company.cofor10}
        productGroupRights={company.product_group_rights}
      />
      <hr className="company-detail-separator" />
      <h2>{t("Feedback to supplier (rejection only)")}</h2>
      <textarea
        style={{ maxWidth: "40rem" }}
        value={feedback}
        onChange={(e) => setFeedback(e.target.value)}
        rows={5}
        cols={40}
        maxLength={feedbackMaxLength}
      />
      <div>{`${t("Characters:")} ${feedback.length}/${feedbackMaxLength}`}</div>
      <hr className="company-detail-separator" />
      <br />
      <button
        className="button button-primary"
        onClick={() => onApproval(true, feedback)}
      >
        {t("APPROVE")}
      </button>
      <button
        className="button button-critical-action"
        onClick={() => onApproval(false, feedback)}
      >
        {t("REJECT")}
      </button>
    </section>
  );
}

export {
  AccountApprovalData,
  AccountApprovalDataFailed,
  AccountApprovalDataUserAlreadyApproved,
  AccountApprovalDataUserAlreadyDeleted,
};

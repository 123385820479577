import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { useTranslation } from "react-i18next";
import { Form, Field } from "react-final-form";

import { Icon } from "../icons";
import { check_rights } from "../util";
import { useMutation } from "../fetchHooks";
import { useNotifications } from "../NotificationContext";

const UPDATE_QUOTA = ({ cofor6, cofor10, product_groups }) => ({
  method: "PUT",
  endpoint: "orders:api_quota_update",
  data: { body: { cofor6, cofor10, product_groups } },
});

const ProductGroupRightsUpdateView = ({
  cofor6,
  cofor10,
  productGroupRights,
}) => {
  const { t } = useTranslation();
  const { addNotification } = useNotifications();

  const initialProductGroupRights = {};

  for (let group of productGroupRights) {
    initialProductGroupRights[`group_${group.id}`] = group.can_order;
  }
  const [mutate] = useMutation(UPDATE_QUOTA);

  const onSubmit = async (values) => {
    let groups = [];
    for (const [key, value] of Object.entries(values)) {
      // we are only interested in the selected product groups
      if (!value) {
        continue;
      }
      // split "group_x" to get the group id
      groups.push(key.split("_")[1]);
    }
    const result = await mutate({ cofor6, cofor10, product_groups: groups });
    if (result.error) {
      addNotification({
        title: t("Product groups update"),
        message: t("Product groups update failed."),
        level: "error",
      });
    } else {
      addNotification({
        title: t("Product groups update"),
        message: t("Product groups updated successfully."),
        level: "success",
      });
    }
  };

  return (
    <Form initialValues={initialProductGroupRights} onSubmit={onSubmit}>
      {({ handleSubmit, pristine, submitting }) => (
        <form onSubmit={handleSubmit}>
          <table className="product-group-table">
            <tbody>
              {productGroupRights.map((group) => (
                <tr key={group.id}>
                  <td>{group.product_group}</td>
                  <td>
                    <Field
                      name={`group_${group.id}`}
                      component="input"
                      type="checkbox"
                    />
                  </td>
                </tr>
              ))}
              <tr colSpan="2">
                <td>
                  <button
                    type="submit"
                    disabled={submitting}
                    className={`button button-primary ${pristine || submitting
                      ? "button-primary--disabled"
                      : "clickable"
                      }`}
                  >
                    {t("UPDATE")}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      )}
    </Form>
  );
};

const ProductGroupRightsView = ({ productGroupRights }) => {
  return (
    <table className="product-group-table">
      <tbody>
        {productGroupRights.map((group) => (
          <tr key={group.id}>
            <td>{group.product_group}</td>
            <td>
              {group.can_order ? (
                <Icon icon={"check"} size="sm" color="green" />
              ) : (
                <Icon icon={"times-circle"} size="sm" color="red" />
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const _ProductGroupRights = ({
  hasRights,
  cofor10,
  cofor6,
  productGroupRights,
}) => {
  const { t } = useTranslation();
  const canUpdate =
    cofor10.startsWith("T") &&
    check_rights(
      ["orders.change_quota", "orders.add_quota", "orders.delete_quota"],
      hasRights
    );

  return (
    <React.Fragment>
      <h2>{t("Product Group Rights")}</h2>
      {canUpdate ? (
        <ProductGroupRightsUpdateView
          cofor6={cofor6}
          cofor10={cofor10}
          productGroupRights={productGroupRights}
        />
      ) : (
        <ProductGroupRightsView productGroupRights={productGroupRights} />
      )}
    </React.Fragment>
  );
};

// we still need the user permissions from redux
const mapStateToProps = (state) => ({
  hasRights: state.accounts.rights,
});

export default compose(connect(mapStateToProps))(_ProductGroupRights);

export const localSettings = {
  // hide register link on login page (accounts module)
  accountsHideRegisterLink: false,
  // hide COFOR6 line in account box (accounts module)
  accountsHideAccountBoxLine: false,
  // hide FAQ (information module)
  // from 2021-05-07 we use a downloadable faq document
  // but keep it in case we have to roll back
  informationHideFaq: true
};

import React, { Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { withTranslation } from "react-i18next";

import { withOnMount } from "../../hoc";
import { localSettings } from "../../settings";

import { ModalRoot } from "./modals";

import { CompanyUsersForm } from "./forms/CompanyUsersForm";
import { AddressForm } from "./forms/AddressForm";
import { ProfileForm } from "./forms/ProfileForm";
import { PasswordForm } from "./forms/PasswordForm";

import {
  accountsFetch,
  accountsFetchAllUsers,
  accountsFetchCountries,
  accountsSendUserData,
  accountsSendUserDisable,
  accountsSendUserEnable,
  accountsSendAddressData,
  accountsSendPasswordReset,
  selectCountries,
} from "./duck";

const Separator = () => {
  return (
    <div className="grid__item xs-12">
      <hr />
    </div>
  );
};

const FormLine = ({ children }) => {
  return (
    <Fragment>
      <div className="grid__item xs-12 sm-12 md-6">{children}</div>
      <Separator />
    </Fragment>
  );
};

class _Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalType: null,
      modalProps: {},
    };
  }

  showModal(modalType, modalProps) {
    this.setState({
      modalType,
      modalProps,
    });
  }

  hideModal() {
    this.setState({
      modalType: null,
      modalProps: {},
    });
  }

  cancelCompanyUsers() {
    this.hideModal();
  }

  disableUsers(values) {
    const userIds = values.users.reduce((result, user) => {
      if (user.value && user.value === true) {
        result.push({ pk: user.pk, email: user.email });
      }
      return result;
    }, []);
    this.props.submitUsersDisable(userIds);
    this.hideModal();
  }

  enableUsers(values) {
    const userIds = values.users.reduce((result, user) => {
      if (user.value && user.value === true) {
        result.push({ pk: user.pk, email: user.email });
      }
      return result;
    }, []);
    this.props.submitUsersEnable(userIds);
    this.hideModal();
  }

  render() {
    const {
      t,
      data,
      users,
      countryOptions,
      submitAddressData,
      submitPasswordReset,
      submitUserData,
    } = this.props;
    const { modalType, modalProps } = this.state;

    if (
      data.company_profile === undefined ||
      countryOptions.length === 0 ||
      users.length === 0
    ) {
      return null;
    }
    const {
      name,
      cofor6,
      cofor10,
      vat_identification_no,
      billing_address,
      delivery_address,
    } = data.company_profile;

    const activeUsers = users.filter((u) => u.is_active);
    const inactiveUsers = users.filter((u) => !u.is_active);

    return (
      <section className="profile">
        <ModalRoot modalType={modalType} modalProps={modalProps} />
        <div className="grid__item xs-12">
          <h1>{t("UPDATE PROFILE")}</h1>
        </div>

        <div className="grid__item xs-12 sm-12 md-12">
          <h2>{t("Company information")}</h2>
          <div className="company-profile-container">
            <div className="grid__item xs-12 vertical-spacing-half">{name}</div>
            {!localSettings.accountsHideAccountBoxLine && (
              <div className="grid__item xs-12 vertical-spacing-half">
                {t("COFOR6")}: {cofor6}
              </div>
            )}
            <div className="grid__item xs-12 vertical-spacing-half">
              {t("Account id")}: {cofor10}
            </div>
            <div className="grid__item xs-12 vertical-spacing-half">
              {t("VAT")}: {vat_identification_no}
            </div>
          </div>
        </div>

        <div className="grid__item xs-12 sm-12 md-6">
          <h2>{t("Active company users")}</h2>
          <CompanyUsersForm
            activeUser={data}
            initialValues={{ users: activeUsers }}
            onSubmit={(values) => {
              this.showModal("DISABLE_COMPANY_USERS", {
                users: values.users,
                handleOk: () => this.disableUsers(values),
                handleCancel: () => this.cancelCompanyUsers(),
              });
            }}
            submitText={t("DISABLE SELECTED COMPANY USERS")}
          />
        </div>

        <div className="grid__item xs-12 sm-12 md-6">
          <h2>{t("Inactive company users")}</h2>
          <CompanyUsersForm
            activeUser={data}
            initialValues={{ users: inactiveUsers }}
            onSubmit={(values) => {
              this.showModal("ENABLE_COMPANY_USERS", {
                users: values.users,
                handleOk: () => this.enableUsers(values),
                handleCancel: () => this.cancelCompanyUsers(),
              });
            }}
            submitText={t("ENABLE SELECTED COMPANY USERS")}
          />
        </div>

        <div className="grid__item xs-12">
          <hr />
        </div>

        {/* All companies can change their Billing-Address
         */}
        <FormLine>
          <h2>{t("Billing address")}</h2>
          <AddressForm
            initialValues={billing_address}
            countryOptions={countryOptions}
            onSubmit={submitAddressData}
          />
        </FormLine>

        {/* only T-Accounts can change their delivery address */}
        {cofor10.startsWith("T") && (
          <FormLine>
            <h2>{t("Delivery address")}</h2>
            <AddressForm
              initialValues={delivery_address}
              countryOptions={countryOptions}
              onSubmit={submitAddressData}
            />
          </FormLine>
        )}

        <div className="grid__item xs-12 sm-12 md-6 profile__address">
          <h2>{t("User profile")}</h2>
          <ProfileForm initialValues={data} onSubmit={submitUserData} />
        </div>

        <div className="grid__item xs-12">
          <hr />
        </div>

        <div className="grid__item xs-12 sm-12 md-6 profile__password">
          <h2>{t("Reset password")}</h2>
          <span>
            {t(
              "Click the button below to obtain a password reset link per email."
            )}
          </span>
          <PasswordForm initialValues={data} onSubmit={submitPasswordReset} />
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.accounts.data,
  users: state.accounts.users,
  countryOptions: selectCountries(state),
});

const mapDispatchToProps = (dispatch) => ({
  onMount: () => {
    dispatch(accountsFetch());
    dispatch(accountsFetchAllUsers());
    dispatch(accountsFetchCountries());
  },
  submitAddressData: (values) => {
    dispatch(accountsSendAddressData(values));
  },
  submitPasswordReset: (values) => {
    dispatch(
      accountsSendPasswordReset({
        email: values.email,
        account_id: values.company_profile.cofor10,
      })
    );
  },
  submitUserData: (values) => {
    dispatch(accountsSendUserData(values));
  },
  submitUsersDisable: (values) => {
    dispatch(accountsSendUserDisable(values));
  },
  submitUsersEnable: (values) => {
    dispatch(accountsSendUserEnable(values));
  },
});

export const Profile = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withOnMount,
  withTranslation()
)(_Profile);

/*
  2017-09-05/GK:
  This file is a mess - and needs to be splitted ...
*/

import { Observable } from "rxjs";
import NProgress from "nprogress";
import { combineEpics } from "redux-observable";
import { formValueSelector, reset } from "redux-form";
import { startSubmit, stopSubmit } from "redux-form";
import { push } from "react-router-redux";
import { redirect } from "react-router-dom";

import { clone } from "../../commonjs/util";
import { endpoint } from "../../commonjs/endpoints";

import {
  notificationError,
  notificationSuccess,
  notificationMessageSuccess,
  notificationMessageError,
} from "../../commonjs/notifications";

import { i18n } from "../../i18next";

import { named_urls } from "../../routing/known_urls";

import { E_REGISTER_CASE } from "./register/defines";

/* Action Types
 */
const FIRST_URL = "FIRST_URL";

export const ACCOUNTS_VERIFY_SESSION = "ACCOUNTS_VERIFY_SESSION";
const ACCOUNTS_SESSION_STATUS = "ACCOUNTS_SESSION_STATUS";
const ACCOUNTS_SESSION_STATUS_SUCCESS = "ACCOUNTS_SESSION_STATUS_SUCCESS";
const ACCOUNTS_SESSION_STATUS_ERROR = "ACCOUNTS_SESSION_STATUS_ERROR";

const ACCOUNTS_LOGIN = "ACCOUNTS_LOGIN";
const ACCOUNTS_LOGIN_CAPTCHA_COMPLETE = "ACCOUNTS_LOGIN_CAPTCHA_COMPLETE";
const ACCOUNTS_LOGIN_SUCCESS = "ACCOUNTS_LOGIN_SUCCESS";
const ACCOUNTS_LOGIN_ERROR = "ACCOUNTS_LOGIN_ERROR";
const ACCOUNTS_LOGOUT = "ACCOUNTS_LOGOUT";
const ACCOUNTS_LOGIN_PASSWORD_EXPIRED = "ACCOUNTS_LOGIN_PASSWORD_EXPIRED";

const ACCOUNTS_FETCH = "ACCOUNTS_FETCH";
const ACCOUNTS_FETCH_SUCCESS = "ACCOUNTS_FETCH_SUCCESS";
const ACCOUNTS_FETCH_ERROR = "ACCOUNTS_FETCH_ERROR";

const ACCOUNTS_FETCH_ALL_USERS = "ACCOUNTS_FETCH_ALL_USERS";
const ACCOUNTS_FETCH_ALL_USERS_SUCCESS = "ACCOUNTS_FETCH_ALL_USERS_SUCCESS";
const ACCOUNTS_FETCH_ALL_USERS_ERROR = "ACCOUNTS_FETCH_ALL_USERS_ERROR";

const SET_SPEC_ID = "SET_SPEC_ID";
const ACCOUNTS_SET_AUTH = "ACCOUNTS_SET_AUTH";

const ACCOUNTS_CAPTCHA_START = "ACCOUNTS_CAPTCHA_START";
const ACCOUNTS_CAPTCHA_COMPLETE = "ACCOUNTS_CAPTCHA_COMPLETE";
const ACCOUNTS_REGISTER_RAW_DATA = "ACCOUNTS_REGISTER_RAW_DATA";
const ACCOUNTS_REGISTER_INITIAL_VALUES = "ACCOUNTS_REGISTER_INITIAL_VALUES";
const ACCOUNTS_REGISTER_COFOR_OPTIONS = "ACCOUNTS_REGISTER_COFOR_OPTIONS";
const ACCOUNTS_REGISTER_SUBMIT = "ACCOUNTS_REGISTER_SUBMIT";
const ACCOUNTS_REGISTER_SUBMIT_SUCCESS = "ACCOUNTS_REGISTER_SUBMIT_SUCCESS";
const ACCOUNTS_REGISTER_SUBMIT_ERROR = "ACCOUNTS_REGISTER_SUBMIT_ERROR";

const ACCOUNTS_SEND_USER_DATA = "ACCOUNTS_SEND_USER_DATA";
const ACCOUNTS_SEND_USER_DATA_SUCCESS = "ACCOUNTS_SEND_USER_DATA_SUCCESS";
const ACCOUNTS_SEND_USER_DATA_ERROR = "ACCOUNTS_SEND_USER_DATA_ERROR";

const ACCOUNTS_SEND_USER_DISABLE = "ACCOUNTS_SEND_USER_DISABLE";
const ACCOUNTS_SEND_USER_DISABLE_SUCCESS = "ACCOUNTS_SEND_USER_DISABLE_SUCCESS";
const ACCOUNTS_SEND_USER_DISABLE_ERROR = "ACCOUNTS_SEND_USER_DISABLE_ERROR";

const ACCOUNTS_SEND_USER_ENABLE = "ACCOUNTS_SEND_USER_ENABLE";
const ACCOUNTS_SEND_USER_ENABLE_SUCCESS = "ACCOUNTS_SEND_USER_ENABLE_SUCCESS";
const ACCOUNTS_SEND_USER_ENABLE_ERROR = "ACCOUNTS_SEND_USER_ENABLE_ERROR";

const ACCOUNTS_SEND_ADDRESS_DATA = "ACCOUNTS_SEND_ADDRESS_DATA";
const ACCOUNTS_SEND_ADDRESS_DATA_SUCCESS = "ACCOUNTS_SEND_ADDRESS_DATA_SUCCESS";
const ACCOUNTS_SEND_ADDRESS_DATA_ERROR = "ACCOUNTS_SEND_ADDRESS_DATA_ERROR";

const ACCOUNTS_SEND_PASSWORD_RESET = "ACCOUNTS_SEND_PASSWORD_RESET";
const ACCOUNTS_SEND_PASSWORD_RESET_SUCCESS =
  "ACCOUNTS_SEND_PASSWORD_RESET_SUCCESS";
const ACCOUNTS_SEND_PASSWORD_RESET_ERROR = "ACCOUNTS_SEND_PASSWORD_RESET_ERROR";

const ACCOUNTS_SEND_PASSWORD_CONFIRM = "ACCOUNTS_SEND_PASSWORD_CONFIRM";
const ACCOUNTS_SEND_PASSWORD_CONFIRM_SUCCESS =
  "ACCOUNTS_SEND_PASSWORD_CONFIRM_SUCCESS";
const ACCOUNTS_SEND_PASSWORD_CONFIRM_ERROR =
  "ACCOUNTS_SEND_PASSWORD_CONFIRM_ERROR";

const ACCOUNTS_FETCH_COUNTRIES = "ACCOUNTS_FETCH_COUNTRIES";
const ACCOUNTS_FETCH_COUNTRIES_SUCCESS = "ACCOUNTS_FETCH_COUNTRIES_SUCCESS";
const ACCOUNTS_FETCH_COUNTRIES_ERROR = "ACCOUNTS_FETCH_COUNTRIES_ERROR";

const ACCOUNTS_EMAIL_CONFIRM = "ACCOUNTS_EMAIL_CONFIRM";
const ACCOUNTS_EMAIL_CONFIRM_SUCCESS = "ACCOUNTS_EMAIL_CONFIRM_SUCCESS";
const ACCOUNTS_EMAIL_CONFIRM_ERROR = "ACCOUNTS_EMAIL_CONFIRM_ERROR";

const ACCOUNTS_CLEAR_NAVIGATION = "ACCOUNTS_CLEAR_NAVIGATION";

/* Action Creators
 */

// needed to find out whether a user is logged in - used in the main index.js
export const accountsVerifySession = (_) => ({ type: ACCOUNTS_VERIFY_SESSION });

// retrieves some information for the session - is used to find out, if the session expired
// message has to be displayed on the login screen
export const accountsSessionStatus = (_) => ({ type: ACCOUNTS_SESSION_STATUS });
export const accountsSessionStatusSuccess = (status) => ({
  type: ACCOUNTS_SESSION_STATUS_SUCCESS,
  payload: status,
});
export const accountsSessionStatusError = (_) => ({
  type: ACCOUNTS_SESSION_STATUS_ERROR,
});

// the user logs in (or out)
export const accountsLogin = (_) => ({ type: ACCOUNTS_LOGIN });
export const accountsLoginCaptchaComplete = (captcha) => ({
  type: ACCOUNTS_LOGIN_CAPTCHA_COMPLETE,
  payload: { captcha },
});
export const accountsLoginSuccess = (rights, params) => ({
  type: ACCOUNTS_LOGIN_SUCCESS,
  payload: { rights, params },
});
export const accountsLoginError = (_) => ({ type: ACCOUNTS_LOGIN_ERROR });
export const accountsLogout = (_) => ({ type: ACCOUNTS_LOGOUT });
export const accountsLoginPasswordExpired = (url) => {
  return {
    type: ACCOUNTS_LOGIN_PASSWORD_EXPIRED,
    payload: { url },
  };
};

// retrieve user account data
export const accountsFetch = (_) => ({ type: ACCOUNTS_FETCH });
export const accountsFetchSuccess = (data) => ({
  type: ACCOUNTS_FETCH_SUCCESS,
  payload: data,
});
export const accountsFetchError = (_) => ({ type: ACCOUNTS_FETCH_ERROR });

export const accountsFetchAllUsers = (_) => ({
  type: ACCOUNTS_FETCH_ALL_USERS,
});
export const accountsFetchAllUsersSuccess = (data) => ({
  type: ACCOUNTS_FETCH_ALL_USERS_SUCCESS,
  payload: data,
});
export const accountsFetchAllUsersError = (_) => ({
  type: ACCOUNTS_FETCH_ALL_USERS_ERROR,
});

// somebody registers for a new account
export const accountsCaptchaStart = (_) => ({ type: ACCOUNTS_CAPTCHA_START });
export const accountsCaptchaComplete = (captcha) => ({
  type: ACCOUNTS_CAPTCHA_COMPLETE,
  payload: { captcha },
});
export const accountsRegisterRawData = (data) => ({
  type: ACCOUNTS_REGISTER_RAW_DATA,
  payload: data,
});
export const accountsRegisterSubmit = (data) => ({
  type: ACCOUNTS_REGISTER_SUBMIT,
  payload: data,
});
export const accountsRegisterSubmitSuccess = (_) => ({
  type: ACCOUNTS_REGISTER_SUBMIT_SUCCESS,
});
export const accountsRegisterSubmitError = (data) => ({
  type: ACCOUNTS_REGISTER_SUBMIT_ERROR,
  payload: data,
});
export const accountsInitialFormValues = (data) => ({
  type: ACCOUNTS_REGISTER_INITIAL_VALUES,
  payload: data,
});
export const accountsRegisterCoforOptions = (data) => ({
  type: ACCOUNTS_REGISTER_COFOR_OPTIONS,
  payload: data,
});

// the user changes user specific data
export const accountsSendUserData = (data) => ({
  type: ACCOUNTS_SEND_USER_DATA,
  payload: data,
});
export const accountsSendUserDataSuccess = (data) => ({
  type: ACCOUNTS_SEND_USER_DATA_SUCCESS,
  payload: data,
});
export const accountsSendUserDataError = (_) => ({
  type: ACCOUNTS_SEND_USER_DATA_ERROR,
});

// the user disables active company users
export const accountsSendUserDisable = (data) => ({
  type: ACCOUNTS_SEND_USER_DISABLE,
  payload: data,
});
export const accountsSendUserDisableSuccess = (_) => ({
  type: ACCOUNTS_SEND_USER_DISABLE_SUCCESS,
});
export const accountsSendUserDisableError = (_) => ({
  type: ACCOUNTS_SEND_USER_DISABLE_ERROR,
});

// the user enables active company users
export const accountsSendUserEnable = (data) => ({
  type: ACCOUNTS_SEND_USER_ENABLE,
  payload: data,
});
export const accountsSendUserEnableSuccess = (_) => ({
  type: ACCOUNTS_SEND_USER_ENABLE_SUCCESS,
});
export const accountsSendUserEnableError = (_) => ({
  type: ACCOUNTS_SEND_USER_ENABLE_ERROR,
});

// the user changes address data
export const accountsSendAddressData = (data) => ({
  type: ACCOUNTS_SEND_ADDRESS_DATA,
  payload: data,
});
export const accountsSendAddressDataSuccess = (data) => ({
  type: ACCOUNTS_SEND_ADDRESS_DATA_SUCCESS,
  payload: data,
});
export const accountsSendAddressDataError = (_) => ({
  type: ACCOUNTS_SEND_ADDRESS_DATA_ERROR,
});

// the user sends a password reset request
export const accountsSendPasswordReset = (data) => ({
  type: ACCOUNTS_SEND_PASSWORD_RESET,
  payload: data,
});
export const accountsSendPasswordResetSuccess = (_) => ({
  type: ACCOUNTS_SEND_PASSWORD_RESET_SUCCESS,
});
export const accountsSendPasswordResetError = (_) => ({
  type: ACCOUNTS_SEND_PASSWORD_RESET_ERROR,
});

// the user sets a new password (via the password reset link)
export const accountsSendPasswordConfirm = (data) => ({
  type: ACCOUNTS_SEND_PASSWORD_CONFIRM,
  payload: data,
});
export const accountsSendPasswordConfirmSuccess = (rights, params) => ({
  type: ACCOUNTS_SEND_PASSWORD_CONFIRM_SUCCESS,
  payload: { rights, params },
});
export const accountsSendPasswordConfirmError = (_) => ({
  type: ACCOUNTS_SEND_PASSWORD_CONFIRM_ERROR,
});

// retrieve country / country code combinations
export const accountsFetchCountries = (_) => ({
  type: ACCOUNTS_FETCH_COUNTRIES,
});
export const accountsFetchCountriesSuccess = (data) => ({
  type: ACCOUNTS_FETCH_COUNTRIES_SUCCESS,
  payload: data,
});
export const accountsFetchCountriesError = (_) => ({
  type: ACCOUNTS_FETCH_COUNTRIES_ERROR,
});

// the user clicks on an email link and confirms the account
export const accountsEmailConfirm = (data) => ({
  type: ACCOUNTS_EMAIL_CONFIRM,
  payload: data,
});
export const accountsEmailConfirmSuccess = (_) => ({
  type: ACCOUNTS_EMAIL_CONFIRM_SUCCESS,
});
export const accountsEmailConfirmError = (_) => ({
  type: ACCOUNTS_EMAIL_CONFIRM_ERROR,
});

export const accountsClearNavigation = () => ({
  type: ACCOUNTS_CLEAR_NAVIGATION,
});

/* Reducer */
const accountsShape = {
  // workaround for redirect after login (should use a parameter next=...)
  first_url: undefined,
  // user rights, session params, account data and expired password
  rights: [],
  params: {},
  authenticated: false,
  timedOut: false,
  isFetching: false,
  data: {},
  users: [],
  // used for registration
  registrationError: undefined,
  registerFormRawData: [],
  initialFormValues: {},
  coforOptions: undefined,
  countries: [],
  captchaComplete: false,
  // used for confirmation
  emailConfirmInProgress: false, // used for account confirmation by user
  emailConfirmResult: undefined, // (by clicking on an activation link)
};

export function accountsReducer(state = accountsShape, action) {
  switch (action.type) {
    // unused ...
    case SET_SPEC_ID:
      return { ...state, screenSpecId: action.payload.path };
    case ACCOUNTS_SET_AUTH:
      return { ...state, authenticated: action.payload };

    // redirect after login workaround
    case FIRST_URL:
      return { ...state, first_url: action.payload };

    // pre-login
    case ACCOUNTS_SESSION_STATUS_SUCCESS:
      return { ...state, timedOut: action.payload.timed_out };

    // login related
    case ACCOUNTS_LOGIN_SUCCESS:
    case ACCOUNTS_SEND_PASSWORD_CONFIRM_SUCCESS:
      return {
        ...state,
        authenticated: action.payload.rights.length > 0,
        rights: action.payload.rights,
        params: action.payload.params,
        navigate: named_urls["Welcome"],
      };
    case ACCOUNTS_LOGIN_PASSWORD_EXPIRED:
      return {
        ...state,
        navigate: action.payload.url,
      };

    case ACCOUNTS_LOGOUT:
      return { ...accountsShape };

    // fetch data (user account, country list)
    case ACCOUNTS_FETCH:
      return { ...state, isFetching: true };
    case ACCOUNTS_FETCH_SUCCESS:
      return { ...state, isFetching: false, data: action.payload };
    case ACCOUNTS_FETCH_ALL_USERS:
      return { ...state, isFetching: true };
    case ACCOUNTS_FETCH_ALL_USERS_SUCCESS:
      return { ...state, isFetching: false, users: action.payload };
    case ACCOUNTS_FETCH_COUNTRIES_SUCCESS:
      return { ...state, countries: action.payload };

    // registration related
    case ACCOUNTS_CAPTCHA_START:
      return { ...state, captchaComplete: false };
    case ACCOUNTS_CAPTCHA_COMPLETE:
      return { ...state, captchaComplete: true };
    case ACCOUNTS_REGISTER_RAW_DATA:
      return { ...state, registerFormRawData: action.payload };
    case ACCOUNTS_REGISTER_INITIAL_VALUES:
      return { ...state, initialFormValues: action.payload };
    case ACCOUNTS_REGISTER_COFOR_OPTIONS:
      return { ...state, coforOptions: action.payload };
    case ACCOUNTS_REGISTER_SUBMIT_SUCCESS:
      return {
        ...state,
        registerFormRawData: [],
        initialFormValues: {},
        coforOptions: undefined,
        captchaComplete: false,
        registrationError: undefined,
        navigate: named_urls["accounts:register_confirm"],
      };
    case ACCOUNTS_REGISTER_SUBMIT_ERROR:
      return {
        ...state,
        registrationError: action.payload.errors,
      };

    // user confirmation
    case ACCOUNTS_EMAIL_CONFIRM:
      return {
        ...state,
        emailConfirmInProgress: true,
        emailConfirmResult: undefined,
      };
    case ACCOUNTS_EMAIL_CONFIRM_SUCCESS:
      return {
        ...state,
        emailConfirmInProgress: false,
        emailConfirmResult: true,
      };
    case ACCOUNTS_EMAIL_CONFIRM_ERROR:
      return {
        ...state,
        emailConfirmInProgress: false,
        emailConfirmResult: false,
      };

    // user profile
    case ACCOUNTS_SEND_USER_DATA_SUCCESS:
      return { ...state, data: { ...state.data, ...action.payload } };
    case ACCOUNTS_SEND_ADDRESS_DATA_SUCCESS:
      const addressType =
        action.payload.category === "BILLING"
          ? "billing_address"
          : "delivery_address";
      return {
        ...state,
        data: {
          ...state.data,
          company_profile: {
            ...state.data.company_profile,
            [addressType]: { ...action.payload },
          },
        },
      };
    case ACCOUNTS_CLEAR_NAVIGATION:
      console.log("The Duck says: Clearing the navigation!!!");
      console.log("State", state);
      return {
        ...state,
        accounts: { ...state.accounts, navigate: undefined },
      };

    default:
      break;
  }
  return state;
}

/* Epics */

// pre-login related epics
export const accountsSessionStatusEpic = (action$, { getState, dispatch }) =>
  action$
    .ofType(ACCOUNTS_SESSION_STATUS)
    .mergeMap((action) => {
      return Observable.from(endpoint("accounts:api_session_status", "GET"))
        .map((response) => {
          if (!response.error) {
            return accountsSessionStatusSuccess(response);
          } else {
            return accountsSessionStatusError();
          }
        })
        .catch((e) => Observable.of(notificationError(e)));
    })
    .catch((e) => {
      // displays error and *stops* listener - only for fatal errors
      return [notificationError(e, "accounts session status: fatal error")];
    });

// login related epics

// called when starting the login procedure - recaptcha sometimes needs some loading time
export const accountsLoginEpic = (action$, store) =>
  action$
    .ofType(ACCOUNTS_LOGIN)
    .do((_) => {
      NProgress.start();
    })
    .ignoreElements();

export const accountsLoginCaptchaCompleteEpic = (
  action$,
  { getState, dispatch }
) =>
  action$.ofType(ACCOUNTS_LOGIN_CAPTCHA_COMPLETE).mergeMap((action) => {
    dispatch(startSubmit("login"));
    const captcha = action.payload.captcha;
    const selector = formValueSelector("login");
    const { username, account_id, password } = selector(
      getState(),
      "username",
      "account_id",
      "password"
    );
    const query = {
      username,
      account_id,
      password,
      "g-recaptcha-response": captcha,
    };
    // call api function - last param false = endpoint does not handle unauthorized or forbidden response
    return Observable.from(
      endpoint("accounts:api_login", "POST", { body: query }, false)
    )
      .map((response) => {
        dispatch(stopSubmit("login", response.errors || {}));
        NProgress.done();
        if (!response.errors) {
          return accountsLoginSuccess(response.permissions, response.params);
        } else if (
          response.errors.type &&
          response.errors.type === "PASSWORD_EXPIRED"
        ) {
          // get base url without params
          let url = named_urls["accounts:reset_expired_password"];
          url = url.replace(":email", response.errors.email);
          url = url.replace(":account_id", response.errors.account_id);
          // dispatch(push(url));  // replaced by navigate flag passed back to UI
          return accountsLoginPasswordExpired(url);
        } else {
          return accountsLoginError();
        }
      })
      .catch((e) => Observable.of(notificationError(e)));
  });

export const accountsLoginSuccessEpic = (action$, { getState, dispatch }) =>
  action$
    .ofType(ACCOUNTS_LOGIN_SUCCESS)
    .mergeMap((action) => {
      const first_url = getState().accounts.first_url;
      if (
        first_url !== undefined &&
        first_url !== named_urls["accounts:login"] &&
        first_url !== named_urls["accounts:register"]
      ) {
        // redirect to first url if not login or register

        dispatch(push(first_url));
      }
      window.document.body.classList.remove("invert");
      return [accountsFetch(), notificationSuccess("Session valid! :)")];
    })
    .catch((e) => Observable.of(notificationError(e)));

export const accountsLogoutEpic = (action$, store) =>
  action$
    .ofType(ACCOUNTS_LOGOUT)
    .mergeMap((action) => {
      return Observable.from(
        endpoint("accounts:api_logout", "POST", { body: {} })
      ).mergeMap((res) => {
        document.cookie = "";
        document.body.classList.add("invert");
        return [notificationSuccess("logout successful")];
      });
    })
    .catch((e) => [notificationError(e)]);

// user password reset

const accountsSendPasswordResetEpic = (action$, { getState, dispatch }) =>
  action$
    .ofType(ACCOUNTS_SEND_PASSWORD_RESET)
    .mergeMap((action) => {
      const formName = "password_reset_request";
      dispatch(startSubmit(formName));
      return Observable.from(
        endpoint("accounts:api_reset_password", "POST", {
          body: action.payload,
        })
      ).mergeMap((response) => {
        dispatch(stopSubmit(formName, response.errors || {}));
        if (!response.errors) {
          if (action.payload.suppress_notification) {
            return [accountsSendPasswordResetSuccess()];
          } else {
            return [
              accountsSendPasswordResetSuccess(),
              notificationMessageSuccess(
                i18n.t("Password reset"),
                i18n.t("A password reset link was sent to your email address.")
              ),
            ];
          }
        } else {
          return [accountsSendPasswordResetError()];
        }
      });
    })
    .catch((e) => {
      return [notificationError(e)];
    });

const accountsSendPasswordConfirmEpic = (action$, { getState, dispatch }) =>
  action$
    .ofType(ACCOUNTS_SEND_PASSWORD_CONFIRM)
    .mergeMap((action) => {
      const formName = "password_reset_confirm";
      dispatch(startSubmit(formName));
      return Observable.from(
        endpoint("accounts:api_confirm_password", "POST", {
          body: action.payload,
        })
      ).map((response) => {
        dispatch(stopSubmit(formName, response.errors || {}));
        if (!response.errors) {
          return accountsSendPasswordConfirmSuccess(
            response.permissions,
            response.params
          );
        } else {
          return accountsSendPasswordConfirmError();
        }
      });
    })
    .catch((e) => {
      return [notificationError(e)];
    });

export const accountsSendPasswordConfirmSuccessEpic = (
  action$,
  { getState, dispatch }
) =>
  action$
    .ofType(ACCOUNTS_SEND_PASSWORD_CONFIRM_SUCCESS)
    .mergeMap((action) => {
      dispatch(push(named_urls["Welcome"]));
      window.document.body.classList.remove("invert");
      return [accountsFetch(), notificationSuccess("Session valid! :)")];
    })
    .catch((e) => Observable.of(notificationError(e)));

// account data related epics (and fetching of country list)

export const accountsFetchEpic = (action$, store) =>
  action$
    .ofType(ACCOUNTS_FETCH)
    .mergeMap((_) => {
      if (
        store &&
        store.getState() &&
        store.getState().accounts &&
        store.getState().accounts &&
        store.getState().accounts.data &&
        store.getState().accounts.data.pk
      ) {
        return [accountsFetchSuccess(store.getState().accounts.data)];
      } else {
        return Observable.from(endpoint("accounts:api_user_list", "GET")).map(
          (response) => {
            if (!response.errors) {
              return accountsFetchSuccess(response[0]);
            } else {
              return accountsFetchError();
            }
          }
        );
      }
    })
    .catch((e) => [notificationError(e)]);

export const accountsFetchAllUsersEpic = (action$, store) =>
  action$
    .ofType(ACCOUNTS_FETCH_ALL_USERS)
    .mergeMap((_) => {
      const query = {
        q_showall: true,
      };
      return Observable.from(
        endpoint("accounts:api_user_list", "GET", { query })
      ).map((response) => {
        if (!response.errors) {
          return accountsFetchAllUsersSuccess(response);
        } else {
          return accountsFetchAllUsersError();
        }
      });
    })
    .catch((e) => [notificationError(e)]);

export const accountsFetchCountriesEpic = (action$, store) =>
  action$
    .ofType(ACCOUNTS_FETCH_COUNTRIES)
    .mergeMap((_) => {
      return Observable.from(endpoint("accounts:api_country_list", "GET")).map(
        (response) => {
          if (!response.errors) {
            return accountsFetchCountriesSuccess(response);
          } else {
            return accountsFetchCountriesError();
          }
        }
      );
    })
    .catch((e) => [notificationError(e)]);

// user confirmation related epics

export const accountsEmailConfirmEpic = (action$, store) =>
  action$
    .ofType(ACCOUNTS_EMAIL_CONFIRM)
    .mergeMap((action) => {
      return Observable.from(
        endpoint("accounts:api_confirmation", "POST", { body: action.payload })
      ).map((response) => {
        if (!response.errors) {
          return accountsEmailConfirmSuccess();
        } else {
          return accountsEmailConfirmError();
        }
      });
    })
    .catch((e) => [notificationError(e + ACCOUNTS_EMAIL_CONFIRM)]);

// registration related epics
export const accountsCaptchaStartEpic = (action$, store) =>
  action$
    .ofType(ACCOUNTS_CAPTCHA_START)
    .do((_) => {
      NProgress.start();
    })
    .ignoreElements();

export const accountsCaptchaCompleteEpic = (action$, store) =>
  action$
    .ofType(ACCOUNTS_CAPTCHA_COMPLETE)
    .mergeMap((action) => {
      const state = store.getState();
      const cofor6 = selectCOFOR6(state);
      const accountId = selectAccountId(state);
      const captcha = action.payload.captcha;
      const query = {
        "g-recaptcha-response": captcha,
      };
      if (cofor6) {
        query.q_cofor6 = cofor6;
      }
      if (accountId) {
        query.q_cofor10 = accountId;
      }

      return Observable.from(
        endpoint("accounts:api_cofor_list", "GET", { query })
      )
        .mergeMap((response) => {
          NProgress.done();
          if (!response.errors) {
            const { mode, options } = response;
            const selectOptions = options.map((x, index) => ({
              index,
              address: x.address,
              name: x.prefill.company_profile.name,
              cofor: x.prefill.company_profile.cofor10,
            }));
            const coforOptions = {
              mode,
              options: selectOptions,
            };
            return [
              accountsRegisterCoforOptions(coforOptions),
              accountsInitialFormValues(selectCompleteForm(state)),
              // this is important, we store the response structure (mode + options) for later use in a special redux-form handler
              // see accountsRegisterChangeCoforEpic
              accountsRegisterRawData(response),
            ];
          } else {
            return [
              notificationMessageError(
                i18n.t("COFOR6 request"),
                i18n.t(
                  "The COFOR6 request failed. Please reload the page and try again."
                )
              ),
            ];
          }
        })
        .catch((e) => [
          notificationError(e, "accounts captcha complete: server error"),
        ]);
    })
    // displays error and *stops* listener - only for fatal errors
    .catch((e) => [
      notificationError(e, "accounts captcha complete: fatal error"),
    ]);

const accountsRegisterChangeCoforEpic = (action$, store) =>
  // Special Redux Form Epic for COFOR10 Select
  // This really crazy, we store coforOptions response as raw response data
  // and use them here to prefill the addresses and contacts fields
  action$
    .ofType("@@redux-form/CHANGE")
    .filter((action) => {
      if (
        action.payload === undefined ||
        !action.meta ||
        action.meta.field !== "company_profile.cofor10"
      ) {
        return false;
      }

      return true;
    })
    .mergeMap((action) => {
      const state = store.getState();

      // get all current form fields
      const currentFormState = selectCompleteForm(state);

      // define required data structures for the form as basis for initial values
      let outHost = Object.assign({}, currentFormState, {
        registerCase: E_REGISTER_CASE.NONE, // set default case non-cofor10 user (might be overwritten below)
        company_profile: {
          cofor6: "",
          cofor10: action.payload,
        },
        addresses: [
          {
            category: "DELIVERY",
            street_address: "",
            postal_code: "",
            city: "",
            country_code: "FR",
            address_details: "",
          },
          {
            category: "BILLING",
            street_address: "",
            postal_code: "",
            city: "",
            country_code: "FR",
            address_details: "",
          },
        ],
        contacts: [
          { category: "PROVISIONING", name: "", phone: "", email: "" },
          { category: "COMMERCIAL", name: "", phone: "", email: "" },
          { category: "TS_INVOICE", name: "", phone: "", email: "" },
          { category: "TS_DELIVERY", name: "", phone: "", email: "" },
        ],
        quotas: [],
      });

      // do nothing if none is selected
      if (action.payload !== "-1") {
        // the value of the cofor10 selection corresponds to the index of the raw data
        const cofor10RawDataIndex = parseInt(action.payload);
        const coforOptions = selectRawResponseData(state);
        const cofor10Prefill = coforOptions.options[cofor10RawDataIndex];
        // make sure all references are new so the store will change the react components
        const deepClone = clone(cofor10Prefill.prefill);
        outHost = { ...deepClone, ...currentFormState };
        // reset cofor10 to selected value
        outHost.company_profile.cofor10 = action.payload;
        // set register mode
        outHost.registerCase = cofor10Prefill.prefill.first_user
          ? E_REGISTER_CASE.COFOR10_FIRST
          : E_REGISTER_CASE.COFOR10_NEXT;
      }
      return [accountsInitialFormValues(outHost), reset("Register")];
    })
    .catch((e) => {
      return [notificationError(e)];
    });

const accountsRegisterSubmitEpic = (action$, { getState, dispatch }) =>
  action$
    .ofType(ACCOUNTS_REGISTER_SUBMIT)
    .mergeMap((action) => {
      dispatch(startSubmit("Register"));
      const formData = action.payload;

      // determine real cofor10 entry from the selection index
      const state = getState();
      // get cofor6 from the form
      // however, in the case where the user provided a cofor10 only,
      // this will be empty - and filled in the cofor10RawDataIndex section
      let cofor6 = selectCOFOR6(state);
      let cofor10ToSubmit = ""; // initialize as requested by the api
      const cofor10RawDataIndex = parseInt(formData.company_profile.cofor10); // read selection index
      if (cofor10RawDataIndex !== -1) {
        // determine the prefill and extract the real cofor10
        const coforOptions = selectRawResponseData(state);
        const cofor10Prefill = coforOptions.options[cofor10RawDataIndex];
        // if the user enters a cofor10 only, no cofor6 will be provided in the form
        // so, extract the cofor6 from the prefill data in any case
        cofor6 = cofor10Prefill.prefill.company_profile.cofor6;
        cofor10ToSubmit = cofor10Prefill.prefill.company_profile.cofor10;
      }

      // modify form data - don't mutate in place
      const newFormData = {
        ...formData,
        company_profile: {
          ...formData.company_profile,
          cofor6,
          cofor10: cofor10ToSubmit,
        },
      };

      return Observable.from(
        endpoint("accounts:api_registration", "POST", { body: newFormData })
      )
        .mergeMap((response) => {
          dispatch(stopSubmit("Register", response.errors || {}));
          if (!response.errors) {
            return [accountsRegisterSubmitSuccess()];
          } else {
            return [accountsRegisterSubmitError(response)];
          }
        })
        .catch((e) => {
          dispatch(stopSubmit("Register", {}));
          return [
            notificationError(e, "accounts register submit: server error"),
          ];
        });
    })
    .catch((e) => {
      // displays error and *stops* listener - only for fatal errors
      return [notificationError(e, "accounts register submit: fatal error")];
    });

const accountsRegisterSubmitSuccessEpic = (action$, { getState, dispatch }) =>
  action$
    .ofType(ACCOUNTS_REGISTER_SUBMIT_SUCCESS)
    .do((action) => {
      console.log(
        "pushing forward the url for the register_confirm....",
        named_urls["accounts:register_confirm"]
      );
      //dispatch(push(named_urls["accounts:register_confirm"]));
      redirect(named_urls["accounts:register_confirm"]);
    })
    .ignoreElements();

// user profile related epics

const accountsSendUserDataEpic = (action$, { getState, dispatch }) =>
  action$
    .ofType(ACCOUNTS_SEND_USER_DATA)
    .mergeMap((action) => {
      dispatch(startSubmit("profile"));
      const userData = action.payload;
      return Observable.from(
        endpoint("accounts:api_user", "PATCH", {
          param: userData.pk,
          body: userData,
        })
      ).mergeMap((response) => {
        dispatch(stopSubmit("profile", response.errors || {}));
        if (!response.errors) {
          return [
            accountsSendUserDataSuccess(response),
            notificationMessageSuccess(
              i18n.t("User profile"),
              i18n.t("User profile successfully changed.")
            ),
          ];
        } else {
          return [accountsSendUserDataError()];
        }
      });
    })
    .catch((e) => {
      return [notificationError(e)];
    });

const accountsSendUserDisableEpic = (action$, { getState, dispatch }) =>
  action$
    .ofType(ACCOUNTS_SEND_USER_DISABLE)
    .mergeMap((action) => {
      const userIds = action.payload;
      return Observable.from(
        endpoint("accounts:api_users_disable", "POST", { body: userIds })
      )
        .mergeMap((response) => {
          if (!response.errors) {
            return [
              accountsSendUserDisableSuccess(response),
              accountsFetchAllUsers(),
              notificationMessageSuccess(
                i18n.t("Active company users"),
                i18n.t(
                  "User(s) successfully disabled. An email was sent to the provisioning manager of your company."
                )
              ),
            ];
          } else {
            return [
              accountsSendUserDisableError(),
              notificationMessageError(
                i18n.t("Active company users"),
                i18n.t("The deactivation request failed. Please try again.")
              ),
            ];
          }
        })
        .catch((e) => {
          return [
            notificationError(e, "accounts send user disable: server error"),
          ];
        });
    })
    .catch((e) => {
      // displays error and *stops* listener - only for fatal errors
      return [notificationError(e)];
    });

const accountsSendUserEnableEpic = (action$, { getState, dispatch }) =>
  action$
    .ofType(ACCOUNTS_SEND_USER_ENABLE)
    .mergeMap((action) => {
      const userIds = action.payload;
      return Observable.from(
        endpoint("accounts:api_users_enable", "POST", { body: userIds })
      )
        .mergeMap((response) => {
          if (!response.errors) {
            return [
              accountsSendUserEnableSuccess(response),
              accountsFetchAllUsers(),
              notificationMessageSuccess(
                i18n.t("Inactive company users"),
                i18n.t(
                  "User(s) successfully enabled. An email was sent to the provisioning manager of your company."
                )
              ),
            ];
          } else {
            return [
              accountsSendUserEnableError(),
              notificationMessageError(
                i18n.t("Inactive company users"),
                i18n.t("The activation request failed. Please try again.")
              ),
            ];
          }
        })
        .catch((e) => {
          return [
            notificationError(e, "accounts send user enable: server error"),
          ];
        });
    })
    .catch((e) => {
      // displays error and *stops* listener - only for fatal errors
      return [notificationError(e)];
    });

const accountsSendAddressDataEpic = (action$, { getState, dispatch }) =>
  action$
    .ofType(ACCOUNTS_SEND_ADDRESS_DATA)
    .mergeMap((action) => {
      const addressData = action.payload;
      const formName =
        addressData.category === "BILLING"
          ? "billing_address"
          : "delivery_address";
      dispatch(startSubmit(formName));
      return Observable.from(
        endpoint("accounts:api_address_list", "POST", { body: addressData })
      ).mergeMap((response) => {
        dispatch(stopSubmit(formName, response.errors || {}));
        if (!response.errors) {
          return [
            accountsSendAddressDataSuccess(response),
            notificationMessageSuccess(
              i18n.t("Address data"),
              i18n.t("Address successfully changed.")
            ),
          ];
        } else {
          return [accountsSendAddressDataError()];
        }
      });
    })
    .catch((e) => {
      return [notificationError(e)];
    });

export const accountsEpics = combineEpics(
  accountsSessionStatusEpic,
  accountsLoginEpic,
  accountsLoginCaptchaCompleteEpic,
  accountsLoginSuccessEpic,
  accountsLogoutEpic,
  accountsFetchEpic,
  accountsFetchAllUsersEpic,
  accountsRegisterSubmitEpic,
  accountsRegisterSubmitSuccessEpic,
  accountsCaptchaStartEpic,
  accountsCaptchaCompleteEpic,
  accountsRegisterChangeCoforEpic,
  accountsSendUserDisableEpic,
  accountsSendUserEnableEpic,
  accountsSendUserDataEpic,
  accountsSendAddressDataEpic,
  accountsSendPasswordResetEpic,
  accountsSendPasswordConfirmEpic,
  accountsSendPasswordConfirmSuccessEpic,
  accountsFetchCountriesEpic,
  accountsEmailConfirmEpic
);

/* Thunks */
export function setSpecId(path) {
  if (!path) throw Error("no path!");
  return (dispatch, getState) =>
    dispatch({ type: SET_SPEC_ID, payload: { path } });
}

/* Selectors */
export function selectAccounts(state) {
  return state.accounts;
}

export function selectUsers(state) {
  return state.accounts.users;
}

export function selectUserData(state) {
  const { pk, email, first_name, last_name, phone } = state.accounts.data;
  return {
    pk,
    email,
    first_name,
    last_name,
    phone,
  };
}

function selectAddress(address) {
  const {
    category,
    street_address,
    postal_code,
    city,
    country_code,
    country,
    address_details,
  } = address;
  return {
    category,
    street_address,
    postal_code,
    city,
    country,
    country_code,
    address_details,
  };
}

export function selectBillingAddress(state) {
  return selectAddress(state.accounts.data.company_profile.billing_address);
}

export function selectDeliveryAddress(state) {
  return selectAddress(state.accounts.data.company_profile.delivery_address);
}

export function selectCountries(state) {
  const { countries } = state.accounts;
  return countries.slice().sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });
}

export const selectRegisterForm = formValueSelector("Register"); // <-- same as form name

function selectCOFOR6(state) {
  return selectRegisterForm(state, "cofor6");
}

function selectAccountId(state) {
  return selectRegisterForm(state, "accountId");
}

function selectCompleteForm(state) {
  return selectRegisterForm(state, "user", "cofor6", "accountId");
}

function selectRawResponseData(state) {
  return state.accounts.registerFormRawData;
}

export function selectCoforOptions(state) {
  return state.accounts.coforOptions;
}

export function selectRegistrationError(state) {
  return state.accounts.registrationError;
}

import React from "react";

export const RenderField = ({
  placeholder,
  disabled,
  input,
  type,
  maxLength,
  meta: { asyncValidating, valid, error, warning }
}) => (
  <div>
    <input
      className={`vertical-spacing ${
        asyncValidating ? "asyncValidating" : null
      }`}
      maxLength={maxLength ? maxLength : 100}
      {...input}
      disabled={disabled}
      placeholder={placeholder}
      type={type}
    />
    {(error && <span className="validation-states error">{error}</span>) ||
      (warning && (
        <span className="validation-states warning">{warning}</span>
      )) ||
      (valid && <span className={`validation-states valid`} />)}
  </div>
);

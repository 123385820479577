import { Route, Routes } from "react-router-dom";
import {
  ClientAddressConfirm,
  ClientConfirm,
  EmailConfirm,
  PasswordExpiredRequest,
  PasswordResetConfirm,
  PasswordResetRequest,
  Register,
  RegisterConfirm,
} from "../licensing/accounts";
import { AuthorizedContent } from "../commonjs/AuthorizedContent";

export const RegistrationRoutes = () => {
  return (
    <Routes>
      <Route exact path="register" element={<Register />} />
      <Route exact path="register_confirm" element={<RegisterConfirm />} />
      <Route exact path="reset_password" element={<PasswordResetRequest />} />
      <Route
        exact
        path="reset_expired_password/:email/:account_id"
        element={<PasswordExpiredRequest />}
      />
      <Route
        exact
        path="password_confirmation/:code/:account_id?"
        element={<PasswordResetConfirm />}
      />
      <Route exact path="confirmation/:code" element={<EmailConfirm />} />
      <Route
        exact
        path="client_confirmation/:code"
        element={
          <AuthorizedContent requires={["accounts.approve_user"]}>
            <ClientConfirm />
          </AuthorizedContent>
        }
      />
      <Route
        exact
        path="client_address_confirmation/:code"
        element={
          <AuthorizedContent requires={["accounts.approve_address"]}>
            <ClientAddressConfirm />
          </AuthorizedContent>
        }
      />
    </Routes>
  );
};

import React, { PureComponent } from "react";
import equal from "fast-deep-equal";

import { endpoint } from "../../../commonjs/endpoints";
import { Spinner } from "../../../commonjs/components";

class Query extends PureComponent {
  state = { result: [], loading: true };
  componentDidMount() {
    this.updateQuery();
  }
  componentDidUpdate(prevProps) {
    if (!equal(this.props.params, prevProps.params)) {
      this.setState({ loading: true });
      this.updateQuery();
    }
  }

  updateQuery() {
    const { query, mappings, method = "GET", params, id } = this.props;
    endpoint(query, method, { body: params, param: id }).then((result) => {
      if (mappings) {
        result = result.map((entry) => mappings(entry));
      }

      this.setState({ result, loading: false });
    });
  }

  render() {
    const { component, children, debug } = this.props;
    const { loading, result } = this.state;

    const to_render = component || children;

    if (loading) {
      return <Spinner />;
    } else {
      if (debug) console.log("query-result", result);
      return to_render(result);
    }
  }
}

export default Query;

import React from "react";
import { Field } from "redux-form";
import { i18n } from "../../../i18next";
import { RenderField } from "../../../commonjs/components";
import { isEmail, isInvalidPassword, normalizePhone } from "../utils";

/* 
  register: user details as partial form 
  only to reduce the size of the Register.js file
*/

export const validate_user_details = (values) => {
  let errors = {};
  if (!values.user) {
    errors.first_name = i18n.t("Required");
    errors.last_name = i18n.t("Required");
    errors.email = i18n.t("Required");
    errors.phone = i18n.t("Required");
    errors.password = i18n.t("Required");
    errors.password_again = i18n.t("Required");
  } else {
    if (!values.user.first_name) errors.first_name = i18n.t("Required");
    if (!values.user.last_name) errors.last_name = i18n.t("Required");
    if (!values.user.email) errors.email = i18n.t("Required");
    if (values.user.email && !isEmail(values.user.email))
      errors.email = i18n.t("Invalid email");
    if (!values.user.phone) errors.phone = i18n.t("Required");
    if (values.user.phone && values.user.phone.length < 8)
      errors.phone = i18n.t("Please provide at least 8 digits");
    if (!values.user.password) errors.password = i18n.t("Required");
    if (values.user.password) {
      const errorText = isInvalidPassword(values.user.password);
      if (errorText) errors.password = errorText;
    }
    if (!values.user.password_again) errors.password_again = i18n.t("Required");
    if (
      values.user.password_again &&
      values.user.password_again !== values.user.password
    ) {
      errors.password_again = i18n.t("Passwords do not match");
    }
  }
  return errors;
};

export const RegisterUserDetailsFormPart = ({ t }) => (
  <div>
    <div className="grid__item xs-12 vertical-spacing">
      <h3>{t("Enter user details")}</h3>
    </div>

    <div className="grid__item xs-12 md-6">
      <Field
        name="user.first_name"
        placeholder={t("First Name")}
        component={RenderField}
        type="text"
      />
    </div>
    <div className="grid__item xs-12 md-6">
      <Field
        name="user.last_name"
        placeholder={t("Last Name")}
        component={RenderField}
        type="text"
      />
    </div>

    <div className="grid__item xs-12 md-6">
      <Field
        name="user.email"
        placeholder={t("E-Mail")}
        component={RenderField}
        type="text"
      />
    </div>
    <div className="grid__item xs-12 md-6">
      <Field
        name="user.phone"
        placeholder={t("Phone")}
        component={RenderField}
        type="text"
        normalize={normalizePhone}
      />
    </div>

    <div className="grid__item xs-12 md-6">
      <Field
        name="user.password"
        placeholder={t("Password")}
        component={RenderField}
        type="password"
      />
    </div>
    <div className="grid__item xs-12 md-6">
      <Field
        name="user.password_again"
        placeholder={t("Repeat Password")}
        component={RenderField}
        type="password"
      />
    </div>
  </div>
);

import React from "react";

import { withTranslation } from "react-i18next";
import { ModalDialog } from "../../../commonjs/modals";

const _ModalCancelOrder = ({ t, order, handleCancel, handleOk }) => {
  return (
    <ModalDialog
      isOpen={true}
      handleOk={handleOk}
      handleCancel={handleCancel}
      isCriticalAction={true}
      title={t("Cancel order No. ") + order.order_id}
    >
      <div className="grid__item xs-12">
        {t("Do you want to cancel the order?")}
      </div>
    </ModalDialog>
  );
};

export const ModalCancelOrder = withTranslation()(_ModalCancelOrder);

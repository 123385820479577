import { Route, Routes } from "react-router-dom";
import { Landing } from "../licensing/welcome/";
import { AuthorizedContent } from "../commonjs/AuthorizedContent";
import { Login } from "../licensing/accounts";
import { TermsOfUse } from "../psa_terms";
import { Profile } from "../licensing/accounts";
import { Contact, ContactInformation } from "../licensing/contact";
import { Information } from "../licensing/information";

import { OrderingRoutes } from "./OrderingRoutes";
import { RegistrationRoutes } from "./RegistrationRoutes";

import React from "react";
import { StatisticsRoutes } from "./StatisticsRoutes";
import { Distribution, AddressList } from "../licensing/distribution";

export const MainRoutes = () => {
  return (
    <Routes>
      <Route exact path="/accounts/login" element={<Login />} />
      <Route
        exact
        path="/"
        element={
          <AuthorizedContent requires={["*"]}>
            <Landing />
          </AuthorizedContent>
        }
      />
      <Route
        exact
        path="/welcome"
        element={
          <AuthorizedContent requires={["*"]}>
            <Landing />
          </AuthorizedContent>
        }
        requires={["*"]}
      />
      <Route
        exact
        path="/information/information"
        element={
          <AuthorizedContent requires={["*"]}>
            <Information />
          </AuthorizedContent>
        }
      />
      <Route
        exact
        path="/contact/contact"
        element={
          <AuthorizedContent requires={["*"]}>
            <Contact />
          </AuthorizedContent>
        }
      />
      <Route
        exact
        path="/contact/contact_information"
        element={
          <AuthorizedContent requires={["*"]}>
            {" "}
            <ContactInformation />
          </AuthorizedContent>
        }
      />

      <Route exact path="/psa_terms/terms" element={<TermsOfUse />} />
      <Route
        exact
        path="/accounts/profile"
        element={
          <AuthorizedContent requires={["*"]}>
            <Profile />
          </AuthorizedContent>
        }
      />
      <Route path="/accounts/*" element={<RegistrationRoutes />} />
      <Route path="/orders/*" element={<OrderingRoutes />} />
      <Route path="/statistics/*" element={<StatisticsRoutes />} />
      <Route path="/distribution/">
        <Route
          exact
          path="addressList"
          element={
            <AuthorizedContent requires={["distribution.*"]}>
              <AddressList />
            </AuthorizedContent>
          }
        />
        <Route
          exact
          path="distribution"
          element={
            <AuthorizedContent requires={["distribution.*"]}>
              <Distribution />
            </AuthorizedContent>
          }
        />
      </Route>
    </Routes>
  );
};

import React from "react";

const getCoOrdsFromPercent = percent => {
  const x = Math.cos(2 * Math.PI * percent);
  const y = Math.sin(2 * Math.PI * percent);
  return [x, y];
};

const PieMarker = props => {
  const { aggregation, total_count, categories } = props;
  let cumulativePercent = 0;

  return (
    <div className={"SVGPieChart"}>
      <svg viewBox="-1 -1 2 2">
        {aggregation.map((status, index) => {
          const [startX, startY] = getCoOrdsFromPercent(cumulativePercent);
          const percent = status.doc_count / total_count;
          cumulativePercent += percent;

          let [endX, endY] = getCoOrdsFromPercent(cumulativePercent);
          const largeArc = percent > 0.5 ? 1 : 0;

          if (aggregation.length === 1) {
            endY = -0.0001; // Edge case to IE 11 and Edge browsers. Have to set a small negative y value which the edge and IE11 doesnt round off.
          }

          const pathData = `M ${startX} ${startY} A 1 1 0 ${largeArc} 1 ${endX} ${endY} L 0 0`;
          const category = categories.find(v => v.status === status.key);
          const color = category ? category.color : "#888";

          return <path key={index} d={pathData} fill={color} />;
        })}
      </svg>
      <p>{total_count}</p>
    </div>
  );
};

export default PieMarker;

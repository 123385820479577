import React from "react";

import { withTranslation } from "react-i18next";

export const OrderSummaryError = withTranslation()(({ t }) => {
  return (
    <div className="orderSummary">
      <h2 className="failed">
        {t("Unfortunately an error occured while processing your order.")}
      </h2>
      <br />
      {t("We apologize for any inconvenience.")}
      <br />
      <br />
      {t("Please contact scribos® <customer-service@scribos.com>.")}
    </div>
  );
});

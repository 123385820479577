import React, { useState, useLayoutEffect } from "react";
import isEqual from "lodash/isEqual";
import { useDebouncedCallback } from "use-debounce";

import { useCanvas } from "../Canvas";
import { useQuery, STATUS } from "../../../../../../commonjs/fetchHooks";

const GET_FIELD_FORMAT = (fieldIdValueList) => ({
  method: "POST",
  endpoint: "orders:api_order_field_format",
  data: { body: fieldIdValueList },
});

export const FieldDebounced = (props) => {
  const { data } = props;
  const [debouncedData, setDebouncedData] = useState({ ...data });

  const debounced = useDebouncedCallback((newData) => {
    if (!isEqual(newData, debouncedData)) {
      setDebouncedData(newData);
    }
  }, 250);

  debounced(data);

  const newProps = { ...props, data: debouncedData };
  return <FieldFormatted {...newProps} />
}

const FieldFormatted = (props) => {
  const { data, fields, component: Component } = props;
  const { setRedrawRequested } = useCanvas();

  let fieldIdValueList = [];
  for (let [name, value] of Object.entries(data)) {
    const field = fields.find(e => e.name === name);
    fieldIdValueList.push({ id: field.pk, name, value: value || "" });
  }

  const { data: formattedData, status } = useQuery(GET_FIELD_FORMAT, fieldIdValueList);

  let newData = {};
  if (status === STATUS.RESOLVED) {
    for (let entry of formattedData) {
      newData[entry.name] = entry.value;
    }
  }

  // without the useLayoutEffect we get the warning "cannot update ... while rendering"
  useLayoutEffect(() => {
    setRedrawRequested(true);
  });

  const newProps = { ...props, data: newData };
  return <Component {...newProps} />;
}

import React from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "../../../../commonjs/icons";

export const translateBlockingReason = (t, reason, fullVersion = false) => {
  const translationsShortVersion = {
    BLOCKED_FOR_ORDERING: t("Has not paid due bills"),
    BLOCKED_COMPLETELY: t("Has not ordered for years"),
    NON_ACTIVATED_PRODUCTS: t("Has not activated delivered labels")
  };
  const translationsFullVersion = {
    BLOCKED_FOR_ORDERING: t("Company blocked due to unpaid bills."),
    BLOCKED_COMPLETELY: t("Company blocked due to inactivity."),
    NON_ACTIVATED_PRODUCTS: t("Company blocked due to non-activated labels.")
  };
  const translations = fullVersion ? translationsFullVersion : translationsShortVersion;

  return translations[reason] || reason;
};

export const Blocked = ({ reasons }) => {
  const { t } = useTranslation();

  if (!reasons?.length > 0) return null;

  const translatedReasons = reasons
    .split(",")
    .map((reason) => translateBlockingReason(t, reason, true))
    .join("<br>");

  return (
    <Icon
      icon={"exclamation-triangle"}
      data-tip={translatedReasons}
      data-place={"right"}
      data-effect={"solid"}
    />
  );
};

export default Blocked;

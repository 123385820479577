import React from "react";

import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";

import "react-virtualized/styles.css";
import { OrderOverviewTable } from "./OrderOverviewTable";

import { useQueries } from "react-query";
import { endpoint } from "../../../../commonjs/endpoints";
import { Spinner } from "../../../../commonjs/components/Spinner";

export const OrderOverview = () => {
  const { t } = useTranslation();

  const queries = useQueries([
    {
      queryKey: "countryCodes",
      queryFn: () => endpoint("accounts:api_country_list"),
    },
    {
      queryKey: "orderOverview",
      queryFn: () => endpoint("statistics:api_order_overview_statistics"),
    },
  ]);

  if (queries.find((query) => query.isLoading)) {
    return <Spinner />;
  }
  const countryCodes = queries[0].data;
  const orders = queries[1].data;

  return (
    <section className={"statistics"}>
      <h1>{t("SUPPLIERS ORDERS")}</h1>
      <div className={"statistics-content"} style={{ height: 1200 }}>
        <OrderOverviewTable orders={orders} countryCodes={countryCodes} />
      </div>
    </section>
  );
};

import React from "react";
import { useTranslation } from "react-i18next";
import { ContactCustomerService } from "../../../commonjs/constants";

const AddressApprovalResultFailed = ({ message }) => {
  const { t } = useTranslation();
  return (
    <section className="client-confirm">
      <h1 className="failed">{t("ACTION FAILED")}</h1>
      <section>
        {message}
        <br />
        <ContactCustomerService />
      </section>
    </section>
  );
};

function AddressApprovalResult({ approved }) {
  const { t } = useTranslation();
  return approved ? (
    <section className="client-confirm">
      <h1 className="success">{t("APPROVAL SUCCESSFUL")}</h1>
      <section>
        {t("You approved the address registration.")}
        <br />
        {t("The company will be informed accordingly.")}
      </section>
    </section>
  ) : (
    <section className="client-confirm">
      <h1 className="success">{t("REJECTION SUCCESSFUL")}</h1>
      <section>
        {t("You rejected the address registration.")}
        <br />
        {t("The company was informed and the address was deleted.")}
      </section>
    </section>
  );
}

export { AddressApprovalResult, AddressApprovalResultFailed };

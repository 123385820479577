import { useTranslation } from "react-i18next";

import { Icon } from "../../../commonjs/icons";
import { RollEntry } from "./RollEntry";
import { Annotation } from "./Annotation";
import { formatCompanyName } from "../utils";

export const DistributionEntries = ({ distributions, removeDistribution }) => {
  const { t } = useTranslation();

  if (!distributions) {
    return (
      <section id="roll-distributions">
        <div className="distributions-header">
          <h1>{t("Generated Distribution Tasks")}</h1>
          <p>
            {t(
              "Please select a recipient and a selection of rolls to distribute."
            )}
          </p>
        </div>
      </section>
    );
  }
  return (
    <section id="roll-distributions">
      <div className="distributions-header">
        <h1>{t("Generated Distribution Tasks")}</h1>
        <p>
          {t(
            "The rolls in the list below will be distributed to the chosen suppliers."
          )}
        </p>
      </div>
      <br />
      {distributions.map((distribution) => {
        return (
          <div
            className={"distribution"}
            key={distribution.recipient.pk}
            style={{ clear: "both" }}
          >
            <div className="distribution-address">
              <h4>{formatCompanyName(distribution.recipient)}</h4>
              <span>
                {distribution.recipient.street_address}, &nbsp;
                {distribution.recipient.postal_code}&nbsp;
                {distribution.recipient.city}
                ,&nbsp;{distribution.recipient.country}
              </span>
              <Annotation
                setAnnotation={(comment) => (distribution.comment = comment)}
                annotation={distribution.comment}
                placeholder={t("Add a comment to this Distribution")}
                helpText={t("Add an Annotation to this Distribution")}
              />
            </div>
            <div className="distribution-entry epic">
              <div className="distribution-rolls">
                <ul>
                  {distribution.rolls.map((roll) => (
                    <RollEntry
                      roll={roll}
                      recipient={distribution.recipient}
                      key={roll.pk}
                    />
                  ))}
                </ul>
              </div>
            </div>
            <div className="distribution-tools">
              <button
                onClick={(_) => removeDistribution(distribution.uuid)}
                title={t(
                  "Remove this Distribution and make rolls available again."
                )}
              >
                <Icon icon={"trash-alt"} />
              </button>
            </div>
          </div>
        );
      })}
    </section>
  );
};

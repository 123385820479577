import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function AddressApprovalDataFailed() {
  const { t } = useTranslation();
  return (
    <section className="client-confirm">
      <h1 className="failed">
        {t("ADDRESS APPROVAL FAILED (ERROR TIME-OUT)")}
      </h1>
      <section>
        {t(
          "Retrieval of the address data failed due to an invalid approval code."
        )}
        <br />
        {t("Please contact scribos® <customer-service@scribos.com>.")}
        <br />
      </section>
    </section>
  );
}

function Address({ address }) {
  const { street_address, address_details, postal_code, city, country } =
    address;
  return (
    <div>
      {street_address}
      <br />
      {address_details && (
        <span>
          {address_details}
          <br />
        </span>
      )}
      {postal_code}&nbsp;{city}
      <br />
      {country}
      <br />
    </div>
  );
}

function DeliveryAddressConfirm({
  addressToConfirm,
  requestingCompany,
  addressHistory,
}) {
  const { t } = useTranslation();

  const previousApprovedAddress =
    addressHistory && addressHistory.length > 0 ? addressHistory[0] : null;

  return (
    <div>
      <h1>{t("APPROVE DELIVERY ADDRESS")}</h1>
      <p>
        {t("The company")}&nbsp;
        <em>
          <b>
            {requestingCompany.name} ({requestingCompany.cofor10})
          </b>
        </em>
        &nbsp;
        {t("has changed their delivery address:")}
      </p>
      <br />
      <Address address={addressToConfirm} />
      <br />
      <p>{t("Previous approved delivery address:")}</p>
      <br />
      {previousApprovedAddress ? (
        <Address address={previousApprovedAddress} />
      ) : (
        "-"
      )}
    </div>
  );
}

function DistributionAddressConfirm({ addressToConfirm, requestingCompany }) {
  const { t } = useTranslation();

  return (
    <div>
      <h1>{t("APPROVE DISTRIBUTION ADDRESS")}</h1>
      <p>
        {t("The company")}&nbsp;
        <em>
          <b>
            {requestingCompany.name} ({requestingCompany.cofor10})
          </b>
        </em>
        &nbsp;{t("has added a new Distribution-Address")}
      </p>
      <br />
      <div>
        {t("COFOR10")}:&nbsp;{addressToConfirm.company_id}
        <br />
        <h4>{addressToConfirm.company_name}</h4>
        <Address address={addressToConfirm} />
      </div>
    </div>
  );
}

function AddressApprovalData({
  requestingCompany,
  addressToConfirm,
  addressHistory,
  onApproval,
}) {
  const { t } = useTranslation();
  const [feedback, setFeedback] = useState("");
  const feedbackMaxLength = 300;

  return (
    <section className="client-confirm">
      {addressToConfirm.category === "DELIVERY" && (
        <DeliveryAddressConfirm
          addressToConfirm={addressToConfirm}
          requestingCompany={requestingCompany}
          addressHistory={addressHistory}
        />
      )}
      {addressToConfirm.category === "DISTRIBUTION" && (
        <DistributionAddressConfirm
          addressToConfirm={addressToConfirm}
          requestingCompany={requestingCompany}
        />
      )}
      <h2>{t("Feedback to supplier (rejection only)")}</h2>
      <textarea
        style={{ maxWidth: "40rem" }}
        value={feedback}
        onChange={(e) => setFeedback(e.target.value)}
        rows={5}
        cols={40}
        maxLength={feedbackMaxLength}
      />
      <div>{`${t("Characters:")} ${feedback.length}/${feedbackMaxLength}`}</div>
      <br />
      <button
        className="button button-primary"
        onClick={() => onApproval(true, feedback)}
      >
        {t("APPROVE")}
      </button>
      <button
        className="button button-critical-action"
        onClick={() => onApproval(false, feedback)}
      >
        {t("REJECT")}
      </button>
    </section>
  );
}

export { AddressApprovalDataFailed, AddressApprovalData };

import React, { useMemo, useEffect } from "react";

import { useQueries } from "react-query";
import { useTranslation } from "react-i18next";

import { Spinner } from "../../../../commonjs/components";

import { ProductListContextProvider } from "./ProductListContext";

import { OrderTotal } from "./OrderTotal";
import { Checkout } from "./Checkout";

import { ProductGroup } from "./StandardProducts";
import { sortProducts } from "../commonCalculations";

import { AllProductsQuery, CartSettingsQuery } from "../Queries";

import { useShoppingCart } from "../ShoppingCart";


export const StandardProducts = () => {
  const { t } = useTranslation();

  const queries = useQueries([AllProductsQuery, CartSettingsQuery]);

  const allQueriesLoaded = !queries.find((query) => query.isLoading);

  const { clearOrder } = useShoppingCart((state) => ({
    clearOrder: state.clearOrder,
  }));

  useEffect(() => {
    clearOrder();
    // eslint-disable-next-line
  }, []);

  const { products, shopSettings, productGroups } = useMemo(() => {
    if (allQueriesLoaded) {
      const products = queries[0].data;
      const shopSettings = queries[1].data[0];

      const standardProducts = products.filter(
        (product) => product.extra_services.length === 0
      );
      const sortedProducts = sortProducts(standardProducts);

      const productGroups = sortedProducts.reduce((groups, currentProduct) => {
        const namedGroup = groups.find(
          (g) => g[0].product_group.name === currentProduct.product_group.name
        );
        if (namedGroup) {
          namedGroup.push(currentProduct);
          return groups;
        } else {
          return [...groups, [currentProduct]];
        }
      }, []);
      return { shopSettings, products, productGroups };
    } else {
      return { products: [], shopSettings: {}, productGroups: [] };
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allQueriesLoaded]);

  if (!allQueriesLoaded) {
    return <Spinner />;
  }

  return (
    <div className="StandardProcess">
      <div className="order-process-description">
        {t("Please fill out the form below to order standard labels in rolls.")}
        <br />
        {t(
          "To order individual prints or partial rolls, please change to NON-STANDARD PRODUCTS above."
        )}
      </div>
      <ProductListContextProvider
        products={products}
        shopSettings={shopSettings}
      >
        {productGroups.map((groupedProducts, idx) => (
          <ProductGroup key={"group_" + idx} products={groupedProducts} />
        ))}
        <OrderTotal />
        <Checkout />
      </ProductListContextProvider>
    </div>
  );
};

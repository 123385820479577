import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";
import {
  VerificationStates,
  ValidCodes,
  ValidState,
} from "./Map/VerificationStates";

import Select from "react-select";
import { selectStyles } from "../Styles";

const deflateStatusOptions = () => {
  const exceptValidOptions = VerificationStates.filter(
    (state) => !ValidCodes.includes(state.status)
  );
  const deflatedStates = [ValidState].concat(exceptValidOptions);

  return deflatedStates.map((s) => {
    return { label: s.description, value: s.status, color: s.color };
  });
};

const verificationStatusOptions = deflateStatusOptions();

class _VerificationStatusFilter extends PureComponent {
  // ValidStates are grouped in the UI - so they need to be ungrouped for the queries
  onSelectVerificationStatus = (selection) => {
    const { onChange } = this.props;
    // react-select returns null if no option is chosen
    selection = selection || [];
    const selectedStates = selection.map((st) => st.value);
    // when VAlID was selected, add all codes marked as valid for the search
    const inflatedStates = selectedStates.includes(ValidState.status)
      ? selectedStates.concat(ValidCodes)
      : selectedStates;

    // and remove the VALID-Placeholder from the options
    onChange(inflatedStates.filter((state) => state !== ValidState.status));
  };

  render() {
    const { t } = this.props;
    return (
      <label>
        {t("Verification Status")}
        <Select
          defaultValue={verificationStatusOptions}
          styles={selectStyles}
          options={verificationStatusOptions}
          isMulti={true}
          closeMenuOnSelect={false}
          className="verification-status"
          onChange={this.onSelectVerificationStatus}
        />
      </label>
    );
  }
}

export const VerificationStatusFilter = withTranslation()(
  _VerificationStatusFilter
);

import React from "react";

import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Field, reduxForm, getFormSyncErrors } from "redux-form";

import Recaptcha from "./google-invisible-recaptcha";

import { RenderField } from "../../commonjs/components";
import { i18n } from "../../i18next";
import { withOnMount } from "../../hoc";

import {
  RegisterUserDetailsFormPart,
  validate_user_details,
} from "./register/RegisterUserDetailsFormPart";
import {
  RegisterCompanyDetailsFormPart,
  validate_company_details,
} from "./register/RegisterCompanyDetailsFormPart";

import {
  accountsCaptchaStart,
  accountsCaptchaComplete,
  accountsRegisterSubmit,
  selectRegisterForm,
  selectCountries,
  accountsFetchCountries,
  selectRegistrationError,
} from "./duck";

import { E_REGISTER_CASE } from "./register/defines";
import { Navigate } from "react-router-dom";

const RegistrationError = ({ t, error }) => {
  // for now we display only the user profile error
  if (error.user_profile) {
    return (
      <div className="grid__item xs-12">
        <span className="form-error">
          {t("A company user with this email address exists already.")}
        </span>
      </div>
    );
  }
  return null;
};

class _RegisterForm extends React.Component {
  constructor(props) {
    super(props);
    this.onCaptchaSubmit = this.onCaptchaSubmit.bind(this);
    this.onCaptchaResolved = this.onCaptchaResolved.bind(this);
  }

  onCaptchaSubmit() {
    this.props.accountsCaptchaStart();
    this.recaptcha.execute();
  }

  onCaptchaResolved() {
    const captcha = this.recaptcha.getResponse();
    this.recaptcha.reset();
    this.props.accountsCaptchaComplete(captcha);
  }

  render() {
    const {
      error,
      t,
      handleSubmit,
      pristine,
      invalid,
      submitting,
      coforOptions,
      initialValues,
      captchaComplete,
      cofor6,
      accountId,
      cofor10,
      countryOptions,
      registrationError,
      navigate,
    } = this.props;

    const { registerCase } = initialValues;
    const language = i18n.language;
    const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;

    if (navigate) {
      return <Navigate to={navigate} />;
    }

    return (
      <form onSubmit={handleSubmit}>
        <div className="grid">
          <h1>{error}</h1>
          {/* render the user details form part */}
          <RegisterUserDetailsFormPart t={t} />
          <div className="grid__item xs-12">
            <hr />
          </div>
          {/* render the cofor6 / cofor10 fields secured with a captcha */}
          <div className="grid__item xs-12 vertical-spacing">
            <h3>
              {t(
                "Option 1: Register a new company or choose from registered companies:"
              )}
            </h3>
            <h4>{t("Enter COFOR (6 first characters)")}</h4>
          </div>
          <div className="grid__item xs-12">
            <Field
              name="cofor6"
              placeholder={t("COFOR (6)")}
              disabled={captchaComplete || accountId}
              component={RenderField}
              type="text"
            />
          </div>
          <div className="grid__item xs-12 vertical-spacing">
            <h3>&mdash; {t("OR")} &mdash;</h3>
          </div>
          <div className="grid__item xs-12 vertical-spacing">
            <h3>{t("Option 2: Add a new user to an existing account:")}</h3>
            <h4>{t("Enter your company's account number")}</h4>
          </div>
          <div className="grid__item xs-12">
            <Field
              name="accountId"
              placeholder={t("Account ID")}
              disabled={captchaComplete || cofor6}
              component={RenderField}
              type="text"
            />
          </div>
          <div className="grid__item xs-12">
            <Recaptcha
              locale={language}
              ref={(ref) => (this.recaptcha = ref)}
              sitekey={RECAPTCHA_KEY}
              onResolved={this.onCaptchaResolved}
            />
            {captchaComplete ? null : (
              <div
                className={`button button-primary ${
                  (cofor6 === undefined && accountId === undefined) ||
                  pristine ||
                  invalid
                    ? "button-primary--disabled"
                    : null
                }`}
                onClick={this.onCaptchaSubmit}
              >
                {t("Continue")}
              </div>
            )}
          </div>
          {/* render the company details form part */}
          {captchaComplete && coforOptions && (
            <RegisterCompanyDetailsFormPart
              t={t}
              coforOptions={coforOptions}
              cofor10={cofor10}
              initialValues={initialValues}
              countryOptions={countryOptions}
            />
          )}
          {/* handle registration errors */}
          {registrationError !== undefined && (
            <RegistrationError t={t} error={registrationError} />
          )}

          {/* render the final submit button */}
          {captchaComplete && cofor10 && (
            <div className="grid__item xs-12">
              <button
                type="submit"
                disabled={
                  (registerCase !== E_REGISTER_CASE.COFOR10_NEXT && pristine) ||
                  submitting
                }
                className={`button button-primary ${
                  (registerCase !== E_REGISTER_CASE.COFOR10_NEXT && pristine) ||
                  submitting ||
                  invalid
                    ? "button-primary--disabled"
                    : "clickable"
                }`}
              >
                {t("SEND")}
              </button>
            </div>
          )}
        </div>
      </form>
    );
  }
}

/* 
  form validation - async fields
  for checking if an email is already in use
  NOT USED ANYMORE - we leave it in as a reference
  
const asyncValidate = (values, dispatch) => {
  if (values.user !== undefined && values.user.email !== undefined) {
    NProgress.start();
    return endpoint("accounts:api_email_list", "GET", {
      query: { q_email: values.user.email },
    }).then(function (res) {
      NProgress.done();
      if (res && res.length > 0)
        throw { user: { email: i18n.t("That email is taken") } };
      return Promise.resolve();
    });
  }

  return Promise.resolve();
};
*/

/* form validation */
const validate = (values) => {
  const userDetailsErrors = validate_user_details(values);
  const companyDetailsErrors = validate_company_details(values);
  return {
    user: userDetailsErrors,
    ...companyDetailsErrors,
  };
};

// warnings
const warn = (values) => {
  const warnings = {};
  return warnings;
};

function mapStateToProps(state) {
  return {
    cofor6: selectRegisterForm(state, "cofor6"),
    accountId: selectRegisterForm(state, "accountId"),
    captchaComplete: state.accounts.captchaComplete,
    cofor10: selectRegisterForm(state, "company_profile.cofor10"),
    coforOptions: state.accounts.coforOptions,
    countryOptions: selectCountries(state),
    initialValues: state.accounts.initialFormValues, // import for form resetting / initalizing
    registrationError: selectRegistrationError(state),
    syncErrors: getFormSyncErrors("myForm")(state),
    navigate: state.accounts.navigate,
  };
}

const mapDispatchToProps = (dispatch) => ({
  onMount: (_) => dispatch(accountsFetchCountries()),
  accountsCaptchaStart: (_) => dispatch(accountsCaptchaStart()),
  accountsCaptchaComplete: (data) => dispatch(accountsCaptchaComplete(data)),
});

function onSubmit(values, dispatch, props) {
  dispatch(accountsRegisterSubmit(values));
}

// Register form
const RegisterForm = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    enableReinitialize: true, // !important
    form: "Register",
    validate,
    /* async disabled
       but leave it as reference, if we need async validation */
    // asyncValidate,
    // asyncBlurFields: ["user.email"],
    warn,
    onSubmit,
  }),
  withOnMount,
  withTranslation()
)(_RegisterForm);

// Register Page
const _Register = ({ t }) => (
  <section className="register">
    <div className="bg-login" />
    <h1>{t("CREATE ACCOUNT")}</h1>
    <h2>{t("Please fill in the information concerning your account.")}</h2>
    <hr />
    <RegisterForm />
  </section>
);

export const Register = compose(withTranslation())(_Register);

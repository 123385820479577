import { toast } from "react-hot-toast";
import { Field, Form } from "react-final-form";
import { useMutation } from "react-query";
import { endpoint } from "../../commonjs/endpoints";
import { useTranslation } from "react-i18next";
import { RenderField } from "../../commonjs/components";

const OtherLanguageFields = ({ initialValues }) => {
  const { t } = useTranslation();

  return Object.keys(initialValues)
    .filter((key) => {
      const m = key.match(/^([a-z]{2,2})$/);
      return m && m[1] !== "en";
    })
    .map((key) => (
      <div key={key}>
        <div className="grid__item xs-12">
          <label htmlFor={key}>{t(initialValues["name_" + key])}</label>
        </div>
        <div key={"lang_edit_" + key} className="grid__item xs-12">
          <Field
            name={key}
            component={RenderField}
            maxLength={200}
            type="text"
          />
        </div>
      </div>
    ));
};
const NewsEditorForm = ({ initialValues, newsUpdated }) => {
  const { t } = useTranslation();
  const notify = (msg) => toast.success(msg, { duration: 5000 });
  const { mutate: saveNews } = useMutation((news) => {
    endpoint("welcome:api_corporate_news", "POST", { body: news }).then(
      (result) => {
        notify(t("Updated corporate news."));
        newsUpdated(news);
      }
    );
  });

  const validateNews = (news) => {
    const errors = {};
    // NOTE: news.link can be empty
    if (!news.en) errors.en = t("Required");
    if (!news.fr) errors.fr = t("Required");

    return errors;
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={saveNews}
      validate={validateNews}
    >
      {({ handleSubmit, pristine, submitting, invalid }) => (
        <form onSubmit={handleSubmit}>
          <div className="grid">
            <br />
            <div className="grid__item xs-12">
              <label htmlFor="link">{t("Link to news")}</label>
            </div>
            <div className="grid__item xs-12">
              <Field
                name="link"
                component={RenderField}
                maxLength={200}
                type="text"
              />
            </div>
            <div className="grid__item xs-12">
              <label htmlFor={"en"}>{t("English")}</label>
            </div>
            <div key={"lang_edit_en"} className="grid__item xs-12">
              <Field
                name={"en"}
                component={RenderField}
                maxLength={200}
                type="text"
              />
            </div>
            <OtherLanguageFields initialValues={initialValues} />
            <button type="submit" className="button button--invert">
              {t("Save News")}
            </button>
          </div>
        </form>
      )}
    </Form>
  );
};

export const NewsEditor = ({ show, news, newsUpdated }) =>
  show ? (
    <section className="grid">
      <div className="grid__item xs-12">
        <NewsEditorForm initialValues={news} newsUpdated={newsUpdated} />
      </div>
    </section>
  ) : null;

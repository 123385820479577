import React from "react";

import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";

import { RenderField } from "../../../commonjs/components";

/* Address forms (billing, delivery, invoice) */
export const AddressForm = ({ initialValues, countryOptions, onSubmit }) => {
  const { t } = useTranslation();

  const validateAddress = (values) => {
    const errors = {};
    if (!values.street_address) errors.street_address = t("Required");
    if (!values.postal_code) errors.postal_code = t("Required");
    if (!values.country_code) errors.country_code = t("Required");
    if (!values.city) errors.city = t("Required");
    return errors;
  };

  const validationPending = initialValues.state === "CLIENT_APPROVAL_REQUESTED";
  const rejected = initialValues.state === "REJECTED";

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validateAddress}
    >
      {({ handleSubmit, pristine, submitting, invalid }) => (
        <form onSubmit={handleSubmit}>
          {validationPending && (
            <p className="warning" style={{ marginBottom: "0.5em" }}>
              {t(
                "Address is currently waiting for approval and cannot be edited."
              )}
            </p>
          )}
          {rejected && (
            <p className="warning" style={{ marginBottom: "0.5em" }}>
              {t(
                "Address has been rejected and cannot be used: Please edit and resubmit."
              )}
            </p>
          )}
          <div className="grid">
            <fieldset disabled={validationPending}>
              <div className="grid__item xs-12">
                <Field
                  name="street_address"
                  component={RenderField}
                  type="text"
                  placeholder={t("Street address")}
                />
              </div>
              <div className="grid__item sm-12 md-4">
                <Field
                  name="postal_code"
                  component={RenderField}
                  type="text"
                  placeholder={t("Postal code")}
                />
              </div>
              <div className="grid__item sm-12 md-8">
                <Field
                  name="city"
                  component={RenderField}
                  type="text"
                  placeholder={t("City")}
                />
              </div>
              <div className="grid__item xs-12">
                <Field
                  name="country_code"
                  component="select"
                  className="vertical-spacing-half"
                >
                  {countryOptions.map((x) => (
                    <option key={x.code} value={x.code}>
                      {x.name} ({x.code})
                    </option>
                  ))}
                </Field>
              </div>
              <div className="grid__item xs-12">
                <Field
                  name="address_details"
                  component={RenderField}
                  type="text"
                  placeholder={t("Address details")}
                />
              </div>
            </fieldset>
            <div className="grid__item xs-12">
              <button
                type="submit"
                disabled={pristine || submitting || validationPending}
                className={`button button-primary ${
                  pristine || submitting || invalid || validationPending
                    ? "button-primary--disabled"
                    : "clickable"
                }`}
              >
                {t("UPDATE")}
              </button>
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};

import React from "react";

import { withTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";

import "react-virtualized/styles.css";
import { CompaniesList } from "./CompaniesList";
import { endpoint } from "../../../../commonjs/endpoints";
import { Spinner } from "../../../../commonjs/components";
import { useQueries } from "react-query";

const _Registrations = (props) => {
  const queries = useQueries([
    {
      queryKey: "countryCodes",
      queryFn: () => endpoint("accounts:api_country_list"),
    },
    {
      queryKey: "registrations",
      queryFn: () => endpoint("statistics:api_registration_statistics"),
    },
  ]);

  const { t } = props;

  if (queries.find((query) => query.isLoading)) {
    return <Spinner />;
  }

  const countryCodes = queries[0].data;
  const registrations = queries[1].data;

  return (
    <section className={"statistics"}>
      <h1>{t("SUPPLIERS ACCOUNTS")}</h1>
      <div className={"statistics-content"} style={{ height: "100%" }}>
        <CompaniesList result={registrations} countryCodes={countryCodes} />
      </div>
    </section>
  );
};

export const Registrations = withTranslation()(_Registrations);

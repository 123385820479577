import React, { useEffect, useRef } from "react";
import JsBarcode from "jsbarcode";
import { useCanvas } from "../Canvas";
import { FieldDebounced } from "./FieldDebounced";
import { createDisplayText, fieldCanBeFormatted } from "./utils";

const Barcode = ({ data, scalingFactor, overlaySpecification }) => {
  const { x, y, width, height, prefix = "", max_x, align, display } = overlaySpecification;

  const text = createDisplayText(data, display);

  const imgBarcode = useRef(new Image());
  const barcodeRef = useRef(null);

  const { renderingContext: ctx, setRedrawRequested } = useCanvas();

  // on text change only, create new barcode image and draw it
  useEffect(() => {
    if (ctx && text) {
      // create new barcode, draw it on the canvas, and set image to valid
      try {
        const canvasBarcode = document.createElement("canvas");
        JsBarcode(canvasBarcode, prefix + text, {
          format: "CODE39",
          height: height * scalingFactor,
          width: width * scalingFactor,
          background: "white",
          fontSize: 0,
          margin: 0,
        });
        imgBarcode.current.onload = () => {
          setRedrawRequested(true);
        };
        imgBarcode.current.src = canvasBarcode.toDataURL();
        barcodeRef.current = canvasBarcode;
      } catch (_) {
        // ignore jsbarcode errors
        // might occur when an invalid text string (e.g. ä à) is passed
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  if (ctx && text && imgBarcode.current.complete) {
    const scaledMaxWidth = (max_x - x) * scalingFactor;
    if (barcodeRef.current && barcodeRef.current.width < scaledMaxWidth) {
      const scaledBarcodeWidth = barcodeRef.current.width;
      let scaledXOffset = 0;
      if (align === "center") {
        scaledXOffset = (scaledMaxWidth - scaledBarcodeWidth) / 2;
      } else if (align === "right") {
        scaledXOffset = scaledMaxWidth - scaledBarcodeWidth;
      }
      ctx.drawImage(imgBarcode.current, x * scalingFactor + scaledXOffset, y * scalingFactor);
    } else {
      ctx.drawImage(
        imgBarcode.current,
        x * scalingFactor,
        y * scalingFactor,
        scaledMaxWidth,
        height * scalingFactor
      );
    }
  }

  return null;
};

export const BarcodePreview = (props) => {
  const { data, overlaySpecification, fields } = props;

  const dataFieldNames = data ? Object.keys(data) : [];
  const requiredProductFields = fields.filter(e => dataFieldNames.includes(e.name));
  const canBeFormatted = requiredProductFields.some(fieldCanBeFormatted);
  // for text fields formatting is DISABLED by default
  const toFormat = overlaySpecification.format ?? false

  if (toFormat && canBeFormatted) {
    return <FieldDebounced {...props} component={Barcode} />
  }
  return <Barcode {...props} />;
}

import { useContext } from "react";
import { useForm, useFormState } from "react-final-form";
import { useTranslation } from "react-i18next";
import { NonStandardProductContext } from "../components/NonStandardProductContext";

const specialButtonStyle = {
  align: "right",
  float: "right",
  backgroundColor: "#ff9e00",
  padding: "0px 10px 0px 10px",
  fontSize: "0.7rem",
  minHeight: "1.2rem",
  lineHeight: "1.4rem",
  margin: "5px",
};

const ClearButton = ({ setIsFormOpen, rowId }) => {
  const { t } = useTranslation();
  const form = useForm();
  const { pristine, dirty } = useFormState();
  const { dispatchRowEvent } = useContext(NonStandardProductContext);

  return (
    <button
      style={
        dirty
          ? specialButtonStyle
          : {
              ...specialButtonStyle,
              opacity: "0.3",
              pointerEvents: "none",
              backgroundColor: "#ffffff",
            }
      }
      disabled={pristine}
      className="button button-secondary clickable"
      onClick={() => {
        form.reset();
        setIsFormOpen(false);
        dispatchRowEvent({
          type: "removePrintingService",
          payload: {
            rowId: rowId,
            hasPrintData: false,
            correctnessConfirmed: false,
          },
        });
      }}
    >
      {t("CLEAR PRINT DATA")}
    </button>
  );
};
export const Header = ({
  isFormOpen,
  setIsFormOpen,
  toggleCollapse,
  hasPrintingServices,
  errorMessage,
  rowId,
}) => {
  const { t } = useTranslation();
  const printEnterText = t(
    "If you need to add an individual label imprint done by scribos click here."
  );
  const printChangeText = t(
    "If you need to change the individual label imprint done by scribos click here."
  );

  return (
    <div className="extraServicesHeader">
      <div className="form-open-close-message">
        <span
          onClick={toggleCollapse}
          className={isFormOpen ? "icon-fold-inverted" : "icon-unfold-inverted"}
        />
        <span onClick={toggleCollapse}>
          {(hasPrintingServices ? printChangeText : printEnterText) + " "}
        </span>
        <ClearButton setIsFormOpen={setIsFormOpen} rowId={rowId} />
        {errorMessage && (
          <div className="form-error-message-header">{errorMessage}</div>
        )}
      </div>
    </div>
  );
};

import React from "react";

import { Icon } from "../../commonjs/icons";

export const formatAddressLabel = (address) => {
  return (
    <div className="recipient-address">
      <h4>{formatCompanyName(address)}</h4>
      <p>
        {address.street_address}, &nbsp;{address.postal_code}&nbsp;
        {address.city}, &nbsp;{address.country}
      </p>
    </div>
  );
};

export const formatCompanyName = (address) => {
  return (
    <span>
      {address.company_name}&nbsp;({address.company_id})&nbsp;
      {address.ownAddress ? <Icon icon="user" color={"grey"} /> : ""}
    </span>
  );
};

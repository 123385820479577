import React from "react";
import { FixedSizeList } from "react-window";
import NoOptionsMessage from "./NoOptionsMessage";

const height = 40;

const WindowedList = props => {
  const { options, children, maxHeight, getValue } = props;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;

  const optionsCount = children.length ? children.length : 1;
  const optionsHeight = optionsCount > 8 ? maxHeight : optionsCount * height;

  return (
    <FixedSizeList
      height={optionsHeight}
      itemCount={children.length || 1}
      itemSize={height}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) =>
        children.length ? (
          <div style={style}>{children[index]}</div>
        ) : (
          <NoOptionsMessage style={style} />
        )
      }
    </FixedSizeList>
  );
};

export default WindowedList;

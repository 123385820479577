import React, { Component } from "react";

import { withTranslation } from "react-i18next";

import Select from "react-select";
import uniqBy from "lodash/uniqBy";
import uniq from "lodash/uniq";
import sortBy from "lodash/sortBy";

import "react-datepicker/dist/react-datepicker.css";

import { createTableMultiSort, SortIndicator } from "react-virtualized";
import "react-virtualized/styles.css";

import { sortList } from "../fn";

import CompanyFilters from "./CompanyFilters";
import CompaniesTable from "./CompaniesTable";

class _CompaniesList extends Component {
  componentDidMount() {
    const { result } = this.props;

    this.setState({ filteredList: result });
  }

  state = {
    filteredList: [],
    showCompanySelect: false,
    filteredCompanies: [],
    sortBy: "registration_date",
    sortDirection: "DESC",
  };

  reportResult = (filteredList) => {
    const { sortBy, sortDirection } = this.state;

    const filteredAndSortedList = sortList({
      list: filteredList,
      sortBy,
      sortDirection,
    });

    this.setState({ filteredList: filteredAndSortedList });
  };

  sort = ({ sortBy, sortDirection }) => {
    const { filteredList } = this.state;
    const sortedList = sortList({ list: filteredList, sortBy, sortDirection });
    this.setState({ filteredList: sortedList, sortBy, sortDirection });
  };

  sortState = createTableMultiSort(this.sort);

  countryOptionsFor = ({ fieldName, exclude }) => {
    const { countryCodes } = this.props;

    const hashedCountries = countryCodes.reduce(
      (acc, entry) => ({ ...acc, [entry.code]: entry.name }),
      {}
    );
    const labelMapping = (label) => hashedCountries[label] + " (" + label + ")";
    return this.optionsFor({ fieldName, exclude, labelMapping });
  };

  optionsFor = ({ fieldName, exclude, labelMapping = (label) => label }) => {
    const { result } = this.props;

    const includedFilters = new Map(this.filters);
    includedFilters.delete(exclude);
    const filteredList = Array.from(includedFilters.values()).reduce(
      (currentResult, filter) =>
        filter.key === exclude
          ? currentResult
          : currentResult.filter((entry) => filter(entry)),
      result
    );

    const labels = uniqBy(filteredList, fieldName)
      .map((entry) => entry[fieldName])
      .filter((fieldValue) => fieldValue.length > 0);

    const uniqueValues = uniq(labels).map((label) => ({
      label: labelMapping(label),
      value: label,
    }));

    const sorted = sortBy(uniqueValues, (entry) => entry.label);

    return sorted;
  };

  headerRenderer = ({ dataKey, label }) => {
    const showSortIndicator = this.sortState.sortBy.includes(dataKey);
    return (
      <div className={"sort-header"}>
        <span title={label}>{label}</span>
        {showSortIndicator && (
          <SortIndicator
            sortDirection={this.sortState.sortDirection[dataKey]}
          />
        )}
      </div>
    );
  };

  companyHeaderRenderer = ({ dataKey, label }) => {
    const { t, result } = this.props;
    const { filteredCompanies } = this.state;

    const options = result.map((entry) => ({
      label: entry.company_name,
      value: entry.company_name,
    }));

    const showSortIndicator = this.sortState.sortBy.includes(dataKey);
    return (
      <div className={"sort-header"}>
        <span title={label}>{label}</span>

        {showSortIndicator && (
          <SortIndicator
            sortDirection={this.sortState.sortDirection[dataKey]}
          />
        )}
        {this.state.showCompanySelect && (
          <div
            className={"filter-companies"}
            ref={this.registerForOutsideClick}
          >
            <Select
              multi
              simpleValue
              openOnFocus={true}
              options={options}
              onChange={this.filterCompanies}
              value={filteredCompanies}
              placeholder={t("select one or more companies")}
            />
          </div>
        )}
      </div>
    );
  };

  registerForOutsideClick = (ref) => {
    if (ref) {
      document.addEventListener("click", this.handleOutsideClick);
      this.filterCompaniesRef = ref;
    } else {
      document.removeEventListener("click", this.handleOutsideClick);
      this.filterCompaniesRef = undefined;
    }
  };

  handleOutsideClick = (event) => {
    if (!this.filterCompaniesRef || !this.state.showCompanySelect) return;

    if (this.filterCompaniesRef.contains(event.target)) {
      return;
    } else {
      this.toggleCompanyFilter(event);
    }
  };

  toggleCompanyFilter = (event) => {
    const { showCompanySelect } = this.state;

    this.setState({ showCompanySelect: !showCompanySelect });
  };

  filterCompanies = (value) => {
    const { result } = this.props;

    const filteredList =
      value.length > 0
        ? result.filter((entry) => value.includes(entry.company_name))
        : result;

    this.setState({ filteredCompanies: value, filteredList });
  };

  filters = new Map();
  updateFilter = (key, filter) => {
    this.filters.set(key, filter);

    this.applyFilters();
  };

  resetFilter = (key) => {
    this.filters.delete(key);

    this.applyFilters();
  };

  applyFilters = () => {
    const { result } = this.props;
    const { sortBy, sortDirection } = this.state;

    const filtered = Array.from(this.filters.values()).reduce(
      (currentResult, filter) => {
        return currentResult.filter((entry) => filter(entry));
      },
      result
    );

    const sortedAndFiltered = sortList({
      list: filtered,
      sortBy,
      sortDirection,
    });

    this.setState({ filteredList: sortedAndFiltered });
  };

  render() {
    const { t } = this.props;
    const { filteredList } = this.state;

    return (
      <div>
        <CompanyFilters
          updateFilter={this.updateFilter}
          resetFilter={this.resetFilter}
          optionsFor={this.optionsFor}
          countryOptionsFor={this.countryOptionsFor}
          companies={filteredList}
        />
        <h3 style={{ paddingTop: "10px", marginLeft: "-15px" }}>
          {filteredList.length} {t("registration(s) matched your criteria")}.
        </h3>
        <CompaniesTable
          companies={filteredList}
          sort={this.sortState.sort}
          headerRenderer={this.companyHeaderRenderer}
        />
        <br />
      </div>
    );
  }
}

export const CompaniesList = withTranslation()(_CompaniesList);

import React, { Fragment } from "react";
import ReactTooltip from "react-tooltip";

import { Icon } from "../icons";
import { useModal, CRITICAL } from "../../licensing/orders/modals/useModal";
import { PendingValue } from "../../licensing/orders/order_process/utils";

export function TableHead(props) {
  const { columns } = props;

  let titles = columns.map((column, id) => {
    return (
      <ColumnTitle
        key={id}
        id={id}
        name={column.name}
        displayName={column.displayName}
        width={column.width}
        title={column.title}
      />
    );
  });
  return (
    <thead>
      <tr>{titles}</tr>
    </thead>
  );
}

function ColumnTitle(props) {
  const { id, name, displayName, title, width } = props;

  if (!displayName || displayName === "")
    return (
      <th title={title} key={id} width={width}>
        <em>&nbsp;</em>
      </th>
    );
  return (
    <th title={title} key={id} width={width}>
      <em className={name}>{displayName}</em>
    </th>
  );
}

export function NumberInput(props) {
  const { onChange, number, blocked, blockingReason } = props;

  const [WarningDialog, openWarningDialog] = useModal({ level: CRITICAL });

  return (
    <td>
      <div className="cell" style={{ textAlign: "center" }}>
        <input
          min="0"
          onChange={onChange}
          value={number <= 0 ? "" : number}
          type="number"
          className="label-input"
          disabled={blocked}
        />
        {blocked && (
          <Fragment>
            <WarningDialog>{blockingReason}</WarningDialog>
            <span
              onClick={openWarningDialog}
              className="icon icon-error error-modal-cell-button"
            >
              !
            </span>
          </Fragment>
        )}
      </div>
    </td>
  );
}

export function Number({ number, pending }) {
  return (
    <td>
      <div className="cell text-right">
        <PendingValue isPending={pending}>{number}</PendingValue>
      </div>
    </td>
  );
}
export function Cell(props) {
  const { value } = props;

  return (
    <td>
      <div className="cell">{value}</div>
    </td>
  );
}

export function StatusCell(props) {
  const { label, status } = props;

  return (
    <td>
      <div className="cell">
        <span className={"status status--" + status} />
        {label}
      </div>
    </td>
  );
}
export function ToolsCell(props) {
  const { icon, callback } = props;

  // todo: support for multiple actions
  return (
    <td>
      <div className="cell tools-cell">
        <button onClick={callback}>
          <Icon icon={icon} />
        </button>
      </div>
    </td>
  );
}

export function IconCell(props) {
  const { icon, message } = props;

  // todo: support for multiple icons
  // todo: do we need a unique id for the tooltip?!
  return (
    <td>
      <div
        className="cell tools-cell"
        data-for="icon-tooltip"
        data-tip={message}
      >
        <Icon icon={icon} />
      </div>
      <ReactTooltip
        id="icon-tooltip"
        place="right"
        effect="solid"
        className="tooltip-info"
      />
    </td>
  );
}

export function SelectCell(props) {
  const { onChange, label, disabled, disabledWarning } = props;
  return (
    <td>
      <div className="cell tools-cell">
        <input
          type="checkbox"
          label={""}
          onChange={onChange}
          key={label}
          disabled={disabled}
          value="on"
          title={disabled ? disabledWarning : ""}
        />
      </div>
    </td>
  );
}

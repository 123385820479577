import React, { Component } from "react";

import { withTranslation } from "react-i18next";
import uniqBy from "lodash/uniqBy";
import Select from "react-select";

const FILTER_NAME = "COUNTRY_FILTER";

class _CountryFilter extends Component {
  state = {
    selectedCountries: [],
    availableCountries: [],
  };

  filterCountries = (selectedOptions) => {
    const { updateFilter, resetFilter } = this.props;

    const values = selectedOptions.map((option) => option.value);
    values.length > 0
      ? updateFilter(FILTER_NAME, (entry) =>
          values.includes(entry.country_code)
        )
      : resetFilter(FILTER_NAME);

    this.setState({ selectedCountries: selectedOptions });
  };

  componentDidMount() {
    const { list, countryCodes } = this.props;

    const hashedCountries = countryCodes.reduce(
      (acc, entry) => ({ ...acc, [entry.code]: entry.name }),
      {}
    );

    const availableCountries = uniqBy(list, "country_code")
      .map((entry) => ({
        label:
          hashedCountries[entry.country_code] + " (" + entry.country_code + ")",
        value: entry.country_code,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    this.setState({ availableCountries });
  }

  render() {
    const { t } = this.props;
    const { availableCountries, selectedCountries } = this.state;
    return (
      <div className={"select-filter"}>
        <label>{t("Country")}</label>
        <Select
          isMulti
          simpleValue
          openOnFocus={true}
          options={availableCountries}
          onChange={this.filterCountries}
          value={selectedCountries}
          placeholder={t("Select one or more countries")}
        />
      </div>
    );
  }
}

export const CountryFilter = withTranslation()(_CountryFilter);

import React from "react";

import { withTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

import {
  formatToCurrency,
  formatToInteger,
  formatToFloat,
  formatToShortDate,
} from "../../../commonjs/langutils";
import { ModalRaw } from "../../../commonjs/modals";

import { AddressDelivery, AddressBilling } from "../../accounts/";

const _ModalOrderHistoryDetails = (props) => {
  const { i18n, t, close, order } = props;
  const language = i18n.language;

  const rowName = {
    RANGE: t("range"),
    REFERENCE: t("reference"),
    DIMENSIONS: t("dimensions"),
    INITQUANT: t("initial quantities"),
    AVAILQUANT: t("available quantities"),
    ROLLS: t("No. of rolls"),
    LABELS: t("No. of labels"),
    PRICE: t("price"),
  };

  const displayRows = [
    //'info-modal',
    rowName["RANGE"],
    rowName["REFERENCE"],
    rowName["DIMENSIONS"],
    //rowName['INIT_QUANT'],
    //rowName['AVAIL_QUANT'],
    rowName["ROLLS"],
    rowName["LABELS"],
    rowName["PRICE"],
  ];

  const buildTooltipText = (extraServiceData) => {
    const printingData = extraServiceData.find(
      (data) => data.category === "PRINTING"
    );

    if (!printingData) {
      return "";
    }

    const sortedFields = printingData.fields.slice().sort((a, b) => {
      return a.index - b.index;
    });

    const result = sortedFields.map((field) => {
      let { index, description, value } = field;
      // replace bool values with "human readable" yes/no
      if (field.field_type === "BOOLEAN") {
        value = value === true ? t("Yes") : t("No");
      }
      // for select fields, replace the value with the corresponding label
      if (field.field_type === "STRING" && field.options) {
        const option = field.options.find((o) => o.value === value);
        if (option !== undefined) {
          value = option.label;
        }
      }
      return `${index} - ${description}: ${value}<br>`;
    });

    return result.join("");
  };

  return (
    <ModalRaw close={close}>
      <section className="modal-order-details">
        <div className="grid">
          <div className="grid__item xs-10 md-10">
            <h3>
              {t("Order No.")} {order.order_id}
            </h3>
            {t("Purchaser: ")} {order.user.email} &nbsp;
            {t("Order Date")}:&nbsp;{formatToShortDate(order.date_created)}
            <br />
            <br />
          </div>

          <div className="table vertical-spacing grid__item xs-12 md-12">
            <table>
              <thead>
                <tr>
                  {displayRows.map((colName, id) => {
                    if (colName === "info-modal")
                      return (
                        <th key={id}>
                          <em>&nbsp;</em>
                        </th>
                      );
                    return (
                      <th key={id}>
                        <em className={colName}>{colName}</em>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className={"order-positions"}>
                {order.order_positions.map((order, id) => {
                  const {
                    label_length,
                    label_width,
                    product_group,
                    labels_per_unit,
                  } = order.product;
                  // there might be an alternative customer references stored at the point of ordering,
                  // otherwise use the product's customer reference as fallback
                  const customer_reference = order.alternative_customer_reference || order.product.customer_reference;
                  let tooltipText = buildTooltipText(order.extra_service_data);

                  const decimalPlaces = 2;
                  const no_of_labels = order.quantity;
                  const no_of_rolls = (
                    order.quantity / labels_per_unit
                  ).toFixed(decimalPlaces);
                  const formattedNoOfRolls =
                    no_of_rolls % 1 === 0
                      ? formatToInteger(no_of_rolls, language)
                      : formatToFloat(no_of_rolls, language, decimalPlaces);

                  return (
                    <tr
                      key={id}
                      data-tip={tooltipText}
                      data-for="order-position"
                    >
                      <td>
                        <div className="cell">{product_group.name}</div>
                      </td>

                      <td>
                        <div className="cell"> {customer_reference}</div>
                      </td>

                      <td>
                        <div className="cell">
                          {" "}
                          {`${label_length}x${label_width}mm`}
                        </div>
                      </td>

                      <td>
                        <div className="cell text-right">
                          {formattedNoOfRolls}
                        </div>
                      </td>

                      <td>
                        <div className="cell text-right">
                          {formatToInteger(no_of_labels, language)}
                        </div>
                      </td>

                      <td>
                        <div className="cell text-right">
                          {formatToCurrency(order.total, language)}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="grid__item xs-12 md-6">
            <h4>{t("Summary")}</h4>
            <table>
              <tbody>
                <tr>
                  <td>{t("Handling costs")}:</td>
                  <td className="currency">
                    {formatToCurrency(order.handling_costs, language)}
                  </td>
                </tr>
                <tr>
                  <td>{t("Delivery costs")}:</td>
                  <td className="currency">
                    {formatToCurrency(order.delivery_costs, language)}
                  </td>
                </tr>
                <tr>
                  <td>{t("Total")}:</td>
                  <td className="currency total">
                    {formatToCurrency(order.total, language)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="grid__item xs-12">
            <hr />
          </div>

          <div className="grid__item xs-12 md-6">
            <AddressDelivery objAddress={order.delivery_address} />
          </div>

          <div className="grid__item xs-12 md-6">
            <AddressBilling objAddress={order.billing_address} />
          </div>
        </div>
        <ReactTooltip
          id="order-position"
          place="right"
          effect="solid"
          className="tooltip-info"
          multiline={true}
        />
      </section>
    </ModalRaw>
  );
};

export const ModalOrderHistoryDetails = withTranslation()(
  _ModalOrderHistoryDetails
);

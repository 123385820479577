import React from "react";

export const RenderPasswordField = (props) => {
  const {
    placeholder,
    disabled,
    input,
    maxLength,
    meta: { asyncValidating, valid, error, warning },
  } = props;

  return (
    <div>
      <input
        className={`vertical-spacing ${
          asyncValidating ? "asyncValidating" : null
        }`}
        maxLength={maxLength ? maxLength : 100}
        {...input}
        disabled={disabled}
        placeholder={placeholder}
        type={"password"}
      />
      {(error && <span className="validation-states error">{error}</span>) ||
        (warning && (
          <span className="validation-states warning">{warning}</span>
        )) ||
        (valid && <span className={`validation-states valid`} />)}
    </div>
  );
};

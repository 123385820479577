import React from "react";

import { useTranslation } from "react-i18next";

export const RegisterConfirm = () => {
  const { t } = useTranslation();

  return (
    <section className="email-confirm">
      <div className="bg-login" />
      <div>
        <h1 className="success">{t("REGISTRATION RECEIVED")}</h1>
        <section>
          {t("Thank you for registering.")}
          <br />
          <br />
          {t(
            "An email with a confirmation link has been sent to your email address."
          )}
          <br />
          {t(
            "Please confirm your registration by clicking on the confirmation link."
          )}
        </section>
      </div>
    </section>
  );
};



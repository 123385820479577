import React from "react";
import { Link } from "react-router-dom";

import { named_urls } from "../../../../routing/known_urls";

const TableDisplay = (props) => {
  const { label, labelId, t } = props;

  const fields = [
    {
      label: t("Spool No."),
      value: (
        <Link to={named_urls["statistics:spool_analysis"]} state={{ labelId }}>
          {label.spool_id}
        </Link>
      ),
      width: "120px",
    },
    { label: t("Reference"), value: label.reference, width: "10px" },
    { label: t("Domain"), value: label.product_group, width: "10px" },
    {
      label: t("Company"),
      value: (
        <span>
          {label.company_name},&nbsp;{label.cofor10}
        </span>
      ),
      width: "15px",
    },
    { label: t("City"), value: label.city, width: "10px" },
    { label: t("Country"), value: label.country_code, width: "5px" },
    {
      label: t("Status"),
      value: (
        <span>
          <span className={`status status--${label.state || "activated"}`} />
          <span>{label.state}</span>
        </span>
      ),
      width: "20px",
    },
  ];

  return (
    <table className={"table"}>
      <thead>
        <tr>
          {fields.map((column, id) => (
            <th key={id}>
              <em className={column.label}>{column.label}</em>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          {fields.map((field, idx) => (
            <td
              key={idx}
              style={{
                overflow: "elipsis",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              <div className={"cell"}> {field.value}</div>
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};

export const LabelDetail = (props) => {
  const { label, labelId, t } = props;
  return <TableDisplay {...{ label, labelId, t }} />;
};

import { endpoint } from "../../../commonjs/endpoints";

const queryDefaults = {
  refetchOnWindowFocus: false,
  cacheTime: Infinity,
  refetchOnMount: false,
};
export const AllProductsQuery = {
  ...queryDefaults,
  cacheTime: 0,
  refetchOnMount: false,
  queryKey: "productsList",
  queryFn: () => endpoint("orders:api_product_list", "GET"),
};

export const CartSettingsQuery = {
  ...queryDefaults,
  queryKey: "cartSettings",
  queryFn: () => endpoint("orders:api_settings_list", "GET"),
};

export const CountryCodesQuery = {
  ...queryDefaults,
  queryKey: "countryCodes",
  queryFn: () => endpoint("accounts:api_country_list", "GET"),
};
export const CountryCodesArabicQuery = {
  ...queryDefaults,
  queryKey: "countryCodesArabic",
  queryFn: () =>
    endpoint("accounts:api_country_list", "GET", { query: { lang: "ar" } }),
};


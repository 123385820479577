import Notifications from "react-notification-system-redux";

const loud = false;

export function notificationError(e = "error", extra = "", notify_user = false) {
  return function(dispatch, getState) {
    console.log(e, extra);
    if (!loud && !notify_user) return;

    dispatch(
      Notifications.error({
        title: "error",
        message: `${e.toString()} ${extra.toString()}`,
        position: "tc",
        autoDismiss: 5
      })
    );
  };
}

export function notificationSuccess(e = "success", notify_user = false) {
  return function(dispatch, getState) {
    if (!loud && !notify_user) return;
    console.log(e);
    dispatch(
      Notifications.success({
        title: "success",
        message: `${e.toString()}`,
        position: "tc",
        autoDismiss: 5
      })
    );
  };
}

export function notificationMessageSuccess(title, message) {
  return function(dispatch, getState) {
    dispatch(
      Notifications.success({
        title,
        message,
        position: "tc",
        autoDismiss: 5
      })
    );
  };
}

export function notificationMessageError(title, message) {
  return function(dispatch, getState) {
    dispatch(
      Notifications.error({
        title,
        message,
        position: "tc",
        autoDismiss: 5
      })
    );
  };
}

import React from "react";
import { useTranslation } from "react-i18next";

const NoOptionsMessage = props => {
  const { t } = useTranslation();
  const getStyle = () => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "gray"
  });
  return (
    <div
      {...props}
      style={{
        ...props.style,
        ...getStyle()
      }}
    >
      {t("No options")}
    </div>
  );
};

export default NoOptionsMessage;

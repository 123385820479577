import React from "react";

import { compose } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import { withTranslation } from "react-i18next";

import { i18n } from "../../i18next";
import { isEmail } from "./utils";

import { RenderField } from "../../commonjs/components";

import { accountsSendPasswordReset } from "./duck";

/* Password reset request form */
const validate_password_reset_request = (values) => {
  const errors = {};
  if (!values["email"]) errors["email"] = i18n.t("Required");
  if (values["email"] && !isEmail(values["email"]))
    errors["email"] = i18n.t("Invalid email");
  if (!values["account_id"]) errors["account_id"] = i18n.t("Required");
  return errors;
};

const _PasswordResetRequestForm = ({
  handleSubmit,
  pristine,
  submitting,
  invalid,
  t,
}) => (
  <form onSubmit={handleSubmit}>
    <div className="grid">
      <div className="grid__item xs-12 md-8">
        <Field
          name="email"
          component={RenderField}
          type="text"
          placeholder={t("Email")}
        />
      </div>
      <div className="grid__item xs-12 md-8">
        <Field
          name="account_id"
          component={RenderField}
          type="text"
          placeholder={t("Account id")}
        />
      </div>
      <div className="grid__item xs-12 md-8">
        <button
          type="submit"
          disabled={pristine || submitting}
          className={`button button-primary ${
            pristine || submitting || invalid
              ? "button-primary--disabled"
              : "clickable"
          }`}
        >
          {t("RESET")}
        </button>
      </div>
    </div>
  </form>
);

export const PasswordResetRequestForm = compose(
  connect(null, {
    submitData: accountsSendPasswordReset,
  }),
  reduxForm({
    form: "password_reset_request",
    validate: validate_password_reset_request,
    onSubmit: function (values, dispatch, props) {
      props.submitData(values);
    },
  }),
  withTranslation()
)(_PasswordResetRequestForm);

/* Password reset request PAGE */
const _PasswordResetRequest = ({ t }) => {
  return (
    <section className="password-reset-request">
      <div className="bg-login" />
      <h1>{t("RESET YOUR PASSWORD")}</h1>
      <h2>{t("Please enter email address and account id")}</h2>
      <PasswordResetRequestForm />
    </section>
  );
};

export const PasswordResetRequest = compose(
  withTranslation() //  use default namespace of i18next
)(_PasswordResetRequest);

import React, { useState } from "react";

import { useQueries } from "react-query";

import { useTranslation } from "react-i18next";

import { ModalRoot } from "../../modals/ModalRoot";
import { endpoint } from "../../../../commonjs/endpoints";
import { Spinner } from "../../../../commonjs/components";
import { AllProductsQuery } from "../../order_process/Queries";

import { Pagination } from "./Pagination";
import { OrderRow } from "./OrderRow";
import { TableHeader } from "./TableHeader";

export const ORDER_HISTORY_QUERY = "orders:api_order_list";
export const PAGINATION_ITEMS_PER_PAGE = 10;

const OrderHistoryWrapper = ({ children }) => {
  const { t } = useTranslation();
  return (
    <section className="orderHistory">
      <h1>{t("HISTORY OF YOUR ORDERS")}</h1>
      {children}
    </section>
  );
};
export const OrderHistory = () => {
  const { t } = useTranslation();
  const [selectedOrder, setSelectedOrder] = useState();

  const [itemsToShow, setItemsToShow] = useState([]);

  const [page, setPage] = useState(1);
  
  const queries = useQueries([
    {
      queryKey: [ORDER_HISTORY_QUERY, page],
      queryFn: () =>
        endpoint("orders:api_order_list", "GET", {
          query: {
            q_not_state: "CREATED",
            limit: PAGINATION_ITEMS_PER_PAGE,
            offset: PAGINATION_ITEMS_PER_PAGE * (page - 1),
          },
        }),
      keepPreviousData: true,
      refetchOnWindowFocus: true,
    },
    AllProductsQuery,
  ]);
  const isFetching = queries.find((query) => query.isFetching);
  const allQueriesLoaded = !queries.find((query) => query.isLoading);

  const orderHistory = allQueriesLoaded ? queries[0].data.results : [];
  const paginationData = allQueriesLoaded
    ? {
        count: queries[0].data.count,
        next: queries[0].data.next,
        previous: queries[0].data.previous,
      }
    : { count: 0, next: undefined, previous: undefined };

  const products = allQueriesLoaded ? queries[1].data : [];

  const productAvailable = (productPk) =>
    products.find((product) => product.pk === productPk);
  
  const hasBlockedProducts =
    products.find((product) => product.blocked === true) !== undefined;
  

  if (!allQueriesLoaded) {
    return (
      <OrderHistoryWrapper>
        <Spinner />
      </OrderHistoryWrapper>
    );
  }

  if (!orderHistory.length > 0) {
    return (
      <OrderHistoryWrapper>{t("No orders available.")}</OrderHistoryWrapper>
    );
  }

  return (
    <OrderHistoryWrapper>
      {selectedOrder && (
        <ModalRoot
          modalType={"ORDER_HISTORY_DETAILS"}
          modalProps={{
            order: selectedOrder,
            close: () => setSelectedOrder(undefined),
          }}
        />
      )}

      <div className="table vertical-spacing">
        <table>
          <TableHeader />
          {/* tbody */}
          <tbody>
            {itemsToShow.map((order) => (
              <OrderRow
                key={order.order_id}
                order={order}
                showDetails={(order) => setSelectedOrder(order)}
                productAvailable={productAvailable}
                hasBlockedProducts={hasBlockedProducts}
              />
            ))}
          </tbody>
        </table>
        <Pagination
          items={orderHistory}
          setItemsToShow={setItemsToShow}
          page={page}
          setPage={setPage}
          paginationData={paginationData}
          isFetching={isFetching}
        />
      </div>
    </OrderHistoryWrapper>
  );
};

import React from "react";

export const KeyValueEntry = ({ label, value }) => {
  return (
    <tr>
      <td className="key">{label}</td>
      <td className="value">{value}</td>
    </tr>
  );
};

export const KeyValueDisplay = ({ keyValueMap }) => {
  return (
    <table className="keyValueList">
      <tbody>
        {keyValueMap.map((entry) => {
          return (
            <KeyValueEntry
              label={entry[0]}
              value={entry[1]}
              key={`${entry[0]}-dsply`}
            />
          );
        })}
      </tbody>
    </table>
  );
};

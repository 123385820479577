import { RenderPasswordField } from "../../commonjs/components";

import { useMutation } from "react-query";
import { Form, Field } from "react-final-form";
import { endpoint } from "../../commonjs/endpoints";
import { toast, Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";

import { isInvalidPassword } from "./utils";
import { useAuthentication } from "../../commonjs/AuthenticationContext";
import { RenderDelayed } from "../../commonjs/RenderDelayed";
import { Fragment, useState } from "react";
import { named_urls } from "../../routing/known_urls";

// 2023-11-29 stf login still runs over redux, so this is our last connection to rdx 
import { store as reduxStore } from "../..";
import { accountsLoginSuccess } from "./duck"

const PasswordResetConfirmForm = ({ code, account_id }) => {
  const { isLoggedIn } = useAuthentication();
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();

  const validatePassword = (values) => {
    const errors = {};
    // all values are required
    if (!values.new_password) errors.new_password = t("Required");
    if (!values.new_password_repeated)
      errors.new_password_repeated = t("Required");
    // a new password must be valid
    if (values.new_password) {
      const errorText = isInvalidPassword(values.new_password);
      if (errorText) errors.new_password = errorText;
    }
    // new password and it's repetition must be equal
    if (values.new_password && values.new_password_repeated) {
      if (values.new_password_repeated !== values.new_password) {
        errors.new_password_repeated = t("Passwords do not match");
      }
    }
    return errors;
  };

  const errorMessages = {
    "You must enter a new password": t("You must enter a new password"),
    "Invalid confirmation code.": t("Invalid confirmation code."),
  };

  const {
    mutate: resetPassword,
    isLoading,
    isSuccess,
    isError,
  } = useMutation(({ new_password, new_password_repeated, code, account_id }) =>
    endpoint("accounts:api_confirm_password", "POST", {
      body: { new_password, new_password_repeated, account_id, token: code },
    }).then((result) => {
      if (result?.errors?.non_field_errors) {
        setErrorMessage(errorMessages[result.errors.non_field_errors]);
        return Promise.reject();
      } else {
        if (!isLoggedIn) {
          reduxStore.dispatch(
            accountsLoginSuccess(result.permissions, result.params)
          );
        }

        setPasswordChanged(true);
        notify(t("Password has been changed. Redirecting you to the homepage."));
        return Promise.resolve();
      }
    })
  );

  /**
    
  */

  const notify = (msg) => toast.success(msg, { duration: 5000 });
  return (
    <Fragment>
      {isSuccess && (
        <RenderDelayed delay={5500}>
          <Navigate to={named_urls["Welcome"]} />
        </RenderDelayed>
      )}
      <Form
        onSubmit={({ new_password, new_password_repeated }) =>
          resetPassword({
            new_password,
            new_password_repeated,
            code,
            account_id,
          })
        }
        validate={validatePassword}
      >
        {({ handleSubmit, error, pristine, submitting }) => {
          const enterPasswordDisabled =
            submitting || isLoading || passwordChanged;
          const sendDisabled =
            pristine || submitting || isLoading || passwordChanged;

          return (
            <form onSubmit={handleSubmit}>
              <Toaster />
              <fieldset disabled={enterPasswordDisabled}>
                <div className="grid">
                  <div className="grid__item xs-12 md-8">
                    <Field
                      className="vertical-spacing-half"
                      name="new_password"
                      component={RenderPasswordField}
                      type="password"
                      disabled={enterPasswordDisabled}
                      placeholder={t("New password")}
                    />
                  </div>
                  <div className="grid__item xs-12 md-8">
                    <Field
                      className="vertical-spacing-half"
                      name="new_password_repeated"
                      component={RenderPasswordField}
                      type="password"
                      disabled={enterPasswordDisabled}
                      placeholder={t("Repeat new password")}
                    />
                  </div>
                  {isError && (
                    <span className="grid__item xs-12 md-8 vertical-spacing form-error">
                      {errorMessage}
                    </span>
                  )}
                  <div className="grid__item xs-12 md-8 vertical-spacing">
                    <button
                      type="submit"
                      disabled={sendDisabled}
                      className={`button button-primary ${sendDisabled ? "button-primary--disabled" : "clickable"
                        }`}
                    >
                      {t("SET NEW PASSWORD")}
                    </button>
                  </div>
                </div>
              </fieldset>
            </form>
          );
        }}
      </Form>
    </Fragment>
  );
};

export const PasswordResetConfirm = () => {
  const { t } = useTranslation();
  const { isLoggedIn } = useAuthentication();

  const { code, account_id } = useParams();

  return (
    <section className="password-reset-confirm">
      {!isLoggedIn && <div className="bg-login" />}
      <h1>{t("RESET YOUR PASSWORD")}</h1>

      <div className="grid">
        <div className="grid__item xs-12">
          {account_id ? (
            <h2>
              {t("Enter a new password for account")} {account_id}
            </h2>
          ) : (
            <h2>{t("Enter a new password")}</h2>
          )}
          <PasswordResetConfirmForm code={code} account_id={account_id} />
        </div>
      </div>
    </section>
  );
};

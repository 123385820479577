import { useTranslation } from "react-i18next";

import {
  TableHead,
  Cell,
  SelectCell,
  Number,
} from "../../../commonjs/components/DataTable";
import { formatDescription } from "../../orders/order_process/StandardProducts/StandardProducts";
import { WindingDirection } from "../../orders/order_process/StandardProducts/SpecialColumns";
import { formatToInteger } from "../../../commonjs/langutils";
import { useState } from "react";

export const Rolls = ({ addToDistribution, availableRolls, enabled }) => {
  const { t, language } = useTranslation();
  const [selectedRolls, setSelectedRolls] = useState([]);
  const addRoll = (roll) => setSelectedRolls(selectedRolls.concat(roll));
  const removeRoll = (rollToRemove) =>
    setSelectedRolls(
      selectedRolls.filter((roll) => roll.pk !== rollToRemove.pk)
    );

  // prettier-ignore
  const columns = [
    { name: "select-roll",          displayName: "",                        title: "" },
    { name: "roll-number",          displayName: t("Roll Number"),          title: "" },
    { name: "range",                displayName: t("Range"),                title: "" },
    { name: "reference",            displayName: t("Reference"),            title: "" },
    { name: "product-description",  displayName: t("Product Description"),  title: t("Dimensions (width x height) in mm, Winding Direction")  },
    { name: "winding-direction",    displayName: "",                        title: t("Winding Direction") },
    { name: "no-of-labels",         displayName: t("No. of Labels"),        title: "" }
  ]

  return (
    <section id="roll-list">
      <h1>{t("Rolls available for distribution")}</h1>
      <p>{t("Select rolls and distribute them to the selected Recipient")}</p>
      <br />
      <table>
        <TableHead columns={columns} />
        <tbody>
          {availableRolls.length > 0 ? (
            availableRolls.map((roll) => {
              const product = roll.product;
              return (
                <tr key={roll.pk}>
                  <SelectCell
                    onChange={(evt) =>
                      evt.target.checked ? addRoll(roll) : removeRoll(roll)
                    }
                    label={roll.pk}
                    disabled={!enabled}
                    disabledWarning={t(
                      "Please select a recipient before distributing Rolls"
                    )}
                  />
                  <Cell value={roll.spool_no} />
                  <Cell value={product.product_group.name} />
                  <Cell value={product.customer_reference} />
                  <Cell value={formatDescription(product)} />
                  <WindingDirection direction={product.description} />
                  <Number
                    number={formatToInteger(product.labels_per_unit, language)}
                  />
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={columns.length}>
                <h1 className={"highlight"} style={{ textAlign: "center" }}>
                  {t("All available Rolls distributed")}
                </h1>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <button
        type="submit"
        disabled={!selectedRolls.length > 0}
        className={`button button-primary ${
          selectedRolls.length > 0 ? "clickable" : "button-primary--disabled"
        }`}
        onClick={() => {
          addToDistribution(selectedRolls);
          setSelectedRolls([]);
        }}
      >
        {t("ADD DISTRIBUTION")}
      </button>

      <br />
    </section>
  );
};
import React from "react";
import { connect } from "react-redux";

import { Route, Navigate } from "react-router-dom";

import { notificationError } from "./commonjs/notifications";
import { named_urls } from "./routing/known_urls";
import { check_rights } from "./commonjs/util";

/* data fetching on mount
HIGHER ORDER COMPONENT
*/
export function withOnMount(Component) {
  class C extends React.Component {
    componentDidMount() {
      if (typeof this.props.onMount !== "function")
        return new Error("onMount must be defined");
      this.props.onMount();
    }
    render() {
      return <Component {...this.props} />;
    }
  }

  C.displayName =
    "withOnMount(" + (Component.displayName || Component.name) + ")";

  return C;
}

/* react router v3 style nested routes

HIGHER ORDER _ROUTE_ COMPONENT

wrap <Route> and use this everywhere instead
this will give the top level component the nested routes as children
*/

export function RouteNest(props) {
  return (
    <Route
      exact={props.exact}
      path={props.path}
      location={props.location}
      computedMatch={props.computedMatch}
      render={(p) => <props.component {...p} children={props.children} />}
    />
  );
}

/* nested routes with authentication/authorisation check

HIGHER ORDER _ROUTE_ COMPONENT

wrap <Route> and use this everywhere instead
this will give the top level component the nested routes as children
AND check if the user is authenticated
*/

// indicates a reload (LAST_PATH === null), or a transition (LAST_PATH !== null)
let LAST_PATH = null;

const forbiddenManualRoutes = [
  named_urls["orders:summary:payment"],
  named_urls["orders:summary:adressAndDelivery"],
  named_urls["orders:summary:thankYou"],
];

function _RouteNestAuth(props, f) {
  function render(routeProps) {
    const currentPath = routeProps.location.pathname;
    if (LAST_PATH === null) {
      // no last path - it is a reload; check if the url can be visited manually
      if (forbiddenManualRoutes.find((x) => x === currentPath)) {
        return (
          <Navigate
            to={{
              pathname: named_urls["Welcome"],
              state: { from: routeProps.location },
            }}
          />
        );
      }
      // yes, can be visited "manually", store it as first url for redirections after a login
      props.dispatch({
        type: "FIRST_URL",
        payload: routeProps.location.pathname,
      });
    }

    // work around for IE11 / google recaptcha version 20171109 - reload register page on react-router switches
    // google recaptcha version 20171109 throws an error in IE11 (not in Chrome or Firefox)
    if (
      LAST_PATH !== null &&
      LAST_PATH !== named_urls["accounts:register"] &&
      routeProps.location.pathname === named_urls["accounts:register"]
    ) {
      window.location = routeProps.location.pathname;
    }

    LAST_PATH = routeProps.location.pathname;

    // check access rights
    const allowed = check_rights(props.rights, props.hasRights);
    if (allowed) {
      return <props.component {...routeProps} children={props.children} />;
    }

    // no access rights: redirect to login
    return (
      <Navigate
        to={{
          pathname: named_urls["accounts:login"],
          state: { from: routeProps.location },
        }}
      />
    );
  }

  return (
    <Route
      exact={props.exact}
      path={props.path}
      location={props.location}
      computedMatch={props.computedMatch}
      render={render}
    />
  );
}

const mapStateToProps = (state) => ({
  hasAuth: state.accounts.authenticated,
  hasRights: state.accounts.rights,
});

const mapDispatchToProps = (dispatch) => ({
  notifError(e) {
    dispatch(notificationError(e));
  },
  dispatch,
});

export const RouteNestAuth = connect(
  mapStateToProps,
  mapDispatchToProps
)(_RouteNestAuth);

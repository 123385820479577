import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { reducer as notifReducer } from "react-notification-system-redux";
import { routerReducer } from "react-router-redux";

import { settingsReducer } from "./commonjs/duck";
import { accountsReducer } from "./licensing/accounts/";

export const rootReducer = combineReducers({
  router: routerReducer,
  notifications: notifReducer,
  accounts: accountsReducer,
  form: formReducer,
  settings: settingsReducer,
});

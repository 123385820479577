import React from "react";

import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";

import "react-virtualized/styles.css";
import {
  formatToInteger,
  formatToShortDate,
} from "../../../../commonjs/langutils";

import { Icon } from "../../../../commonjs/icons";

import ProductGroupRights from "../../../../commonjs/components/ProductGroupRights";
import { translateBlockingReason } from "./Blocked";

const nrStyle = {
  textAlign: "right",
  width: "10%",
  paddingRight: "1em",
};

const PRODUCT_GROUP_NAME_ARRAYS = [
  ["Original Part", "Pièce d'Origine"],
  ["Accessories", "Accessoires"],
  ["Eurorepar", "Eurorepar"],
  ["MOPAR", "MOPAR"],
  ["SUSTAINera", "SUSTAINera"],
];

const EventColumn = ({ productGroupName, event }) => {
  const { language } = useTranslation();
  return (
    <td style={nrStyle}>
      {productGroupName.includes(event.product_group)
        ? formatToInteger(-event.amount, language)
        : ""}
    </td>
  );
};

const EventsTable = ({ events }) => {
  const { t } = useTranslation();

  const event_names = {
    WITHDRAWAL: t("Label Orders"),
    CONTRACT: t("Part Orders"),
    CANCEL_WITHDRAWAL: t("Order Cancelled"),
  };

  return (
    <table>
      <thead>
        <tr>
          <th style={{ width: "30%", paddingRight: "1em", textAlign: "left" }}>
            {t("Date")}
          </th>
          <th style={{ width: "15%", paddingRight: "1em", textAlign: "left" }}>
            {t("Event Type")}
          </th>
          {PRODUCT_GROUP_NAME_ARRAYS.map((productGroupName) => (
            <th style={nrStyle} key={productGroupName}>
              {t(productGroupName[0])}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {!(events && events.length > 0) && (
          <tr>
            <td colSpan="6">
              <h3>{t("No Volume Control Events recorded for this company")}</h3>
            </td>
          </tr>
        )}
        {events.map((event) => (
          <tr key={event.id}>
            <td style={{ width: "30%", paddingRight: "1em" }}>
              {formatToShortDate(event.created_at)}
            </td>
            <td style={{ width: "30%", paddingRight: "1em" }}>
              {event_names[event.type]}
            </td>
            {PRODUCT_GROUP_NAME_ARRAYS.map((productGroupName) => (
              <EventColumn productGroupName={productGroupName} event={event} />
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const QuotaBadge = ({ quota }) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  // 2022-03-22: new PSA team insists on "Original Parts"; previous team used "Original Part".
  // Therefore, rewriting it - and prepare for additional rewrites.
  const quotaGroupRewrite = {
    "Original Part": "Original Parts",
    "Pièce d'Origine": "Pièces d'Origine",
  };

  return (
    <div className="quota_group">
      <h3>{quotaGroupRewrite[quota.group] || quota.group}</h3>
      <div className="contract-volume badge">
        <Icon icon={"arrow-right"} size="2x" className="badge__icon" />
        <div className="datum">
          <span className="amount">
            {formatToInteger(quota.contracts, language)}
          </span>
          <span className="legend">{t("Quantity of parts")}</span>
        </div>
      </div>
      <div className="order-volume badge">
        <Icon icon={"arrow-right"} size="2x" className="badge__icon" />
        <div className="datum">
          <span className="amount">
            {formatToInteger(quota.orders, language)}
          </span>
          <span className="legend">{t("Quantity of labels")}</span>
        </div>
      </div>
      <div className="balance badge">
        {quota.quota <= 0 ? (
          // if your quota is <= 0, you can still order labels - display in green
          // but actually the logic for PSA is reversed: If you are under your quota,
          // you have not fullfilled your obligations: display this in green
          <Icon icon={"minus"} size="2x" className="badge__icon red" />
        ) : (
          // if your quota is > 0, you ordered too many labels - display in red
          // it is actually the other way around: You have over-fullfilled your obligations,
          // which by PSA-definition is a good thing - so we display it in green
          <Icon icon={"plus"} size="2x" className="badge__icon green" />
        )}
        <div className="datum">
          <span className={`amount ${quota.quota <= 0 ? "red" : "gree"}`}>
            {formatToInteger(Math.abs(quota.quota), language)}
          </span>
          <span className="legend">{t("Balance")}</span>
        </div>
      </div>
      <br />
    </div>
  );
};
const Quotas = ({ quotas }) => {
  return (
    <div className="quotas">
      {quotas.map((quota) => (
        <QuotaBadge quota={quota} key={quota.group} />
      ))}
    </div>
  );
};

const Contact = ({ contact }) => {
  return (
    <React.Fragment>
      <b>{contact.label}:</b>
      {contact.email ? (
        <React.Fragment>
          &nbsp;{contact.name},&nbsp;
          <a
            href={"mailto:" + contact.email}
            style={{ textDecoration: "underline", display: "inline" }}
          >
            {contact.email}
          </a>
          ,&nbsp;
          {contact.phone ? contact.phone : "-"}
        </React.Fragment>
      ) : (
        <React.Fragment>&nbsp;&mdash;</React.Fragment>
      )}
    </React.Fragment>
  );
};

const Contacts = ({ contacts }) => {
  const { t } = useTranslation();
  const category_labels = [
    { category: "COMMERCIAL", label: t("Commercial") },
    { category: "PROVISIONING", label: t("Provisioning") },
    { category: "TS_DELIVERY", label: t("scribos Delivery") },
    { category: "TS_INVOICE", label: t("scribos Invoice") },
  ];

  const filledUpContacts = category_labels.map((category_label) => ({
    ...contacts.find((contact) => contact.category === category_label.category),
    label: category_label.label,
  }));

  return (
    <div>
      <h3>{t("Contacts")}</h3>
      <ul>
        {filledUpContacts.map((contact) => (
          <li style={{ lineHeight: "1.5rem" }} key={contact.pk}>
            <Contact contact={contact} />
          </li>
        ))}
      </ul>
    </div>
  );
};

const User = ({ user }) => {
  return (
    <span>
      {user.first_name}&nbsp;{user.last_name},&nbsp;
      <a
        href={"mailto:" + user.email}
        style={{ textDecoration: "underline", display: "inline" }}
      >
        {user.email}
      </a>
      ,&nbsp;{user.phone}
    </span>
  );
};

const Users = ({ users }) => {
  const { t } = useTranslation();
  return (
    <div>
      <h3>{t("Users")}</h3>
      <ul>
        {users.map((user) => (
          <li style={{ lineHeight: "1.5rem" }} key={user.pk}>
            <User user={user} t={t} key={user.pk} />
          </li>
        ))}
      </ul>
    </div>
  );
};

const Address = ({ address }) => {
  const { t } = useTranslation();
  const category_names = {
    BILLING: t("Billing"),
    DELIVERY: t("Delivery"),
    DISTRIBUTION: t("Distribution"),
  };
  return (
    <span>
      <b>{category_names[address.category]}</b>:&nbsp;{address.street_address}
      ,&nbsp;
      {address.postal_code}&nbsp;{address.city} ({address.country})
    </span>
  );
};
const Addresses = ({ addresses }) => {
  // sort according to category
  const sortedAddresses = [...addresses].sort((a, b) => {
    if (a.category < b.category) return -1;
    if (a.category > b.category) return 1;
    return 0;
  });
  const { t } = useTranslation();
  return (
    <div>
      <h3>{t("Addresses")}</h3>
      <ul>
        {/* only show primary addresses */}
        {sortedAddresses
          .filter((address) => address.primary)
          .map((address) => (
            <li style={{ lineHeight: "1.5rem" }} key={address.pk}>
              <Address address={address} t={t} />
            </li>
          ))}
      </ul>
    </div>
  );
};

const ReferenceData = ({ reference }) => {
  const { t } = useTranslation();
  const parsedData = JSON.parse(reference);
  return (
    <div>
      <h2>{t("Reference Data")}</h2>
      <ul>
        <li style={{ lineHeight: "1.5rem" }}>
          {t("UV/UC Ratio")}:&nbsp; {parsedData["UV_UC_RELATION"]}
        </li>
      </ul>
    </div>
  );
};

const BlockingReasons = ({ blocked }) => {
  const { t } = useTranslation();
  return (
    <div>
      <h2>{t("Blocked")}</h2>
      <ul>
        {blocked.split(",").map((reason) => (
          <li>{translateBlockingReason(t, reason, true)}</li>
        ))}
      </ul>
    </div>
  );
};

export const CompanyDetail = ({ company }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <h2>{t("Company Details")}</h2>
      <br />
      <h3>
        {company.name} ({company.cofor6} / {company.cofor10})
      </h3>
      <hr className="company-detail-separator" />
      <Users users={company.users} />
      <hr className="company-detail-separator" />
      <Contacts contacts={company.contacts} />
      <hr className="company-detail-separator" />
      <Addresses addresses={company.addresses} />
      <hr className="company-detail-separator" />
      <ReferenceData reference={company.reference} />
      <hr className="company-detail-separator" />
      {company.blocked && (
        <>
          <BlockingReasons blocked={company.blocked} />
          <hr className="company-detail-separator" />
        </>
      )}
      <ProductGroupRights
        cofor6={company.cofor6}
        cofor10={company.cofor10}
        productGroupRights={company.product_group_rights}
      />
      <hr className="company-detail-separator" />
      <Quotas quotas={company.quotas} />
      <br />
      <hr className="company-detail-separator" />
      <EventsTable events={company.licensing_events} />
      <br />
    </React.Fragment>
  );
};

export default CompanyDetail;

import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";

import { useTranslation } from "react-i18next";
import NProgress from "nprogress";
import Select from "react-select";

import { useQuery, useMutation, STATUS } from "../../commonjs/fetchHooks";
import { useNotifications } from "../../commonjs/NotificationContext";

import { Icon } from "../../commonjs/icons";
import { Spinner } from "../../commonjs/components/";
import { IfRight } from "../../commonjs/util";

import { named_urls } from "../../routing/known_urls";
import { localSettings } from "../../settings";

const GET_DOCUMENTS = () => ({
  endpoint: "information:api_document_list",
});

const UPLOAD_DOCUMENT = (data) => ({
  method: "POST",
  endpoint: "information:api_document_list",
  data: { body: { ...data } },
});

const _Information = ({ hasRights }) => {
  const { t, i18n } = useTranslation();
  const { addNotification } = useNotifications();

  const fileInputEl = useRef(null);

  const { data, status, refetch } = useQuery(GET_DOCUMENTS);
  const [mutate, { status: statusUpload }] = useMutation(UPLOAD_DOCUMENT);

  const [selectedValue, setSelectedValue] = useState(0);
  const [file, setFile] = useState(undefined);

  // clears file input
  const clearFile = () => {
    if (fileInputEl.current) {
      fileInputEl.current.value = "";
      setFile(undefined);
    }
  };

  // uploads file and refetches data
  const uploadFile = () => {
    NProgress.start();
    const filereader = new FileReader();
    filereader.onload = async function (data) {
      const result = await mutate({
        category_id: selectedValue,
        language: language,
        filename: file.name,
        filedata: data.target.result,
      });
      if (result.error) {
        addNotification({
          title: t("File upload"),
          message: t("The file upload failed."),
          level: "error",
        });
      } else {
        addNotification({
          title: t("File upload"),
          message: t("The file was uploaded successfully."),
          level: "success",
        });
      }
      clearFile();
      NProgress.done();
      refetch();
    };
    filereader.readAsDataURL(file);
  };

  const categoryDisabled = !selectedValue;
  const fileUploadDisabled = !file || statusUpload === STATUS.PENDING;

  if (status === STATUS.IDLE || status === STATUS.PENDING) {
    return <Spinner />;
  }

  const language = i18n.language;
  const entries = data && data[language] ? data[language] : null;

  if (!entries || entries.length === 0) {
    return (
      <section className="information">
        <h1>{t("Documentation")}</h1>
        {t("No documents available.")}
      </section>
    );
  }

  // sort all document entries by the sort index
  const sortedEntries = entries.slice().sort((a, b) => {
    return a.sort_index - b.sort_index;
  });

  // filter for document entries with a document link
  const categoriesWithDocuments = sortedEntries.filter((e) => e.document);

  // create the options for the category select box
  const options = sortedEntries.map((e) => ({
    value: e.category_id,
    label: e.description,
  }));

  // (re-)create the selected option to handle language changes
  const selectedOption = selectedValue
    ? options.find((e) => e.value === selectedValue)
    : null;

  return (
    <section className="information">
      <h1>{t("Documentation")}</h1>

      <div className="grid">
        <div className="grid__item xs-12 md-8">
          {/* document list (with download links) for all users */}
          <div className="information-view">
            {!categoriesWithDocuments.length && t("No documents available.")}
            {categoriesWithDocuments.map((d, id) => {
              return (
                <div key={id}>
                  <a href={d.document} target="_blank" rel="noreferrer">
                    <div className="document-entry">{d.description}</div>
                  </a>
                </div>
              );
            })}
          </div>

          <IfRight
            needed={["information.add_documentversion"]}
            rights={hasRights}
          >
            {/* document editor for expert users only */}
            <div className="information-editor">
              <h2 className="information-editor__header">
                {t("Edit documentation")}
              </h2>
              <Select
                name="select-category"
                clearable={true}
                value={selectedOption}
                placeholder={t("Select category...")}
                searchable={false}
                onChange={(option) => setSelectedValue(option.value)}
                options={options}
              />
              <div className="file-input">
                <div className="file-input__input">
                  <input
                    className={`button button-primary ${
                      !selectedValue ? "button-primary--disabled" : ""
                    }`}
                    type="button"
                    value={t("SELECT FILE")}
                    disabled={categoryDisabled}
                  />
                  <input
                    ref={fileInputEl}
                    type="file"
                    onChange={(e) => setFile(e.currentTarget.files[0])}
                    name="upload"
                    disabled={categoryDisabled}
                  />
                </div>
                <div className="file-input__filename">
                  <span>{file ? file.name : t("No file selected.")}</span>
                </div>
                <div
                  className={`file-input__upload ${
                    fileUploadDisabled && "file-input__upload--disabled"
                  }`}
                  onClick={() => {
                    !fileUploadDisabled && uploadFile();
                  }}
                >
                  <Icon icon={"file-upload"} size="3x" />
                </div>
              </div>
            </div>
          </IfRight>
        </div>

        <div className="grid__item xs-12 md-4">
          {!localSettings.informationHideFaq && (
            <div className="grid">
              <Link
                to={named_urls["information:faq"]}
                className="grid__item xs-12"
              >
                <div className="o-box vert-sm c-box--primary">{t("FAQS")}</div>
              </Link>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

// we still need the user permissions from redux
const mapStateToProps = (state) => ({
  hasRights: state.accounts.rights,
});

export const Information = compose(connect(mapStateToProps))(_Information);

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

/**
 *
 * these are compiled into the bundle, they therefore life together with the code in the src-dir
 *
 */
import E1 from "./psaIcons/winding-direction_e1.png";
import E2 from "./psaIcons/winding-direction_e2.png";
import E3 from "./psaIcons/winding-direction_e3.png";
import E4 from "./psaIcons/winding-direction_e4.png";

import { faHome } from "@fortawesome/pro-solid-svg-icons/faHome";
import { faShoppingCart } from "@fortawesome/pro-solid-svg-icons/faShoppingCart";
import { faChartBar } from "@fortawesome/pro-solid-svg-icons/faChartBar";
import { faUser } from "@fortawesome/pro-solid-svg-icons/faUser";
import { faFileAlt } from "@fortawesome/pro-solid-svg-icons/faFileAlt";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons/faEnvelope";
import { faAddressCard } from "@fortawesome/pro-solid-svg-icons/faAddressCard";
import { faCodeBranch } from "@fortawesome/pro-solid-svg-icons/faCodeBranch";
import { faGlobe } from "@fortawesome/pro-solid-svg-icons/faGlobe";
import { faSquare } from "@fortawesome/pro-solid-svg-icons/faSquare";
import { faShieldCheck } from "@fortawesome/pro-solid-svg-icons/faShieldCheck";
import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons/faTrashAlt";
import { faShareAlt } from "@fortawesome/pro-solid-svg-icons/faShareAlt";
import { faEye } from "@fortawesome/pro-solid-svg-icons/faEye";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons/faSpinner";
import { faArrowSquareUp } from "@fortawesome/pro-solid-svg-icons/faArrowSquareUp";
import { faArrowSquareRight } from "@fortawesome/pro-solid-svg-icons/faArrowSquareRight";
import { faArrowSquareLeft } from "@fortawesome/pro-solid-svg-icons/faArrowSquareLeft";
import { faArrowSquareDown } from "@fortawesome/pro-solid-svg-icons/faArrowSquareDown";
import { faSignOut } from "@fortawesome/pro-solid-svg-icons/faSignOut";
import { faCommentAltPlus } from "@fortawesome/pro-solid-svg-icons/faCommentAltPlus";
import { faCommentAlt } from "@fortawesome/pro-solid-svg-icons/faCommentAlt";
import { faLongArrowAltRight } from "@fortawesome/pro-solid-svg-icons/faLongArrowAltRight";
import { faSearch } from "@fortawesome/pro-solid-svg-icons/faSearch";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import { faCalendarAlt } from "@fortawesome/pro-solid-svg-icons/faCalendarAlt";
import { faPlus } from "@fortawesome/pro-solid-svg-icons/faPlus";
import { faMinus } from "@fortawesome/pro-solid-svg-icons/faMinus";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { faUndo } from "@fortawesome/pro-solid-svg-icons/faUndo";

import { faArrowRight } from "@fortawesome/pro-solid-svg-icons/faArrowRight";
import { faFilter } from "@fortawesome/pro-light-svg-icons/faFilter";
import { faSortDown } from "@fortawesome/pro-light-svg-icons/faSortDown";
import { faSortUp } from "@fortawesome/pro-light-svg-icons/faSortUp";

import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons/faTimesCircle";
import { faFileUpload } from "@fortawesome/pro-solid-svg-icons/faFileUpload";
import { faWindowClose } from "@fortawesome/pro-solid-svg-icons/faWindowClose";

library.add(
  faWindowClose,
  faHome,
  faShoppingCart,
  faChartBar,
  faUser,
  faFileAlt,
  faEnvelope,
  faAddressCard,
  faCodeBranch,
  faFilter,
  faGlobe,
  faShieldCheck,
  faSquare,
  faCheck,
  faTrashAlt,
  faShareAlt,
  faEye,
  faSpinner,
  faArrowSquareUp,
  faArrowSquareRight,
  faArrowSquareLeft,
  faArrowSquareDown,
  faSignOut,
  faCommentAltPlus,
  faCommentAlt,
  faLongArrowAltRight,
  faSearch,
  faCalendarAlt,
  faPlus,
  faMinus,
  faArrowRight,
  faTimesCircle,
  faFileUpload,
  faSortUp,
  faSortDown,
  faExclamationTriangle,
  faUndo
);

export const Icon = FontAwesomeIcon;

export const WindingDirections = {
  E1: E1,
  E2: E2,
  E3: E3,
  E4: E4,
};

import { Routes, Route } from "react-router-dom";
import { AuthorizedContent } from "../commonjs/AuthorizedContent";

import {
  OrderStatistics,
  OrderStatisticsLanding,
  OrderHistory,
  OrderOverview,
  Registrations,
  CompanyDetail,
  IdAnalysis,
  SpoolAnalysis,
  SupplierAnalysis,
  Authentications,
  VolumeStatus,
} from "../licensing/orders";
export const StatisticsRoutes = () => (
  <Routes>
    <Route
      path="landing"
      element={
        <AuthorizedContent requires={["orders.view_order"]}>
          <OrderStatisticsLanding />
        </AuthorizedContent>
      }
    />
    <Route
      path="orderStatistics"
      element={
        <AuthorizedContent requires={["orders.view_order"]}>
          <OrderStatistics />
        </AuthorizedContent>
      }
    />
    <Route
      exact
      path="history"
      element={
        <AuthorizedContent requires={["orders.view_order"]}>
          <OrderHistory />
        </AuthorizedContent>
      }
      rights={["orders.*"]}
    />

    <Route
      path="registrations"
      element={
        <AuthorizedContent requires={["statistics.*"]}>
          <Registrations />
        </AuthorizedContent>
      }
    />
    <Route
      exact
      path="order_overview"
      element={
        <AuthorizedContent requires={["orders.*"]}>
          <OrderOverview />
        </AuthorizedContent>
      }
    />
    <Route
      exact
      path="company_detail"
      element={
        <AuthorizedContent requires={["statistics.*"]}>
          <CompanyDetail />
        </AuthorizedContent>
      }
    />
    <Route
      exact
      path="id_analysis"
      element={
        <AuthorizedContent requires={["statistics.*"]}>
          <IdAnalysis />
        </AuthorizedContent>
      }
    />
    <Route
      exact
      path="spool_analysis"
      element={
        <AuthorizedContent requires={["statistics.*"]}>
          <SpoolAnalysis />
        </AuthorizedContent>
      }
    />
    <Route
      exact
      path="supplier_analysis"
      element={
        <AuthorizedContent requires={["statistics.*"]}>
          <SupplierAnalysis />
        </AuthorizedContent>
      }
    />
    <Route
      exact
      path="authentications"
      element={
        <AuthorizedContent requires={["statistics.*"]}>
          <Authentications />
        </AuthorizedContent>
      }
    />
    <Route
      exact
      path="volume_status"
      element={
        <Authentications requires={["orders.*"]}>
          <VolumeStatus />
        </Authentications>
      }
    />
  </Routes>
);